import { useLayoutEffect, useState } from 'react'
import { Filter } from '../../../../../../backend/types'
import { QueryMode } from '../../../../../../library/query/types'
import { LogEntry } from '../../../../../../state/types'
import { Header } from '../../Logs'
import LogContainer from './LogContainer/LogContainer'

interface LogScrollContainerProps {
  logs: LogEntry[]
  newLogs: string[]

  term: string

  queryMode: QueryMode
  previousQueryMode: QueryMode

  atStart: boolean
  atEnd: boolean

  headers: Header[]
  setHeaders: (headers: Header[]) => void
  toggleHeader: (header: string) => void

  filters: Filter[]
  setFilters: (filters: Filter[]) => void

  logContainerRef: React.RefObject<HTMLDivElement | null>
}

const LogScrollContainer = ({
  logs,
  newLogs,
  term,
  queryMode,
  previousQueryMode,
  atStart,
  atEnd,
  headers,
  setHeaders,
  toggleHeader,
  filters,
  setFilters,
  logContainerRef,
}: LogScrollContainerProps) => {
  const [savedHeight, setSavedHeight] = useState<number>(0)

  useLayoutEffect(() => {
    const container = logContainerRef.current
    if (!container) return

    if (queryMode === 'none' && previousQueryMode === 'loading_more_up') {
      const heightDiff = container.scrollHeight - savedHeight
      if (heightDiff > 0) container.scrollTop += heightDiff
    }

    if (queryMode === 'none') {
      setSavedHeight(container.scrollHeight)
    }
  }, [logContainerRef, queryMode, previousQueryMode, savedHeight])

  return (
    <div
      id="log-scroll-container"
      ref={logContainerRef}
      className="h-full flex flex-col overflow-scroll z-[0] will-change-scroll"
    >
      <LogContainer
        logs={logs}
        newLogs={newLogs}
        term={term}
        queryMode={queryMode}
        atStart={atStart}
        atEnd={atEnd}
        headers={headers}
        setHeaders={setHeaders}
        toggleHeader={toggleHeader}
        filters={filters}
        setFilters={setFilters}
      />
    </div>
  )
}

export default LogScrollContainer
