import { useAtomValue } from 'jotai'
import useBackend from '../services/useBackend'
import { instanceIdAtom } from '../../state/state'
import useLoadOrganizationInfo from '../data/load/useLoadOrganizationInfo'
import useToast from '../utils/useToast'
import useLoadOrganizations from '../data/load/useLoadOrganizations'
import { LogsTTL } from '../../state/types'
import { captureAxiosError } from '../../utils/obeservability'

const useUpdateOrganizationLogsTTL = () => {
  const backend = useBackend()
  const toast = useToast()

  const { organizationId } = useAtomValue(instanceIdAtom)
  const loadOrganizationInfo = useLoadOrganizationInfo()
  const loadOrganizations = useLoadOrganizations()

  return async (logs_ttl: LogsTTL) => {
    if (!organizationId) return
    try {
      await backend.updateOrganization(organizationId, { logs_ttl })
      await loadOrganizationInfo()
      await loadOrganizations()
      toast(`Logs retention updated`, 'success')
    } catch (e) {
      captureAxiosError(e)
      toast('Logs retention update failed', 'error')
    }
  }
}

export default useUpdateOrganizationLogsTTL
