import { Search } from 'lucide-react'
import TimeButton from './TimeButton/TimeButton'
import LiveButton from './LiveButton/LiveButton'
import FilterPill from './FilterPill/FilterPill'
import { Filter } from '../../../../../../backend/types'
import { useEffect, useRef, useState } from 'react'
import { TimeRange } from '../../../../../../utils/time'
import FilterButton from './FilterButton/FilterButton'
import RefreshButton from './RefreshButton/RefreshButton'
import QueryButton from './QueryButton/QueryButton'
import { QueryMode } from '../../../../../../library/query/types'
import LoadingDots from '../../../../../library/LoadingDots/LoadingDots'
import { formatNumber } from '../../../../../../utils/properties'
import { useAtomValue } from 'jotai'
import { userDataSearchesAtom } from '../../../../../../state/state'
import RecentSearches from './RecentSearches/RecentSearches'
import SettingsButton from './SettingsButton/SettingsButton'

interface SearchbarProps {
  term: string
  setTerm: (term: string) => void

  runSearch: () => Promise<void>

  live: boolean
  setLive: (live: boolean) => void

  timeRange: TimeRange
  setTimeRange: (range: TimeRange) => void

  filters: Filter[]
  setFilters: (filters: Filter[]) => void

  visibleProperties: string[]
  hiddenProperties: string[]

  queryMode: QueryMode
  total: number
}

const Searchbar = ({
  term,
  setTerm,
  runSearch,
  live,
  setLive,
  timeRange,
  setTimeRange,
  filters,
  setFilters,
  visibleProperties,
  hiddenProperties,
  queryMode,
  total,
}: SearchbarProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [localTerm, setLocalTerm] = useState(term)
  const [justFocused, setJustFocused] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const selectedRecentRef = useRef(false)

  const searches = useAtomValue(userDataSearchesAtom)
  const filteredSearches = getFilteredSearches(localTerm, searches)

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!inputRef.current) return
    if (
      e.key === '/' &&
      document.activeElement instanceof HTMLElement &&
      !['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName)
    ) {
      e.preventDefault()
      inputRef.current?.focus()
      inputRef.current?.select()
    }
  }

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (selectedIndex >= 0) {
        selectedRecentRef.current = true
        setTerm(filteredSearches[selectedIndex])
      }
      inputRef.current?.blur()
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      if (selectedIndex < filteredSearches.length - 1) {
        setSelectedIndex(selectedIndex + 1)
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      if (selectedIndex >= 0) {
        setSelectedIndex(selectedIndex - 1)
      }
    }
  }

  const handleFocus = () => {
    setJustFocused(true)
    setIsFocused(true)
    setSelectedIndex(-1)
  }

  const handleBlur = () => {
    if (localTerm !== term && !selectedRecentRef.current) setTerm(localTerm)
    setIsFocused(false)
    setSelectedIndex(-1)
    selectedRecentRef.current = false
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const handleMouseUp = () => {
    const input = inputRef.current
    if (!input) return

    const noSelectionRange = input.selectionStart === input.selectionEnd
    if (justFocused && noSelectionRange) {
      input.select()
    }
    setJustFocused(false)
  }

  const handleWrapperMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!inputRef.current) return

    const input = inputRef.current
    const isFullySelected =
      input.selectionStart === 0 && input.selectionEnd === input.value.length

    if (document.activeElement !== input) {
      input.focus()
      input.select()
    } else if (isFullySelected) {
      input.setSelectionRange(input.value.length, input.value.length)
    } else {
      input.select()
    }
  }

  const handleSelectRecent = (term: string) => {
    selectedRecentRef.current = true
    setTerm(term)
    inputRef.current?.blur()
  }

  const handleRemoveFilter = (index: number) => {
    setFilters(filters.filter((_, i) => i !== index))
  }

  const handleUpdateFilter = (filter: Filter, index: number) => {
    setFilters([
      ...filters.slice(0, index),
      filter,
      ...filters.slice(index + 1),
    ])
  }

  useEffect(() => {
    setLocalTerm(term)
  }, [term])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <div className="p-4 w-full flex flex-col gap-2 bg-main-bg">
      <div className="flex flex-col gap-2">
        <div className="text-small-code text-text-1 flex items-center gap-1">
          Total results:{' '}
          {queryMode !== 'user_query' && queryMode !== 'initial_load' ? (
            formatNumber(total)
          ) : (
            <div className="flex items-center">
              <LoadingDots />
            </div>
          )}
        </div>
        <div className="flex gap-2 items-center">
          <div className="flex-1 flex items-center gap-2">
            <FilterButton />
            <div className="relative max-w-[600px] flex-1 flex rounded-[4px]">
              <div
                className="px-3 w-full flex flex-col gap-2 rounded-l-[4px] items-stretch cursor-text bg-input-bgTint border border-input-border  hover:bg-input-tint"
                onMouseDown={handleWrapperMouseDown}
              >
                <div className="flex-1 flex items-center">
                  <Search size={16} className="text-text-2 flex-none" />
                  <input
                    ref={inputRef}
                    className="w-full px-2 py-2 bg-transparent text-small-code text-text-1 
                             placeholder:text-text-2 outline-none"
                    value={localTerm}
                    onChange={(e) => setLocalTerm(e.target.value)}
                    onKeyDown={handleInputKeyDown}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    placeholder="Search body"
                  />
                  <div className="flex-none flex items-center gap-2">
                    <p className="text-small text-text-2">/</p>
                  </div>
                </div>
              </div>
              {isFocused && (
                <RecentSearches
                  selectedIndex={selectedIndex}
                  handleSelectRecent={handleSelectRecent}
                  searches={searches}
                  filteredSearches={filteredSearches}
                />
              )}
              <TimeButton range={timeRange} setRange={setTimeRange} />
              <QueryButton runSearch={runSearch} />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <SettingsButton />
            <RefreshButton runSearch={runSearch} />
            <LiveButton live={live} setLive={setLive} />
          </div>
        </div>
      </div>
      {filters.length > 0 && (
        <div className="w-full flex flex-wrap items-center justify-start gap-2 z-[1]">
          {filters.map((filter, index) => (
            <FilterPill
              key={`${filter.field}-${index}`}
              filter={filter}
              remove={() => handleRemoveFilter(index)}
              setFilter={(filter) => handleUpdateFilter(filter, index)}
              visibleProperties={visibleProperties}
              hiddenProperties={hiddenProperties}
            />
          ))}
        </div>
      )}
    </div>
  )
}

function getFilteredSearches(term: string, searches: string[]) {
  return searches.filter((search) =>
    search.toLowerCase().includes(term.toLowerCase()),
  )
}

export default Searchbar
