interface LogoIconProps {
  logo: keyof typeof logoIcons
  size?: number
}

const logoIcons = {
  cpp: '/logos/logo=c++.svg',
  dart: '/logos/logo=dart.svg',
  flyio: '/logos/logo=flyio.svg',
  go: '/logos/logo=go.svg',
  javascript: '/logos/logo=javascript.svg',
  nextjs: '/logos/logo=nextjs.svg',
  nodejs: '/logos/logo=nodejs.svg',
  python: '/logos/logo=python.svg',
  rails: '/logos/logo=rails.svg',
  react: '/logos/logo=react.svg',
  render: '/logos/logo=render.svg',
  ruby: '/logos/logo=ruby.svg',
  rust: '/logos/logo=rust.svg',
} as const

const LogoIcon: React.FC<LogoIconProps> = ({ logo, size = 16 }) => {
  return (
    <div className="w-4 h-4">
      <img
        src={logoIcons[logo]}
        alt={`${logo} icon`}
        className="w-full h-full object-contain"
      />
    </div>
  )
}

export default LogoIcon
