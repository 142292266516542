import { useEffect, useState } from 'react'
import useNav, { LinkKey } from '../../../hooks/nav/useNav'
import useLogin from '../../../hooks/actions/useLogin'
import useLoggedIn from '../../../hooks/utils/useLoggedIn'
import { setBrandCTA } from '../../../utils/setBrandColor'
import SolidInput from '../../library/SolidInput/SolidInput'
import { isValidEmail } from '../../../utils/inputs'
import BrandButton from '../../library/BrandButton/BrandButton'

const Login: React.FC = () => {
  const loggedIn = useLoggedIn()

  const navigate = useNav()
  const login = useLogin()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await login(email, password)
  }

  useEffect(() => {
    setBrandCTA('success')
  }, [])

  if (loggedIn) return <></>

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-main-bg">
      <form
        className="p-6 w-[360px] flex flex-col items-start justify-start gap-6 rounded-[4px] border border-panel-border"
        onSubmit={handleSubmit}
      >
        <div className="w-full flex flex-col items-start justify-start gap-1.5">
          <h1 className="text-title1-medium text-text-1">Login</h1>
          <p className="text-normal-light text-text-2">
            Enter your email to login to your account
          </p>
        </div>
        <div className="w-full flex flex-col items-start justify-start gap-4">
          <div className="w-full flex flex-col items-start justify-start gap-2">
            <p className="text-small-light text-text-2">Email</p>
            <SolidInput
              value={email}
              onChange={setEmail}
              placeholder="Enter email"
              validFn={isValidEmail}
            />
          </div>
          <div className="w-full flex flex-col items-start justify-start gap-2">
            <p className="text-small-light text-text-2">Password</p>
            <SolidInput
              value={password}
              onChange={setPassword}
              placeholder="Enter password"
              secure={true}
            />
          </div>
        </div>
        <div className="w-full flex flex-col items-start gap-4">
          <BrandButton className="w-full" mode="success" onClick={handleSubmit}>
            Login
          </BrandButton>
          <div className="flex items-center justify-start">
            <p className="text-normal-light text-text-2">
              Don't have an account?{' '}
              <span
                className="text-text-1 underline cursor-pointer"
                onClick={() => navigate(LinkKey.Signup)}
              >
                Sign Up
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Login
