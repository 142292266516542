import { useAtomValue } from 'jotai'
import { instanceIdAtom } from '../../state/state'
import useBackend from '../services/useBackend'
import useLoadTokens from '../data/load/useLoadTokens'
import { captureAxiosError, logger } from '../../utils/obeservability'

const useCreateToken = () => {
  const backend = useBackend()
  const { projectId } = useAtomValue(instanceIdAtom)
  const loadTokens = useLoadTokens()

  const createToken = async (label: string, description: string) => {
    if (!projectId) return
    try {
      const key = await backend.createProjectToken(
        projectId,
        label,
        description,
      )
      logger.info(`new token created - ${label}`)
      await loadTokens()
      return key
    } catch (e) {
      captureAxiosError(e)
    }
  }

  return createToken
}

export default useCreateToken
