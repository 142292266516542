import * as chrono from 'chrono-node'
import { AnalyticsTimeRange, TimeRangePresets } from '../../../utils/analytics'
import { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import useSelectOnFocus from '../../../hooks/utils/useSelectOnFocus'

interface TimeRangeDisplayProps {
  timeRange: AnalyticsTimeRange
  setTimeRange: (timeRange: AnalyticsTimeRange) => void
}

const TimeRangeDisplay = ({
  timeRange,
  setTimeRange,
}: TimeRangeDisplayProps) => {
  const startTime = useMemo(() => getStartValue(timeRange), [timeRange])
  const endTime = useMemo(() => getEndValue(timeRange), [timeRange])

  const [localStart, setLocalStart] = useState(startTime)
  const [localEnd, setLocalEnd] = useState(endTime)

  const startInputRef = useRef<HTMLInputElement>(null)
  const endInputRef = useRef<HTMLInputElement>(null)

  const startInputSelectOnFocus = useSelectOnFocus(startInputRef)
  const endInputSelectOnFocus = useSelectOnFocus(endInputRef)

  const handleSubmit = useCallback(
    (lastModified: 'start' | 'end') => {
      if (localStart === startTime && localEnd === endTime) return

      const parsedTimeRange = parseTimeRange(localStart, localEnd, lastModified)
      if (parsedTimeRange) {
        setLocalStart(getStartValue(parsedTimeRange))
        setLocalEnd(getEndValue(parsedTimeRange))
        setTimeRange(parsedTimeRange)
      } else {
        setLocalStart(startTime)
        setLocalEnd(endTime)
      }
    },
    [localStart, startTime, localEnd, endTime, setTimeRange],
  )

  const handleStartBlur = useCallback(() => {
    handleSubmit('start')
  }, [handleSubmit])

  const handleEndBlur = useCallback(() => {
    handleSubmit('end')
  }, [handleSubmit])

  useEffect(() => {
    setLocalStart(startTime)
    setLocalEnd(endTime)
  }, [timeRange, startTime, endTime])

  return (
    <div className="w-full max-w-[400px] h-8 flex items-center justify-between">
      <input
        ref={startInputRef}
        type="text"
        className="w-full h-full px-[10px] py-2 text-small-light text-text-1 bg-input-bgTint border border-input-border rounded-l focus:outline-none hover:bg-input-tint focus:bg-input-tint placeholder:text-text-2"
        placeholder="Oldest log"
        value={localStart}
        onChange={(e) => setLocalStart(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleStartBlur()}
        onBlur={handleStartBlur}
        onMouseDown={startInputSelectOnFocus.handleMouseDown}
        onMouseUp={startInputSelectOnFocus.handleMouseUp}
      />
      <div className="px-[10px] h-full flex items-center justify-center text-small-light text-text-1 bg-input-bgTint border-y border-input-border">
        to
      </div>
      <input
        ref={endInputRef}
        type="text"
        className="w-full h-full px-[10px] py-2 text-small-light text-text-1  bg-input-bgTint border border-input-border rounded-r focus:outline-none hover:bg-input-tint focus:bg-input-tint placeholder:text-text-2"
        placeholder="Now"
        value={localEnd || ''}
        onChange={(e) => setLocalEnd(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleEndBlur()}
        onBlur={handleEndBlur}
        onMouseDown={endInputSelectOnFocus.handleMouseDown}
        onMouseUp={endInputSelectOnFocus.handleMouseUp}
      />
    </div>
  )
}

function getStartValue(timeRange: AnalyticsTimeRange): string {
  switch (timeRange.type) {
    case 'absolute':
      return formatTime(timeRange.startTime, timeRange)
    case 'preset':
      switch (timeRange.value) {
        case '1m':
          return '1 min ago'
        case '5m':
          return '5 min ago'
        case '15m':
          return '15 min ago'
        case '30m':
          return '30 min ago'
        case '1h':
          return '1 hour ago'
        case '4h':
          return '4 hours ago'
        case '12h':
          return '12 hours ago'
        case '1d':
          return '1 day ago'
        case '3d':
          return '3 days ago'
        case '1w':
          return '1 week ago'
        default:
          return '1 hour ago'
      }
  }
}

function getEndValue(timeRange: AnalyticsTimeRange): string | undefined {
  switch (timeRange.type) {
    case 'absolute':
      if (!timeRange.endTime) return undefined
      return formatTime(timeRange.endTime, timeRange)
    case 'preset':
      return undefined
    default:
      return undefined
  }
}

function formatTime(time: string, timeRange: AnalyticsTimeRange): string {
  const date = new Date(time)
  const month = date.toLocaleString('en-US', { month: 'short' })
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const seconds = date.getSeconds()
  const ampm = hours >= 12 ? 'pm' : 'am'
  const formattedHours = hours % 12 || 12
  const formattedMinutes = minutes.toString().padStart(2, '0')

  const shouldShowSeconds = seconds !== 0 || isTimeRangeLessThanHour(timeRange)
  if (shouldShowSeconds) {
    const formattedSeconds = seconds.toString().padStart(2, '0')
    return `${month} ${day}, ${formattedHours}:${formattedMinutes}:${formattedSeconds}${ampm}`
  } else {
    return `${month} ${day}, ${formattedHours}:${formattedMinutes}${ampm}`
  }
}

function isTimeRangeLessThanHour(timeRange: AnalyticsTimeRange): boolean {
  if (
    timeRange?.type === 'absolute' &&
    timeRange.startTime &&
    timeRange.endTime
  ) {
    const start = new Date(timeRange.startTime)
    const end = new Date(timeRange.endTime)
    const diffMs = end.getTime() - start.getTime()
    const diffHours = diffMs / (1000 * 60 * 60)
    return diffHours < 1
  }
  return false
}

function parseTimeRange(
  startInput: string,
  endInput: string | undefined,
  lastModifiedField: 'start' | 'end',
): AnalyticsTimeRange | null {
  const presetTimeRange = checkForPreset(startInput, endInput)
  if (presetTimeRange) {
    return presetTimeRange
  }

  try {
    const now = new Date()
    const parsedStartResult = chrono.parseDate(startInput, now, {
      forwardDate: false,
    })
    if (!parsedStartResult) {
      throw new Error('Could not parse start time')
    }
    const parsedStart: Date = parsedStartResult

    if (
      (!endInput || endInput.toLowerCase() === 'now') &&
      lastModifiedField === 'start'
    ) {
      return {
        type: 'absolute',
        startTime: parsedStart.toISOString(),
        endTime: undefined,
      }
    }

    let parsedEnd: Date
    if (!endInput || endInput.toLowerCase() === 'now') {
      parsedEnd = now
    } else {
      const parsedEndResult = chrono.parseDate(endInput, now)
      if (!parsedEndResult) {
        throw new Error('Could not parse end time')
      }
      parsedEnd = parsedEndResult

      if (parsedEnd < parsedStart) {
        if (lastModifiedField === 'end') {
          parsedStart.setTime(parsedEnd.getTime() - 60 * 60 * 1000)
        } else if (lastModifiedField === 'start') {
          parsedEnd.setTime(parsedStart.getTime() + 60 * 60 * 1000)
        }
      }
    }

    return {
      type: 'absolute',
      startTime: parsedStart.toISOString(),
      endTime: parsedEnd.toISOString(),
    }
  } catch (error) {
    return null
  }
}

function checkForPreset(
  startInput: string,
  endInput: string | undefined,
): AnalyticsTimeRange | null {
  if (endInput && endInput.toLowerCase() !== 'now') {
    return null
  }

  const presetMap: Record<string, TimeRangePresets> = {
    '1 min ago': '1m',
    '5 min ago': '5m',
    '15 min ago': '15m',
    '30 min ago': '30m',
    '1 hour ago': '1h',
    '4 hours ago': '4h',
    '12 hours ago': '12h',
    '1 day ago': '1d',
    '3 days ago': '3d',
    '1 week ago': '1w',
  }

  const normalizedInput = startInput.toLowerCase().trim()
  if (presetMap[normalizedInput]) {
    return { type: 'preset', value: presetMap[normalizedInput] }
  }

  const timeRegex =
    /^(\d+)\s*(min|minute|minutes|h|hour|hours|d|day|days|w|week|weeks)\s*ago$/i
  const match = normalizedInput.match(timeRegex)

  if (match) {
    const value = parseInt(match[1], 10)
    const unit = match[2].toLowerCase()
    if (unit.startsWith('min')) {
      if (value === 1) return { type: 'preset', value: '1m' }
      if (value === 5) return { type: 'preset', value: '5m' }
      if (value === 15) return { type: 'preset', value: '15m' }
      if (value === 30) return { type: 'preset', value: '30m' }
    } else if (unit.startsWith('h')) {
      if (value === 1) return { type: 'preset', value: '1h' }
      if (value === 4) return { type: 'preset', value: '4h' }
      if (value === 12) return { type: 'preset', value: '12h' }
    } else if (unit.startsWith('d')) {
      if (value === 1) return { type: 'preset', value: '1d' }
      if (value === 3) return { type: 'preset', value: '3d' }
    } else if (unit.startsWith('w')) {
      if (value === 1) return { type: 'preset', value: '1w' }
    }
  }

  return null
}

export default TimeRangeDisplay
