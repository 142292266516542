import { useCallback, useEffect, useRef, useState } from 'react'
import { LogEntry } from '../../../../../../../../../state/types'
import {
  getFormattedExtractor,
  getRawExtractor,
} from '../../../../../../../../../utils/logs'
import {
  COLUMN_LEVEL,
  formatValue,
} from '../../../../../../../../../utils/properties'
import { isCommandKey } from '../../../../../../../../../utils/browser'

interface LogCellProps {
  term: string
  width: number
  column: string
  log: LogEntry
  rowActive: boolean
  addEqualsFilter: (property: string, value: string) => void
}

const LogCell = ({
  term,
  width,
  column,
  log,
  rowActive,
  addEqualsFilter,
}: LogCellProps) => {
  const content = getFormattedExtractor(column)(log)
  const rawContent = getRawExtractor(column)(log)

  const cellRef = useRef<HTMLDivElement>(null)
  const [hover, setHover] = useState(false)
  const [command, setCommand] = useState<boolean>(false)

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      const isTargetCell = e.target === cellRef.current
      const isTargetDescendant = cellRef.current?.contains(e.target as Node)
      setHover(isTargetCell || isTargetDescendant || false)
      setCommand(isCommandKey(e))
    },
    [cellRef],
  )

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Meta') setCommand(true)
  }, [])

  const handleKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Meta') setCommand(false)
  }, [])

  const handleMouseDown = useCallback(() => {
    if (command && hover) addEqualsFilter(column, rawContent)
  }, [command, hover, addEqualsFilter, column, rawContent])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    window.addEventListener('mousemove', handleMouseMove)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [handleKeyDown, handleKeyUp, handleMouseMove])

  return (
    <div
      ref={cellRef}
      className="log-cell relative flex-none px-2 py-[5px] min-h-6 flex items-start gap-2 border-r border-main-border"
      style={{ width }}
      onMouseDown={handleMouseDown}
    >
      {column === COLUMN_LEVEL && <LevelBar content={content} />}
      <p
        className={`text-small-code whitespace-pre-wrap break-all overflow-x-scroll ${
          hover && command
            ? 'text-brand-cta underline'
            : rowActive
              ? 'text-text-1'
              : 'text-text-2'
        }`}
      >
        {column === 'column.body'
          ? highlightText(content, term)
          : formatValue(column, content)}
      </p>
    </div>
  )
}

function LevelBar({ content }: { content: string }) {
  if (
    content !== 'ERROR' &&
    content !== 'WARNING' &&
    content !== 'INFO' &&
    content !== 'DEBUG' &&
    content !== 'TRACE'
  )
    return null
  const color = {
    ERROR: 'bg-brand-error',
    WARNING: 'bg-brand-warning',
    INFO: 'bg-brand-info',
    DEBUG: 'bg-brand-success',
    TRACE: 'bg-brand-trace',
  }
  return (
    <div
      className={`my-[2px] w-[1px] h-[12px] ${color[content]} rounded-full`}
    ></div>
  )
}

function highlightText(text: string, searchTerm: string): React.ReactNode {
  if (!searchTerm || typeof text !== 'string') return text
  try {
    const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const parts = text.split(new RegExp(`(${escapedSearchTerm})`, 'gi'))
    return parts.map((part, i) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={i} className="text-brand-error">
          {part}
        </span>
      ) : (
        part
      ),
    )
  } catch (error) {
    return text
  }
}

export default LogCell
