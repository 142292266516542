import OutlineIconButton from '../OutlineIconButton/OutlineIconButton'
import {
  BookOpen,
  ChartLine,
  CircleHelp,
  Logs,
  MessageCircle,
  X,
  Siren,
} from 'lucide-react'
import { useRef, useState } from 'react'
import usePopupPauseScroll from '../../../hooks/utils/usePopupPauseScroll'
import usePopupStack from '../../../hooks/utils/usePopupStack'
import usePopupPosition from '../../../hooks/utils/usePopupPosition'
import Portal from '../Portal/Portal'
import IconButton from '../IconButton/IconButton'
import OutlineIconTextButtonSmall from '../OutlineIconTextButtonSmall/OutlineIconTextButtonSmall'
import { useSetAtom } from 'jotai'
import { supportOpenAtom } from './Navbar'
import useNav, { LinkKey } from '../../../hooks/nav/useNav'

const HelpButton = () => {
  const nav = useNav()

  const [isHelpOpen, setIsHelpOpen] = useState(false)
  const setSupportOpen = useSetAtom(supportOpenAtom)

  const buttonRef = useRef<HTMLButtonElement>(null)
  const portalRef = useRef<HTMLDivElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)

  usePopupStack(portalRef, () => setIsHelpOpen(false), buttonRef)
  usePopupPauseScroll(portalRef, isHelpOpen)
  const position = usePopupPosition(
    isHelpOpen,
    buttonRef,
    popupRef,
    'bottom-right',
    { x: -280, y: 6 },
  )

  return (
    <>
      <OutlineIconButton
        buttonRef={buttonRef}
        active={isHelpOpen}
        onClick={() => setIsHelpOpen(!isHelpOpen)}
      >
        <CircleHelp size={16} />
      </OutlineIconButton>
      {isHelpOpen && (
        <Portal ref={portalRef}>
          <div
            ref={popupRef}
            className="absolute w-[280px] flex flex-col border border-popup-border bg-popup-bg rounded"
            style={position}
          >
            <div className="w-full p-2 flex items-center border-b border-popup-border">
              <div className="w-full flex pl-2 items-center justify-between">
                <p className="text-body text-text-1">Help</p>
                <IconButton
                  size="28"
                  icon={<X size={16} />}
                  onClick={() => setIsHelpOpen(false)}
                />
              </div>
            </div>
            <div className="w-full p-2 py-[10px] flex flex-col gap-2 items-start border-b border-popup-border">
              <div className="px-2 flex items-center gap-2">
                <p className="text-mini-medium text-text-2">Resources</p>
              </div>
              <OutlineIconTextButtonSmall
                onClick={() => {
                  window.open('https://docs.vigilant.run/overview', '_blank')
                  setIsHelpOpen(false)
                }}
              >
                <BookOpen size={16} />
                Docs
              </OutlineIconTextButtonSmall>
              <OutlineIconTextButtonSmall
                onClick={() => {
                  setSupportOpen(true)
                  setIsHelpOpen(false)
                }}
              >
                <MessageCircle size={16} />
                Support
              </OutlineIconTextButtonSmall>
            </div>
            <div className="w-full p-2 flex flex-col gap-2 items-start">
              <div className="px-2 flex items-center gap-2">
                <p className="text-mini-medium text-text-2">Setup Guide</p>
              </div>
              <OutlineIconTextButtonSmall
                onClick={() => {
                  nav(LinkKey.SetupLogs)
                  setIsHelpOpen(false)
                }}
              >
                <Logs size={16} />
                Logs
              </OutlineIconTextButtonSmall>
              <OutlineIconTextButtonSmall
                onClick={() => {
                  nav(LinkKey.SetupAlerts)
                  setIsHelpOpen(false)
                }}
              >
                <Siren size={16} />
                Alerts
              </OutlineIconTextButtonSmall>
              <OutlineIconTextButtonSmall
                onClick={() => {
                  nav(LinkKey.SetupMetrics)
                  setIsHelpOpen(false)
                }}
              >
                <ChartLine size={16} />
                Metrics
              </OutlineIconTextButtonSmall>
            </div>
          </div>
        </Portal>
      )}
    </>
  )
}

export default HelpButton
