import { useEffect } from 'react'
import useBackend from '../services/useBackend'

const { REACT_APP_SERVICE_VERSION } = process.env

const useSyncVersion = () => {
  const backend = useBackend()

  useEffect(() => {
    const checkVersion = () => {
      backend.getVersion().then((version) => {
        if (version.version !== REACT_APP_SERVICE_VERSION) {
          console.log(
            'version',
            version,
            'REACT_APP_SERVICE_VERSION',
            REACT_APP_SERVICE_VERSION,
          )
        }
      })
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkVersion()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [backend])
}

export default useSyncVersion
