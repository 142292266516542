import { useEffect, useRef, useState } from 'react'
import { Filter } from '../../../../../../../../backend/types'
import { cn } from '../../../../../../../../utils/cn'

interface FilterValueInputProps {
  filter: Filter
  setFilter: (filter: Filter) => void
}

const FilterValueInput = ({ filter, setFilter }: FilterValueInputProps) => {
  const valueInputRef = useRef<HTMLInputElement>(null)
  const [localValue, setLocalValue] = useState(filter.value)
  const [editingValue, setEditingValue] = useState(false)

  const handleInputBlur = () => {
    setFilter({ ...filter, value: localValue })
    setEditingValue(false)
  }

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.currentTarget.blur()
    }
  }

  useEffect(() => {
    if (valueInputRef.current && editingValue) {
      valueInputRef.current.focus()
      valueInputRef.current.select()
    }
  }, [editingValue, valueInputRef])

  useEffect(() => {
    setLocalValue(filter.value ?? '')
  }, [filter.value])

  return (
    <div
      className="max-w-[500px] px-[10px] h-6 flex-none flex items-center justify-center text-mini-code border border-l-0 border-brand-ctaTint text-brand-cta bg-brand-ctaTint hover:bg-brand-ctaTint2"
      onClick={() => setEditingValue(true)}
    >
      {editingValue ? (
        <input
          className={'w-[200px]'}
          ref={valueInputRef}
          type={'text'}
          spellCheck={false}
          value={localValue ?? ''}
          onChange={(e) => setLocalValue(e.target.value)}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeyDown}
          onFocus={(e) => e.currentTarget.select()}
        />
      ) : (
        <p
          className={cn(
            'overflow-hidden text-ellipsis whitespace-nowrap',
            `${filter.value === null ? 'opacity-50' : ''}`,
          )}
        >
          {filter.value ? filter.value : '"..."'}
        </p>
      )}
    </div>
  )
}

export default FilterValueInput
