import NavbarPlaceholder from '../../../library/NavbarPlaceholder/NavbarPlaceholder'
import useSetBrandColor from '../../../../hooks/utils/useSetBrandColor'
import AlertsOnboardingDisplay from './AlertsOnboardingDisplay/AlertsOnboardingDisplay'
import useInitHasData from '../../../../hooks/data/effects/useInitHasData'
import AlertsList from './AlertsList/AlertsList'
import AlertsActionBar from './AlertsActionBar/AlertsActionBar'
import useInitAlerts from '../../../../hooks/data/effects/useInitAlerts'

const Alerts = () => {
  useSetBrandColor('warning')
  useInitHasData()
  useInitAlerts()

  return (
    <div className="flex flex-col w-full h-full">
      <NavbarPlaceholder />
      <div className="flex-1 pb-4 flex flex-col items-center overflow-hidden">
        <div className="w-full max-w-[1200px] h-full flex flex-col items-start">
          <AlertsActionBar />
          <AlertsList />
        </div>
      </div>
      <AlertsOnboardingDisplay />
    </div>
  )
}

export default Alerts
