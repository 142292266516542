import useLoadUsageTotals from '../../../../../../../hooks/data/load/useLoadUsageTotals'
import { Organization } from '../../../../../../../state/types'
import {
  computeCost,
  computeProjectedValue,
  formatBillingDate,
  formatCurrency,
  formatUsage,
  getFreeTier,
} from '../../../../../../../utils/billing'

type BillingMatrixHeader =
  | 'usage_current'
  | 'usage_projected'
  | 'free_tier'
  | 'cost_current'
  | 'cost_projected'

const headers: BillingMatrixHeader[] = [
  'usage_current',
  'usage_projected',
  'free_tier',
  'cost_current',
  'cost_projected',
]

interface BillingMatrixProps {
  organizationInfo: Organization
}

const BillingMatrix = ({ organizationInfo }: BillingMatrixProps) => {
  const billingDate = organizationInfo.billing_date
  const logTotals = useLoadUsageTotals('logs', billingDate)
  const metricTotals = useLoadUsageTotals('metrics', billingDate)

  const logFreeTier = getFreeTier('logs')
  const metricFreeTier = getFreeTier('metrics')

  return (
    <div className="py-12 w-full flex flex-col items-start justify-center gap-12">
      <table className="w-full table-fixed">
        <thead>
          <tr>
            <th className="w-[120px] pb-1 text-left text-normal-light text-text-2"></th>
            {headers.map((header) => (
              <th
                className="pb-1 text-left text-normal-light text-text-2 w-1/5"
                key={header}
              >
                {formatBillingHeader(header)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-[120px] py-1 text-normal-light text-text-2">
              Logs
            </td>
            <td className="py-1 text-normal-light text-text-1">
              {formatUsage(logTotals?.total ?? 0, 'logs')}
            </td>
            <td className="py-1 text-normal-light text-text-1">
              {formatUsage(
                computeProjectedValue(logTotals?.total ?? 0, organizationInfo),
                'logs',
              )}
            </td>
            <td className="py-1 text-normal-light text-text-1">
              {formatUsage(logFreeTier, 'logs')}
            </td>
            <td className="py-1 text-normal-light text-text-1">
              {formatCurrency(
                computeCost(logTotals?.total ?? 0, 'logs', organizationInfo),
              )}
            </td>
            <td className="py-1 text-normal-light text-text-1">
              {formatCurrency(
                computeCost(
                  computeProjectedValue(
                    logTotals?.total ?? 0,
                    organizationInfo,
                  ),
                  'logs',
                  organizationInfo,
                ),
              )}
            </td>
          </tr>
          <tr>
            <td className="w-[120px] py-1 text-normal-light text-text-2">
              Metrics
            </td>
            <td className="py-1 text-normal-light text-text-1">
              {formatUsage(metricTotals?.total ?? 0, 'metrics')}
            </td>
            <td className="py-1 text-normal-light text-text-1">
              {formatUsage(
                computeProjectedValue(
                  metricTotals?.total ?? 0,
                  organizationInfo,
                ),
                'metrics',
              )}
            </td>
            <td className="py-1 text-normal-light text-text-1">
              {formatUsage(metricFreeTier, 'metrics')}
            </td>
            <td className="py-1 text-normal-light text-text-1">
              {formatCurrency(
                computeCost(
                  metricTotals?.total ?? 0,
                  'metrics',
                  organizationInfo,
                ),
              )}
            </td>
            <td className="py-1 text-normal-light text-text-1">
              {formatCurrency(
                computeCost(
                  computeProjectedValue(
                    metricTotals?.total ?? 0,
                    organizationInfo,
                  ),
                  'metrics',
                  organizationInfo,
                ),
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="w-full flex items-center">
        <p className="w-[120px] text-normal-light text-text-2">Billing Date</p>
        <p className="text-normal-light text-text-1">
          {formatBillingDate(organizationInfo.billing_date)}
        </p>
      </div>
    </div>
  )
}

function formatBillingHeader(header: BillingMatrixHeader) {
  switch (header) {
    case 'usage_current':
      return 'Usage (Current)'
    case 'usage_projected':
      return 'Usage (Projected)'
    case 'free_tier':
      return 'Free Tier'
    case 'cost_current':
      return 'Cost (Current)'
    case 'cost_projected':
      return 'Cost (Projected)'
    default:
      return header
  }
}

export default BillingMatrix
