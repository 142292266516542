import useNav, { LinkKey } from '../../../hooks/nav/useNav'
import BrandButton from '../BrandButton/BrandButton'

interface EmptyDataPlaceholderProps {
  imgUrl: string
  title: string
  paragraphs: string[]
  ctaText: string
  ctaLink: LinkKey
}

const EmptyDataPlaceholder = ({
  imgUrl,
  title,
  paragraphs,
  ctaText,
  ctaLink,
}: EmptyDataPlaceholderProps) => {
  const navigate = useNav()

  return (
    <div className="w-[640px] p-12 flex gap-12 bg-popup-bg border border-popup-border rounded pointer-events-auto">
      <img src={imgUrl} alt="Empty data placeholder" width={200} height={200} />
      <div className="flex flex-col items-start">
        <p className="text-title2-medium text-text-1 mb-3">{title}</p>
        <div className="flex flex-col gap-2 mb-6">
          {paragraphs.map((paragraph) => (
            <p className="text-normal-light text-text-2">{paragraph}</p>
          ))}
        </div>
        <BrandButton onClick={() => navigate(ctaLink)}>{ctaText}</BrandButton>
      </div>
    </div>
  )
}

export default EmptyDataPlaceholder
