interface EventDetailsRowProps {
  label: string
  value: string
  className?: string
}

const EventDetailsRow = ({ label, value, className }: EventDetailsRowProps) => {
  return (
    <div className="w-full flex items-start gap-2 overflow-hidden">
      <div className="w-[120px] py-2 h-full flex items-start justify-start">
        <p className="text-text-2 text-mini-code">{label}</p>
      </div>
      <p
        className={`flex-1 px-3 py-2 text-text-1 text-mini-code overflow-x-auto bg-input-bgTint rounded ${className}`}
      >
        {value}
      </p>
    </div>
  )
}

export default EventDetailsRow
