import {
  QueryParameters,
  QueryRequestState,
  QueryState,
  ResponseSuccess,
} from './types'

export type MergeFunction = (
  prevState: QueryState,
  state: QueryState,
  result: ResponseSuccess,
) => QueryState

export const loadMoreUpMerge: MergeFunction = (
  prevState: QueryState,
  state: QueryState,
  result: ResponseSuccess,
): QueryState => {
  switch (result.type) {
    case 'loadMore':
      return {
        ...state,
        result: {
          count: prevState.result?.count ?? 0,
          properties: prevState.result?.properties ?? [],
          serviceOptions: prevState.result?.serviceOptions ?? [],
          levelOptions: prevState.result?.levelOptions ?? [],
          logs: [...result.logs.reverse(), ...(prevState.result?.logs || [])],
          newLogs: [],
          shouldFocus: false,
          atStart: result.logs.length < state.request.limit,
          atEnd: prevState.result?.atEnd ?? false,
        },
        previousMode: state.mode,
        mode: 'none',
      }
    default:
      return prevState
  }
}

export const loadMoreDownMerge: MergeFunction = (
  prevState: QueryState,
  state: QueryState,
  result: ResponseSuccess,
): QueryState => {
  switch (result.type) {
    case 'loadMore':
      return {
        ...state,
        result: {
          count: prevState.result?.count ?? 0,
          properties: prevState.result?.properties ?? [],
          serviceOptions: prevState.result?.serviceOptions ?? [],
          levelOptions: prevState.result?.levelOptions ?? [],
          logs: [...(prevState.result?.logs || []), ...result.logs],
          newLogs: [],
          shouldFocus: false,
          atStart: prevState.result?.atStart ?? false,
          atEnd: result.logs.length < state.request.limit,
        },
        previousMode: state.mode,
        mode: 'none',
      }
    default:
      return prevState
  }
}

export const defaultMerge: MergeFunction = (
  _: QueryState,
  state: QueryState,
  result: ResponseSuccess,
): QueryState => {
  switch (result.type) {
    case 'init':
      return {
        ...state,
        result: {
          ...result,
          newLogs: [],
          shouldFocus: true,
          atStart: true,
          atEnd: result.count < state.request.limit,
        },
        previousMode: state.mode,
        mode: 'none',
      }
    case 'query':
    case 'liveTick':
      return {
        ...state,
        result: {
          ...result,
          newLogs: [],
          shouldFocus: false,
          atStart: true,
          atEnd: result.count < state.request.limit,
        },
        previousMode: state.mode,
        mode: 'none',
      }
    default:
      return state
  }
}

export const viewContextMerge: MergeFunction = (
  _: QueryState,
  state: QueryState,
  result: ResponseSuccess,
): QueryState => {
  switch (result.type) {
    case 'viewContext':
      return {
        ...state,
        result: {
          count: result.count,
          properties: result.properties,
          serviceOptions: result.serviceOptions,
          levelOptions: result.levelOptions,
          logs: [
            ...result.logsBefore.reverse(),
            ...(result.log ? [result.log] : []),
            ...result.logsAfter,
          ],
          newLogs: [],
          shouldFocus: true,
          atStart:
            result.logsBefore.length < state.request.limit &&
            state.request.time_range.start !== undefined,
          atEnd: result.logsAfter.length < state.request.limit,
        },
        previousMode: state.mode,
        mode: 'none',
      }
    default:
      return state
  }
}

export const liveTickMerge: MergeFunction = (
  prevState: QueryState,
  state: QueryState,
  result: ResponseSuccess,
): QueryState => {
  switch (result.type) {
    case 'init':
    case 'query':
    case 'liveTick':
      return {
        ...state,
        result: {
          ...result,
          newLogs: result.logs
            .map((l) => l.log_id)
            .filter((id) => isNewLog(prevState, id)),
          atStart: true,
          atEnd: false,
          shouldFocus: false,
        },
        previousMode: state.mode,
        mode: 'none',
      }
    default:
      return state
  }
}

export const createInitialState = (
  index: number,
  params: QueryParameters,
): QueryState => {
  return {
    index: index,
    previousMode: 'initial_load',
    mode: 'initial_load',
    request: {
      term: params.term,
      time_range: params.time_range,
      filters: params.filters,
      cursor_start: params.cursor_start,
      cursor_end: params.cursor_end,
      cursor_focus: params.cursor_focus,
      order_by: params.order_by,
      limit: params.limit,
    },
    controller: new AbortController(),
    result: null,
  }
}

export const createQueryState = (
  index: number,
  prevState: QueryState,
  params: QueryParameters,
): QueryState => {
  return {
    ...prevState,
    index: index,
    previousMode: prevState.mode,
    mode: 'user_query',
    request: {
      term: params.term,
      time_range: params.time_range,
      filters: params.filters,
      limit: params.limit,
      cursor_start: params.cursor_start,
      cursor_end: params.cursor_end,
      cursor_focus: params.cursor_focus,
      order_by: params.order_by,
    },
    result: prevState.result
      ? {
          ...prevState.result,
          count: 0,
          logs: [],
          atEnd: false,
        }
      : null,
    controller: new AbortController(),
  }
}

export const createViewContextState = (
  index: number,
  prevState: QueryState,
  params: QueryParameters,
): QueryState => {
  return {
    ...prevState,
    index: index,
    previousMode: prevState.mode,
    mode: 'view_context',
    request: {
      term: params.term,
      time_range: params.time_range,
      filters: params.filters,
      cursor_start: undefined,
      cursor_end: undefined,
      cursor_focus: params.cursor_focus,
      order_by: 'desc',
      limit: 100,
    },
    result: prevState.result
      ? {
          ...prevState.result,
        }
      : null,
    controller: new AbortController(),
  }
}

export const createLiveTickState = (
  index: number,
  prevState: QueryState,
): QueryState => {
  return {
    ...prevState,
    index: index,
    previousMode: prevState.mode,
    mode: 'live_tick',
    request: {
      ...prevState.request,
      cursor_start: undefined,
      cursor_end: undefined,
      order_by: 'desc',
      limit: 100,
    },
    controller: new AbortController(),
  }
}

export const createLoadMoreDownState = (
  index: number,
  prevState: QueryState,
): QueryState => {
  return {
    ...prevState,
    index: index,
    previousMode: prevState.mode,
    mode: 'loading_more_down',
    request: {
      ...prevState.request,
      cursor_start: prevState.result?.logs[prevState.result.logs.length - 1],
      cursor_end: undefined,
      order_by: 'desc',
      limit: 100,
    },
    result: prevState.result
      ? {
          ...prevState.result,
        }
      : null,
    controller: new AbortController(),
  }
}

export const createLoadMoreUpState = (
  index: number,
  prevState: QueryState,
): QueryState => {
  return {
    ...prevState,
    index: index,
    mode: 'loading_more_up',
    request: {
      ...prevState.request,
      cursor_start: undefined,
      cursor_end: prevState.result?.logs[0],
      order_by: 'asc',
      limit: 100,
    },
    result: prevState.result
      ? {
          ...prevState.result,
        }
      : null,
    controller: new AbortController(),
  }
}

export function formatQuery(query: QueryRequestState): string {
  return JSON.stringify(query, null, 2)
}

const isNewLog = (prevState: QueryState, logId: string) => {
  return !prevState.result?.logs.some((l) => l.log_id === logId)
}
