import { useRef, useState } from 'react'
import useClickOutside from '../../../../../../../hooks/utils/useClickOutside'
import Fullscreen from '../../../../../../library/Fullscreen/Fullscreen'
import PanelSection from '../../../../../../library/PanelSection/PanelSection'
import PopupContainer from '../../../../../../library/PopupContainer/PopupContainer'
import { X } from 'lucide-react'
import CTAButton from '../../../../../../library/CTAButton/CTAButton'
import LabeledInput from '../../../../../../library/LabeledInput/LabeledInput'

interface DeleteProjectPopupProps {
  name: string
  onSubmit: () => Promise<void>
  close: () => void
}

const DeleteProjectPopup: React.FC<DeleteProjectPopupProps> = ({
  name,
  onSubmit,
  close,
}) => {
  const popupRef = useRef<HTMLDivElement>(null)
  useClickOutside(popupRef, () => close())

  const handleSubmit = async () => {
    await onSubmit()
  }

  const [projectName, setProjectName] = useState('')

  return (
    <Fullscreen>
      <PopupContainer popupRef={popupRef} className="w-[380px]">
        <PanelSection>
          <div className="w-full flex justify-between items-center">
            <h2 className="text-h2 text-text-1">Delete Project</h2>
            <X
              className="w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer"
              onClick={close}
            />
          </div>
        </PanelSection>
        <PanelSection className="gap-2">
          <p className="text-body text-text-1">
            Please type in <span className="font-bold">{name}</span> to confirm
            you want to delete this project.
          </p>
          <p className="text-body text-brand-error">
            Warning: This cannot be undone. All data in this project will be
            deleted.
          </p>
        </PanelSection>
        <PanelSection>
          <LabeledInput
            label="Project Name"
            placeholder={name}
            value={projectName}
            onChange={(v) => setProjectName(v)}
          />
        </PanelSection>
        <PanelSection bottom={true}>
          <CTAButton
            disabled={projectName !== name}
            mode="error"
            onClick={handleSubmit}
          >
            Delete Project
          </CTAButton>
        </PanelSection>
      </PopupContainer>
    </Fullscreen>
  )
}

export default DeleteProjectPopup
