import { Copy } from 'lucide-react'
import { Check } from 'lucide-react'
import { useRef, useState } from 'react'
import { cn } from '../../../utils/cn'

interface CopyButtonProps {
  content: string
  disabled?: boolean
  size?: '4' | '8'
}

const CopyButton = ({ content, disabled, size = '8' }: CopyButtonProps) => {
  const copyTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [copied, setCopied] = useState(false)

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(content)
    setCopied(true)
    if (copyTimeoutRef.current) {
      clearTimeout(copyTimeoutRef.current)
    }
    copyTimeoutRef.current = setTimeout(() => setCopied(false), 2000)
  }

  return (
    <button
      onClick={copyToClipboard}
      className={cn(
        'flex items-center justify-center outline-none',
        size === '4' ? 'w-4 h-4' : 'w-8 h-8',
      )}
      disabled={disabled}
    >
      {copied ? (
        <Check className="w-4 h-4 text-brand-success" />
      ) : (
        <Copy
          className={cn(
            'w-4 h-4 text-text-2',
            disabled ? 'hover:text-text-2' : 'hover:text-text-1',
          )}
        />
      )}
    </button>
  )
}

export default CopyButton
