import { Header } from '../../../../../Logs'

interface DraggingHapticsProps {
  overIndex: number | null
  draggingIndex: number | null
  mouseDelta: number | null
  headers: Header[]
  containerRef: React.RefObject<HTMLDivElement | null>
}

const DraggingHaptics = ({
  overIndex,
  draggingIndex,
  mouseDelta,
  headers,
  containerRef,
}: DraggingHapticsProps) => {
  if (draggingIndex === null) return null
  if (overIndex === null) return null

  const headerLeft = getHeaderLeft(draggingIndex, headers)
  const headerWidth = headers[draggingIndex].width
  const draggingBarLeft = getDraggingBarLeft(
    overIndex,
    draggingIndex,
    headers,
    containerRef,
  )
  const highlightWindowLeft = getHighlightWindowLeft(
    draggingIndex,
    headers,
    containerRef,
    mouseDelta,
  )

  return (
    <>
      <div
        className="absolute top-0 left-0 h-full bg-brand-infoTint border border-brand-info z-[2]"
        style={{ left: headerLeft, width: headerWidth }}
      />
      <div
        className="absolute top-0 left-0 h-full bg-input-tint z-[2]"
        style={{ left: highlightWindowLeft, width: headerWidth }}
      />
      <div
        className="absolute top-0 left-0 w-0.5 h-full bg-white z-[2]"
        style={{ left: draggingBarLeft }}
      />
    </>
  )
}

function getDraggingBarLeft(
  overIndex: number | null,
  draggingIndex: number | null,
  headers: Header[],
  containerRef: React.RefObject<HTMLDivElement | null>,
) {
  if (overIndex === null) return -1
  if (draggingIndex === null) return -1

  const container = containerRef.current
  if (!container) return -1

  let x = 0
  for (let i = 0; i < overIndex; i++) {
    x += headers[i].width
  }

  if (overIndex > draggingIndex && overIndex < headers.length) {
    x += headers[overIndex].width
  }

  return x
}

function getHighlightWindowLeft(
  draggingIndex: number | null,
  headers: Header[],
  containerRef: React.RefObject<HTMLDivElement | null>,
  mouseDelta: number | null,
) {
  if (draggingIndex === null) return -1

  const container = containerRef.current
  if (!container) return -1

  let x = 0
  for (let i = 0; i < draggingIndex; i++) {
    x += headers[i].width
  }

  return x + (mouseDelta ?? 0)
}

function getHeaderLeft(index: number, headers: Header[]) {
  let x = 0
  for (let i = 0; i < index; i++) {
    x += headers[i].width
  }
  return x
}

export default DraggingHaptics
