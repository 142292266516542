import { useEffect, useMemo, useState } from 'react'
import { alertsAtom, alertsQueryAtom } from '../../../../../state/state'
import { useAtomValue } from 'jotai'
import { Alert, AlertStatus } from '../../../../../backend/types'
import AlertHeaderRow from './AlertHeaderRow/AlertHeaderRow'
import AlertRow from './AlertRow/AlertRow'
import AlertActionPopup from './AlertActionPopup/AlertActionPopup'
import useUpdateAlerts from '../../../../../hooks/actions/useUpdateAlerts'

const AlertsList = () => {
  const updateAlerts = useUpdateAlerts()

  const alerts = useAtomValue(alertsAtom)
  const alertsQuery = useAtomValue(alertsQueryAtom)

  const [selectedAlerts, setSelectedAlerts] = useState<string[]>([])
  const selectedStatuses = useMemo(
    () => getStatuses(selectedAlerts, alerts ?? []),
    [selectedAlerts, alerts],
  )

  const handleToggleAll = () => {
    if (selectedAlerts.length === 0) {
      setSelectedAlerts(alerts?.map((alert) => alert.id) ?? [])
    } else {
      setSelectedAlerts([])
    }
  }

  const handleToggleAlert = (alertId: string) => {
    if (selectedAlerts.includes(alertId)) {
      setSelectedAlerts((prev) => prev.filter((id) => id !== alertId))
    } else {
      setSelectedAlerts((prev) => [...prev, alertId])
    }
  }

  const handleClearAll = () => {
    setSelectedAlerts([])
  }

  const handleCloseAll = async () => {
    await updateAlerts({ alert_ids: selectedAlerts, status: 'closed' })
    setSelectedAlerts([])
  }

  const handleSetClose = async (alertId: string) => {
    await updateAlerts({ alert_ids: [alertId], status: 'closed' })
  }

  useEffect(() => {
    setSelectedAlerts([])
  }, [alertsQuery])

  return (
    <div className="h-full w-full overflow-hidden flex flex-col bg-panel-bg border border-panel-border rounded">
      <AlertHeaderRow
        selectedCount={selectedAlerts.length}
        toggleAll={handleToggleAll}
      />
      <div className="w-full flex-1 flex flex-col overflow-y-auto">
        {alerts?.map((alert) => (
          <AlertRow
            key={alert.id}
            alert={alert}
            selected={selectedAlerts.includes(alert.id)}
            toggleAlert={() => handleToggleAlert(alert.id)}
            setClose={() => handleSetClose(alert.id)}
          />
        ))}
      </div>
      <AlertActionPopup
        alertIds={selectedAlerts}
        statuses={selectedStatuses}
        clearAll={handleClearAll}
        closeAll={handleCloseAll}
      />
    </div>
  )
}

function getStatuses(selectedAlerts: string[], alerts: Alert[]) {
  const statuses = new Set<AlertStatus>()
  for (const alertId of selectedAlerts) {
    const alert = alerts.find((alert) => alert.id === alertId)
    if (alert) {
      statuses.add(alert.status)
    }
  }
  return Array.from(statuses)
}

export default AlertsList
