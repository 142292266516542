import { cn } from '../../../utils/cn'

interface OutlineButtonSmallProps {
  children: React.ReactNode
  onClick?: () => void
  className?: string
}

const OutlineButtonSmall = ({
  children,
  onClick,
  className,
}: OutlineButtonSmallProps) => {
  return (
    <button
      className={cn(
        'px-2 h-[28px] flex items-center gap-2 rounded text-small-light text-text-2 hover:bg-input-tint hover:text-text-1',
        className,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default OutlineButtonSmall
