import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import './App.css'
import './index.css'
import Dashboard from './components/pages/Dashboard/Dashboard'
import Signup from './components/pages/Signup/Signup'
import Login from './components/pages/Login/Login'
import Posthog from './components/library/Posthog/Posthog'
import Logs from './components/pages/Dashboard/Logs/Logs'
import Settings from './components/pages/Dashboard/Settings/Settings'
import NewProject from './components/pages/NewProject/NewProject'
import NewOrganization from './components/pages/NewOrganization/NewOrganization'
import AuthSlack from './components/pages/AuthSlack/AuthSlack'
import ToastContainer from './components/library/ToastContainer/ToastContainer'
import SetupLogsNodeJS from './components/pages/Setup/Logs/SetupLogsNodeJS/SetupLogsNodeJS'
import SetupLogsCpp from './components/pages/Setup/Logs/SetupLogsCpp/SetupLogsCpp'
import SetupLogsDart from './components/pages/Setup/Logs/SetupLogsDart/SetupLogsDart'
import SetupLogsGo from './components/pages/Setup/Logs/SetupLogsGo/SetupLogsGo'
import SetupLogsNextJS from './components/pages/Setup/Logs/SetupLogsNextJS/SetupLogsNextJS'
import SetupLogsPython from './components/pages/Setup/Logs/SetupLogsPython/SetupLogsPython'
import SetupLogsRuby from './components/pages/Setup/Logs/SetupLogsRuby/SetupLogsRuby'
import SetupLogsRubyRails from './components/pages/Setup/Logs/SetupLogsRubyRails/SetupLogsRubyRails'
import SetupLogsRust from './components/pages/Setup/Logs/SetupLogsRust/SetupLogsRust'
import SetupLogsRustLog from './components/pages/Setup/Logs/SetupLogsRustLog/SetupLogsRustLog'
import SetupLogsRustTracing from './components/pages/Setup/Logs/SetupLogsRustTracing/SetupLogsRustTracing'
import SetupLogsRender from './components/pages/Setup/Logs/SetupLogsRender/SetupLogsRender'
import SetupLogsReact from './components/pages/Setup/Logs/SetupLogsReact/SetupLogsReact'
import Metrics from './components/pages/Dashboard/Metrics/Metrics'
import Analytics from './components/pages/Dashboard/Analytics/Analytics'
import SetupMetricsGo from './components/pages/Setup/Metrics/SetupMetricsGo/SetupMetricsGo'
import SetupMetricsNodeJS from './components/pages/Setup/Metrics/SetupMetricsNodeJS/SetupMetricsNodeJS'
import SetupLogsFlyIO from './components/pages/Setup/Logs/SetupLogsFlyIO/SetupLogsFlyIO'
import useObservability from './hooks/utils/useObservability'
import SetupLogsReactNative from './components/pages/Setup/Logs/SetupLogsReactNative/SetupLogsReactNative'
import ProjectGeneralSettings from './components/pages/Dashboard/Settings/ProjectSettings/ProjectGeneralSettings/ProjectGeneralSettings'
import ProjectAPIKeySettings from './components/pages/Dashboard/Settings/ProjectSettings/ProjectAPIKeySettings/ProjectAPIKeySettings'
import ProjectAlertsSettings from './components/pages/Dashboard/Settings/ProjectSettings/ProjectAlertsSettings/ProjectAlertsSettings'
import ProfileSettings from './components/pages/Dashboard/Settings/UserSettings/ProfileSettings/ProfileSettings'
import OrganizationGeneralSettings from './components/pages/Dashboard/Settings/OrganizationSettings/OrganizationGeneralSettings/OrganizationGeneralSettings'
import OrganizationTeamSettings from './components/pages/Dashboard/Settings/OrganizationSettings/OrganizationTeamSettings/OrganizationTeamSettings'
import OrganizationBillingSettings from './components/pages/Dashboard/Settings/OrganizationSettings/OrganizationBillingSettings/OrganizationBillingSettings'
import useSyncVersion from './hooks/utils/useSyncVersion'
import PortalRoot from './components/library/PortalRoot/PortalRoot'
import SetupMetricsPython from './components/pages/Setup/Metrics/SetupMetricsPython/SetupMetricsPython'
import Welcome from './components/pages/Welcome/Welcome'
import InviteTeam from './components/pages/InviteTeam/InviteTeam'
import InviteAccept from './components/pages/InviteAccept/InviteAccept'
import Start from './components/pages/Start/Start'
import SetupLogs from './components/pages/Setup/Logs/SetupLogs'
import SetupMetrics from './components/pages/Setup/Metrics/SetupMetrics'
import NotFound from './components/pages/NotFound/NotFound'
import AppError from './components/pages/AppError/AppError'
import Alerts from './components/pages/Dashboard/Alerts/Alerts'
import SetupAlerts from './components/pages/Setup/Alerts/SetupAlerts'
import SetupAlertsGo from './components/pages/Setup/Alerts/SetupAlertsGo/SetupAlertsGo'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Dashboard />,
    errorElement: <AppError />,
    children: [
      { index: true, element: <Navigate to="logs" replace={true} /> },
      { path: 'setup/logs', element: <SetupLogs /> },
      { path: 'setup/logs/cpp', element: <SetupLogsCpp /> },
      { path: 'setup/logs/dart', element: <SetupLogsDart /> },
      { path: 'setup/logs/go', element: <SetupLogsGo /> },
      { path: 'setup/logs/nextjs', element: <SetupLogsNextJS /> },
      { path: 'setup/logs/nodejs', element: <SetupLogsNodeJS /> },
      { path: 'setup/logs/react', element: <SetupLogsReact /> },
      {
        path: 'setup/logs/react-native',
        element: <SetupLogsReactNative />,
      },
      { path: 'setup/logs/python', element: <SetupLogsPython /> },
      { path: 'setup/logs/ruby', element: <SetupLogsRuby /> },
      { path: 'setup/logs/ruby-rails', element: <SetupLogsRubyRails /> },
      { path: 'setup/logs/rust', element: <SetupLogsRust /> },
      { path: 'setup/logs/rust-log', element: <SetupLogsRustLog /> },
      {
        path: 'setup/logs/rust-tracing',
        element: <SetupLogsRustTracing />,
      },
      { path: 'setup/logs/render', element: <SetupLogsRender /> },
      { path: 'setup/logs/flyio', element: <SetupLogsFlyIO /> },
      { path: 'setup/alerts', element: <SetupAlerts /> },
      { path: 'setup/alerts/go', element: <SetupAlertsGo /> },
      { path: 'setup/metrics', element: <SetupMetrics /> },
      { path: 'setup/metrics/go', element: <SetupMetricsGo /> },
      { path: 'setup/metrics/nodejs', element: <SetupMetricsNodeJS /> },
      { path: 'setup/metrics/python', element: <SetupMetricsPython /> },
      { path: 'logs', element: <Logs /> },
      { path: 'metrics', element: <Metrics /> },
      { path: 'analytics', element: <Analytics /> },
      { path: 'alerts', element: <Alerts /> },
      {
        path: 'settings',
        element: <Settings />,
        children: [
          {
            path: 'organization/general',
            element: <OrganizationGeneralSettings />,
          },
          {
            path: 'organization/team',
            element: <OrganizationTeamSettings />,
          },
          {
            path: 'organization/billing',
            element: <OrganizationBillingSettings />,
          },
          { path: 'project/general', element: <ProjectGeneralSettings /> },
          { path: 'project/api', element: <ProjectAPIKeySettings /> },
          { path: 'project/alerts', element: <ProjectAlertsSettings /> },
          { path: 'user/profile', element: <ProfileSettings /> },
        ],
      },
    ],
  },
  { path: '/login', element: <Login /> },
  { path: '/signup', element: <Signup /> },
  { path: '/welcome', element: <Welcome /> },
  { path: '/start', element: <Start /> },
  { path: '/organization', element: <NewOrganization /> },
  { path: '/project', element: <NewProject /> },
  { path: '/invite', element: <InviteTeam /> },
  { path: '/invite/accept', element: <InviteAccept /> },
  { path: '/auth/slack', element: <AuthSlack /> },
  { path: '*', element: <NotFound /> },
])

function App() {
  return (
    <VersionWrapper>
      <ObservabilityWrapper>
        <Posthog>
          <ErrorBoundary fallback={<AppError />}>
            <RouterProvider router={router} />
          </ErrorBoundary>
          <ToastContainer />
        </Posthog>
      </ObservabilityWrapper>
      <PortalRoot />
    </VersionWrapper>
  )
}

const ObservabilityWrapper = ({ children }: { children: React.ReactNode }) => {
  const ready = useObservability()
  return ready ? children : <></>
}

const VersionWrapper = ({ children }: { children: React.ReactNode }) => {
  useSyncVersion()
  return <>{children}</>
}

export default App
