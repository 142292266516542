import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import PreviewLogs from '../../../../library/PreviewLogs/PreviewLogs'
import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const SetupLogsReactNative = () => {
  useSetBrandColor('info')

  return (
    <SetupTemplate
      title="Log Setup - React Native"
      keyName="react-native-api-key"
      installStep={installSnippets}
      sendTitle="Send a log"
      sendEventStep={getSendMessageSnippets}
      preview={<PreviewLogs />}
      done={LinkKey.Logs}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'npm',
    language: 'bash',
    displayLanguage: 'bash',
    content: `npm install vigilant-react-native`,
  },
]

function getSendMessageSnippets(key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'React Native',
      language: 'javascript',
      displayLanguage: 'javascript',
      content: `import React from 'react';
import { View, Text } from 'react-native';
import { initLogger, enableLogAutocapture } from 'vigilant-react-native';

// Initialize the logger
initLogger({
  name: 'react-native-app',
  token: '${key}',
});

// Enable autocapture to read logs from console.log and console.error
enableLogAutocapture();

const SampleComponent = () => {
  console.log('User logged in');
  console.error('Database connection failed');
  return (
    <View>
      <Text>Hello World</Text>
    </View>
  )
}

export default SampleComponent;`,
    },
  ]
}

export default SetupLogsReactNative
