import useNav, { LinkKey } from '../../../../hooks/nav/useNav'
import SetupLogoButton from '../../../library/SetupLogoButton/SetupLogoButton'
import LogoIcon from '../../../library/LogoIcon/LogoIcon'
import NavbarPlaceholder from '../../../library/NavbarPlaceholder/NavbarPlaceholder'

const SetupLogs = () => {
  const navigate = useNav()

  const languageOptions = logLanguageOptions(navigate)
  const hostingOptions = logHostingOptions(navigate)

  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-start">
      <NavbarPlaceholder />
      <div className="px-6 py-12 w-full h-full max-w-[800px] flex flex-col items-start justify-start gap-8">
        <div className="w-full flex flex-col items-start justify-start gap-2">
          <h1 className="text-h1 text-text-1">Logging Setup</h1>
        </div>

        {languageOptions.length > 0 && (
          <>
            <div className="w-full flex flex-col items-start justify-start gap-2">
              <h2 className="text-h2 text-text-1">Language (recommended)</h2>
              <p className="text-body text-text-2">
                Send logs by using an SDK and adding a few lines of code to your
                app.
              </p>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-start">
              {languageOptions.map((option) => (
                <SetupLogoButton
                  key={option.title}
                  icon={option.icon}
                  title={option.title}
                  options={option.options}
                  onClick={option.onClick}
                />
              ))}
            </div>
          </>
        )}

        {hostingOptions.length > 0 && (
          <>
            <div className="w-full flex flex-col items-start justify-start gap-2">
              <h2 className="text-h2 text-text-1">Hosting Service</h2>
              <p className="text-body text-text-2">
                Send data directly from a hosting service.
              </p>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-start">
              {logHostingOptions(navigate).map((option) => (
                <SetupLogoButton
                  key={option.title}
                  icon={option.icon}
                  title={option.title}
                  onClick={option.onClick}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

function logLanguageOptions(navigate: (key: LinkKey) => void) {
  return [
    {
      icon: <LogoIcon logo="javascript" />,
      title: 'JavaScript',
      options: [
        {
          icon: <LogoIcon logo="nodejs" />,
          title: 'Node.js',
          onClick: () => navigate(LinkKey.SetupLogsNodeJS),
        },
        {
          icon: <LogoIcon logo="nextjs" />,
          title: 'Next.js',
          onClick: () => navigate(LinkKey.SetupLogsNextJS),
        },
        {
          icon: <LogoIcon logo="react" />,
          title: 'React',
          onClick: () => navigate(LinkKey.SetupLogsReact),
        },
        {
          icon: <LogoIcon logo="react" />,
          title: 'React Native',
          onClick: () => navigate(LinkKey.SetupLogsReactNative),
        },
      ],
    },
    {
      icon: <LogoIcon logo="python" />,
      title: 'Python',
      options: [],
      onClick: () => navigate(LinkKey.SetupLogsPython),
    },
    {
      icon: <LogoIcon logo="go" />,
      title: 'Go',
      options: [],
      onClick: () => navigate(LinkKey.SetupLogsGo),
    },
    {
      icon: <LogoIcon logo="ruby" />,
      title: 'Ruby',
      options: [
        {
          icon: <LogoIcon logo="rails" />,
          title: 'Ruby on Rails',
          onClick: () => navigate(LinkKey.SetupLogsRails),
        },
        {
          icon: <LogoIcon logo="ruby" />,
          title: 'Ruby',
          onClick: () => navigate(LinkKey.SetupLogsRuby),
        },
      ],
    },
    {
      icon: <LogoIcon logo="rust" />,
      title: 'Rust',
      options: [
        {
          icon: <LogoIcon logo="rust" />,
          title: 'Rust (Log crate)',
          onClick: () => navigate(LinkKey.SetupLogsRustLog),
        },
        {
          icon: <LogoIcon logo="rust" />,
          title: 'Rust (Tracing crate)',
          onClick: () => navigate(LinkKey.SetupLogsRustTracing),
        },
        {
          icon: <LogoIcon logo="rust" />,
          title: 'Rust',
          onClick: () => navigate(LinkKey.SetupLogsRust),
        },
      ],
    },
    {
      icon: <LogoIcon logo="dart" />,
      title: 'Dart',
      options: [],
      onClick: () => navigate(LinkKey.SetupLogsDart),
    },
    {
      icon: <LogoIcon logo="cpp" />,
      title: 'C++',
      options: [],
      onClick: () => navigate(LinkKey.SetupLogsCpp),
    },
  ]
}

function logHostingOptions(navigate: (key: LinkKey) => void) {
  return [
    {
      icon: <LogoIcon logo="render" />,
      title: 'Render',
      onClick: () => navigate(LinkKey.SetupLogsRender),
    },
    {
      icon: <LogoIcon logo="flyio" />,
      title: 'Fly.io',
      onClick: () => navigate(LinkKey.SetupLogsFlyIO),
    },
  ]
}

export default SetupLogs
