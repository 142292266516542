import { useAtomValue } from 'jotai'
import { userDataAtom } from '../../state/state'
import { captureAxiosError } from '../../utils/obeservability'
import useUpdateUserData from './useUpdateUserData'

const useAddRecentSearch = () => {
  const userData = useAtomValue(userDataAtom)
  const updateUserData = useUpdateUserData()

  const addRecentSearch = async (search: string) => {
    if (!userData || search === '') return

    const searches = [
      search,
      ...(userData.searches ?? []).filter((s) => s !== search && s !== ''),
    ]

    try {
      await updateUserData({
        ...userData,
        searches: searches.slice(0, 50),
      })
    } catch (e) {
      captureAxiosError(e)
    }
  }

  return addRecentSearch
}

export default useAddRecentSearch
