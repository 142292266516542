import { useCallback } from 'react'
import useBackend from '../../services/useBackend'
import { useSetAtom } from 'jotai'
import { analyticsLayoutAtom } from '../../../state/state'
import { captureAxiosError } from '../../../utils/obeservability'

const useLoadAnalyticsLayout = () => {
  const backend = useBackend()

  const setAnalyticsLayout = useSetAtom(analyticsLayoutAtom)

  const loadAnalyticsLayout = useCallback(async () => {
    try {
      const response = await backend.getAnalyticsLayout()
      setAnalyticsLayout(response.layout)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, setAnalyticsLayout])

  return loadAnalyticsLayout
}

export default useLoadAnalyticsLayout
