import { X } from 'lucide-react'
import SettingsSection from '../../../../../../library/SettingsSection/SettingsSection'
import SolidButton from '../../../../../../library/SolidButton/SolidButton'
import SettingsTitle from '../../../../../../library/SettingsTitle/SettingsTitle'
import SolidInput from '../../../../../../library/SolidInput/SolidInput'
import useUpdateAlertSettings from '../../../../../../../hooks/actions/useUpdateAlertSettings'
import { useAtomValue } from 'jotai'
import { alertSettingsAtom } from '../../../../../../../state/state'
import { useState, useMemo } from 'react'
import { UpdateAlertSettingsRequest } from '../../../../../../../backend/types'
import { AlertActionEmail } from '../../../../../../../backend/types'
import { AlertSettings } from '../../../../../../../backend/types'
import { isValidEmail } from '../../../../../../../utils/inputs'
import SolidIconButton from '../../../../../../library/SolidIconButton/SolidIconButton'

const AlertEmailSettings = () => {
  const updateAlertSettings = useUpdateAlertSettings()

  const alertSettings = useAtomValue(alertSettingsAtom)
  const emailAction = useMemo(
    () => getEmailAction(alertSettings),
    [alertSettings],
  )

  const [isAddingEmail, setIsAddingEmail] = useState(false)

  const handleToggleEmailEnabled = async () => {
    if (!emailAction || !alertSettings) return
    const updatedSettings = toggleEmail(alertSettings, emailAction)
    await updateAlertSettings(updatedSettings)
  }

  const handleAddEmailAction = async (value: string) => {
    if (!emailAction || !alertSettings || !isAddingEmail) return
    if (isValidEmail(value)) {
      const updatedSettings = addEmail(alertSettings, emailAction, value)
      await updateAlertSettings(updatedSettings)
      setIsAddingEmail(false)
    } else {
      setIsAddingEmail(false)
    }
  }

  const handleRemoveEmailAction = async (index: number) => {
    if (!emailAction || !alertSettings) return
    const updatedSettings = removeEmail(alertSettings, emailAction, index)
    await updateAlertSettings(updatedSettings)
  }

  const handleUpdateEmailAction = async (index: number, value: string) => {
    if (!emailAction || !alertSettings) return
    const updatedSettings = updateEmail(
      alertSettings,
      emailAction,
      index,
      value,
    )
    await updateAlertSettings(updatedSettings)
  }

  return (
    <SettingsSection>
      <SettingsTitle
        title="Email Alerts"
        description="When you get an alert, send an email"
        button={emailAction?.enabled ? 'Enabled' : 'Disabled'}
        mode={emailAction?.enabled ? 'success' : 'error'}
        onClick={handleToggleEmailEnabled}
      />
      <div className="w-full max-w-[400px] py-4 flex flex-col gap-2">
        <p className="text-small-light text-text-2">Emails</p>
        <div className="flex flex-col items-start gap-2">
          {emailAction && (
            <>
              {emailAction.settings.emails.map((email, index) => (
                <div className="w-full flex gap-2">
                  <SolidInput
                    value={email}
                    onSubmit={(value) => handleUpdateEmailAction(index, value)}
                    validFn={isValidEmail}
                    managed={true}
                  />
                  <SolidIconButton
                    icon={<X size={16} />}
                    onClick={() => handleRemoveEmailAction(index)}
                  />
                </div>
              ))}
              {isAddingEmail && (
                <div className="w-full flex gap-2">
                  <SolidInput
                    value={''}
                    onSubmit={handleAddEmailAction}
                    validFn={isValidEmail}
                    managed={true}
                    autoFocus={true}
                  />
                  <SolidIconButton
                    icon={<X size={16} />}
                    onClick={() => setIsAddingEmail(false)}
                  />
                </div>
              )}
              <SolidButton
                onClick={() => setIsAddingEmail(true)}
                disabled={isAddingEmail}
              >
                Add Email
              </SolidButton>
            </>
          )}
        </div>
      </div>
    </SettingsSection>
  )
}

const getEmailAction = (
  settings: AlertSettings | null,
): AlertActionEmail | null => {
  if (!settings) return null
  return settings.actions.find((a) => a.type === 'email') ?? null
}

function updateEmail(
  settings: AlertSettings,
  emailAction: AlertActionEmail,
  index: number,
  value: string,
): UpdateAlertSettingsRequest {
  return {
    settings_id: settings.id,
    actions: [
      ...settings.actions.filter((a) => a.type !== 'email'),
      {
        ...emailAction,
        settings: {
          ...emailAction.settings,
          emails: [
            ...emailAction.settings.emails.slice(0, index),
            value,
            ...emailAction.settings.emails.slice(index + 1),
          ],
        },
      },
    ],
  }
}

function addEmail(
  settings: AlertSettings,
  emailAction: AlertActionEmail,
  value: string,
): UpdateAlertSettingsRequest {
  return {
    settings_id: settings.id,
    actions: [
      ...settings.actions.filter((a) => a.type !== 'email'),
      {
        ...emailAction,
        settings: {
          ...emailAction.settings,
          emails: [...emailAction.settings.emails, value],
        },
      },
    ],
  }
}

function removeEmail(
  settings: AlertSettings,
  emailAction: AlertActionEmail,
  index: number,
): UpdateAlertSettingsRequest {
  return {
    settings_id: settings.id,
    actions: [
      ...settings.actions.filter((a) => a.type !== 'email'),
      {
        ...emailAction,
        settings: {
          ...emailAction.settings,
          emails: emailAction.settings.emails.filter((_, i) => i !== index),
        },
      },
    ],
  }
}

function toggleEmail(
  settings: AlertSettings,
  emailAction: AlertActionEmail,
): UpdateAlertSettingsRequest {
  return {
    settings_id: settings.id,
    actions: [
      ...settings.actions.filter((a) => a.type !== 'email'),
      {
        ...emailAction,
        enabled: !emailAction.enabled,
      },
    ],
  }
}

export default AlertEmailSettings
