export const getModifierKey = (): string => {
  const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.platform)
  return isMac ? '⌘' : 'Ctrl'
}

export const isCommandKey = (e: MouseEvent): boolean => {
  const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.platform)
  return isMac ? e.metaKey : e.altKey
}

export const doesClickContainElement = (
  event: React.MouseEvent,
  ref: React.RefObject<HTMLElement | null>,
): boolean => {
  if (!ref.current) return false
  return ref.current.contains(event.target as Node)
}
