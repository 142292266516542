interface OutlineButtonProps {
  children: React.ReactNode
  onClick?: () => void
}

const OutlineButton = ({ children, onClick }: OutlineButtonProps) => {
  return (
    <button
      className={`px-[14px] h-8 flex-none flex items-center rounded-[4px] text-small-light text-text-2 hover:bg-input-tint hover:text-text-1`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default OutlineButton
