import { useCallback } from 'react'
import { captureAxiosError } from '../../../utils/obeservability'
import useBackend from '../../services/useBackend'

const useLoadAlertEvents = () => {
  const backend = useBackend()

  const loadAlertEvents = useCallback(
    async (alertID: string) => {
      try {
        const { events } = await backend.getAlertEvents(alertID)
        return events
      } catch (e) {
        captureAxiosError(e)
        return []
      }
    },
    [backend],
  )

  return loadAlertEvents
}

export default useLoadAlertEvents
