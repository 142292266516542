import { useRef } from 'react'

interface TextInputProps {
  value: string
  type?: 'text' | 'password' | 'email' | 'number'
  placeholder?: string
  onChange: (value: string) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const TextInput = ({
  value,
  type = 'text',
  placeholder = '',
  onChange,
  onKeyDown,
}: TextInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select()
    }
  }

  return (
    <input
      ref={inputRef}
      className="w-full h-9 px-3 py-1 text-input text-text-1 bg-input-bgTint border border-input-border rounded placeholder:text-text-2 hover:bg-input-tint focus:bg-input-tint outline-none"
      type={type}
      value={value}
      placeholder={placeholder}
      onFocus={handleFocus}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={onKeyDown}
    />
  )
}

export default TextInput
