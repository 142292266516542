import { useState } from 'react'
import { cn } from '../../../utils/cn'

interface ButtonProps {
  buttonRef?: React.RefObject<HTMLButtonElement | null>
  active?: boolean
  disabled?: boolean
  icon?: React.ReactNode
  children: React.ReactNode
  onClick?: () => void
  href?: string
}

const Button = ({
  buttonRef,
  children,
  active = false,
  disabled = false,
  icon = null,
  onClick,
  href,
}: ButtonProps) => {
  const [hover, setHover] = useState(false)
  const hoverClass = hover && !disabled ? 'bg-input-tint' : 'bg-transparent'
  const textClass =
    (hover || active) && !disabled ? 'text-text-1' : 'text-text-2'
  const disabledClass = disabled ? 'opacity-60' : 'opacity-100'

  const buttonContent = (
    <button
      ref={buttonRef}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`px-2 py-1 flex items-center justify-start gap-2 rounded-[4px] ${disabledClass} ${hoverClass} whitespace-nowrap outline-none`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <div className="w-4 h-4 text-text-2">{icon}</div>}
      <p className={cn('text-input', textClass)}>{children}</p>
    </button>
  )

  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block"
      >
        {buttonContent}
      </a>
    )
  }

  return buttonContent
}

export default Button
