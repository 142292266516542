import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import PreviewMetrics from '../../../../library/PreviewMetrics/PreviewMetrics'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const SetupMetricsNodeJS = () => {
  useSetBrandColor('success')

  return (
    <SetupTemplate
      title="Metrics Setup - NodeJS"
      keyName="nodejs-api-key"
      installStep={installSnippets}
      sendTitle="Send a metric"
      sendEventStep={getSendMetricsSnippets}
      preview={<PreviewMetrics />}
      done={LinkKey.Metrics}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'npm',
    language: 'bash',
    displayLanguage: 'bash',
    content: `npm install vigilant-js`,
  },
  {
    title: 'yarn',
    language: 'bash',
    displayLanguage: 'bash',
    content: `yarn add vigilant-js`,
  },
  {
    title: 'bun',
    language: 'bash',
    displayLanguage: 'bash',
    content: `bun install vigilant-js`,
  },
]

function getSendMetricsSnippets(key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'NodeJS',
      language: 'javascript',
      displayLanguage: 'javascript',
      content: `import { initMetricsHandler, shutdownMetricsHandler, emitMetric } from "vigilant-js";

// Initialize the metrics handler
initMetricsHandler({
  name: "my-service",
  token: "${key}",
});

// Emit a metric
emitMetric("http_response_time", 100.0, { path: "/api/login" });

// Call shutdownMetricsHandler() before your app terminates
await shutdownMetricsHandler();`,
    },
  ]
}

export default SetupMetricsNodeJS
