import BrandButton from '../BrandButton/BrandButton'

interface SettingsTitleProps {
  title: string
  description: string
  button?: string
  mode?: 'info' | 'warning' | 'error' | 'success' | 'default'
  onClick?: () => void
}

const SettingsTitle = ({
  title,
  description,
  button,
  mode,
  onClick,
}: SettingsTitleProps) => {
  return (
    <div className="w-full py-3 flex items-center justify-start gap-6 border-b border-panel-border">
      <div className="flex-1 flex flex-col items-start justify-start gap-2">
        <div className="text-h2 text-text-1">{title}</div>
        <div className="text-body text-text-1">{description}</div>
      </div>
      {button && (
        <BrandButton mode={mode} onClick={onClick}>
          {button}
        </BrandButton>
      )}
    </div>
  )
}

export default SettingsTitle
