import { LogsTTL, MetricsTTL } from '../state/types'

type PricingMatrix = {
  logs: { [key in LogsTTL]: number }
  metrics: { [key in MetricsTTL]: number }
}

type FreeTierMatrix = {
  logs: number
  metrics: number
}

export const PRICING_MATRIX: PricingMatrix = {
  logs: {
    '1_day': 0.5 / 1_000_000_000,
    '3_days': 0.6 / 1_000_000_000,
    '7_days': 0.7 / 1_000_000_000,
    '14_days': 0.85 / 1_000_000_000,
    '30_days': 1 / 1073_741_824,
    '60_days': 1.2 / 1073_741_824,
    '90_days': 1.4 / 1073_741_824,
  },
  metrics: {
    '1_month': 1 / 1_000_000,
    '3_months': 1.5 / 1_000_000,
    '6_months': 2 / 1_000_000,
    '1_year': 3 / 1_000_000,
  },
}

export const FREE_TIER_MATRIX: FreeTierMatrix = {
  logs: 50_000_000_000,
  metrics: 1_000_000,
}
