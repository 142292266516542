import {
  useState,
  RefObject,
  useMemo,
  useLayoutEffect,
  CSSProperties,
} from 'react'

type Position = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

interface PositionCoordinates {
  top: number
  left: number
}

interface PositionOffset {
  x?: number
  y?: number
}

const usePopupPosition = (
  isVisible: boolean = false,
  triggerRef: RefObject<HTMLElement | null>,
  popupRef: RefObject<HTMLElement | null>,
  position: Position = 'bottom-left',
  offset: PositionOffset = { x: 0, y: 0 },
): CSSProperties => {
  const [coordinates, setCoordinates] = useState<PositionCoordinates>({
    top: 0,
    left: 0,
  })
  const [isRefLoaded, setIsRefLoaded] = useState(false)
  const [isMeasured, setIsMeasured] = useState(false)

  const memoizedOffset = useMemo(
    () => ({ x: offset.x || 0, y: offset.y || 0 }),
    [offset.x, offset.y],
  )

  const memoizedStyle = useMemo((): CSSProperties => {
    const baseStyles: CSSProperties = {
      position: 'absolute',
      top: coordinates.top,
      left: coordinates.left,
    }
    if (!isVisible || !isMeasured) {
      return { ...baseStyles, opacity: 0, pointerEvents: 'none' }
    } else {
      return { ...baseStyles, opacity: 1, pointerEvents: 'auto' }
    }
  }, [coordinates, isVisible, isMeasured])

  useLayoutEffect(() => {
    if (!isVisible) {
      setCoordinates({ top: 0, left: 0 })
      setIsRefLoaded(false)
      setIsMeasured(false)
    }
  }, [isVisible])

  useLayoutEffect(() => {
    if (!isVisible) return

    let observer: MutationObserver | null = null

    if (popupRef.current) {
      setIsRefLoaded(true)
    } else {
      observer = new MutationObserver(() => {
        if (popupRef.current) {
          setIsRefLoaded(true)
          observer?.disconnect()
          observer = null
        }
      })
      observer.observe(document.body, { childList: true, subtree: true })
    }

    return () => {
      if (observer) {
        observer.disconnect()
        observer = null
      }
    }
  }, [popupRef, isVisible])

  useLayoutEffect(() => {
    if (!isRefLoaded || !isVisible || !triggerRef.current || !popupRef.current)
      return

    const triggerElem = triggerRef.current
    const popupElem = popupRef.current

    const calculatePosition = () => {
      const triggerRect = triggerElem.getBoundingClientRect()
      const popupRect = popupElem.getBoundingClientRect()

      const x = calculateXPosition(triggerRect, position, memoizedOffset.x)
      const y = calculateYPosition(triggerRect, position, memoizedOffset.y)

      setIsMeasured(true)
      setCoordinates(
        constrainToViewport(
          x,
          y,
          popupRect.width,
          popupRect.height,
          position,
          triggerElem,
        ),
      )
    }

    const scrollableParent = findScrollableParent(triggerElem)
    const handleScroll = () => requestAnimationFrame(calculatePosition)

    let resizeTimeoutId: NodeJS.Timeout | null = null
    const handleResize = () => {
      requestAnimationFrame(calculatePosition)
    }

    calculatePosition()
    window.addEventListener('resize', handleResize)

    if (scrollableParent instanceof Window) {
      window.addEventListener('scroll', handleScroll, { passive: true })
    } else {
      scrollableParent.addEventListener('scroll', handleScroll, {
        passive: true,
      })
    }

    let resizeObserver: ResizeObserver | null = null
    if ('ResizeObserver' in window) {
      resizeObserver = new ResizeObserver(calculatePosition)
      resizeObserver.observe(triggerElem)
      resizeObserver.observe(popupElem)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
      if (scrollableParent instanceof Window) {
        window.removeEventListener('scroll', handleScroll)
      } else {
        scrollableParent.removeEventListener('scroll', handleScroll)
      }
      if (resizeTimeoutId) clearTimeout(resizeTimeoutId)
      if (resizeObserver) resizeObserver.disconnect()
    }
  }, [position, memoizedOffset, isRefLoaded, isVisible, triggerRef, popupRef])

  return memoizedStyle
}

const findScrollableParent = (element: HTMLElement): HTMLElement | Window => {
  let parent = element.parentElement

  while (parent) {
    const style = window.getComputedStyle(parent)
    const overflow =
      style.getPropertyValue('overflow') + style.getPropertyValue('overflow-y')

    if (overflow.includes('scroll') || overflow.includes('auto')) {
      return parent
    }

    parent = parent.parentElement
  }

  return window
}

const calculateXPosition = (
  triggerRect: DOMRect,
  position: Position,
  offsetX: number,
): number => {
  if (position.includes('left')) {
    return triggerRect.left + offsetX
  } else if (position.includes('right')) {
    return triggerRect.right + offsetX
  }
  return 0
}

const calculateYPosition = (
  triggerRect: DOMRect,
  position: Position,
  offsetY: number,
): number => {
  if (position.includes('top')) {
    return triggerRect.top + offsetY
  } else if (position.includes('bottom')) {
    return triggerRect.bottom + offsetY
  }
  return 0
}

const constrainToViewport = (
  x: number,
  y: number,
  popupWidth: number,
  popupHeight: number,
  position: Position,
  triggerElement: HTMLElement,
): PositionCoordinates => {
  const viewportWidth = window.innerWidth
  const viewportHeight = window.innerHeight

  if (position.includes('bottom') && y + popupHeight > viewportHeight - 4) {
    const triggerRect = triggerElement.getBoundingClientRect()
    y = triggerRect.top - popupHeight - 4
  }

  const maxX = viewportWidth - popupWidth - 4
  const maxY = viewportHeight - popupHeight - 4

  return {
    left: Math.min(maxX, Math.max(4, x)),
    top: Math.min(maxY, Math.max(4, y)),
  }
}

export default usePopupPosition
