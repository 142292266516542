import { useState } from 'react'

interface IconButtonProps {
  buttonRef?: React.RefObject<HTMLButtonElement | null>
  icon: React.ReactNode
  size?: '24' | '28' | '32'
  disabled?: boolean
  active?: boolean
  onClick?: () => void
}

const IconButton = ({
  buttonRef,
  icon,
  size = '24',
  disabled = false,
  active = false,
  onClick,
}: IconButtonProps) => {
  const [hover, setHover] = useState(false)
  const textClass =
    (hover && !disabled) || active ? 'text-text-1' : 'text-text-2'
  const bgClass =
    (hover && !disabled) || active ? 'bg-input-tint' : 'bg-input-bg'
  const disabledClass = disabled ? 'opacity-60' : 'opacity-100'
  const sizeClass = getSizeClass(size)

  return (
    <button
      ref={buttonRef}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`${sizeClass} flex-none flex items-center justify-center rounded ${textClass} ${bgClass} ${disabledClass}`}
      disabled={disabled}
      onClick={onClick}
    >
      {icon}
    </button>
  )
}

function getSizeClass(size: '24' | '28' | '32') {
  switch (size) {
    case '24':
      return 'w-6 h-6'
    case '28':
      return 'w-7 h-7'
    case '32':
      return 'w-8 h-8'
  }
}

export default IconButton
