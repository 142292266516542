import { RefObject, useEffect } from 'react'

const useFocusOnClick = (
  containerRef: RefObject<HTMLDivElement | null>,
  inputRef: RefObject<HTMLInputElement | null>,
  selectOnFocus: boolean = true,
) => {
  useEffect(() => {
    const container = containerRef.current
    const input = inputRef.current

    if (!container || !input) return

    const handleClick = (event: MouseEvent) => {
      if (event.target === input) return
      input.focus()
      if (selectOnFocus) {
        input.select()
      }
    }

    container.addEventListener('click', handleClick)
    return () => container.removeEventListener('click', handleClick)
  }, [containerRef, inputRef, selectOnFocus])
}

export default useFocusOnClick
