import { useSetAtom, useAtomValue } from 'jotai'
import { alertsAtom, alertsQueryAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'
import { captureAxiosError } from '../../../utils/obeservability'

const useLoadAlerts = () => {
  const backend = useBackend()
  const setAlerts = useSetAtom(alertsAtom)
  const query = useAtomValue(alertsQueryAtom)

  const loadAlerts = useCallback(async () => {
    try {
      const { alerts } = await backend.getAlerts(query)
      setAlerts(alerts)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, setAlerts, query])

  return loadAlerts
}

export default useLoadAlerts
