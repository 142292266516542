import { useAtomValue } from 'jotai'
import { hasAlertsAtom } from '../../../../../state/state'
import EmptyDataPlaceholder from '../../../../library/EmptyDataPlaceholder/EmptyDataPlaceholder'
import { LinkKey } from '../../../../../hooks/nav/useNav'

const AlertsOnboardingDisplay = () => {
  const hasalerts = useAtomValue(hasAlertsAtom)
  return (
    hasalerts === false && (
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center pointer-events-none">
        <EmptyDataPlaceholder
          imgUrl={'/onboarding/alerts.svg'}
          title="Alerts"
          paragraphs={[
            'Vigilant lets you send alerts when your application is experiencing issues.',
            'Get started by setting up alerts for your application.',
          ]}
          ctaText="Setup Alerts"
          ctaLink={LinkKey.SetupAlerts}
        />
      </div>
    )
  )
}

export default AlertsOnboardingDisplay
