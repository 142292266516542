import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import PreviewLogs from '../../../../library/PreviewLogs/PreviewLogs'
import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const SetupLogsGo = () => {
  useSetBrandColor('info')

  return (
    <SetupTemplate
      title="Log Setup - Go"
      keyName="go-api-key"
      installStep={installSnippets}
      sendTitle="Send a log"
      sendEventStep={getSendMessageSnippets}
      preview={<PreviewLogs />}
      done={LinkKey.Logs}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'Go',
    language: 'go',
    displayLanguage: 'go',
    content: `go get github.com/vigilant-run/vigilant-golang`,
  },
]

function getSendMessageSnippets(key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'Go',
      language: 'go',
      displayLanguage: 'go',
      content: `package main

import (
  "github.com/vigilant-run/vigilant-golang/v2"
)

func main() {
  // Create the logger
  config := vigilant.NewLoggerConfigBuilder().
    WithName("sample-app").
    WithToken("${key}").
    Build()

  // Initialize the logger
  vigilant.InitLogger(config)

  // Log a message
  vigilant.LogInfo("Hello, World!")

  // Shutdown the logger
  vigilant.ShutdownLogger()
}`,
    },
  ]
}

export default SetupLogsGo
