import { useRef } from 'react'
import { LogEntry } from '../../../../../../../state/types'
import { Header } from '../../../Logs'
import LoadingDots from '../../../../../../library/LoadingDots/LoadingDots'
import LogHeaderRow from './LogHeaderRow/LogHeaderRow'
import LogRow from './LogRow/LogRow'
import { QueryMode } from '../../../../../../../library/query/types'
import { hasLogsAtom } from '../../../../../../../state/state'
import { useAtomValue } from 'jotai'
import { Filter } from '../../../../../../../backend/types'

interface LogContainerProps {
  logs: LogEntry[]
  newLogs: string[]

  term: string

  queryMode: QueryMode
  atStart: boolean
  atEnd: boolean

  headers: Header[]
  setHeaders: (headers: Header[]) => void
  toggleHeader: (header: string) => void

  filters: Filter[]
  setFilters: (filters: Filter[]) => void
}

const LogContainer = ({
  logs,
  newLogs,
  term,
  queryMode,
  atEnd,
  headers,
  setHeaders,
  toggleHeader,
  filters,
  setFilters,
}: LogContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const hasLogs = useAtomValue(hasLogsAtom)

  const addEqualsFilter = (property: string, value: string) => {
    setFilters([
      ...filters.filter((f) => f.field !== property),
      { field: property, operator: 'equals', value },
    ])
  }

  return headers.length > 0 ? (
    <div
      ref={containerRef}
      className="relative w-fit flex flex-col bg-main-bg z-[0]"
    >
      <div className="relative flex flex-col">
        {logs.length > 0 && (
          <LogHeaderRow
            containerRef={containerRef}
            headers={headers}
            setHeaders={setHeaders}
            toggleHeader={toggleHeader}
            filters={filters}
            setFilters={setFilters}
          />
        )}
        {logs.map((log, index) => (
          <LogRow
            key={`${log.log_id}-${index}`}
            isNew={newLogs.includes(log.log_id)}
            log={log}
            term={term}
            columns={headers}
            addEqualsFilter={addEqualsFilter}
          />
        ))}
      </div>
      <div className="py-8 w-[calc(100vw-280px)] flex justify-center items-center text-small-code text-text-1">
        {getMessage(hasLogs, logs, queryMode, atEnd)}
      </div>
      <div className="h-64"></div>
    </div>
  ) : (
    <div className="py-8 w-[calc(100vw-280px)] flex justify-center items-center text-small-code text-text-1">
      Add headers on the left to start viewing logs.
    </div>
  )
}

function getMessage(
  hasLogs: boolean | null,
  logs: LogEntry[],
  queryMode: QueryMode,
  atEnd: boolean,
): React.ReactNode {
  if (queryMode === 'initial_load') {
    return (
      <div className="w-[120px] flex items-center">
        Fetching logs
        <LoadingDots />
      </div>
    )
  } else if (queryMode === 'user_query') {
    return (
      <div className="w-[156px] flex items-center">
        Running query
        <LoadingDots />
      </div>
    )
  } else if (queryMode === 'loading_more_down') {
    return (
      <div className="w-[156px] flex items-center">
        Fetching more logs
        <LoadingDots />
      </div>
    )
  } else if (queryMode === 'none' || queryMode === 'live_tick') {
    if (!hasLogs) return
    if (atEnd) {
      if (logs.length === 0) {
        return 'No logs found matching this query.'
      } else if (logs.length > 0) {
        return "That's all the logs for this query."
      }
    } else {
      return (
        <div className="w-[156px] flex items-center">
          Fetching more logs
          <LoadingDots />
        </div>
      )
    }
  }
  return <></>
}

export default LogContainer
