import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import PreviewLogs from '../../../../library/PreviewLogs/PreviewLogs'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const SetupLogsRust = () => {
  useSetBrandColor('info')

  return (
    <SetupTemplate
      title="Log Setup - Rust"
      keyName="rust-api-key"
      installStep={installSnippets}
      sendTitle="Send a log"
      sendEventStep={getSendMessageSnippets}
      preview={<PreviewLogs />}
      done={LinkKey.Logs}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'cargo',
    language: 'bash',
    displayLanguage: 'bash',
    content: `cargo add vigilant`,
  },
]

function getSendMessageSnippets(key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'Rust',
      language: 'rust',
      displayLanguage: 'rust',
      content: `use vigilant::LoggerBuilder;

// Initialize the logger
let mut logger = LoggerBuilder::new()
  .name("my-service")
  .token("${key}")
  .build();

// Send a log
logger.info("Hello, World!");

// Shutdown the logger
let _ = logger.shutdown();`,
    },
  ]
}

export default SetupLogsRust
