import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import PreviewLogs from '../../../../library/PreviewLogs/PreviewLogs'
import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const { REACT_APP_INGRESS_ADDRESS } = process.env
const INGRESS_ADDRESS = REACT_APP_INGRESS_ADDRESS || 'ingress.vigilant.run'

const SetupLogsPython = () => {
  useSetBrandColor('info')

  return (
    <SetupTemplate
      title="Log Setup - Python"
      keyName="python-api-key"
      installStep={installSnippets}
      sendTitle="Send a log"
      sendEventStep={getSendMessageSnippets(INGRESS_ADDRESS)}
      preview={<PreviewLogs />}
      done={LinkKey.Logs}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'pip',
    language: 'bash',
    displayLanguage: 'bash',
    content: `pip install vigilant-py`,
  },
]

function getSendMessageSnippets(
  ingressAddress: string,
): (key: string) => CodeSnippetDetails[] {
  return (key) => [
    {
      title: 'Python',
      language: 'python',
      displayLanguage: 'python',
      content: `from vigilant import Logger

# Initialize the logger
logger = Logger(
    name="my-service",
    endpoint="${ingressAddress}",
    token="${key}",
)

# Start reading logs from the console
logger.autocapture_enable()

# Autocapture print statements
print("Hello, World!")

# Log a message using the logger
logger.info("Hello, World!")

# Shutdown the logger before application exit
logger.shutdown()`,
    },
  ]
}

export default SetupLogsPython
