import { Play, Settings2, X } from 'lucide-react'
import { useAtomValue } from 'jotai'
import { userDataAtom } from '../../../../../../../state/state'
import { useRef, useState } from 'react'
import { cn } from '../../../../../../../utils/cn'
import Portal from '../../../../../../library/Portal/Portal'
import usePopupPosition from '../../../../../../../hooks/utils/usePopupPosition'
import usePopupStack from '../../../../../../../hooks/utils/usePopupStack'
import usePopupPauseScroll from '../../../../../../../hooks/utils/usePopupPauseScroll'
import IconButton from '../../../../../../library/IconButton/IconButton'
import PopupToggle from '../../../../../../library/PopupToggle/PopupToggle'
import useUpdateUserData from '../../../../../../../hooks/actions/useUpdateUserData'

const SettingsButton = () => {
  const updateUserData = useUpdateUserData()

  const userData = useAtomValue(userDataAtom)

  const buttonRef = useRef<HTMLButtonElement>(null)
  const portalRef = useRef<HTMLDivElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)

  usePopupStack(popupRef, () => setOpen(false), buttonRef)
  usePopupPauseScroll(portalRef, open)
  const popupStyle = usePopupPosition(
    open,
    buttonRef,
    popupRef,
    'bottom-right',
    { y: 4, x: -280 },
  )

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <button
        ref={buttonRef}
        className={cn(
          'p-2 flex items-center justify-center gap-2 rounded-[4px] border border-input-border bg-input-bgTint outline-none hover:bg-input-tint hover:text-text-1',
          open ? 'bg-input-tint text-text-1' : 'bg-input-bgTint text-text-2',
        )}
        onClick={handleClick}
      >
        <Settings2 size={16} />
      </button>
      <Portal ref={portalRef}>
        <div
          ref={popupRef}
          className="absolute w-[280px] flex flex-col bg-popup-bg border border-popup-border rounded shadow-popup-shadow"
          style={{ ...popupStyle }}
        >
          <div className="w-full p-2 flex items-center gap-2 border-b border-popup-border">
            <div className="w-full pl-2 flex items-center justify-between">
              <p className="text-small-medium text-text-1">
                Log Display Settings
              </p>
              <IconButton
                icon={<X size={16} />}
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
          <div className="w-full p-2 py-[10px] flex flex-col gap-2">
            <div className="w-full px-2 flex items-center justify-between">
              <p className="text-mini-medium text-text-2">Live</p>
            </div>
            <PopupToggle
              icon={<Play size={16} />}
              title="Default live"
              value={userData?.logs_live_default ?? false}
              onUpdateValue={(value) => {
                updateUserData({ logs_live_default: value })
              }}
            />
          </div>
        </div>
      </Portal>
    </>
  )
}

export default SettingsButton
