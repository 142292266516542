import { useEffect } from 'react'
import useLoadOrganizationInfo from '../load/useLoadOrganizationInfo'
import useLoadOrganizations from '../load/useLoadOrganizations'
import useLoadProjects from '../load/useLoadProjects'
import useLoadUserInfo from '../load/useLoadUserInfo'
import useLoadUserData from '../load/useLoadUserData'
import useLoadBillingInfo from '../load/useLoadBillingInfo'
import useLoadOrganizationMembers from '../load/useLoadOrganizationMembers'

const useInitAdminData = () => {
  const loadUserInfo = useLoadUserInfo()
  const loadUserData = useLoadUserData()
  const loadOrganizations = useLoadOrganizations()
  const loadOrganizationMembers = useLoadOrganizationMembers()
  const loadOrganizationInfo = useLoadOrganizationInfo()
  const loadBillingInfo = useLoadBillingInfo()
  const loadProjects = useLoadProjects()

  useEffect(() => {
    loadUserInfo()
  }, [loadUserInfo])

  useEffect(() => {
    loadUserData()
  }, [loadUserData])

  useEffect(() => {
    loadOrganizations()
  }, [loadOrganizations])

  useEffect(() => {
    loadOrganizationInfo()
  }, [loadOrganizationInfo])

  useEffect(() => {
    loadOrganizationMembers()
  }, [loadOrganizationMembers])

  useEffect(() => {
    loadBillingInfo()
  }, [loadBillingInfo])

  useEffect(() => {
    loadProjects()
  }, [loadProjects])
}

export default useInitAdminData
