import { RefreshCcw } from 'lucide-react'

interface RefreshButtonProps {
  runSearch: () => Promise<void>
}

const RefreshButton = ({ runSearch }: RefreshButtonProps) => {
  return (
    <button
      className={`p-2 flex items-center justify-center gap-2 rounded-[4px] text-text-2 border border-input-border bg-input-bgTint outline-none hover:bg-input-tint hover:text-text-1`}
      onClick={runSearch}
    >
      <RefreshCcw size={16} />
    </button>
  )
}

export default RefreshButton
