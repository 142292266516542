import { cn } from '../../../utils/cn'

interface SolidIconButtonProps {
  icon: React.ReactNode
  disabled?: boolean
  onClick?: () => void
}

const SolidIconButton = ({
  icon,
  disabled = false,
  onClick,
}: SolidIconButtonProps) => {
  return (
    <button
      className={cn(
        'w-8 h-8 flex-none flex items-center justify-center rounded border border-input-border text-text-1 bg-input-bgTint hover:bg-input-tint',
        disabled ? 'opacity-60' : 'opacity-100',
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {icon}
    </button>
  )
}

export default SolidIconButton
