import SettingsSection from '../../../../../../library/SettingsSection/SettingsSection'
import SettingsTitle from '../../../../../../library/SettingsTitle/SettingsTitle'
import useUpdateAlertSettings from '../../../../../../../hooks/actions/useUpdateAlertSettings'
import { useAtomValue } from 'jotai'
import {
  alertSettingsAtom,
  integrationsAtom,
  integrationDetailsAtom,
} from '../../../../../../../state/state'
import { useMemo } from 'react'
import {
  AlertSettings,
  AlertActionSlack,
  UpdateAlertSettingsRequest,
} from '../../../../../../../backend/types'
import useConnectSlack from '../../../../../../../hooks/utils/useConnectSlack'
import useLoadIntegrations from '../../../../../../../hooks/data/load/useLoadIntegrations'
import useInitIntegrationDetails from '../../../../../../../hooks/data/effects/useInitIntegrationDetails'
import SolidDropdown from '../../../../../../library/SolidDropdown/SolidDropdown'

const AlertSlackSettings = () => {
  const updateAlertSettings = useUpdateAlertSettings()

  const loadIntegrations = useLoadIntegrations()

  const alertSettings = useAtomValue(alertSettingsAtom)
  const slackAction = useMemo(
    () => getSlackAction(alertSettings),
    [alertSettings],
  )

  const integrations = useAtomValue(integrationsAtom)
  const integrationDetails = useAtomValue(integrationDetailsAtom)

  const slackIntegration = integrations.find((i) => i.type === 'slack')
  useInitIntegrationDetails(slackIntegration?.id)

  const handleToggleSlackEnabled = async () => {
    if (!slackAction || !alertSettings) return
    const updatedSettings = toggleSlack(alertSettings, slackAction)
    await updateAlertSettings(updatedSettings)
  }

  const handleSlackConnected = async () => {
    if (!alertSettings) return
    const updatedSettings = addSlack(alertSettings)
    await loadIntegrations()
    await updateAlertSettings(updatedSettings)
  }

  const handleUpdateSlackChannel = async (channel: string) => {
    if (!alertSettings) return
    const updatedSettings = updateSlackChannel(alertSettings, channel)
    await updateAlertSettings(updatedSettings)
  }

  const connectSlack = useConnectSlack(handleSlackConnected)

  return (
    <SettingsSection>
      <SettingsTitle
        title="Slack Alerts"
        description="When you get an alert, send a message to Slack"
        button={
          !slackAction
            ? 'Connect'
            : slackAction.enabled
              ? 'Enabled'
              : 'Disabled'
        }
        mode={
          !slackAction ? 'default' : slackAction.enabled ? 'success' : 'error'
        }
        onClick={!slackAction ? connectSlack : handleToggleSlackEnabled}
      />
      {slackAction && (
        <div className="w-full max-w-[400px] py-4 flex flex-col gap-2">
          <p className="text-small-light text-text-2">Channel Name</p>
          <div className="flex flex-col items-start gap-2">
            <SolidDropdown
              placeholder="Select a channel"
              options={
                integrationDetails?.details.channels.map((c) => ({
                  label: c,
                  value: c,
                })) ?? []
              }
              selected={slackAction?.settings.channel}
              onSelect={handleUpdateSlackChannel}
            />
          </div>
        </div>
      )}
    </SettingsSection>
  )
}

const getSlackAction = (
  settings: AlertSettings | null,
): AlertActionSlack | null => {
  if (!settings) return null
  return settings.actions.find((a) => a.type === 'slack') ?? null
}

const addSlack = (settings: AlertSettings): UpdateAlertSettingsRequest => {
  return {
    settings_id: settings.id,
    actions: [
      ...settings.actions.filter((a) => a.type !== 'slack'),
      { type: 'slack', enabled: true, settings: { channel: '' } },
    ],
  }
}

const toggleSlack = (
  settings: AlertSettings,
  slackAction: AlertActionSlack,
): UpdateAlertSettingsRequest => {
  return {
    settings_id: settings.id,
    actions: settings.actions.map((a) =>
      a.type === 'slack' ? { ...a, enabled: !slackAction.enabled } : a,
    ),
  }
}

const updateSlackChannel = (
  settings: AlertSettings,
  channel: string,
): UpdateAlertSettingsRequest => {
  return {
    settings_id: settings.id,
    actions: settings.actions.map((a) =>
      a.type === 'slack' ? { ...a, settings: { ...a.settings, channel } } : a,
    ),
  }
}

export default AlertSlackSettings
