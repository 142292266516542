const { REACT_APP_DASHBOARD_ADDRESS } = process.env
const address = REACT_APP_DASHBOARD_ADDRESS || 'https://dashboard.vigilant.run'

const useConnectSlack = (onConnect: () => Promise<void>) => {
  const scopes = [
    'channels:join',
    'channels:read',
    'links:write',
    'chat:write',
    'chat:write.public',
  ].join(',')

  const params = new URLSearchParams({
    scope: scopes,
    user_scope: '',
    redirect_uri: `${address}/auth/slack`,
    client_id: '6009738006352.8186705464551',
  })

  const link = `https://slack.com/oauth/v2/authorize?${params.toString()}`

  return () => {
    const popup = window.open(
      link,
      'Slack Connect',
      [
        'width=600',
        'height=700',
        `left=${window.screen.width / 2 - 300}`,
        `top=${window.screen.height / 2 - 350}`,
        'toolbar=no',
        'location=no',
        'directories=no',
        'status=no',
        'menubar=no',
        'scrollbars=yes',
        'resizable=yes',
        'centerscreen=yes',
        'chrome=yes',
        'popup=yes',
      ].join(','),
    )

    if (!popup) {
      window.location.href = link
      return
    }

    const checkPopup = setInterval(async () => {
      try {
        if (popup.closed) {
          clearInterval(checkPopup)
          await onConnect()
        }
      } catch (error) {
        clearInterval(checkPopup)
      }
    }, 500)

    return () => clearInterval(checkPopup)
  }
}

export default useConnectSlack
