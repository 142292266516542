import { cn } from '../../../utils/cn'

interface CodeTabButtonProps {
  children: React.ReactNode
  selected?: boolean

  onClick?: () => void
  className?: string
}

const CodeTabButton = ({
  children,
  selected,
  onClick,
  className,
}: CodeTabButtonProps) => {
  return (
    <button
      className={cn(
        'py-2 px-3 h-8 flex items-center gap-2 text-mini-code rounded-[4px] border',
        selected
          ? 'border-brand-ctaTint text-brand-cta bg-brand-ctaTint'
          : 'border-transparent text-text-2 hover:text-text-1 hover:bg-input-tint',
        className,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default CodeTabButton
