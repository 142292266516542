import { useAtomValue, useSetAtom } from 'jotai'
import { instanceIdAtom, organizationInfoAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'
import { captureAxiosError } from '../../../utils/obeservability'

const useLoadOrganizationInfo = () => {
  const backend = useBackend()
  const setOrganizationInfo = useSetAtom(organizationInfoAtom)
  const instanceId = useAtomValue(instanceIdAtom)

  const loadOrganizationInfo = useCallback(async () => {
    if (!instanceId.organizationId) return
    try {
      const organizationInfo = await backend.getOrganization(
        instanceId.organizationId,
      )
      setOrganizationInfo(organizationInfo)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, setOrganizationInfo, instanceId])

  return loadOrganizationInfo
}

export default useLoadOrganizationInfo
