import { OrganizationMember } from '../backend/types'

export function isOrgOwner(
  userId: string,
  organizationMembers: OrganizationMember[],
) {
  return (
    organizationMembers.find((member) => member.user_id === userId)?.role ===
    'owner'
  )
}
