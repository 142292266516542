import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import PreviewLogs from '../../../../library/PreviewLogs/PreviewLogs'
import SetupSDKTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const { REACT_APP_INGRESS_ADDRESS } = process.env
const INGRESS_ADDRESS = REACT_APP_INGRESS_ADDRESS || 'ingress.vigilant.run'

const SetupLogsDart = () => {
  useSetBrandColor('info')

  return (
    <SetupSDKTemplate
      title="Log Setup - Dart"
      keyName="dart-api-key"
      installStep={installSnippets}
      sendTitle="Send a log"
      sendEventStep={getSendMessageSnippets(INGRESS_ADDRESS)}
      preview={<PreviewLogs />}
      done={LinkKey.Logs}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'Dart',
    language: 'dart',
    displayLanguage: 'dart',
    content: `dart pub add vigilant`,
  },
]

function getSendMessageSnippets(
  ingressAddress: string,
): (key: string) => CodeSnippetDetails[] {
  return (key) => [
    {
      title: 'Dart',
      language: 'dart',
      displayLanguage: 'dart',
      content: `import 'package:vigilant/vigilant.dart';

// Initialize the logger
final logger = Logger(
  name: 'my-service',
  endpoint: '${ingressAddress}',
  token: '${key}',
);

// Send a log
logger.info('Hello, World!');

// Shutdown the logger
await logger.shutdown();`,
    },
  ]
}

export default SetupLogsDart
