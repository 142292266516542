import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import useNav, { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'
import PreviewAlerts from '../../../../library/PreviewAlerts/PreviewAlerts'
import BrandButton from '../../../../library/BrandButton/BrandButton'

const SetupAlertsGo = () => {
  const navigate = useNav()
  useSetBrandColor('warning')

  return (
    <SetupTemplate
      title="Alerts Setup - Go"
      keyName="go-api-key"
      installStep={installSnippets}
      sendTitle="Send an alert"
      sendEventStep={getSendAlertSnippets}
      preview={<PreviewAlerts />}
      extraContent={
        <div className="w-full p-6 flex items-start justify-start gap-2 bg-brand-warningTint rounded border border-brand-warningTint">
          <p className="text-normal-light text-brand-cta">
            By default, alerts send you an email. You can control the
            destination from the settings page.
          </p>
          <BrandButton onClick={() => navigate(LinkKey.SettingsProjectAlerts)}>
            Go to settings
          </BrandButton>
        </div>
      }
      done={LinkKey.Alerts}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'Go',
    language: 'go',
    displayLanguage: 'go',
    content: `go get github.com/vigilant-run/vigilant-golang/v2`,
  },
]

function getSendAlertSnippets(key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'Go',
      language: 'go',
      displayLanguage: 'go',
      content: `package main

import (
  "github.com/vigilant-run/vigilant-golang/v2"
)


func main() {
  config := vigilant.NewAgentConfigBuilder().
    WithName("server-name").
    WithToken("${key}")

  vigilant.Init(config.Build())
  defer vigilant.Shutdown()

  vigilant.SendAlert("Database crashed")
}`,
    },
  ]
}

export default SetupAlertsGo
