import MainTabs, { TabDetails } from '../MainTabs/MainTabs'
import OrganizationButton from './OrganizationButton'
import ProjectButton from './ProjectButton'
import { CircleUser, Minus, Settings } from 'lucide-react'
import useNav, { LinkKey, linkMap } from '../../../hooks/nav/useNav'
import HelpButton from './HelpButton'
import OutlineIconButton from '../OutlineIconButton/OutlineIconButton'
import { atom, useAtom } from 'jotai'
import SupportPopup from '../SupportPopup/SupportPopup'

export type NavbarTab =
  | 'logs'
  | 'metrics'
  | 'analytics'
  | 'alerts'
  | 'settingsProject'
  | 'settingsOrganization'
  | 'settingsUser'

export const supportOpenAtom = atom(false)

interface NavbarProps {
  activeTab: NavbarTab | null
}

const Navbar = ({ activeTab }: NavbarProps) => {
  const navigate = useNav()
  const [isSupportOpen, setIsSupportOpen] = useAtom(supportOpenAtom)

  return (
    <div className="fixed top-0 px-4 w-full h-12 flex items-center justify-between bg-main-bg border-b border-main-border z-[1]">
      <div className="flex-1 basis-0 flex items-center gap-2">
        <OrganizationButton />
        <Minus size={12} className="text-text-2" />
        <ProjectButton />
      </div>
      <MainTabs tabs={tabs} activeTab={activeTab} />
      <div className="flex-1 basis-0 flex items-center justify-end gap-2">
        <OutlineIconButton
          active={
            activeTab === 'settingsProject' ||
            activeTab === 'settingsOrganization'
          }
          onClick={() => navigate(LinkKey.SettingsProjectGeneral)}
        >
          <Settings size={16} />
        </OutlineIconButton>
        <HelpButton />
        <OutlineIconButton
          active={activeTab === 'settingsUser'}
          onClick={() => navigate(LinkKey.SettingsUserProfile)}
        >
          <CircleUser size={16} />
        </OutlineIconButton>
      </div>
      {isSupportOpen && <SupportPopup close={() => setIsSupportOpen(false)} />}
    </div>
  )
}

const tabs: TabDetails<NavbarTab>[] = [
  {
    title: 'Logs',
    value: 'logs',
    mode: 'info',
    href: linkMap[LinkKey.Logs],
  },
  {
    title: 'Alerts',
    value: 'alerts',
    mode: 'warning',
    href: linkMap[LinkKey.Alerts],
  },
  {
    title: 'Metrics',
    value: 'metrics',
    mode: 'success',
    href: linkMap[LinkKey.Metrics],
  },
  {
    title: 'Analytics',
    value: 'analytics',
    mode: 'trace',
    href: linkMap[LinkKey.Analytics],
  },
]

export default Navbar
