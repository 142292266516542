import { motion } from 'framer-motion'
import { useState, useEffect } from 'react'

interface AnimationFadeShrinkInProps {
  children: React.ReactNode
  renderDelay?: number
  delay?: number
  distance?: number
  duration?: number
  scale?: number
}

const AnimationFadeShrinkIn: React.FC<AnimationFadeShrinkInProps> = ({
  children,
  renderDelay = 0,
  delay = 0,
  distance = 200,
  duration = 2,
  scale = 3,
}) => {
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldRender(true)
    }, renderDelay * 1000)
    return () => clearTimeout(timer)
  }, [renderDelay])

  if (!shouldRender) return null

  return (
    <motion.div
      initial={{ opacity: 0, y: distance, scale: scale, z: 100 }}
      animate={{ opacity: 1, y: 0, scale: 1, z: 0 }}
      transition={{
        duration: duration,
        delay: delay,
        ease: [0.16, 1, 0.3, 1],
      }}
      style={{
        transformPerspective: '800px',
        transformStyle: 'preserve-3d',
      }}
    >
      {children}
    </motion.div>
  )
}

export default AnimationFadeShrinkIn
