import { useAtomValue, useSetAtom } from 'jotai'
import { instanceIdAtom, metricDetailsAtom } from '../../../state/state'
import { useCallback } from 'react'
import { useNavMetric } from '../../nav/useNavMetric'
import useBackend from '../../services/useBackend'
import { captureAxiosError } from '../../../utils/obeservability'

const useLoadMetricsDetails = () => {
  const backend = useBackend()

  const { projectId } = useAtomValue(instanceIdAtom)
  const [navMetric] = useNavMetric()
  const setMetricDetails = useSetAtom(metricDetailsAtom)

  const loadMetricsDetails = useCallback(async () => {
    if (!projectId || !navMetric?.name) return

    try {
      const details = await backend.metricsDetails({
        project_id: projectId,
        name: navMetric.name,
      })

      setMetricDetails(details)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [projectId, navMetric?.name, backend, setMetricDetails])

  return loadMetricsDetails
}

export default useLoadMetricsDetails
