import { useAtom } from 'jotai'
import useJoinOrganization from './useJoinOrganization'
import { joinOrganizationTokenAtom } from '../../state/state'
import { captureAxiosError } from '../../utils/obeservability'

const useJoinToken = () => {
  const [token, setToken] = useAtom(joinOrganizationTokenAtom)
  const joinOrganization = useJoinOrganization()

  return async () => {
    if (token) {
      try {
        await joinOrganization(token)
        setToken(null)
        return true
      } catch (e) {
        captureAxiosError(e)
      }
    }
    return false
  }
}

export default useJoinToken
