import { useState } from 'react'
import useInitAnalytics from '../../../../hooks/data/effects/useInitAnalytics'
import useSetBrandColor from '../../../../hooks/utils/useSetBrandColor'
import NavbarPlaceholder from '../../../library/NavbarPlaceholder/NavbarPlaceholder'
import AnalyticsActionBar from './AnalyticsActionBar/AnalyticsActionBar'
import AnalyticsDisplay from './AnalyticsDisplay/AnalyticsDisplay'
import useInitMetricsNames from '../../../../hooks/data/effects/useInitMetricsNames'
import useUpdateAnalyticsLayout from '../../../../hooks/actions/useUpdateAnalyticsLayout'
import { useAtomValue } from 'jotai'
import {
  analyticsAtom,
  metricNamesAtom,
  analyticsLayoutAtom,
  analyticsTimerangeDefaultAtom,
} from '../../../../state/state'
import {
  getLayoutAfterMove,
  AnalyticsTimeRange,
} from '../../../../utils/analytics'
import { useLocation } from 'react-router-dom'
import {
  AnalyticsCreateRequest,
  AnalyticsEntry,
  AnalyticsUpdateRequest,
} from '../../../../backend/types'
import useCreateAnalytics from '../../../../hooks/actions/useCreateAnalytics'
import useUpdateAnalytics from '../../../../hooks/actions/useUpdateAnalytics'
import useDeleteAnalytics from '../../../../hooks/actions/useDeleteAnalytics'
import AnalyticsOnboardingDisplay from './AnalyticsOnboardingDisplay/AnalyticsOnboardingDisplay'
import useUpdateUserData from '../../../../hooks/actions/useUpdateUserData'
import useInitHasData from '../../../../hooks/data/effects/useInitHasData'

const Analytics = () => {
  useSetBrandColor('trace')
  useInitHasData()
  useInitAnalytics()
  useInitMetricsNames()

  const location = useLocation()

  const createAnalytics = useCreateAnalytics()
  const updateAnalytics = useUpdateAnalytics()
  const deleteAnalytics = useDeleteAnalytics()
  const updateAnalyticsLayout = useUpdateAnalyticsLayout()
  const updateUserData = useUpdateUserData()

  const analytics = useAtomValue(analyticsAtom)
  const layout = useAtomValue(analyticsLayoutAtom)
  const metricsNames = useAtomValue(metricNamesAtom)
  const timerange = useAtomValue(analyticsTimerangeDefaultAtom)

  const [rearranging, setRearranging] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const isCreating = isCreatingPage(location.pathname)
  const isEditing = isEditingPage(location.pathname)

  const [timeRange, setTimeRange] = useState<AnalyticsTimeRange>({
    type: 'preset',
    value: timerange,
  })

  const handleMove = async (
    id: string,
    direction: 'up' | 'down' | 'left' | 'right',
  ) => {
    if (!layout) return
    const updatedLayout = getLayoutAfterMove(id, direction, layout)
    if (updatedLayout) await updateAnalyticsLayout({ layout: updatedLayout })
  }

  const handleSetTimeRange = async (timeRange: AnalyticsTimeRange) => {
    setTimeRange(timeRange)
    switch (timeRange.type) {
      case 'preset':
        updateUserData({ analytics_timerange_default: timeRange.value })
        break
    }
  }

  const handleCreateGraph = async () => {
    const request = getCreateGraphRequest(metricsNames)
    const newId = await createAnalytics(request)
    if (newId) setTimeout(() => scrollToAnalytics(newId), 100)
  }

  const handleCreateCounter = async () => {
    const request = getCreateCounterRequest(metricsNames)
    const newId = await createAnalytics(request)
    if (newId) setTimeout(() => scrollToAnalytics(newId), 100)
  }

  const handleDuplicate = async (id: string) => {
    const analyticsEntry = analytics.find((a) => a.id === id)
    if (!analyticsEntry) return
    const request = createDuplicateAnalyticsRequest(analyticsEntry)
    const newId = await createAnalytics(request)
    if (newId) setTimeout(() => scrollToAnalytics(newId), 100)
  }

  const handleUpdate = async (id: string, request: AnalyticsUpdateRequest) => {
    await updateAnalytics(id, request)
  }

  const handleDelete = async (id: string) => {
    await deleteAnalytics(id)
  }

  const handleRefresh = async () => {
    setRefreshing(true)
    setTimeout(() => setRefreshing(false), 100)
  }

  return (
    <div className="flex flex-col w-full h-full items-center">
      <NavbarPlaceholder />
      <div className="w-full max-w-[1400px] flex-1 flex flex-col items-center overflow-y-auto z-[0]">
        {!isCreating && !isEditing && (
          <AnalyticsActionBar
            canRearrange={analytics.length > 0}
            canCreate={metricsNames.length > 0}
            createGraph={handleCreateGraph}
            createCounter={handleCreateCounter}
            rearranging={rearranging}
            toggleRearranging={() => setRearranging(!rearranging)}
            timeRange={timeRange}
            setTimeRange={handleSetTimeRange}
            refreshData={handleRefresh}
          />
        )}
        <AnalyticsDisplay
          rearranging={rearranging}
          onMove={handleMove}
          timeRange={timeRange}
          refreshing={refreshing}
          onUpdate={handleUpdate}
          onDuplicate={handleDuplicate}
          onDelete={handleDelete}
        />
      </div>
      <AnalyticsOnboardingDisplay />
    </div>
  )
}

function isCreatingPage(pathname: string) {
  return pathname.includes('/analytics/create')
}

function isEditingPage(pathname: string) {
  return pathname.includes('/analytics/edit')
}

function getCreateGraphRequest(metricsNames: string[]): AnalyticsCreateRequest {
  return {
    type: 'graph',
    settings: {
      name: 'Graph of ' + metricsNames[0],
      metric_name: metricsNames[0],
      aggregation_function: 'sum',
    },
  }
}

function getCreateCounterRequest(
  metricsNames: string[],
): AnalyticsCreateRequest {
  return {
    type: 'counter',
    settings: {
      name: 'New Counter',
      metric_name: metricsNames[0],
      aggregation_function: 'sum',
      diff: true,
    },
  }
}

function createDuplicateAnalyticsRequest(
  analyticsEntry: AnalyticsEntry,
): AnalyticsCreateRequest {
  switch (analyticsEntry.type) {
    case 'graph':
      return {
        type: 'graph',
        settings: {
          ...analyticsEntry.settings,
          name: analyticsEntry.settings.name + ' (Copy)',
        },
      }
    case 'counter':
      return {
        type: 'counter',
        settings: {
          ...analyticsEntry.settings,
          name: analyticsEntry.settings.name + ' (Copy)',
        },
      }
  }
}

function scrollToAnalytics(id: string) {
  const analyticsElement = document.getElementById(`analytics-${id}`)
  if (analyticsElement) {
    analyticsElement.scrollIntoView({ behavior: 'smooth' })
  }
}

export default Analytics
