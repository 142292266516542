import { AlertUpdateRequest } from '../../backend/types'
import useLoadAlerts from '../data/load/useLoadAlerts'
import { useCallback } from 'react'
import useBackend from '../services/useBackend'
import { captureAxiosError } from '../../utils/obeservability'

const useUpdateAlerts = () => {
  const backend = useBackend()
  const loadAlerts = useLoadAlerts()

  const updateAlert = useCallback(
    async (request: AlertUpdateRequest) => {
      try {
        await backend.updateAlert(request)
        await loadAlerts()
      } catch (e) {
        captureAxiosError(e)
      }
    },
    [backend, loadAlerts],
  )

  return updateAlert
}

export default useUpdateAlerts
