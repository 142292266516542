import axios from 'axios'
import {
  LogAttributesResponse,
  LogColumnsResponse,
  LogDetailsRequest,
  LogQueryRequest,
} from '../../backend/types'
import {
  SERVICE_FIELD,
  LEVEL_FIELD,
  QueryResponse,
  QueryRequestState,
  LoadMoreResponse,
  InitResponse,
  ViewContextResponse,
} from './types'
import { getTimestamps } from '../../utils/time'
import {
  ATTRIBUTE_PREFIX,
  COLUMN_PREFIX,
  formatFilters,
} from '../../utils/properties'
import { BackendService } from '../../backend/backend'
import { captureError, logger, logWithTiming } from '../../utils/obeservability'
import { formatQuery } from './utils'

export class QueryRunner {
  private projectId: string
  private backend: BackendService

  constructor(projectId: string, backend: BackendService) {
    this.projectId = projectId
    this.backend = backend
  }

  init = async (
    query: QueryRequestState,
    signal?: AbortSignal,
  ): Promise<InitResponse> => {
    return logWithTiming(
      'logs init',
      async () => {
        const result = await this.runInit(query, signal)
        if (result.result === 'canceled') {
          logger.info('logs init canceled')
        }
        return result
      },
      { 'logs.query': formatQuery(query) },
    )
  }

  query = async (
    query: QueryRequestState,
    signal?: AbortSignal,
  ): Promise<QueryResponse> => {
    return logWithTiming(
      'logs query',
      async () => {
        const result = await this.runQuery(query, signal)
        if (result.result === 'canceled') {
          logger.info('logs query canceled')
        }
        return result
      },
      { 'logs.query': formatQuery(query) },
    )
  }

  viewContext = async (
    query: QueryRequestState,
    signal?: AbortSignal,
  ): Promise<ViewContextResponse> => {
    return logWithTiming(
      'logs viewContext',
      async () => {
        const result = await this.runViewContext(query, signal)
        if (result.result === 'canceled') {
          logger.info('logs viewContext canceled')
        }
        return result
      },
      { 'logs.query': formatQuery(query) },
    )
  }

  loadMoreUp = async (
    query: QueryRequestState,
    signal?: AbortSignal,
  ): Promise<LoadMoreResponse> => {
    return logWithTiming(
      'logs loadMoreUp',
      async () => {
        const result = await this.runLoadMoreUp(query, signal)
        if (result.result === 'canceled') {
          logger.info('logs loadMoreUp canceled')
        }
        return result
      },
      { 'logs.query': formatQuery(query) },
    )
  }

  loadMoreDown = async (
    query: QueryRequestState,
    signal?: AbortSignal,
  ): Promise<LoadMoreResponse> => {
    return logWithTiming(
      'logs loadMoreDown',
      async () => {
        const result = await this.runLoadMoreDown(query, signal)
        if (result.result === 'canceled') {
          logger.info('logs loadMoreDown canceled')
        }
        return result
      },
      { 'logs.query': formatQuery(query) },
    )
  }

  private runInit = async (
    request: QueryRequestState,
    signal?: AbortSignal,
  ): Promise<InitResponse> => {
    try {
      const [start, end] = getTimestamps(request.time_range)
      const query: LogQueryRequest = {
        project_id: this.projectId,
        search_term: request.term,
        start_timestamp: start,
        end_timestamp: end,
        filters: formatFilters(request.filters),
        cursor_start: request.cursor_start,
        cursor_end: request.cursor_end,
        order_by: request.order_by,
        limit: request.limit,
      }

      if (signal?.aborted) {
        return { result: 'canceled', type: 'init' }
      }

      const serviceQuery = { ...query, field: SERVICE_FIELD }
      const levelQuery = { ...query, field: LEVEL_FIELD }
      const [
        countResult,
        queryResult,
        columnsResult,
        attributesResult,
        serviceOptionsResult,
        levelOptionsResult,
      ] = await Promise.all([
        this.backend.logsCount(query, signal),
        this.backend.logsQuery(query, signal),
        this.backend.logsColumns(query, signal),
        this.backend.logsAttributes(query, signal),
        this.backend.logsAttributeOptions(serviceQuery, signal),
        this.backend.logsColumnOptions(levelQuery, signal),
      ])

      if (signal?.aborted) {
        return { result: 'canceled', type: 'init' }
      }

      return {
        result: 'success',
        type: 'init',
        count: countResult.count ?? 0,
        logs: queryResult.logs ?? [],
        properties: [
          ...getColumns(columnsResult),
          ...getAttributes(attributesResult),
        ],
        serviceOptions: serviceOptionsResult.options ?? [],
        levelOptions: levelOptionsResult.options ?? [],
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        captureError(e)
      }
      return { result: 'canceled', type: 'init' }
    }
  }

  private runQuery = async (
    request: QueryRequestState,
    signal?: AbortSignal,
  ): Promise<QueryResponse> => {
    try {
      const [start, end] = getTimestamps(request.time_range)
      const query: LogQueryRequest = {
        project_id: this.projectId,
        search_term: request.term,
        start_timestamp: start,
        end_timestamp: end,
        filters: formatFilters(request.filters),
        cursor_start: request.cursor_start,
        order_by: request.order_by,
        limit: request.limit,
      }

      if (signal?.aborted) {
        return { result: 'canceled', type: 'query' }
      }

      const serviceQuery = { ...query, field: SERVICE_FIELD }
      const levelQuery = { ...query, field: LEVEL_FIELD }
      const [
        countResult,
        queryResult,
        columnsResult,
        attributesResult,
        serviceOptionsResult,
        levelOptionsResult,
      ] = await Promise.all([
        this.backend.logsCount(query, signal),
        this.backend.logsQuery(query, signal),
        this.backend.logsColumns(query, signal),
        this.backend.logsAttributes(query, signal),
        this.backend.logsAttributeOptions(serviceQuery, signal),
        this.backend.logsColumnOptions(levelQuery, signal),
      ])

      if (signal?.aborted) {
        return { result: 'canceled', type: 'query' }
      }

      return {
        result: 'success',
        type: 'query',
        count: countResult.count ?? 0,
        logs: queryResult.logs ?? [],
        properties: [
          ...getColumns(columnsResult),
          ...getAttributes(attributesResult),
        ],
        serviceOptions: serviceOptionsResult.options ?? [],
        levelOptions: levelOptionsResult.options ?? [],
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        captureError(e)
      }
      return { result: 'canceled', type: 'query' }
    }
  }

  private runViewContext = async (
    request: QueryRequestState,
    signal?: AbortSignal,
  ): Promise<ViewContextResponse> => {
    try {
      const [start, end] = getTimestamps(request.time_range)
      const baseQuery: LogQueryRequest = {
        project_id: this.projectId,
        search_term: request.term,
        start_timestamp: start,
        end_timestamp: end,
        filters: formatFilters(request.filters),
        limit: request.limit,
      }
      const beforeQuery: LogQueryRequest = {
        ...baseQuery,
        cursor_end: request.cursor_focus,
        order_by: 'asc',
      }
      const afterQuery: LogQueryRequest = {
        ...baseQuery,
        cursor_start: request.cursor_focus,
        order_by: 'desc',
      }
      const logQuery: LogDetailsRequest = {
        project_id: this.projectId,
        log_id: request.cursor_focus?.log_id ?? '',
      }
      const serviceQuery = {
        ...baseQuery,
        field: SERVICE_FIELD,
      }
      const levelQuery = {
        ...baseQuery,
        field: LEVEL_FIELD,
      }
      if (signal?.aborted) {
        return { result: 'canceled', type: 'viewContext' }
      }

      const [
        count,
        beforeResult,
        afterResult,
        logResult,
        columnsResult,
        attributesResult,
        serviceOptionsResult,
        levelOptionsResult,
      ] = await Promise.all([
        this.backend.logsCount(baseQuery, signal),
        this.backend.logsQuery(beforeQuery, signal),
        this.backend.logsQuery(afterQuery, signal),
        this.backend.logsDetails(logQuery, signal),
        this.backend.logsColumns(baseQuery, signal),
        this.backend.logsAttributes(baseQuery, signal),
        this.backend.logsAttributeOptions(serviceQuery, signal),
        this.backend.logsColumnOptions(levelQuery, signal),
      ])

      return {
        result: 'success',
        type: 'viewContext',
        count: count.count ?? 0,
        logsBefore: beforeResult.logs ?? [],
        logsAfter: afterResult.logs ?? [],
        log: logResult.log ?? null,
        properties: [
          ...getColumns(columnsResult),
          ...getAttributes(attributesResult),
        ],
        serviceOptions: serviceOptionsResult.options ?? [],
        levelOptions: levelOptionsResult.options ?? [],
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        captureError(e)
      }
      return { result: 'canceled', type: 'viewContext' }
    }
  }

  private runLoadMoreUp = async (
    request: QueryRequestState,
    signal?: AbortSignal,
  ): Promise<LoadMoreResponse> => {
    try {
      const [start, end] = getTimestamps(request.time_range)
      const query: LogQueryRequest = {
        project_id: this.projectId,
        search_term: request.term,
        start_timestamp: start,
        end_timestamp: end,
        filters: formatFilters(request.filters),
        cursor_start: request.cursor_start,
        cursor_end: request.cursor_end,
        order_by: request.order_by,
        limit: request.limit,
      }

      if (signal?.aborted) {
        return { result: 'canceled', type: 'loadMore' }
      }

      const [queryResult] = await Promise.all([
        this.backend.logsQuery(query, signal),
      ])

      if (signal?.aborted) {
        return { result: 'canceled', type: 'loadMore' }
      }

      return {
        result: 'success',
        type: 'loadMore',
        logs: queryResult.logs ?? [],
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        captureError(e)
      }
      return { result: 'canceled', type: 'loadMore' }
    }
  }

  private runLoadMoreDown = async (
    request: QueryRequestState,
    signal?: AbortSignal,
  ): Promise<LoadMoreResponse> => {
    try {
      const [start, end] = getTimestamps(request.time_range)
      const query: LogQueryRequest = {
        project_id: this.projectId,
        search_term: request.term,
        start_timestamp: start,
        end_timestamp: end,
        filters: formatFilters(request.filters),
        cursor_start: request.cursor_start,
        cursor_end: request.cursor_end,
        order_by: request.order_by,
        limit: request.limit,
      }

      if (signal?.aborted) {
        return { result: 'canceled', type: 'loadMore' }
      }

      const [queryResult] = await Promise.all([
        this.backend.logsQuery(query, signal),
      ])

      if (signal?.aborted) {
        return { result: 'canceled', type: 'loadMore' }
      }

      return {
        result: 'success',
        type: 'loadMore',
        logs: queryResult.logs ?? [],
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        captureError(e)
      }
      return { result: 'canceled', type: 'loadMore' }
    }
  }
}

function getColumns(columnsResult: LogColumnsResponse) {
  return (columnsResult.values || []).map((value) => `${COLUMN_PREFIX}${value}`)
}

function getAttributes(attributesResult: LogAttributesResponse) {
  return (attributesResult.values || []).map(
    (value) => `${ATTRIBUTE_PREFIX}${value}`,
  )
}
