import { useCallback, useEffect, useRef, useState } from 'react'
import {
  getUpdatedFilters,
  propertyToDisplay,
} from '../../../../../../../../../utils/properties'
import { cn } from '../../../../../../../../../utils/cn'
import { ChevronDown } from 'lucide-react'
import LogHeaderPopup from './LogHeaderPopup/LogHeaderPopup'
import { Filter } from '../../../../../../../../../backend/types'
import { isCommandKey } from '../../../../../../../../../utils/browser'

interface LogHeaderCellProps {
  headerRef: React.RefObject<HTMLDivElement | null>

  width: number

  property: string

  resizing: boolean
  setResizing: (e: React.MouseEvent<HTMLDivElement>) => void

  setDragging: (e: React.MouseEvent<HTMLDivElement>) => void

  selected: string | null
  setSelected: (property: string | null) => void

  filters: Filter[]
  setFilters: (filters: Filter[]) => void

  toggleHeader: (header: string) => void
}

const LogHeaderCell = ({
  headerRef,
  width,
  resizing,
  setResizing,
  setDragging,
  property,
  selected,
  setSelected,
  filters,
  setFilters,
  toggleHeader,
}: LogHeaderCellProps) => {
  const resizeRef = useRef<HTMLDivElement>(null)
  const [mouseDownX, setMouseDownX] = useState<number | null>(null)
  const [hover, setHover] = useState(false)
  const [command, setCommand] = useState<boolean>(false)

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (clickedResizeRef(e)) return
      if (hover && command) {
        setFilters(getUpdatedFilters(filters, property, null))
      } else {
        setSelected(selected === property ? null : property)
      }
    },
    [hover, command, setFilters, filters, property, setSelected, selected],
  )

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (clickedResizeRef(e)) return
    setMouseDownX(e.clientX)
  }, [])

  const handleCellMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (mouseDownX === null) return
      const delta = Math.abs(e.clientX - mouseDownX)
      if (delta > 5) setDragging(e)
    },
    [mouseDownX, setDragging],
  )

  const handleResizeMousedown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setResizing(e)
    },
    [setResizing],
  )

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Meta') setCommand(true)
  }, [])

  const handleKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Meta') setCommand(false)
  }, [])

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      const isTargetCell = e.target === headerRef.current
      const isTargetDescendant = headerRef.current?.contains(e.target as Node)
      setHover(isTargetCell || isTargetDescendant || false)
      setCommand(isCommandKey(e))
    },
    [headerRef],
  )

  const handleMouseUp = useCallback(() => {
    setMouseDownX(null)
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
    return () => {
      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [handleKeyDown, handleKeyUp, handleMouseMove, handleMouseUp])

  return (
    <div className="relative">
      <div
        ref={headerRef}
        className={cn(
          'flex-none px-2 py-1 h-6 flex items-center justify-between gap-1 border-r border-main-border',
          selected === property ? 'bg-input-tint' : 'hover:bg-input-bgTint',
          command && hover ? 'cursor-pointer' : 'cursor-default',
        )}
        style={{ width }}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseMove={handleCellMouseMove}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <p
          className={cn(
            'text-small-code overflow-hidden whitespace-pre text-ellipsis select-none',
            hover && command
              ? 'text-brand-cta underline cursor-pointer'
              : 'text-text-1',
          )}
        >
          {propertyToDisplay(property)}
        </p>
        <ChevronDown size={12} className="text-text-2 select-none" />
        <div
          ref={resizeRef}
          className={cn(
            'resize-ref absolute top-0 right-[-4px] h-full w-2 z-[1]',
            !resizing ? 'cursor-ew-resize' : '',
          )}
          onMouseDown={handleResizeMousedown}
        />
      </div>
      {selected === property && (
        <LogHeaderPopup
          property={property}
          filters={filters}
          setFilters={setFilters}
          toggleHeader={toggleHeader}
          headerRef={headerRef}
          close={() => setSelected(null)}
        />
      )}
    </div>
  )
}

const clickedResizeRef = (e: React.MouseEvent<HTMLDivElement>) => {
  const target = e.target as HTMLElement
  const resizeRef = target.closest('.resize-ref')
  return resizeRef !== null
}

export default LogHeaderCell
