import { useCallback } from 'react'
import useBackend from '../../services/useBackend'
import { useSetAtom, useAtomValue } from 'jotai'
import { userDataAtom } from '../../../state/state'
import { userInfoAtom } from '../../../state/state'
import { captureAxiosError } from '../../../utils/obeservability'

const useLoadUserData = () => {
  const backend = useBackend()
  const userInfo = useAtomValue(userInfoAtom)
  const setUserData = useSetAtom(userDataAtom)

  const loadUserData = useCallback(async () => {
    try {
      if (!userInfo.id) return
      const userData = await backend.getUserData(userInfo.id)
      setUserData(userData)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, setUserData, userInfo.id])

  return loadUserData
}

export default useLoadUserData
