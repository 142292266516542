import { useState, useRef } from 'react'
import { cn } from '../../../utils/cn'
import HoverTooltip from './HoverTooltip/HoverTooltip'
import { formatNumber } from '../../../utils/properties'
import Spinner from '../Spinner/Spinner'
import Dropdown from '../Dropdown/Dropdown'

export type ModeOption<T extends string> = {
  label: string
  value: T
}

export type Point = {
  name: string
  value: number
}

export type GroupedSeries = {
  time: Date
  values: Point[]
}

export type Grouping = 'hourly' | 'daily' | 'weekly' | 'monthly'

interface ChartProps<T extends string> {
  title: string
  loading: boolean

  unit: string
  grouping: Grouping
  data: GroupedSeries[]
  maxYAxisValue: number

  mode: T
  modeOptions: ModeOption<T>[]
  setMode: (mode: T) => void
}

const Chart = <T extends string>({
  title,
  loading,
  unit,
  grouping,
  data,
  maxYAxisValue,
  mode,
  modeOptions,
  setMode,
}: ChartProps<T>) => {
  const [hoverIndex, setHoverIndex] = useState<number | null>(null)
  const [hoverX, setHoverX] = useState<number | null>(null)
  const [columnWidth, setColumnWidth] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement>(null)

  const maxValue = getAdjustedMaxValue(maxYAxisValue)
  const yAxisLabels = getYAxisLabels(maxValue)
  const columnCount = getColumnCount(data)
  const visibleXAxisIndices = getVisibleXAxisIndices(columnCount, 6)

  const handleMouseLeaveChart = () => {
    setHoverIndex(null)
    setHoverX(null)
    setColumnWidth(0)
  }

  const handleMouseEnterColumn = (
    index: number,
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    setHoverIndex(index)
    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect()
    setHoverX(rect.left + rect.width / 2)
    setColumnWidth(rect.width)
  }

  return (
    <div className="w-full h-[300px] flex flex-col items-start justify-start bg-panel-bg border border-main-border">
      <div className="px-6 py-2 w-full h-[50px] flex items-center justify-between border-b border-main-border">
        <p className="text-code text-text-1">{title}</p>
        <Dropdown selected={mode} options={modeOptions} onChange={setMode} />
      </div>
      {loading && (
        <div className="w-full flex-1 flex items-center justify-center">
          <Spinner mode="default" />
        </div>
      )}
      {!loading && data.length === 0 && (
        <div className="w-full flex-1 flex items-center justify-center">
          <p className="text-code text-text-2">No data to display.</p>
        </div>
      )}
      {!loading && data.length > 0 && (
        <div className="w-full flex-1 flex">
          <div className="py-6 pr-4 h-full w-16 flex flex-col items-end justify-between">
            {yAxisLabels.map((label) => (
              <p key={label} className="text-code text-text-2">
                {formatNumber(label)}
              </p>
            ))}
          </div>
          <div
            ref={containerRef}
            className="flex-1 flex flex-col items-start justify-start border-main-border relative"
          >
            <div
              className="relative px-4 w-full flex-1 flex items-end border-l border-main-border"
              onMouseLeave={handleMouseLeaveChart}
            >
              <div className="py-9 absolute inset-0 flex flex-col justify-between pointer-events-none z-[0]">
                <div className="h-px bg-main-border" />
                <div className="h-px bg-main-border" />
                <div className="h-px bg-main-border" />
                <div className="h-px bg-main-border" />
                <div className="h-px bg-main-border" />
              </div>
              {data.map((point, index) => (
                <div
                  key={index}
                  className="w-full h-full flex flex-col items-end"
                >
                  <div
                    key={index}
                    className="px-2 w-full h-full flex flex-col items-end z-[1] hover:bg-input-tint"
                    onMouseEnter={(e) => handleMouseEnterColumn(index, e)}
                  >
                    <div className="w-full h-full flex flex-col-reverse gap-0.5">
                      {point.values.map((value, valueIndex) => (
                        <div
                          key={`point-${index}-${valueIndex}`}
                          className={cn(
                            'w-full h-full',
                            getBackgroundColor(valueIndex),
                            value.value > 0 ? 'min-h-1' : 'min-h-0',
                          )}
                          style={{
                            height: `calc(${computeNormalizedHeight(
                              value.value,
                              maxValue * 1.25,
                            )}%)`,
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="h-[36px] w-full flex-none flex items-center justify-center overflow-visible">
                    {visibleXAxisIndices.includes(index) && (
                      <p className="absolute text-code text-text-2 whitespace-nowrap">
                        {getXAxisLabel(point.time, grouping)}
                      </p>
                    )}
                  </div>
                </div>
              ))}
              {hoverIndex !== null && hoverX !== null && data.length > 0 && (
                <HoverTooltip
                  containerRef={containerRef}
                  hoverX={hoverX}
                  columnWidth={columnWidth}
                  pointIndex={hoverIndex}
                  unit={unit}
                  grouping={grouping}
                  groupedSeries={data}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

function getBackgroundColor(index: number): string {
  switch (index % 4) {
    case 0:
      return 'bg-brand-info'
    case 1:
      return 'bg-brand-warning'
    case 2:
      return 'bg-brand-error'
    case 3:
      return 'bg-brand-success'
    default:
      return 'bg-brand-info'
  }
}

function computeNormalizedHeight(value: number, maxValue: number): number {
  return (value / maxValue) * 100
}

function getAdjustedMaxValue(yAxisMaxValue: number): number {
  const magnitude = Math.pow(10, Math.floor(Math.log10(yAxisMaxValue)))
  const normalizedValue = yAxisMaxValue / magnitude
  if (normalizedValue <= 2) return 2 * magnitude
  if (normalizedValue <= 4) return 4 * magnitude
  if (normalizedValue <= 6) return 6 * magnitude
  if (normalizedValue <= 8) return 8 * magnitude
  return 10 * magnitude
}

function getYAxisLabels(maxValue: number): number[] {
  const step = maxValue / 4
  return Array.from({ length: 5 }, (_, i) => maxValue - step * i)
}

function getXAxisLabel(value: Date, grouping: Grouping): string {
  if (grouping === 'hourly') {
    return value.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true })
  } else {
    return new Date(value.getTime() + 24 * 60 * 60 * 1000).toLocaleDateString(
      'en-US',
      { day: 'numeric', month: 'short' },
    )
  }
}

function getColumnCount(data: GroupedSeries[]): number {
  return data.length
}

function getVisibleXAxisIndices(totalColumns: number, maxLabels = 6): number[] {
  if (totalColumns <= 1) return [0]
  if (totalColumns <= maxLabels) {
    return Array.from({ length: totalColumns }, (_, i) => i)
  }

  let bestLabelCount = Math.min(totalColumns, maxLabels)
  let foundExactStep = false

  for (let n = bestLabelCount; n >= 3; n--) {
    if ((totalColumns - 1) % (n - 1) === 0) {
      bestLabelCount = n
      foundExactStep = true
      break
    }
  }

  if (foundExactStep) {
    const step = (totalColumns - 1) / (bestLabelCount - 1)
    const result: number[] = []
    for (let i = 0; i < bestLabelCount; i++) {
      result.push(i * step)
    }
    return result
  }

  const indices = new Set<number>()
  indices.add(0)
  indices.add(totalColumns - 1)

  const inBetween = bestLabelCount - 2
  const step = (totalColumns - 1) / (inBetween + 1)

  for (let i = 1; i <= inBetween; i++) {
    indices.add(Math.round(i * step))
  }

  return Array.from(indices).sort((a, b) => a - b)
}

export default Chart
