import { useState } from 'react'

const useClosePanel = (): [boolean, () => void] => {
  const [isClosing, setIsClosing] = useState(false)

  const handleClose = () => {
    setIsClosing(true)
    setTimeout(() => {
      setIsClosing(false)
    }, 300)
  }

  return [isClosing, handleClose]
}

export default useClosePanel
