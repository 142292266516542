import { QueryParameters } from './types'

export type QueryParametersSubscriber = (params: QueryParameters) => void

export class QueryParameterManager {
  private params: QueryParameters

  private subscribers: { [key: string]: QueryParametersSubscriber } = {}

  constructor(params: QueryParameters) {
    this.params = { ...params }
  }

  subscribe(key: string, subscriber: QueryParametersSubscriber) {
    this.subscribers[key] = subscriber
  }

  unsubscribe(key: string) {
    delete this.subscribers[key]
  }

  updateParams(params: Partial<QueryParameters>) {
    this.params = { ...this.params, ...params }
    this.publish()
  }

  private publish() {
    for (const subscriber of Object.values(this.subscribers)) {
      subscriber(this.params)
    }
  }
}
