import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import PreviewMetrics from '../../../../library/PreviewMetrics/PreviewMetrics'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const SetupMetricsPython = () => {
  useSetBrandColor('success')

  return (
    <SetupTemplate
      title="Metrics Setup - Python"
      keyName="python-api-key"
      installStep={installSnippets}
      sendTitle="Send a metric"
      sendEventStep={getSendMetricsSnippets}
      preview={<PreviewMetrics />}
      done={LinkKey.Metrics}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'pip',
    language: 'bash',
    displayLanguage: 'bash',
    content: `pip install vigilant-py`,
  },
]

function getSendMetricsSnippets(key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'Python',
      language: 'python',
      displayLanguage: 'python',
      content: `from vigilant import init_metrics_handler, shutdown_metrics_handler, emit_metric

# Initialize the metrics handler only once in your application
init_metrics_handler(
    name="python-app",
    token="${key}",
)

# Emit a metric
emit_metric(
    name="user_login",
    value=1,
    attrs={"user_id": "123"},
)

# Shutdown the metrics handler before your application exits
shutdown_metrics_handler()`,
    },
  ]
}

export default SetupMetricsPython
