import CodeTabButton from '../../../../library/CodeTabButton/CodeTabButton'

interface MetricsSidebarProps {
  metricNames: string[]
  navMetric: string | null
  setNavMetric: (metricName: string | null) => void
}

const MetricsSidebar = ({
  metricNames,
  navMetric,
  setNavMetric,
}: MetricsSidebarProps) => {
  return (
    <div className="p-6 h-full w-[300px] flex-none flex flex-col gap-1 border-r border-main-border overflow-auto">
      <div className="px-3 py-1 w-full">
        <p className="text-normal-medium text-text-1">Metrics</p>
      </div>
      {metricNames.length === 0 && (
        <div className="px-3 py-1 w-full">
          <p className="text-small-light text-text-2">No metrics found.</p>
        </div>
      )}
      {metricNames.length > 0 && (
        <>
          {metricNames.map((metricName) => (
            <CodeTabButton
              key={metricName}
              selected={navMetric === metricName}
              onClick={() => setNavMetric(metricName)}
            >
              {metricName}
            </CodeTabButton>
          ))}
        </>
      )}
    </div>
  )
}

export default MetricsSidebar
