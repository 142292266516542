export const DEFAULT_QUERY_PARAMETERS = {
  term: '',
  time_range: { start: undefined, end: undefined },
  filters: [],
  cursor_start: undefined,
  cursor_end: undefined,
  cursor_focus: undefined,
  order_by: undefined,
  limit: 100,
}
