import { Check, X } from 'lucide-react'
import SolidButtonSmall from '../../../../../library/SolidButtonSmall/SolidButtonSmall'
import { AlertStatus } from '../../../../../../backend/types'

interface AlertActionPopupProps {
  alertIds: string[]
  statuses: AlertStatus[]
  clearAll: () => void
  closeAll: () => Promise<void>
}

const AlertActionPopup = ({
  alertIds,
  statuses,
  clearAll,
  closeAll,
}: AlertActionPopupProps) => {
  if (alertIds.length === 0) return null
  return (
    <div className="absolute bottom-4 left-1/2 translate-x-[-50%] p-2 flex gap-2 bg-popup-bg border border-popup-border rounded-[4px] z-[2] slide-popup-bottom">
      <div className="flex items-center justify-center border border-dashed border-input-border rounded-[4px]">
        <div className="px-2 h-[28px] flex items-center justify-center gap-2 text-mini-light text-text-2 border-r border-dashed border-input-border">
          {alertIds.length} Selected
        </div>
        <div
          className="w-[28px] h-[28px] flex items-center justify-center gap-2 text-mini-light text-text-2 cursor-pointer hover:bg-input-bgTint hover:text-text-1"
          onClick={clearAll}
        >
          <X size={12} />
        </div>
      </div>
      <SolidButtonSmall
        disabled={!canClose(statuses)}
        onClick={async () => await closeAll()}
      >
        <Check size={14} />
        Close
      </SolidButtonSmall>
    </div>
  )
}

function canClose(statuses: AlertStatus[]) {
  return statuses.includes('open')
}

export default AlertActionPopup
