import { useAtomValue, useSetAtom } from 'jotai'
import { metricNamesAtom, instanceIdAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'
import { captureAxiosError } from '../../../utils/obeservability'

const useLoadMetricsNames = () => {
  const backend = useBackend()
  const { projectId } = useAtomValue(instanceIdAtom)
  const setMetricNames = useSetAtom(metricNamesAtom)

  const loadMetricsNames = useCallback(async () => {
    if (!projectId) return
    try {
      const { names } = await backend.metricsList({
        project_id: projectId,
      })
      setMetricNames(names)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, projectId, setMetricNames])

  return loadMetricsNames
}

export default useLoadMetricsNames
