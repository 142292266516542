import { useRef } from 'react'
import useClickOutside from '../../../../../../../hooks/utils/useClickOutside'
import Fullscreen from '../../../../../../library/Fullscreen/Fullscreen'
import PanelSection from '../../../../../../library/PanelSection/PanelSection'
import PopupContainer from '../../../../../../library/PopupContainer/PopupContainer'
import { X } from 'lucide-react'
import CTAButton from '../../../../../../library/CTAButton/CTAButton'

interface RemoveMemberPopupProps {
  email: string
  onSubmit: () => Promise<void>
  close: () => void
}

const RemoveMemberPopup: React.FC<RemoveMemberPopupProps> = ({
  email,
  onSubmit,
  close,
}) => {
  const popupRef = useRef<HTMLDivElement>(null)
  useClickOutside(popupRef, () => close())

  const handleSubmit = async () => {
    await onSubmit()
  }

  return (
    <Fullscreen>
      <PopupContainer popupRef={popupRef} className="w-[380px]">
        <PanelSection>
          <div className="w-full flex justify-between items-center">
            <h2 className="text-h2 text-text-1">Delete Member</h2>
            <X
              className="w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer"
              onClick={close}
            />
          </div>
        </PanelSection>
        <PanelSection className="gap-2">
          <p className="text-body text-text-1">
            Are you sure you want to remove {email} from your organization?
          </p>
          <p className="text-body text-brand-error">
            Warning: This cannot be undone.
          </p>
        </PanelSection>
        <PanelSection bottom={true}>
          <CTAButton mode="error" onClick={handleSubmit}>
            Remove Member
          </CTAButton>
        </PanelSection>
      </PopupContainer>
    </Fullscreen>
  )
}

export default RemoveMemberPopup
