import { useRef } from 'react'
import useClickOutside from '../../../../../../../../hooks/utils/useClickOutside'
import PopupCodeButton from '../../../../../../../library/PopupCodeButton/PopupCodeButton'
import { propertyToDisplay } from '../../../../../../../../utils/properties'

interface FilterOperatorPopupProps {
  visibleProperties: string[]
  hiddenProperties: string[]
  setProperty: (property: string) => void

  buttonRef: React.RefObject<HTMLButtonElement | null>
  close: () => void
}

const FilterPropertyPopup = ({
  visibleProperties,
  hiddenProperties,
  setProperty,
  buttonRef,
  close,
}: FilterOperatorPopupProps) => {
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, close, buttonRef)

  const handleSetProperty = (property: string) => {
    setProperty(property)
    close()
  }

  return (
    <div
      ref={ref}
      className="absolute top-[calc(100%+4px)] left-0 w-[200px] bg-popup-bg border border-popup-border rounded-[4px] shadow-popup-shadow flex flex-col z-[2]"
    >
      <div className="px-4 py-2 w-full flex items-center gap-1 border-b border-popup-border">
        <p className="text-text-2 text-small-code">attribute</p>
      </div>
      <div className="p-2 w-full flex flex-col items-center gap-1">
        {visibleProperties.map((property) => (
          <PopupCodeButton
            key={property}
            text={propertyToDisplay(property)}
            active={true}
            onClick={() => handleSetProperty(property)}
          />
        ))}
        {hiddenProperties.map((property) => (
          <PopupCodeButton
            key={property}
            text={propertyToDisplay(property)}
            active={false}
            onClick={() => handleSetProperty(property)}
          />
        ))}
      </div>
    </div>
  )
}

export default FilterPropertyPopup
