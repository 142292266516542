import { useAtomValue } from 'jotai'
import useBackend from '../services/useBackend'
import { instanceIdAtom } from '../../state/state'
import useLoadOrganizationInfo from '../data/load/useLoadOrganizationInfo'
import useToast from '../utils/useToast'
import useLoadOrganizations from '../data/load/useLoadOrganizations'
import { MetricsTTL } from '../../state/types'
import { captureAxiosError } from '../../utils/obeservability'

const useUpdateOrganizationMetricsTTL = () => {
  const backend = useBackend()
  const toast = useToast()

  const { organizationId } = useAtomValue(instanceIdAtom)
  const loadOrganizationInfo = useLoadOrganizationInfo()
  const loadOrganizations = useLoadOrganizations()

  return async (metrics_ttl: MetricsTTL) => {
    if (!organizationId) return
    try {
      await backend.updateOrganization(organizationId, { metrics_ttl })
      await loadOrganizationInfo()
      await loadOrganizations()
      toast(`Metrics retention updated`, 'success')
    } catch (e) {
      captureAxiosError(e)
      toast('Metrics retention update failed', 'error')
    }
  }
}

export default useUpdateOrganizationMetricsTTL
