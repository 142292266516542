import useLoadAnalytics from '../data/load/useLoadAnalytics'
import useLoadAnalyticsLayout from '../data/load/useLoadAnalyticsLayout'
import useBackend from '../services/useBackend'
import { captureAxiosError } from '../../utils/obeservability'

const useDeleteAnalytics = () => {
  const backend = useBackend()

  const loadAnalytics = useLoadAnalytics()
  const loadAnalyticsLayout = useLoadAnalyticsLayout()

  const deleteAnalytics = async (id: string) => {
    try {
      await backend.deleteAnalytics(id)
      await Promise.all([loadAnalytics(), loadAnalyticsLayout()])
    } catch (e) {
      captureAxiosError(e)
    }
  }

  return deleteAnalytics
}

export default useDeleteAnalytics
