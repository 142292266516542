const PortalRoot = () => {
  return (
    <div
      id="portal-root"
      className="portal-root"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
        pointerEvents: 'none',
        isolation: 'isolate',
      }}
    />
  )
}

export default PortalRoot
