import PreviewLogs from '../../../../library/PreviewLogs/PreviewLogs'
import SetupCustomTemplate from '../../../../library/SetupCustomTemplate/SetupCustomTemplate'
import APIKeyInput from '../../../../library/APIKeyInput/APIKeyInput'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const { REACT_APP_SYSLOG_ADDRESS } = process.env
const SYSLOG_ADDRESS = REACT_APP_SYSLOG_ADDRESS || 'syslog.vigilant.run:6516'

const SetupLogsRender = () => {
  useSetBrandColor('info')

  return (
    <SetupCustomTemplate
      title="Render"
      keyName="render-api-key"
      customContent={(apiKeyValue) => (
        <div className="w-full flex flex-col items-start justify-start gap-4">
          <h2 className="text-h2 text-text-1">Add Default Log Stream</h2>
          <p className="text-body text-text-2 mb-2">
            Go to{' '}
            <a
              href="https://dashboard.render.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              Render workspace settings
            </a>{' '}
            and add Vigilant as the default log stream destination. Use the
            following details when adding the log stream.
          </p>
          <div className="w-full flex flex-col items-start justify-start gap-1 text-body text-text-2 mb-2">
            <span>Log Endpoint:</span>
            <APIKeyInput
              apiKey={SYSLOG_ADDRESS}
              onGenerate={() => Promise.resolve()}
            />
          </div>
          <div className="w-full flex flex-col items-start justify-start gap-1 text-body text-text-2">
            <span>Token:</span>
            <APIKeyInput
              apiKey={apiKeyValue ? apiKeyValue : 'Generate an api key first!'}
              valid={apiKeyValue !== ''}
              onGenerate={() => Promise.resolve()}
            />
          </div>
        </div>
      )}
      preview={<PreviewLogs />}
      done={LinkKey.Logs}
    />
  )
}

export default SetupLogsRender
