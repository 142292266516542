import { AnalyticsUpdateLayoutRequest } from '../../backend/types'
import useLoadAnalyticsLayout from '../data/load/useLoadAnalyticsLayout'
import useBackend from '../services/useBackend'
import { captureAxiosError } from '../../utils/obeservability'

const useUpdateAnalyticsLayout = () => {
  const backend = useBackend()

  const loadAnalyticsLayout = useLoadAnalyticsLayout()

  const updateAnalyticsLayout = async (
    request: AnalyticsUpdateLayoutRequest,
  ) => {
    try {
      await backend.updateAnalyticsLayout(request)
      await loadAnalyticsLayout()
    } catch (e) {
      captureAxiosError(e)
    }
  }

  return updateAnalyticsLayout
}

export default useUpdateAnalyticsLayout
