import { useEffect } from 'react'
import PanelSection from '../../../../../library/PanelSection/PanelSection'
import RowButtonDropdown from '../../../../../library/RowButtonDropdown/RowDropdown'
import CodeButton from '../../../../../library/CodeButton/CodeButton'
import { Minus, Plus, Square } from 'lucide-react'
import { SquareCheckBig } from 'lucide-react'
import { Filter } from '../../../../../../backend/types'
import {
  COLUMN_LEVEL,
  COLUMN_BODY,
  COLUMN_TIMESTAMP,
  ATTRIBUTE_PREFIX,
  ATTRIBUTE_SERVICE,
  COLUMN_PREFIX,
  propertyToDisplay,
  getUpdatedFilters,
} from '../../../../../../utils/properties'
import { Header } from '../../Logs'
import { QueryMode } from '../../../../../../library/query/types'
import LoadingDots from '../../../../../library/LoadingDots/LoadingDots'
import IconButton from '../../../../../library/IconButton/IconButton'
import { useAtom, useAtomValue } from 'jotai'
import { sidebarVisibleAtom } from '../Searchbar/FilterButton/FilterButton'
import persistAtom from '../../../../../../state/persistAtom'
import { hasLogsAtom } from '../../../../../../state/state'

const serviceOpenAtom = persistAtom({
  key: 'logSidebarServiceOpenAtom',
  defaultValue: true,
  persistMode: 'local',
})

const levelOpenAtom = persistAtom({
  key: 'logSidebarLevelOpenAtom',
  defaultValue: true,
  persistMode: 'local',
})

const attributesOpenAtom = persistAtom({
  key: 'logSidebarAttributesOpenAtom',
  defaultValue: true,
  persistMode: 'local',
})

interface LogSidebarProps {
  queryMode: QueryMode

  services: string[]
  levels: string[]
  properties: string[]

  headers: Header[]
  toggleHeader: (header: string) => void

  filters: Filter[]
  setFilters: (filters: Filter[]) => void

  visibleProperties: string[]
  hiddenProperties: string[]
}

const LogSidebar = ({
  queryMode,
  services,
  levels,
  properties,
  headers,
  toggleHeader,
  filters,
  setFilters,
  visibleProperties,
  hiddenProperties,
}: LogSidebarProps) => {
  const [serviceOpen, setServiceOpen] = useAtom(serviceOpenAtom)
  const [levelOpen, setLevelOpen] = useAtom(levelOpenAtom)
  const [attributesOpen, setAttributesOpen] = useAtom(attributesOpenAtom)
  const [sidebarVisible, setSidebarVisible] = useAtom(sidebarVisibleAtom)
  const hasLogs = useAtomValue(hasLogsAtom)
  const loaded = isLoaded(queryMode)

  const handleSelectService = (service: string) => {
    const exists = filters.some(
      (f) => f.field === ATTRIBUTE_SERVICE && f.value === service,
    )
    const newFilters = filters.filter((f) => f.field !== ATTRIBUTE_SERVICE)
    if (!exists) {
      newFilters.push({
        field: ATTRIBUTE_SERVICE,
        operator: 'equals',
        value: service,
      })
    }
    setFilters(newFilters)
  }

  const handleSelectLevel = (level: string) => {
    const exists = filters.some(
      (f) => f.field === COLUMN_LEVEL && f.value === level,
    )
    const newFilters = filters.filter((f) => f.field !== COLUMN_LEVEL)
    if (!exists) {
      newFilters.push({
        field: COLUMN_LEVEL,
        operator: 'equals',
        value: level,
      })
    }
    setFilters(newFilters)
  }

  const addFilter = (property: string) => {
    setFilters(getUpdatedFilters(filters, property, null))
  }

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth
      if (width < 1024) {
        setSidebarVisible(false)
      } else {
        setSidebarVisible(true)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [setSidebarVisible])

  if (!sidebarVisible) return null

  return (
    <div className="flex-none w-[280px] border-r border-main-border overflow-y-auto">
      {hasLogs && !loaded && (
        <PanelSection>
          <div className="w-full h-6 flex items-center gap-1">
            <div className="px-2 flex items-center text-small-header text-text-1">
              Loading
              <LoadingDots />
            </div>
            <div className="w-6"></div>
          </div>
        </PanelSection>
      )}
      {loaded && hasLogs && services.length > 0 && (
        <PanelSection>
          <div className="w-full h-6 flex items-center gap-1">
            <RowButtonDropdown
              title={'Service'}
              open={serviceOpen}
              setOpen={setServiceOpen}
            />
            <div className="w-6"></div>
          </div>
          {serviceOpen &&
            services.map((service) => (
              <ServiceButton
                key={service}
                service={service}
                active={isServiceFiltered(service, filters)}
                onClick={() => handleSelectService(service)}
              />
            ))}
        </PanelSection>
      )}
      {loaded && hasLogs && levels.length > 0 && (
        <PanelSection>
          <div className="w-full h-6 flex items-center gap-1">
            <RowButtonDropdown
              title="Level"
              open={levelOpen}
              setOpen={setLevelOpen}
            />
            <div className="w-6"></div>
          </div>
          {levelOpen &&
            sortLevels(levels).map((level) => (
              <LevelButton
                key={level}
                level={level}
                active={isLevelFiltered(level, filters)}
                onClick={() => handleSelectLevel(level)}
              />
            ))}
        </PanelSection>
      )}
      {loaded && hasLogs && properties.length > 0 && (
        <PanelSection>
          <div className="w-full h-6 flex items-center gap-1">
            <RowButtonDropdown
              title="Attributes"
              open={attributesOpen}
              setOpen={setAttributesOpen}
            />
            <div className="w-6"></div>
          </div>
          {attributesOpen && (
            <>
              {visibleProperties.map((property) => (
                <PropertyButton
                  key={property}
                  property={property}
                  visible={true}
                  active={headers.some((h) => h.value === property)}
                  onClick={() => addFilter(property)}
                  onClickToggle={() => toggleHeader(property)}
                />
              ))}
              {hiddenProperties.length > 0 && <div className="w-full h-2" />}
              {sortProperties(hiddenProperties).map((property) => (
                <PropertyButton
                  key={property}
                  property={property}
                  visible={false}
                  active={headers.some((h) => h.value === property)}
                  onClick={() => addFilter(property)}
                  onClickToggle={() => toggleHeader(property)}
                />
              ))}
            </>
          )}
        </PanelSection>
      )}
    </div>
  )
}

interface ServiceButtonProps {
  service: string
  active: boolean
  onClick: () => void
}

const ServiceButton = ({ service, active, onClick }: ServiceButtonProps) => {
  return (
    <div className="h-6 flex items-center gap-1">
      <CodeButton
        text={service}
        active={active}
        icon={active ? <SquareCheckBig size={16} /> : <Square size={16} />}
        onClick={onClick}
      />
      <div className="w-6"></div>
    </div>
  )
}

interface LevelButtonProps {
  level: string
  active: boolean
  onClick: () => void
}

const LevelButton = ({ level, active, onClick }: LevelButtonProps) => {
  return (
    <div className="w-full h-6 flex items-center gap-1">
      <CodeButton
        text={formatLevel(level)}
        active={active}
        onClick={onClick}
        color={getLevelColor(level)}
        icon={active ? <SquareCheckBig size={16} /> : <Square size={16} />}
      />
      <div className="w-6"></div>
    </div>
  )
}

function getLevelColor(level: string) {
  if (level === 'INFO') return 'info'
  if (level === 'WARNING') return 'warning'
  if (level === 'ERROR') return 'error'
  if (level === 'DEBUG') return 'success'
  if (level === 'TRACE') return 'trace'
  return 'info'
}

function formatLevel(level: string) {
  if (level === 'INFO') return 'info'
  if (level === 'WARNING') return 'warning'
  if (level === 'ERROR') return 'error'
  if (level === 'DEBUG') return 'debug'
  if (level === 'TRACE') return 'trace'
  return 'info'
}

interface PropertyButtonProps {
  property: string
  active: boolean
  visible: boolean
  onClick: () => void
  onClickToggle: () => void
}

const PropertyButton = ({
  property,
  active,
  visible,
  onClick,
  onClickToggle,
}: PropertyButtonProps) => {
  return (
    <div className="property-button w-full h-6 flex items-center gap-1">
      <CodeButton
        text={propertyToDisplay(property)}
        active={active}
        onClick={onClick}
      />
      <div className="w-6 flex items-center justify-center">
        {visible ? (
          <IconButton
            icon={<Minus size={16} />}
            size="24"
            onClick={onClickToggle}
          />
        ) : (
          <IconButton
            icon={<Plus size={16} />}
            size="24"
            onClick={onClickToggle}
          />
        )}
      </div>
    </div>
  )
}

function isLevelFiltered(level: string, filters: Filter[]) {
  return filters.some(
    (filter) =>
      filter.field === COLUMN_LEVEL &&
      filter.value === level &&
      filter.operator === 'equals',
  )
}

function isServiceFiltered(service: string, filters: Filter[]) {
  return filters.some(
    (filter) =>
      filter.field === ATTRIBUTE_SERVICE &&
      filter.value === service &&
      filter.operator === 'equals',
  )
}

function sortProperties(properties: string[]) {
  return [...properties].sort((a, b) => {
    const aPriority = propertyPriority(a)
    const bPriority = propertyPriority(b)
    if (aPriority === bPriority) {
      return a.toLowerCase().localeCompare(b.toLowerCase())
    }
    return aPriority - bPriority
  })
}

function sortLevels(levels: string[]) {
  return [...levels].sort((a, b) => {
    return levelPriority(a) - levelPriority(b)
  })
}

function levelPriority(level: string) {
  if (level === 'ERROR') return 0
  if (level === 'WARNING') return 1
  if (level === 'INFO') return 2
  if (level === 'DEBUG') return 3
  if (level === 'TRACE') return 4
  return 5
}

function propertyPriority(property: string) {
  if (property.startsWith(COLUMN_PREFIX)) {
    switch (property) {
      case COLUMN_TIMESTAMP:
        return 0
      case COLUMN_BODY:
        return 1
      case COLUMN_LEVEL:
        return 2
    }
  }
  if (property.startsWith(ATTRIBUTE_PREFIX)) {
    switch (property) {
      case ATTRIBUTE_SERVICE:
        return 3
    }
  }
  return 4
}

function isLoaded(queryMode: QueryMode) {
  return queryMode !== 'initial_load'
}

export default LogSidebar
