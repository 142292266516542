import { X } from 'lucide-react'
import { cn } from '../../../utils/cn'
import IconButton from '../IconButton/IconButton'
import BrandButton from '../BrandButton/BrandButton'

interface BillingNoticePopupProps {
  mode: 'error' | 'warning'
  title: string
  message: string
  ctaMessage: string
  onClickCTA: () => void
  onClose: () => void
}

const BillingNoticePopup = ({
  mode,
  title,
  message,
  ctaMessage,
  onClickCTA,
  onClose,
}: BillingNoticePopupProps) => {
  return (
    <div className="w-[580px] p-6 flex flex-col items-start gap-3 rounded border border-popup-border bg-popup-bg">
      <div className="w-full flex items-center justify-between">
        <p className={cn(getTitleClass(mode), 'text-title2-medium')}>{title}</p>
        <IconButton size="32" icon={<X size={16} />} onClick={onClose} />
      </div>
      <p className="w-full text-normal-light text-text-1 mb-2">{message}</p>
      <BrandButton mode={mode} onClick={onClickCTA}>
        {ctaMessage}
      </BrandButton>
    </div>
  )
}

function getTitleClass(mode: 'error' | 'warning') {
  switch (mode) {
    case 'error':
      return 'text-brand-error'
    case 'warning':
      return 'text-brand-warning'
  }
}

export default BillingNoticePopup
