import { useEffect } from 'react'
import useLoadMetricsNames from '../load/useLoadMetricsNames'

const useInitMetricsNames = () => {
  const loadMetricsNames = useLoadMetricsNames()

  useEffect(() => {
    loadMetricsNames()
  }, [loadMetricsNames])
}

export default useInitMetricsNames
