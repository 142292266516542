export const colors = {
  info: {
    main: '#80EAFF',
    tint: '#80EAFF1A',
    tint2: '#80EAFF29',
  },
  warning: {
    main: '#FFD980',
    tint: '#FFD9801A',
    tint2: '#FFD98029',
  },
  trace: {
    main: '#FF80EC',
    tint: '#FF80EC1A',
    tint2: '#FF80EC29',
  },
  success: {
    main: '#81FF80',
    tint: '#81FF801A',
    tint2: '#81FF8029',
  },
  error: {
    main: '#FF8480',
    tint: '#FF84801A',
    tint2: '#FF848029',
  },
}

export const setBrandCTA = (color: keyof typeof colors) => {
  document.documentElement.style.setProperty(
    '--brand-cta-color',
    colors[color].main,
  )
  document.documentElement.style.setProperty(
    '--brand-cta-tint',
    colors[color].tint,
  )
  document.documentElement.style.setProperty(
    '--brand-cta-tint2',
    colors[color].tint2,
  )
}
