export type RelativeTime =
  | '1m'
  | '5m'
  | '30m'
  | '60m'
  | '3h'
  | '6h'
  | '12h'
  | '24h'
  | '2d'
  | '7d'

export type TimeRange = {
  start: Date | undefined | RelativeTime
  end: Date | undefined
}

export function getTimestamps(
  timeRange: TimeRange,
): [string | undefined, string | undefined] {
  const { start, end } = timeRange
  const endStr = end instanceof Date ? toUTCString(end) : undefined

  if (start instanceof Date) {
    return [toUTCString(start), endStr]
  }

  if (typeof start === 'string') {
    return [parseRelativeStart(start), endStr]
  }

  return [undefined, endStr]
}

function parseRelativeStart(startStr: string): string | undefined {
  const matches = startStr.match(/^(\d+)([mhd])$/)
  if (!matches) return undefined

  const [, amount, unit] = matches
  const value = parseInt(amount)
  const referenceDate = new Date()

  switch (unit) {
    case 'm':
      referenceDate.setUTCMinutes(referenceDate.getUTCMinutes() - value)
      break
    case 'h':
      referenceDate.setUTCHours(referenceDate.getUTCHours() - value)
      break
    case 'd':
      referenceDate.setUTCDate(referenceDate.getUTCDate() - value)
      break
  }

  return referenceDate.toISOString()
}

function toUTCString(date: Date): string {
  return date.toISOString()
}

export function formatTimestamp(
  timestamp: string | Date,
  format?: Intl.DateTimeFormatOptions,
) {
  let date: Date
  if (typeof timestamp === 'string') {
    date = new Date(timestamp)
  } else {
    date = timestamp
  }
  return date
    .toLocaleDateString(
      'en-US',
      format
        ? format
        : {
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
          },
    )
    .replace(',', '')
}

export const timeFormatDate: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
}

export function formatTimeAsDate(timestamp: string | Date) {
  let date: Date
  if (typeof timestamp === 'string') {
    date = new Date(timestamp)
  } else {
    date = timestamp
  }
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
}
