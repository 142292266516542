import { useEffect } from 'react'
import useLoadAnalytics from '../load/useLoadAnalytics'
import useLoadAnalyticsLayout from '../load/useLoadAnalyticsLayout'

const useInitAnalytics = () => {
  const loadAnalytics = useLoadAnalytics()
  const loadAnalyticsLayout = useLoadAnalyticsLayout()

  useEffect(() => {
    loadAnalytics()
  }, [loadAnalytics])

  useEffect(() => {
    loadAnalyticsLayout()
  }, [loadAnalyticsLayout])
}

export default useInitAnalytics
