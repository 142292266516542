import PanelRow from '../../../../../library/PanelRow/PanelRow'
import PanelSection from '../../../../../library/PanelSection/PanelSection'
import { useRef } from 'react'
import useClickOutside from '../../../../../../hooks/utils/useClickOutside'
import IconButton from '../../../../../library/IconButton/IconButton'
import { X } from 'lucide-react'
import CodeDisplay from '../../../../../library/CodeDisplay/CodeDisplay'
import { MetricEntry } from '../../../../../../backend/types'

interface MetricEntryDetailsProps {
  metricEntry: MetricEntry | null
  close: () => void
}

const MetricEntryDetails = ({
  metricEntry,
  close,
}: MetricEntryDetailsProps) => {
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, close, undefined, ['metric-row'])

  if (!metricEntry) return null

  return (
    <div
      ref={ref}
      className="absolute top-12 right-0 bottom-0 w-[600px] flex flex-col bg-panel-bg border border-y-0 border-main-border overflow-y-auto"
    >
      <PanelSection bottom={true}>
        <PanelRow>
          <p className="text-small-header text-text-1">Raw Metric Entry</p>
          <IconButton icon={<X className="w-4 h-4" />} onClick={close} />
        </PanelRow>
        <div className="px-2 py-1">
          <CodeDisplay
            language="json"
            content={JSON.stringify(metricEntry, null, 2)}
          />
        </div>
      </PanelSection>
    </div>
  )
}

export default MetricEntryDetails
