import useBackend from '../services/useBackend'
import useToast from '../utils/useToast'
import { captureAxiosError } from '../../utils/obeservability'

const useSendSupport = () => {
  const backend = useBackend()
  const toast = useToast()

  return async (message: string) => {
    try {
      await backend.sendSupport(message)
      toast('Support message sent', 'success')
    } catch (e) {
      captureAxiosError(e)
    }
  }
}

export default useSendSupport
