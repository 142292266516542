import { QueryManager } from './queryManager'
import { QueryMode, QueryState } from './types'

export class QueryLiveTick {
  private queryManager: QueryManager

  private queryState: QueryState | null

  private timeout: ReturnType<typeof setTimeout> | null
  private isLive: boolean

  constructor(queryManager: QueryManager, isLive: boolean) {
    this.queryManager = queryManager
    this.queryState = null

    this.isLive = isLive
    this.timeout = null
  }

  init = () => {
    this.queryManager.subscribe('live-tick', this.handleState)
  }

  shutdown = () => {
    this.queryManager.unsubscribe('live-tick')
  }

  setLive = (isLive: boolean) => {
    if (this.isLive === isLive) return
    this.isLive = isLive
    if (
      this.queryState &&
      cancellableMode.includes(this.queryState.mode) &&
      isLive
    ) {
      this.queryManager.liveTick(this.queryState)
    } else if (!isLive && this.timeout) {
      clearTimeout(this.timeout)
      this.queryManager.cancelLiveTick()
    }
  }

  private handleState = (queryState: QueryState) => {
    this.queryState = queryState
    this.handleLiveTick()
  }

  private handleLiveTick = () => {
    if (!this.queryState || !this.isLive) return
    if (this.timeout) clearTimeout(this.timeout)
    if (this.queryState.mode === 'none' && this.isLive) {
      this.timeout = setTimeout(() => {
        if (!this.queryState || !this.isLive) return
        this.queryManager.liveTick(this.queryState)
      }, 1000)
    }
  }
}

const cancellableMode: (QueryMode | undefined)[] = [
  'none',
  'loading_more_down',
  'loading_more_up',
]
