import { cn } from '../../../utils/cn'

interface PanelRowProps {
  children: React.ReactNode
  mode?: 'default' | 'popup'
  className?: string
}

const PanelRow = ({ children, mode = 'default', className }: PanelRowProps) => {
  const borderColor = getBorderColor(mode)

  return (
    <div
      className={cn(
        'flex-none px-2 w-full min-h-6 flex items-center justify-between gap-2',
        borderColor,
        className,
      )}
    >
      {children}
    </div>
  )
}

function getBorderColor(mode: 'default' | 'popup') {
  return mode === 'default' ? 'border-main-border' : 'border-panel-border'
}

export default PanelRow
