import { useCallback, useEffect, useRef, useState } from 'react'
import useClickOutside from '../../../../../../../../../../hooks/utils/useClickOutside'
import { ListFilter, Square, SquareCheckBig, X } from 'lucide-react'
import PopupCodeButton from '../../../../../../../../../library/PopupCodeButton/PopupCodeButton'
import { Filter } from '../../../../../../../../../../backend/types'
import { OptionsQueryState } from '../../../../../../../../../../library/queryOptions/types'
import {
  COLUMN_LEVEL,
  COLUMN_TIMESTAMP,
  formatValue,
  getUpdatedFilters,
  getValueColor,
} from '../../../../../../../../../../utils/properties'
import LoadingDots from '../../../../../../../../../library/LoadingDots/LoadingDots'
import useQueryOptionsManager from '../../../../../../../../../../hooks/stateServices/useQueryOptionsManager'
import { genKey } from '../../../../../../../../../../utils/sub'
import { cn } from '../../../../../../../../../../utils/cn'

interface LogHeaderPopupProps {
  property: string

  filters: Filter[]
  setFilters: (filters: Filter[]) => void

  toggleHeader: (header: string) => void

  headerRef: React.RefObject<HTMLDivElement | null>
  close: () => void
}

const LogHeaderPopup = ({
  property,
  filters,
  setFilters,
  toggleHeader,
  headerRef,
  close,
}: LogHeaderPopupProps) => {
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, close, headerRef)

  const queryOptionsManager = useQueryOptionsManager()
  const [queryState, setQueryState] = useState<OptionsQueryState | null>(null)

  const scrollableRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    scrollableRef.current?.addEventListener(
      'wheel',
      (e) => {
        e.preventDefault()
        scrollableRef.current!.scrollTop += e.deltaY
      },
      { passive: false },
    )
  }, [])

  const handleFilterEquals = useCallback(
    (value: string) => {
      setFilters(getUpdatedFilters(filters, property, value))
      close()
    },
    [filters, property, setFilters, close],
  )

  const handleAddFilter = useCallback(() => {
    setFilters(getUpdatedFilters(filters, property, null))
    close()
  }, [filters, property, setFilters, close])

  const handleToggleHeader = useCallback(() => {
    toggleHeader(property)
    close()
  }, [toggleHeader, property, close])

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!queryState) return
      queryOptionsManager?.queryOptions(queryState, {
        ...queryState.request,
        value: e.target.value,
      })
    },
    [queryOptionsManager, queryState],
  )

  useEffect(() => {
    if (!queryOptionsManager) return
    const key = genKey()
    queryOptionsManager.subscribe(key, setQueryState)
    queryOptionsManager?.queryOptions(null, {
      term: '',
      time_range: { start: undefined, end: undefined },
      filters: [],
      properties: [],
      field: property,
    })
    return () => {
      queryOptionsManager.unsubscribe(key)
    }
  }, [queryOptionsManager, property])

  return (
    <div
      ref={ref}
      className="absolute top-[calc(100%+4px)] left-0 w-[300px] max-h-[400px] bg-popup-bg border border-popup-border rounded-[4px] shadow-popup-shadow flex flex-col"
    >
      <div className="p-4 w-full flex flex-col items-center gap-1 border-b border-popup-border">
        <button
          className="px-2 w-full h-[28px] flex flex-row items-center gap-2 rounded-[4px] hover:bg-input-tint text-text-1 text-small-light select-none"
          onClick={handleAddFilter}
        >
          <ListFilter size={16} className="text-text-2" />
          Filter
        </button>
        <button
          className="px-2 w-full h-[28px] flex flex-row items-center gap-2 rounded-[4px] hover:bg-input-tint text-text-1 text-small-light select-none"
          onClick={handleToggleHeader}
        >
          <X size={16} className="text-text-2" />
          Hide Column
        </button>
      </div>
      <div
        ref={scrollableRef}
        className="w-full flex flex-col items-center gap-1 overflow-y-auto z-[0]"
      >
        {canSearch(property) && (
          <div className="p-4 pb-2 sticky top-0 w-full flex flex-col items-center bg-popup-bg z-[1]">
            <input
              type={'text'}
              className="px-2 w-full h-[28px] rounded-[4px] bg-input-bgTint border border-popup-border text-text-1 text-mini-code placeholder:text-text-2"
              placeholder={'search values'}
              value={queryState?.request.value}
              onChange={handleSearchChange}
            />
          </div>
        )}
        {queryState?.mode === 'loading' &&
          (queryState.result?.options.length ?? 0) === 0 && (
            <div
              className={cn(
                'p-4 w-full flex flex-col items-center gap-1',
                canSearch(property) ? 'pt-0' : '',
              )}
            >
              <div className="px-2 w-full h-[28px] flex items-center gap-1 text-text-2 text-small-light">
                Loading Values <LoadingDots />
              </div>
            </div>
          )}
        {(queryState?.result?.options.length ?? 0) > 0 && (
          <div
            className={cn(
              'p-4 w-full flex flex-col items-center gap-1',
              canSearch(property) ? 'pt-0' : '',
            )}
          >
            {(queryState?.result?.options ?? []).map((option) => (
              <PopupCodeButton
                key={option}
                text={formatValue(property, option) ?? '"..."'}
                icon={
                  isValueFiltered(property, option, filters) ? (
                    <SquareCheckBig size={16} />
                  ) : (
                    <Square size={16} />
                  )
                }
                color={getValueColor(property, option)}
                onClick={() => {
                  handleFilterEquals(option)
                  close()
                }}
              />
            ))}
            {(queryState?.result?.options.length ?? 0) > 99 && (
              <div className="w-full py-8 flex flex-col items-center gap-1">
                <p className="w-full text-center text-text-2 text-small-code">
                  Too many results to display.
                </p>
                <p className="w-full text-center text-text-2 text-small-code">
                  Try searching.
                </p>
              </div>
            )}
          </div>
        )}
        {queryState?.mode === 'none' &&
          (queryState?.result?.options.length ?? 0) === 0 && (
            <div
              className={cn(
                'p-4 w-full flex flex-col items-center gap-1',
                canSearch(property) ? 'pt-0' : '',
              )}
            >
              {(queryState?.result?.options.length ?? 0) === 0 && (
                <div className="px-2 w-full h-[28px] flex items-center gap-1">
                  <p className="text-text-2 text-small-light">
                    No values found
                  </p>
                </div>
              )}
            </div>
          )}
      </div>
    </div>
  )
}

function isValueFiltered(property: string, value: string, filters: Filter[]) {
  return filters.some(
    (filter) =>
      filter.field === property &&
      filter.operator === 'equals' &&
      filter.value === value,
  )
}

function canSearch(property: string) {
  switch (property) {
    case COLUMN_LEVEL:
    case COLUMN_TIMESTAMP:
      return false
    default:
      return true
  }
}

export default LogHeaderPopup
