import { useMemo } from 'react'
import { useAtomValue } from 'jotai'
import { instanceIdAtom } from '../../state/state'
import { QueryManager } from '../../library/query/queryManager'
import { QueryRunner } from '../../library/query/queryRunner'
import useBackend from '../services/useBackend'

const useQueryManager = () => {
  const { projectId } = useAtomValue(instanceIdAtom)
  const backend = useBackend()

  const queryManager = useMemo(() => {
    if (!projectId) return null
    const queryRunner = new QueryRunner(projectId, backend)
    return new QueryManager(queryRunner)
  }, [projectId, backend])

  return queryManager
}

export default useQueryManager
