import { useSetAtom } from 'jotai'
import {
  instanceIdAtom,
  organizationsAtom,
  projectsAtom,
} from '../../state/state'
import useAuthService from '../services/useAuthService'
import { useCallback } from 'react'
import useNav, { LinkKey } from '../nav/useNav'

const useLogout = () => {
  const auth = useAuthService()
  const navigate = useNav()

  const setInstanceId = useSetAtom(instanceIdAtom)
  const setOrganizations = useSetAtom(organizationsAtom)
  const setProjects = useSetAtom(projectsAtom)

  return useCallback(() => {
    auth.clearUserToken()
    setInstanceId({ organizationId: null, projectId: null })
    setOrganizations(null)
    setProjects(null)
    navigate(LinkKey.Login)
  }, [auth, navigate, setInstanceId, setOrganizations, setProjects])
}

export default useLogout
