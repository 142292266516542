import { RefObject, useEffect } from 'react'

const useSubmitOnHotkey = (
  inputRef: RefObject<HTMLInputElement | null>,
  onSubmit: () => void = () => {},
  hotkeys: string[] = ['Enter', 'Escape'],
) => {
  useEffect(() => {
    const input = inputRef.current
    if (!input) return
    const handleKeyDown = (event: KeyboardEvent) => {
      if (hotkeys.includes(event.key) && document.activeElement === input) {
        event.preventDefault()
        input.blur()
        onSubmit()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [inputRef, hotkeys, onSubmit])
}

export default useSubmitOnHotkey
