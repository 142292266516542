import { CornerDownLeft } from 'lucide-react'

interface QueryButtonProps {
  runSearch: () => Promise<void>
}

const QueryButton = ({ runSearch }: QueryButtonProps) => {
  return (
    <button
      className={`px-2 flex items-center justify-center gap-2 rounded-[4px] rounded-l-none text-text-2 border border-l-0 border-input-border bg-input-bgTint outline-none hover:bg-input-tint hover:text-text-1`}
      onClick={runSearch}
    >
      <CornerDownLeft size={16} />
    </button>
  )
}

export default QueryButton
