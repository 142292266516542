import { useSetAtom } from 'jotai'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'
import { captureAxiosError } from '../../../utils/obeservability'
import { alertSettingsAtom } from '../../../state/state'

const useLoadAlertSettings = () => {
  const backend = useBackend()
  const setAlertSettings = useSetAtom(alertSettingsAtom)

  const loadAlertSettings = useCallback(async () => {
    try {
      const { settings } = await backend.getAlertSettings()
      setAlertSettings(settings)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, setAlertSettings])

  return loadAlertSettings
}

export default useLoadAlertSettings
