import { useAtomValue } from 'jotai'
import { instanceIdAtom } from '../../state/state'
import useBackend from '../services/useBackend'
import useLoadTokens from '../data/load/useLoadTokens'
import { captureAxiosError } from '../../utils/obeservability'

const useDeleteToken = () => {
  const backend = useBackend()
  const { projectId } = useAtomValue(instanceIdAtom)
  const loadTokens = useLoadTokens()

  const deleteAPIKey = async (id: string) => {
    if (!projectId) return
    try {
      await backend.deleteProjectToken(projectId, id)
      await loadTokens()
    } catch (e) {
      captureAxiosError(e)
    }
  }

  return deleteAPIKey
}

export default useDeleteToken
