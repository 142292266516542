import { useAtomValue } from 'jotai'
import useBackend from '../services/useBackend'
import { instanceIdAtom } from '../../state/state'
import useLoadOrganizationInfo from '../data/load/useLoadOrganizationInfo'
import useToast from '../utils/useToast'
import useLoadOrganizations from '../data/load/useLoadOrganizations'
import useLoadOrganizationMembers from '../data/load/useLoadOrganizationMembers'
import { captureAxiosError } from '../../utils/obeservability'

const useUpdateOrganizationOwner = () => {
  const backend = useBackend()
  const toast = useToast()

  const { organizationId } = useAtomValue(instanceIdAtom)
  const loadOrganizationInfo = useLoadOrganizationInfo()
  const loadOrganizations = useLoadOrganizations()
  const loadOrganizationMembers = useLoadOrganizationMembers()

  return async (owner_id: string) => {
    if (!organizationId) return
    try {
      await backend.updateOrganization(organizationId, { owner_id })
      await loadOrganizationInfo()
      await loadOrganizations()
      await loadOrganizationMembers()
      toast('Organization owner updated', 'success')
    } catch (e) {
      captureAxiosError(e)
      toast('Organization owner update failed', 'error')
    }
  }
}

export default useUpdateOrganizationOwner
