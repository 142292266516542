import { useAtomValue } from 'jotai'
import { instanceIdAtom, projectsAtom } from '../../state/state'
import useBackend from '../services/useBackend'
import useLoadProjects from '../data/load/useLoadProjects'
import useSetInstance from './useSetInstance'
import { captureAxiosError } from '../../utils/obeservability'

const useDeleteProject = () => {
  const backend = useBackend()
  const { organizationId } = useAtomValue(instanceIdAtom)
  const projects = useAtomValue(projectsAtom)

  const loadProjects = useLoadProjects()
  const switchProject = useSetInstance()

  const deleteProject = async (projectId: string) => {
    if (!organizationId) return
    try {
      await backend.deleteProject(projectId)
      await loadProjects()
      if (!projects || projects.length === 0) return
      const newProject = projects.find((p) => p.id !== projectId)
      if (!newProject) return
      await switchProject(organizationId, newProject.id)
    } catch (e) {
      captureAxiosError(e)
    }
  }

  return deleteProject
}

export default useDeleteProject
