import useLoggedOut from '../../../hooks/utils/useLoggedOut'
import SolidInput from '../../library/SolidInput/SolidInput'
import AnimationFadeSlideIn from '../../library/AnimationFadeSlideIn/AnimationFadeSlideIn'
import useNav, { LinkKey } from '../../../hooks/nav/useNav'
import useInitAdminData from '../../../hooks/data/effects/useInitAdminData'
import BrandButton from '../../library/BrandButton/BrandButton'
import { useState } from 'react'
import useCreateOrganizationInvite from '../../../hooks/actions/useCreateOrganizationInvite'
import { isValidEmail } from '../../../utils/inputs'
import SolidButton from '../../library/SolidButton/SolidButton'

const InviteTeam = () => {
  const loggedOut = useLoggedOut()
  useInitAdminData()

  const navigate = useNav()
  const createInvite = useCreateOrganizationInvite()

  const [email, setEmail] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await createInvite(email)
    setEmail('')
  }

  if (loggedOut) return <></>

  return (
    <div className="h-screen w-screen flex flex-col gap-6 items-center justify-center bg-main-bg">
      <AnimationFadeSlideIn renderDelay={0.2} delay={0}>
        <p className="text-title1-medium text-text-1">Invite your team</p>
      </AnimationFadeSlideIn>
      <AnimationFadeSlideIn renderDelay={0.2} delay={0.2}>
        <p className="w-[400px] text-normal-light text-text-2 text-center">
          Vigilant can help your whole software team.
        </p>
      </AnimationFadeSlideIn>
      <AnimationFadeSlideIn renderDelay={0.2} delay={0.4}>
        <form
          className="w-[400px] p-6 border border-panel-border bg-panel-bg rounded"
          onSubmit={handleSubmit}
        >
          <div className="w-full flex flex-col items-start justify-start gap-2">
            <p className="text-small-light text-text-2">Coworker email</p>
            <div className="w-full flex items-start gap-2">
              <SolidInput
                value={email}
                placeholder="Enter email"
                onChange={(e) => setEmail(e)}
              />
              <SolidButton
                disabled={!email || !isValidEmail(email)}
                onClick={handleSubmit}
              >
                Send Invite
              </SolidButton>
            </div>
          </div>
        </form>
      </AnimationFadeSlideIn>
      <AnimationFadeSlideIn renderDelay={0.2} delay={0.6}>
        <BrandButton mode="trace" onClick={() => navigate(LinkKey.Start)}>
          Done Inviting
        </BrandButton>
      </AnimationFadeSlideIn>
    </div>
  )
}

export default InviteTeam
