import { useAtomValue } from 'jotai'
import { hasLogsAtom } from '../../../../../../state/state'
import EmptyDataPlaceholder from '../../../../../library/EmptyDataPlaceholder/EmptyDataPlaceholder'
import { LinkKey } from '../../../../../../hooks/nav/useNav'

const LogsOnboardingDisplay = () => {
  const hasLogs = useAtomValue(hasLogsAtom)
  return (
    hasLogs === false && (
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center pointer-events-none">
        <EmptyDataPlaceholder
          imgUrl={'/onboarding/logs.svg'}
          title="Log management"
          paragraphs={[
            'Vigilant helps you debug faster by storing and searching logs.',
            'Get started by sending logs from your app.',
          ]}
          ctaText="Setup Logging"
          ctaLink={LinkKey.SetupLogs}
        />
      </div>
    )
  )
}

export default LogsOnboardingDisplay
