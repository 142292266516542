import { LogEntry } from '../../../../../../../../state/types'
import { Header } from '../../../../Logs'
import LogCell from './LogCell/LogCell'
import { useState, useEffect, useRef } from 'react'
import { cn } from '../../../../../../../../utils/cn'
import { useNavLog } from '../../../../../../../../hooks/nav/useNavLog'
import useHovered from '../../../../../../../../hooks/utils/useHover'

interface LogRowProps {
  log: LogEntry
  isNew: boolean
  term: string
  columns: Header[]
  addEqualsFilter: (property: string, value: string) => void
}

const LogRow = ({
  log,
  isNew,
  term,
  columns,
  addEqualsFilter,
}: LogRowProps) => {
  const [navLog, setNavLog] = useNavLog()
  const rowRef = useRef<HTMLDivElement>(null)
  const hovered = useHovered(rowRef)
  const [initialMousePosition, setInitialMousePosition] = useState<{
    x: number
    y: number
  } | null>(null)

  const isSelected = navLog.logId === log.log_id
  const [flashActive, setFlashActive] = useState(isNew)
  const [flashDone, setFlashDone] = useState(!isNew)

  useEffect(() => {
    if (!isNew) return
    setFlashActive(false)
    const removeTransitionTimer = setTimeout(() => setFlashDone(true), 300)
    return () => clearTimeout(removeTransitionTimer)
  }, [isNew])

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const isLogCell = (e.target as HTMLElement).closest('.log-cell') !== null
    if (isLogCell && e.metaKey) return

    if (initialMousePosition) {
      const dx = Math.abs(e.clientX - initialMousePosition.x)
      const dy = Math.abs(e.clientY - initialMousePosition.y)
      if (Math.sqrt(dx * dx + dy * dy) > 5) return

      if (isSelected) {
        setNavLog({ logId: null, logTimestamp: null })
      } else {
        setNavLog({ logId: log.log_id, logTimestamp: log.timestamp })
      }
    }
  }

  return (
    <div
      id={`log-row-${log.log_id}`}
      ref={rowRef}
      className={cn(
        'log-row relative flex border-b border-main-border z-[0] cursor-pointer',
        flashActive || isSelected
          ? 'bg-input-tint'
          : hovered
            ? 'bg-input-tintSoft'
            : 'bg-transparent',
        !flashDone ? 'transition-colors duration-300 ease-in' : '',
      )}
      onMouseDown={(e) =>
        setInitialMousePosition({ x: e.clientX, y: e.clientY })
      }
      onClick={handleClick}
    >
      {columns.map((column, index) => (
        <LogCell
          key={`${column.value}-${index}`}
          term={term}
          width={column.width}
          column={column.value}
          log={log}
          rowActive={hovered || isSelected}
          addEqualsFilter={addEqualsFilter}
        />
      ))}
      <div className="w-[75vw]"></div>
    </div>
  )
}

export default LogRow
