import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import PreviewLogs from '../../../../library/PreviewLogs/PreviewLogs'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const SetupLogsReact = () => {
  useSetBrandColor('info')

  return (
    <SetupTemplate
      title="Log Setup - React"
      keyName="react-api-key"
      installStep={installSnippets}
      sendTitle="Send a log"
      sendEventStep={getSendMessageSnippets}
      preview={<PreviewLogs />}
      done={LinkKey.Logs}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'npm',
    language: 'bash',
    displayLanguage: 'bash',
    content: `npm install vigilant-js`,
  },
  {
    title: 'yarn',
    language: 'bash',
    displayLanguage: 'bash',
    content: `yarn add vigilant-js`,
  },
  {
    title: 'bun',
    language: 'bash',
    displayLanguage: 'bash',
    content: `bun install vigilant-js`,
  },
]

function getSendMessageSnippets(key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'React',
      language: 'javascript',
      displayLanguage: 'javascript',
      content: `import { Logger } from "vigilant-js/react";

const logger = new Logger({
  name: "my-service",
  token: "${key}",
});

// Enable autocapture to read logs from stdout and stderr
logger.autocapture_enable();

const SampleComponent = () => {
  console.log("User logged in");
  console.error("Database connection failed");
  return (
    <div>
      <h1>Sample Component</h1>
    </div>
  )
}

export default SampleComponent;`,
    },
  ]
}

export default SetupLogsReact
