import { MetricSeries } from '../../../../../backend/types'
import { formatNumber } from '../../../../../utils/properties'
import { formatTimestamp, timeFormatDate } from '../../../../../utils/time'

interface LegendProps {
  hoverIndex: number | null
  left: number | null
  data: MetricSeries[]
  units?: string
}

const Legend = ({ hoverIndex, left, data, units }: LegendProps) => {
  if (hoverIndex === null || left === null) return null

  const legendDate = getLegendDate(hoverIndex, data)
  const sortedSeriesIndices = getSortedSeriesIndices(hoverIndex, data)

  return (
    <div
      className="absolute w-[240px] max-h-[308px] top-4 p-2 flex flex-col items-start justify-start gap-1 bg-popup-bg border border-popup-border rounded shadow-popup z-[1] pointer-events-none"
      style={{ left }}
    >
      <div className="px-2 h-4 flex items-center gap-2 w-full">
        <p className="text-mini-medium text-text-1">{legendDate}</p>
      </div>
      {sortedSeriesIndices.map((seriesIndex) => (
        <div
          key={`legend-${seriesIndex}`}
          className="w-full px-2 h-4 flex-none flex items-center justify-between gap-4 whitespace-nowrap overflow-hidden"
        >
          <div className="flex items-center gap-2">
            <div
              className="w-2 h-2"
              style={{ backgroundColor: getSeriesColor(seriesIndex) }}
            />
            <p className="text-mini-medium text-text-1">
              {data[seriesIndex].name}
            </p>
          </div>
          <p className="max-w-[80px] text-mini-medium text-text-1 overflow-hidden text-ellipsis">
            {formatNumber(data[seriesIndex].entries[hoverIndex].value ?? 0)}{' '}
            {units}
          </p>
        </div>
      ))}
      {data.length > 10 && (
        <div className="w-full px-2 h-4 flex-none flex items-center justify-start">
          <p className="text-mini-medium text-text-2">
            {data.length - 10} more not shown
          </p>
        </div>
      )}
    </div>
  )
}

function getLegendDate(hoverIndex: number, data: MetricSeries[]): string {
  if (!data.length || !data[0].entries.length) return ''

  const lastIndex = data[0].entries.length - 1
  const index = hoverIndex === null ? lastIndex : hoverIndex
  if (index < 0 || index >= data[0].entries.length) return ''

  return formatTimestamp(data[0].entries[index].timestamp, timeFormatDate)
}

function getSortedSeriesIndices(
  hoverIndex: number,
  data: MetricSeries[],
): number[] {
  return data
    .map((series, index) => ({
      series,
      index,
      value: series.entries[hoverIndex].value ?? 0,
    }))
    .sort((a, b) => b.value - a.value)
    .map((item) => item.index)
    .slice(0, 10)
}

function getSeriesColor(index: number): string {
  const colors = [
    '#80EAFF',
    '#FFD980',
    '#81FF80',
    '#FF8480',
    '#8480FF',
    '#D980FF',
    '#80ACFF',
    '#F4FF80',
  ]
  return colors[index % colors.length]
}

export default Legend
