import useLoggedOut from '../../../hooks/utils/useLoggedOut'
import useInitAdminData from '../../../hooks/data/effects/useInitAdminData'
import useProjectSelected from '../../../hooks/utils/useProjectSelected'
import { Outlet, useLocation } from 'react-router-dom'
import Navbar, { NavbarTab } from '../../library/Navbar/Navbar'
import { LinkKey } from '../../../hooks/nav/useNav'
import { linkMap } from '../../../hooks/nav/useNav'
import useProjectLink from '../../../hooks/utils/useProjectLink'
import useLogPageVisit from '../../../hooks/utils/useLogPageVisit'
import { isAdminDataInitializedAtom } from '../../../state/state'
import { useAtomValue } from 'jotai'
import BillingNotice from './BillingNotice/BillingNotice'

const Dashboard: React.FC = () => {
  const loggedOut = useLoggedOut()
  const location = useLocation()
  const activeTab = getActiveTab(location.pathname)
  const initialized = useAtomValue(isAdminDataInitializedAtom)

  useInitAdminData()
  useProjectSelected()
  useProjectLink()
  useLogPageVisit()

  if (loggedOut || !initialized) return <></>

  return (
    <div className="h-screen w-screen bg-background">
      <Navbar activeTab={activeTab} />
      <Outlet />
      <BillingNotice />
    </div>
  )
}

const getActiveTab = (location: string): NavbarTab | null => {
  const cleanPath = new URL(location, 'http://x').pathname.replace(/\/$/, '')
  switch (cleanPath) {
    case linkMap[LinkKey.SettingsProjectGeneral]:
    case linkMap[LinkKey.SettingsProjectAPI]:
    case linkMap[LinkKey.SettingsProjectAlerts]:
      return 'settingsProject'
    case linkMap[LinkKey.SettingsUserProfile]:
      return 'settingsUser'
    case linkMap[LinkKey.SettingsOrganizationGeneral]:
    case linkMap[LinkKey.SettingsOrganizationTeam]:
    case linkMap[LinkKey.SettingsOrganizationBilling]:
      return 'settingsOrganization'
    case linkMap[LinkKey.Logs]:
    case linkMap[LinkKey.SetupLogs]:
    case linkMap[LinkKey.SetupLogsNodeJS]:
    case linkMap[LinkKey.SetupLogsNextJS]:
    case linkMap[LinkKey.SetupLogsReact]:
    case linkMap[LinkKey.SetupLogsReactNative]:
    case linkMap[LinkKey.SetupLogsPython]:
    case linkMap[LinkKey.SetupLogsGo]:
    case linkMap[LinkKey.SetupLogsRuby]:
    case linkMap[LinkKey.SetupLogsRails]:
    case linkMap[LinkKey.SetupLogsDart]:
    case linkMap[LinkKey.SetupLogsRust]:
    case linkMap[LinkKey.SetupLogsRustLog]:
    case linkMap[LinkKey.SetupLogsRustTracing]:
    case linkMap[LinkKey.SetupLogsCpp]:
    case linkMap[LinkKey.SetupLogsRender]:
    case linkMap[LinkKey.SetupLogsFlyIO]:
      return 'logs'
    case linkMap[LinkKey.Metrics]:
    case linkMap[LinkKey.SetupMetrics]:
    case linkMap[LinkKey.SetupMetricsGo]:
    case linkMap[LinkKey.SetupMetricsNodeJS]:
    case linkMap[LinkKey.SetupMetricsPython]:
      return 'metrics'
    case linkMap[LinkKey.Analytics]:
      return 'analytics'
    case linkMap[LinkKey.Alerts]:
    case linkMap[LinkKey.SetupAlerts]:
    case linkMap[LinkKey.SetupAlertsGo]:
      return 'alerts'
    default:
      return null
  }
}

export default Dashboard
