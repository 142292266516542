import {
  AnalyticsAggregationFunction,
  AnalyticsSettingsCounter,
  AnalyticsUpdateRequest,
} from '../../../backend/types'
import { cn } from '../../../utils/cn'
import { formatNumber } from '../../../utils/properties'
import EditButton from './EditButton/EditButton'

interface AnalyticsDisplayCounterProps {
  settings: AnalyticsSettingsCounter
  value: number
  previousValue: number

  rearranging?: boolean

  onUpdate: (settings: AnalyticsUpdateRequest) => void
  onDuplicate: () => void
  onDelete: () => void
}

const AnalyticsDisplayCounter = ({
  settings,
  value,
  previousValue,
  onUpdate,
  onDuplicate,
  onDelete,
}: AnalyticsDisplayCounterProps) => {
  return (
    <div className="w-full px-6 py-4 flex flex-col gap-2 bg-panel-bg border border-panel-border rounded">
      <div className="w-full flex items-center justify-between">
        <p className="text-normal-medium text-text-1">
          {settings.name || 'Counter'}
        </p>
        <EditButton
          settings={settings}
          onUpdate={onUpdate}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      </div>
      <div className="w-full flex items-end justify-start gap-2">
        <p className="text-unique-counter text-text-1">{formatNumber(value)}</p>
        {settings.unit && (
          <p className="text-small-light text-text-1 mb-1.5">{settings.unit}</p>
        )}
        {settings.diff && (
          <p
            className={cn(
              'text-small-light mb-1.5',
              getPercentageColor(value, previousValue),
            )}
          >
            {formatPercentage(value, previousValue)}
          </p>
        )}
      </div>
      <div className="w-full flex items-end justify-start gap-2">
        <p className="text-small-light text-text-2">
          {formatFunction(settings.aggregation_function, settings.metric_name)}
        </p>
      </div>
    </div>
  )
}

function formatFunction(
  func: AnalyticsAggregationFunction,
  metricName: string,
) {
  switch (func) {
    case 'sum':
      return `Sum of ${metricName}`
    case 'avg':
      return `Avg of ${metricName}`
    case 'min':
      return `Min of ${metricName}`
    case 'max':
      return `Max of ${metricName}`
    case 'cumulative':
      return `Cumulative sum of ${metricName}`
  }
}

function formatPercentage(value: number, previousValue: number) {
  if (value === 0 && previousValue === 0) {
    return '0%'
  } else if (previousValue === 0) {
    return '+100%'
  } else if (value === 0 && previousValue !== 0) {
    return '-100%'
  } else if (value > previousValue) {
    return `+${Math.round(((value - previousValue) / previousValue) * 100)}%`
  } else {
    return `${Math.round(((value - previousValue) / previousValue) * 100)}%`
  }
}

function getPercentageColor(value: number, previousValue: number) {
  if (value === 0 && previousValue === 0) {
    return 'text-text-2'
  } else if (previousValue === 0 && value > 0) {
    return 'text-brand-success'
  } else if (value === 0 && previousValue !== 0) {
    return 'text-brand-error'
  } else if (value > previousValue) {
    return 'text-brand-success'
  } else {
    return 'text-brand-error'
  }
}

export default AnalyticsDisplayCounter
