import Sidebar from './LogSidebar/LogSidebar'
import NavbarPlaceholder from '../../../../library/NavbarPlaceholder/NavbarPlaceholder'
import { Filter, LogCursor } from '../../../../../backend/types'
import { LogEntry } from '../../../../../state/types'
import { Header } from '../Logs'
import { TimeRange } from '../../../../../utils/time'
import { QueryMode } from '../../../../../library/query/types'
import Searchbar from './Searchbar/Searchbar'
import LogDetailsPanel from './LogDetailsPanel/LogDetailsPanel'
import LogScrollContainer from './LogScrollContainer/LogScrollContainer'
import LogsOnboardingDisplay from './LogsOnboardingDisplay/LogsOnboardingDisplay'
import { useLayoutEffect } from 'react'
import { scrollToElement } from '../../../../../utils/scroll'

interface LogDisplayProps {
  logs: LogEntry[]
  newLogs: string[]

  shouldFocus: boolean
  focusedCursor: LogCursor | null

  queryMode: QueryMode
  previousQueryMode: QueryMode

  atStart: boolean
  atEnd: boolean

  runSearch: () => Promise<void>
  viewContext: (log: LogEntry) => Promise<void>

  term: string
  setTerm: (term: string) => void

  live: boolean
  setLive: (live: boolean) => void

  filters: Filter[]
  setFilters: (filters: Filter[]) => void

  timeRange: TimeRange
  setTimeRange: (timeRange: TimeRange) => void

  total: number
  services: string[]
  levels: string[]
  properties: string[]

  headers: Header[]
  setHeaders: (headers: Header[]) => void
  toggleHeader: (header: string) => void

  logContainerRef: React.RefObject<HTMLDivElement | null>
}

const LogDisplay = ({
  logs,
  newLogs,
  shouldFocus,
  focusedCursor,
  queryMode,
  previousQueryMode,
  atStart,
  atEnd,
  term,
  setTerm,
  runSearch,
  viewContext,
  live,
  setLive,
  filters,
  setFilters,
  timeRange,
  setTimeRange,
  total,
  services,
  levels,
  properties,
  headers,
  setHeaders,
  toggleHeader,
  logContainerRef,
}: LogDisplayProps) => {
  const visibleProperties = headers.map((h) => h.value)
  const hiddenProperties = properties.filter(
    (a) => !headers.some((h) => h.value === a),
  )

  useLayoutEffect(() => {
    if (
      shouldFocus &&
      focusedCursor &&
      logs.some((l) => l.log_id === focusedCursor.log_id)
    ) {
      scrollToElement(`log-row-${focusedCursor.log_id}`, 'log-scroll-container')
    }
  }, [shouldFocus, focusedCursor, logs])

  return (
    <div className="flex flex-col w-full h-full">
      <NavbarPlaceholder />
      <div className="flex-1 flex overflow-hidden">
        <Sidebar
          queryMode={queryMode}
          services={services}
          levels={levels}
          properties={properties}
          headers={headers}
          toggleHeader={toggleHeader}
          filters={filters}
          setFilters={setFilters}
          visibleProperties={visibleProperties}
          hiddenProperties={hiddenProperties}
        />
        <div className="flex-1 min-w-0 bg-main-bg">
          <Searchbar
            term={term}
            setTerm={setTerm}
            runSearch={runSearch}
            live={live}
            setLive={setLive}
            filters={filters}
            setFilters={setFilters}
            visibleProperties={visibleProperties}
            hiddenProperties={hiddenProperties}
            timeRange={timeRange}
            setTimeRange={setTimeRange}
            queryMode={queryMode}
            total={total}
          />
          <LogScrollContainer
            logs={logs}
            newLogs={newLogs}
            term={term}
            queryMode={queryMode}
            previousQueryMode={previousQueryMode}
            atStart={atStart}
            atEnd={atEnd}
            headers={headers}
            setHeaders={setHeaders}
            toggleHeader={toggleHeader}
            filters={filters}
            setFilters={setFilters}
            logContainerRef={logContainerRef}
          />
          <LogsOnboardingDisplay />
        </div>
        <LogDetailsPanel logs={logs} onViewContext={viewContext} />
      </div>
    </div>
  )
}

export default LogDisplay
