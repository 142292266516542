import { ChartLine, Logs, Siren } from 'lucide-react'
import AnimationFadeSlideIn from '../../library/AnimationFadeSlideIn/AnimationFadeSlideIn'
import useNav, { LinkKey } from '../../../hooks/nav/useNav'

const Start = () => {
  const navigate = useNav()

  return (
    <div className="h-screen w-screen flex flex-col gap-6 items-center justify-center bg-main-bg">
      <AnimationFadeSlideIn renderDelay={0.2} delay={0}>
        <p className="text-title1-medium text-text-1">
          Where do you want to start?
        </p>
      </AnimationFadeSlideIn>
      <div className="flex items-center justify-center gap-4">
        <AnimationFadeSlideIn renderDelay={0.2} delay={0.4}>
          <div
            className="w-[232px] p-6 flex flex-col gap-3 border border-brand-infoTint bg-brand-infoTint rounded cursor-pointer hover:bg-brand-infoTint2 transition-all duration-200"
            onClick={() => navigate(LinkKey.SetupLogs)}
          >
            <Logs className="text-brand-info" size={24} />
            <div className="flex flex-col gap-1.5">
              <p className="text-normal-medium text-brand-info">
                Log Management
              </p>
              <p className="text-small-light text-brand-info">
                Store and search logs to debug faster.
              </p>
            </div>
          </div>
        </AnimationFadeSlideIn>
        <AnimationFadeSlideIn renderDelay={0.2} delay={0.6}>
          <div
            className="w-[232px] p-6 flex flex-col gap-3 border border-brand-warningTint bg-brand-warningTint rounded cursor-pointer hover:bg-brand-warningTint2 transition-all duration-200"
            onClick={() => navigate(LinkKey.SetupAlerts)}
          >
            <Siren className="text-brand-warning" size={24} />
            <div className="flex flex-col gap-1.5">
              <p className="text-normal-medium text-brand-warning">Alerts</p>
              <p className="text-small-light text-brand-warning">
                Create alerts to notify you when something goes wrong.
              </p>
            </div>
          </div>
        </AnimationFadeSlideIn>
        <AnimationFadeSlideIn renderDelay={0.2} delay={0.8}>
          <div
            className="w-[232px] p-6 flex flex-col gap-3 border border-brand-successTint bg-brand-successTint rounded cursor-pointer hover:bg-brand-successTint2 transition-all duration-200"
            onClick={() => navigate(LinkKey.SetupMetrics)}
          >
            <ChartLine className="text-brand-success" size={24} />
            <div className="flex flex-col gap-1.5">
              <p className="text-normal-medium text-brand-success">Metrics</p>
              <p className="text-small-light text-brand-success">
                Create custom metrics to track application activity.
              </p>
            </div>
          </div>
        </AnimationFadeSlideIn>
      </div>
    </div>
  )
}

export default Start
