import { MetricEntry } from '../backend/types'
import { propertyToDisplay } from './properties'
import { formatTimestamp } from './time'

export function getMetricColumnWidth(
  column: string,
  entries: MetricEntry[],
): number {
  const measureDiv = document.createElement('div')
  measureDiv.style.visibility = 'hidden'
  measureDiv.style.position = 'absolute'
  measureDiv.style.whiteSpace = 'pre'
  measureDiv.style.fontFamily = 'JetBrains Mono'
  measureDiv.style.fontSize = '12px'
  measureDiv.style.fontWeight = '300'
  document.body.appendChild(measureDiv)

  const maxWidth = entries.reduce((max, entry) => {
    let content = ''
    switch (column) {
      case 'timestamp':
        content = formatTimestamp(entry.timestamp)
        break
      case 'name':
        content = entry.name
        break
      case 'value':
        content = entry.value.toString()
        break
      default:
        content = entry.attributes[column]
        break
    }
    measureDiv.textContent = content
    const width = measureDiv.offsetWidth
    return Math.max(max, width)
  }, 0)

  measureDiv.textContent = propertyToDisplay(column)
  const headerTextWidth = measureDiv.offsetWidth

  document.body.removeChild(measureDiv)

  return Math.min(Math.max(maxWidth, headerTextWidth) + 80, 1000)
}
