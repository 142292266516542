import { useEffect } from 'react'
import useQueryLiveTick from '../../../hooks/stateServices/useQueryLiveTick'
import { genKey } from '../../../utils/sub'
import { QueryState } from '../../../library/query/types'
import { useState } from 'react'
import Terminal from '../Terminal/Terminal'
import useQueryManager from '../../../hooks/stateServices/useQueryManager'
import { DEFAULT_QUERY_PARAMETERS } from '../../../library/query/consts'

interface PreviewLogsProps {
  className?: string
}

const PreviewLogs = ({ className }: PreviewLogsProps) => {
  const queryManager = useQueryManager()
  useQueryLiveTick(queryManager, true)

  const [logState, setLogState] = useState<QueryState | undefined>(undefined)

  useEffect(() => {
    if (!queryManager) return
    const key = genKey()
    queryManager.subscribe(key, setLogState)
    queryManager.init(DEFAULT_QUERY_PARAMETERS)
    return () => {
      queryManager.unsubscribe(key)
    }
  }, [queryManager])

  return (
    <div
      className={`w-full flex flex-col items-start justify-start gap-2 ${className}`}
    >
      <h2 className="text-h2 text-text-1">Preview Logs</h2>
      <Terminal
        className="w-full h-[220px]"
        content={logState?.result?.logs.map((log) => log.body) ?? []}
      />
    </div>
  )
}

export default PreviewLogs
