import {
  Clock,
  Copy,
  Ellipsis,
  Group,
  Hash,
  Sigma,
  Tag,
  Trash,
  Type,
  X,
} from 'lucide-react'
import usePopupPosition from '../../../../hooks/utils/usePopupPosition'
import Portal from '../../Portal/Portal'
import IconButton from '../../IconButton/IconButton'
import OutlineIconTextButtonSmall from '../../OutlineIconTextButtonSmall/OutlineIconTextButtonSmall'
import PopupDropdown from '../../PopupDropdown/PopupDropdown'
import useInitMetricsNames from '../../../../hooks/data/effects/useInitMetricsNames'
import { metricAttributesAtom, metricNamesAtom } from '../../../../state/state'
import { useAtomValue } from 'jotai'
import usePopupPauseScroll from '../../../../hooks/utils/usePopupPauseScroll'
import usePopupStack from '../../../../hooks/utils/usePopupStack'
import { useEffect, useState } from 'react'
import { useRef } from 'react'
import useLoadMetricAttributes from '../../../../hooks/data/load/useLoadMetricAttributes'
import PopupInput from '../../PopupInput/PopupInput'
import {
  AnalyticsAggregationFunction,
  AnalyticsUpdateRequest,
  AnalyticsSettingsGraph,
  AnalyticsBucketSize,
} from '../../../../backend/types'

interface EditButtonProps {
  settings: AnalyticsSettingsGraph

  onUpdate: (settings: AnalyticsUpdateRequest) => void

  onDuplicate: () => void
  onDelete: () => void
}

const EditButton = ({
  settings,
  onUpdate,
  onDuplicate,
  onDelete,
}: EditButtonProps) => {
  useInitMetricsNames()

  const loadMetricAttributes = useLoadMetricAttributes()

  const metricNames = useAtomValue(metricNamesAtom)
  const metricAttributes = useAtomValue(metricAttributesAtom)

  const [isOpen, setIsOpen] = useState(false)

  const buttonRef = useRef<HTMLButtonElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  const portalRef = useRef<HTMLDivElement>(null)

  usePopupStack(portalRef, () => setIsOpen(false), buttonRef)
  usePopupPauseScroll(portalRef, isOpen)
  const position = usePopupPosition(isOpen, buttonRef, popupRef, 'top-right', {
    x: 8,
  })

  const handleDelete = () => {
    onDelete()
    setIsOpen(false)
  }

  const handleUpdate = (update: Partial<AnalyticsSettingsGraph>) => {
    onUpdate({ type: 'graph', settings: { ...settings, ...update } })
  }

  const handleDuplicate = () => {
    onDuplicate()
    setIsOpen(false)
  }

  const handleMetricChange = (metric_name: string) => {
    handleUpdate({ metric_name })
  }

  const handleAggregationFunctionChange = (
    aggregation_function: AnalyticsAggregationFunction,
  ) => {
    handleUpdate({ aggregation_function })
  }

  const handleGroupByChange = (group_by_attribute: string | undefined) => {
    handleUpdate({ group_by_attribute })
  }

  const handleBucketSizeChange = (
    bucket_size: AnalyticsBucketSize | undefined,
  ) => {
    handleUpdate({ bucket_size })
  }

  const handleNameChange = (name: string) => {
    handleUpdate({ name })
  }

  const handleUnitChange = (unit: string) => {
    handleUpdate({ unit })
  }

  useEffect(() => {
    loadMetricAttributes(settings.metric_name)
  }, [loadMetricAttributes, settings.metric_name])

  return (
    <>
      <IconButton
        buttonRef={buttonRef}
        size="32"
        icon={<Ellipsis size={16} />}
        active={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <Portal ref={portalRef}>
          <div
            ref={popupRef}
            className="absolute w-[280px] bg-popup-bg border border-popup-border rounded shadow-popup-shadow"
            style={{ ...position }}
          >
            <div className="w-full p-2 border-b border-popup-border">
              <div className="w-full pl-2 flex items-center justify-between">
                <p className="text-small-medium text-text-1">Edit Graph</p>
                <IconButton
                  size="28"
                  icon={<X size={16} />}
                  onClick={() => setIsOpen(false)}
                />
              </div>
            </div>
            <div className="w-full p-2 py-[10px] flex flex-col gap-2 items-start border-b border-popup-border">
              <div className="px-2 flex items-center gap-2">
                <p className="text-mini-medium text-text-2">Data</p>
              </div>
              <PopupDropdown
                icon={<Hash size={16} />}
                title="Metric"
                options={metricNames.map((name) => ({
                  label: name,
                  value: name,
                }))}
                selected={settings.metric_name}
                onSelect={handleMetricChange}
                scrollable={true}
              />
              <PopupDropdown
                icon={<Sigma size={16} />}
                title="Function"
                options={aggregationFunctionOptions}
                selected={settings.aggregation_function}
                onSelect={handleAggregationFunctionChange}
              />
              <PopupDropdown
                icon={<Clock size={16} />}
                title="Bucketing"
                placeholder="Default"
                options={bucketSizeOptions}
                selected={settings.bucket_size}
                onSelect={handleBucketSizeChange}
              />
              <PopupDropdown
                icon={<Group size={16} />}
                title="Group By"
                placeholder="None"
                options={getGroupByOptions(
                  metricAttributes[settings.metric_name] ?? [],
                )}
                selected={settings.group_by_attribute}
                onSelect={handleGroupByChange}
              />
            </div>
            <div className="w-full p-2 py-[10px] flex flex-col gap-2 items-start border-b border-popup-border">
              <div className="px-2 flex items-center gap-2">
                <p className="text-mini-medium text-text-2">Display</p>
              </div>
              <PopupInput
                icon={<Type size={16} />}
                title="Title"
                emptyValue="None"
                placeholder="Total Users"
                value={settings.name ?? ''}
                onUpdateValue={handleNameChange}
              />
              <PopupInput
                icon={<Tag size={16} />}
                title="Units"
                emptyValue="None"
                placeholder="ms"
                value={settings.unit ?? ''}
                onUpdateValue={handleUnitChange}
              />
            </div>
            <div className="w-full p-2 py-[10px] flex flex-col gap-2">
              <OutlineIconTextButtonSmall onClick={handleDuplicate}>
                <Copy size={16} />
                Duplicate
              </OutlineIconTextButtonSmall>
              <OutlineIconTextButtonSmall onClick={handleDelete}>
                <Trash size={16} />
                Delete
              </OutlineIconTextButtonSmall>
            </div>
          </div>
        </Portal>
      )}
    </>
  )
}

const aggregationFunctionOptions: {
  label: string
  value: AnalyticsAggregationFunction
}[] = [
  {
    label: 'Sum',
    value: 'sum',
  },
  {
    label: 'Cumulative Sum',
    value: 'cumulative',
  },
  {
    label: 'Avg',
    value: 'avg',
  },
  {
    label: 'Min',
    value: 'min',
  },
  {
    label: 'Max',
    value: 'max',
  },
]

const bucketSizeOptions: {
  label: string
  value: AnalyticsBucketSize | undefined
}[] = [
  { label: 'Every minute', value: '1m' },
  { label: 'Every 5 minutes', value: '5m' },
  { label: 'Every 15 minutes', value: '15m' },
  { label: 'Every hour', value: '1h' },
  { label: 'Every 4 hours', value: '4h' },
  { label: 'Every day', value: '1d' },
  { label: 'Default', value: undefined },
]

function getGroupByOptions(metricAttributes: string[]): {
  label: string
  value: string | undefined
}[] {
  return [
    ...metricAttributes.map((attribute) => ({
      label: attribute,
      value: attribute,
    })),
    { label: 'None', value: undefined },
  ]
}

export default EditButton
