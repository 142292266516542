import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import PreviewLogs from '../../../../library/PreviewLogs/PreviewLogs'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const SetupLogsNodeJS = () => {
  useSetBrandColor('info')

  return (
    <SetupTemplate
      title="Log Setup - NodeJS"
      keyName="nodejs-api-key"
      installStep={installSnippets}
      sendTitle="Send a log"
      sendEventStep={getSendMessageSnippets}
      preview={<PreviewLogs />}
      done={LinkKey.Logs}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'npm',
    language: 'bash',
    displayLanguage: 'bash',
    content: `npm install vigilant-js`,
  },
  {
    title: 'yarn',
    language: 'bash',
    displayLanguage: 'bash',
    content: `yarn add vigilant-js`,
  },
  {
    title: 'bun',
    language: 'bash',
    displayLanguage: 'bash',
    content: `bun install vigilant-js`,
  },
]

function getSendMessageSnippets(key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'NodeJS',
      language: 'javascript',
      displayLanguage: 'javascript',
      content: `import { Logger } from "vigilant-js";

const logger = new Logger({
  name: "my-service",
  token: "${key}",
});

// Enable autocapture to read logs from stdout and stderr
logger.autocapture_enable();

console.log("User logged in");
console.error("Database connection failed");

// Call shutdown() before your app terminates
await logger.shutdown();`,
    },
  ]
}

export default SetupLogsNodeJS
