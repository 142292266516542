import { useSetAtom, useAtomValue } from 'jotai'
import { instanceIdAtom, integrationsAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'
import { captureAxiosError } from '../../../utils/obeservability'

const useLoadIntegrations = () => {
  const backend = useBackend()
  const { projectId } = useAtomValue(instanceIdAtom)
  const setIntegrations = useSetAtom(integrationsAtom)

  const loadIntegrations = useCallback(async () => {
    if (!projectId) return
    try {
      const { integrations } = await backend.getIntegrations()
      setIntegrations(integrations)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, setIntegrations, projectId])

  return loadIntegrations
}

export default useLoadIntegrations
