import { cn } from '../../../utils/cn'
import CopyButton from '../CopyButton/CopyButton'

interface APIKeyInputProps {
  apiKey: string
  valid?: boolean
  onGenerate: () => Promise<void>
}

const APIKeyInput = ({
  apiKey,
  valid = true,
  onGenerate,
}: APIKeyInputProps) => {
  const hasApiKey = apiKey !== ''

  return (
    <div
      className={cn(
        'w-full h-16 px-6 flex items-center justify-between border border-input-border rounded',
        !hasApiKey
          ? 'bg-input-bgTint hover:bg-input-tint cursor-pointer'
          : 'bg-input-bgTint',
      )}
      onClick={async () => {
        if (hasApiKey) return
        await onGenerate()
      }}
    >
      {hasApiKey ? (
        <>
          <p className="text-small-code text-text-1">{apiKey}</p>
          {valid && <CopyButton content={apiKey} />}
        </>
      ) : (
        <p className="text-small-code text-text-1">
          Click to generate an API Key!
        </p>
      )}
    </div>
  )
}

export default APIKeyInput
