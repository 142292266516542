import { useAtomValue } from 'jotai'
import useBackend from '../services/useBackend'
import { useCallback } from 'react'
import { instanceIdAtom } from '../../state/state'

const useCreateBillingPortalSession = () => {
  const backend = useBackend()
  const instanceId = useAtomValue(instanceIdAtom)

  const createBillingPortalSession = useCallback(async () => {
    if (!instanceId.organizationId) return
    const response = await backend.createBillingPortalSession(
      instanceId.organizationId,
    )
    return response.url
  }, [backend, instanceId])

  return createBillingPortalSession
}

export default useCreateBillingPortalSession
