import { useEffect } from 'react'
import useLoadMetricsDetails from '../load/useLoadMetricsDetails'

const useInitMetricsDetails = () => {
  const loadMetricsDetails = useLoadMetricsDetails()

  useEffect(() => {
    loadMetricsDetails()
  }, [loadMetricsDetails])
}

export default useInitMetricsDetails
