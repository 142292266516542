import { FREE_TIER_MATRIX } from '../backend/consts'
import { PRICING_MATRIX } from '../backend/consts'
import { UsageType } from '../hooks/data/load/useLoadUsageSeries'
import { Organization } from '../state/types'
import { formatEvents, formatVolume } from './properties'

export function getUsageTier(type: UsageType, organizationInfo: Organization) {
  switch (type) {
    case 'logs':
      return PRICING_MATRIX.logs[organizationInfo.logs_ttl]
    case 'metrics':
      return PRICING_MATRIX.metrics[organizationInfo.metrics_ttl]
  }
}

export function getFreeTier(type: UsageType) {
  switch (type) {
    case 'logs':
      return FREE_TIER_MATRIX.logs
    case 'metrics':
      return FREE_TIER_MATRIX.metrics
  }
}

export function formatCurrency(value: number) {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

export function formatUsage(value: number, type: UsageType) {
  switch (type) {
    case 'logs':
      return formatVolume(value)
    case 'metrics':
      return formatEvents(value)
  }
}

export function formatBillingDate(date: string) {
  const newDate = new Date(date)
  newDate.setMonth(newDate.getMonth() + 1)
  return newDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
  })
}

export function computeCost(
  value: number,
  type: UsageType,
  organizationInfo: Organization,
) {
  const freeTier = getFreeTier(type)
  const usageTier = getUsageTier(type, organizationInfo)
  if (freeTier > value) return 0
  return (value - freeTier) * usageTier
}

export function computeProjectedValue(
  value: number,
  organizationInfo: Organization,
) {
  const billingDate = organizationInfo.billing_date
  if (!billingDate) return value

  const startDate = new Date(billingDate)
  const endDate = new Date(startDate)
  endDate.setMonth(endDate.getMonth() + 1)
  endDate.setDate(endDate.getDate() - 1)
  endDate.setHours(23, 59, 59, 999)

  const currentDate = new Date()
  const totalMillis = endDate.getTime() - startDate.getTime()
  const millisElapsed = currentDate.getTime() - startDate.getTime()
  if (millisElapsed <= 0 || millisElapsed >= totalMillis) {
    return value
  }

  const percentElapsed = millisElapsed / totalMillis
  const projectedValue = value / percentElapsed
  return projectedValue
}

export function getPercent(value: number, max: number): string {
  return `${((value / max) * 100).toFixed(2)}%`
}
