import { ChevronDown } from 'lucide-react'
import { useRef, useState } from 'react'
import useClickOutside from '../../../hooks/utils/useClickOutside'
import Button from '../Button/Button'

type Option<T> = { label: string; value: T }

interface DropdownProps<T> {
  selected: T | undefined
  options: Option<T>[]

  placeholder?: string
  onChange: (value: T) => void

  disabled?: boolean
}

const Dropdown = <T extends string | number>({
  selected,
  options,
  placeholder,
  onChange,
  disabled,
}: DropdownProps<T>) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  useClickOutside(dropdownRef, () => setOpen(false))

  const [open, setOpen] = useState(false)
  const selectedOption = options.find((option) => option.value === selected)

  const handleDropdownClick = () => {
    if (options.length === 0 || disabled) return
    setOpen(!open)
  }

  const handleOptionClick = (value: T) => {
    if (disabled) return
    onChange(value)
    setOpen(false)
  }

  const hoverClass =
    options.length > 0 && !disabled ? 'hover:bg-input-tint' : ''

  const cursorClass = disabled ? 'cursor-default' : 'cursor-pointer'

  return (
    <div className="relative w-[256px] flex flex-col gap-2">
      <button
        className={`px-3 py-2 w-full flex items-center justify-between gap-1 border border-input-border bg-input-bgTint rounded-[4px]  ${hoverClass} ${cursorClass}`}
        onClick={handleDropdownClick}
      >
        {selectedOption ? (
          <p className="text-input text-text-1">{selectedOption.label}</p>
        ) : (
          <p className="text-input text-text-2">{placeholder}</p>
        )}
        <ChevronDown className="w-4 h-4 text-text-1" />
      </button>
      {open && (
        <div
          ref={dropdownRef}
          className="absolute top-0 left-0 min-w-full border border-popup-border bg-popup-bg rounded-[4px] z-[3]"
        >
          <div className="p-2 flex flex-col gap-2">
            {options.map((option) => (
              <div className="w-full flex flex-col" key={option.value}>
                <Button onClick={() => handleOptionClick(option.value)}>
                  {option.label}
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Dropdown
