import { useRef, useState } from 'react'
import { MetricSeries } from '../../../../backend/types'
import { formatNumber } from '../../../../utils/properties'
import Legend from './Legend/Legend'
import useSVGDimensions from '../../../../hooks/utils/useDimensions'

interface GraphDisplayProps {
  data: MetricSeries[]
  units?: string

  disabled?: boolean
}

const yLabelTopOffset = 32
const yLabelBottomOffset = 48
const yLabelXOffset = 40
const yLineLeftGap = 16
const yLineRightGap = 32

const xLabelYOffset = 24
const xLabelLeftOffset = 56
const xLabelRightOffset = 32
const xLineTopOffset = 32
const xLineBottomOffset = 24

const hitboxOffset = 0

const GraphDisplay = ({ data, units, disabled }: GraphDisplayProps) => {
  const svgRef = useRef<SVGSVGElement>(null)
  const [hoverIndex, setHoverIndex] = useState<number | null>(null)
  const [legendLeft, setLegendLeft] = useState<number | null>(null)
  const dimensions = useSVGDimensions(svgRef)

  const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name))
  if (sortedData.length === 0 || hasNoValidData(sortedData)) {
    return (
      <div className="w-full h-[324px] flex flex-col items-center justify-center">
        <p className="text-normal-light text-text-2">No Data to Display</p>
      </div>
    )
  }

  const maxValue = getAdjustedMaxValue(sortedData)
  const yAxisLabels = getYAxisLabels(maxValue)
  const maxEntries = getMaxEntries(sortedData)
  const xAxisLabels = generateXAxisLabelsFromData(sortedData)

  const handleHover = (
    index: number | null,
    e: React.MouseEvent<SVGGElement>,
  ) => {
    if (disabled || !svgRef.current) return
    setHoverIndex(index)

    const target = e.currentTarget as SVGGElement
    if (!target) return

    const rect = target.getBoundingClientRect()
    const svgRect = svgRef.current.getBoundingClientRect()
    const relativeX = rect.left - svgRect.left

    const halfLength = Math.floor(maxEntries / 2)
    if (index !== null && index < halfLength) {
      setLegendLeft(relativeX + rect.width / 2 + 32)
    } else {
      setLegendLeft(relativeX - 272 + rect.width / 2)
    }
  }

  const handleMouseLeave = () => {
    if (disabled) return
    setHoverIndex(null)
    setLegendLeft(null)
  }

  return (
    <div
      className="relative w-full h-[324px] flex flex-col items-start justify-start"
      onMouseLeave={handleMouseLeave}
    >
      <svg ref={svgRef} className="w-full h-full">
        {yAxisLabels.map((value, index) => (
          <text
            key={`y-axis-${index}`}
            x={yLabelXOffset}
            y={
              yLabelTopOffset +
              (index *
                (dimensions.height - yLabelTopOffset - yLabelBottomOffset)) /
                (yAxisLabels.length - 1)
            }
            dominantBaseline="middle"
            textAnchor="end"
            fill="#A6A6A6"
            fontSize="12"
            fontFamily="sans-serif"
          >
            {formatNumber(value)}
          </text>
        ))}
        {yAxisLabels.map((_, index) => (
          <line
            key={`y-axis-line-${index}`}
            x1={yLabelXOffset + yLineLeftGap}
            y1={
              yLabelTopOffset +
              (index *
                (dimensions.height - yLabelTopOffset - yLabelBottomOffset)) /
                (yAxisLabels.length - 1)
            }
            x2={dimensions.width - yLineRightGap}
            y2={
              yLabelTopOffset +
              (index *
                (dimensions.height - yLabelTopOffset - yLabelBottomOffset)) /
                (yAxisLabels.length - 1)
            }
            stroke="#1F1F1F"
            strokeWidth="1"
            strokeLinecap="square"
            strokeLinejoin="miter"
          />
        ))}
        {xAxisLabels.map((value, index) => (
          <text
            key={`x-axis-${index}`}
            x={
              xLabelLeftOffset +
              index *
                ((dimensions.width - xLabelLeftOffset - xLabelRightOffset) /
                  (xAxisLabels.length - 1))
            }
            y={dimensions.height - xLabelYOffset}
            dominantBaseline="middle"
            textAnchor="middle"
            fill="#A6A6A6"
            fontSize="12"
            fontFamily="sans-serif"
          >
            {(xAxisLabels.length % 2 === 0 &&
              index % Math.ceil(xAxisLabels.length / 8) === 0) ||
            (xAxisLabels.length % 2 !== 0 &&
              index % Math.ceil(xAxisLabels.length / 8) === 0)
              ? value
              : ''}
          </text>
        ))}
        {xAxisLabels.map((_, index) => {
          return index !== 0 &&
            index !== xAxisLabels.length - 1 &&
            ((xAxisLabels.length % 2 === 0 &&
              index % Math.ceil(xAxisLabels.length / 8) === 0) ||
              (xAxisLabels.length % 2 !== 0 &&
                index % Math.ceil(xAxisLabels.length / 8) === 0)) ? (
            <line
              key={`x-axis-line-${index}`}
              x1={
                xLabelLeftOffset +
                index *
                  ((dimensions.width - xLabelLeftOffset - xLabelRightOffset) /
                    (xAxisLabels.length - 1))
              }
              y1={xLineTopOffset}
              x2={
                xLabelLeftOffset +
                index *
                  ((dimensions.width - xLabelLeftOffset - xLabelRightOffset) /
                    (xAxisLabels.length - 1))
              }
              y2={dimensions.height - xLabelYOffset - xLineBottomOffset}
              stroke="#1F1F1F"
              strokeWidth="1"
              strokeLinecap="square"
              strokeLinejoin="miter"
            />
          ) : null
        })}
        {Array.from({ length: maxEntries }, (_, index) => {
          const height =
            dimensions.height - yLabelTopOffset - yLabelBottomOffset
          const width =
            (dimensions.width -
              yLabelXOffset -
              yLineRightGap -
              yLineLeftGap +
              hitboxOffset) /
            (maxEntries - 1)
          const x = yLabelXOffset + yLineLeftGap + index * width - width / 2
          const y = yLabelTopOffset
          return (
            <g key={`rect-group-${index}`}>
              <rect
                onMouseEnter={(e) => handleHover(index, e)}
                x={x}
                y={0}
                width={width}
                height={dimensions.height}
                fill="transparent"
              />
              {hoverIndex === index && (
                <line
                  x1={x + width / 2}
                  y1={y}
                  x2={x + width / 2}
                  y2={y + height}
                  stroke="#A6A6A6"
                  strokeWidth="0.5"
                />
              )}
            </g>
          )
        })}
        {sortedData.map((series, index) =>
          generateLinePath(series, index, maxValue, dimensions),
        )}
        {sortedData.map((series, index) =>
          generateLineDots(
            series,
            index,
            maxValue,
            dimensions,
            xLabelLeftOffset,
            yLabelTopOffset,
            yLabelBottomOffset,
            hoverIndex,
          ),
        )}
      </svg>
      <Legend
        hoverIndex={hoverIndex}
        left={legendLeft}
        data={sortedData}
        units={units}
      />
    </div>
  )
}

function getMaxEntries(data: MetricSeries[]): number {
  return data.length > 0
    ? Math.max(...data.map((series) => series.entries.length))
    : 0
}

function getAdjustedMaxValue(data: MetricSeries[]): number {
  const maxValue = Math.max(
    ...data.map((series) =>
      Math.max(...series.entries.map((entry) => entry.value ?? 0)),
    ),
  )

  const magnitude = Math.pow(10, Math.floor(Math.log10(maxValue || 1)))
  const normalizedValue = maxValue / magnitude
  if (normalizedValue <= 2) return 2 * magnitude
  if (normalizedValue <= 4) return 4 * magnitude
  if (normalizedValue <= 6) return 6 * magnitude
  if (normalizedValue <= 8) return 8 * magnitude
  return 10 * magnitude
}

function getYAxisLabels(maxValue: number): number[] {
  const step = maxValue / 4
  return Array.from({ length: 5 }, (_, i) => maxValue - step * i)
}

function formatTime(timeDiff: number, date: Date): string {
  if (timeDiff <= 24 * 60 * 60 * 1000) {
    return date.toLocaleTimeString('en-US', {
      minute: 'numeric',
      hour: 'numeric',
      hour12: true,
    })
  } else {
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
    })
  }
}

function generateXAxisValues(
  start: Date,
  end: Date,
  data: MetricSeries[],
): string[] {
  const timeDiff = end.getTime() - start.getTime()
  const xAxisLabels = getAllXAxisLabels(data)
  return xAxisLabels.map((label) => formatTime(timeDiff, new Date(label)))
}

function getTimeRangeFromData(
  data: MetricSeries[],
): { min: Date; max: Date } | null {
  if (!data.length || !data[0].entries.length) return null

  let min = new Date(data[0].entries[0].timestamp)
  let max = new Date(data[0].entries[0].timestamp)

  data.forEach((series) => {
    series.entries.forEach((entry) => {
      const date = new Date(entry.timestamp)
      if (date < min) min = date
      if (date > max) max = date
    })
  })

  return { min, max }
}

function generateXAxisLabelsFromData(data: MetricSeries[]): string[] {
  const timeRange = getTimeRangeFromData(data)
  if (!timeRange) return []

  const { min, max } = timeRange
  return generateXAxisValues(min, max, data)
}

function getAllXAxisLabels(data: MetricSeries[]): string[] {
  const set = new Set<string>()
  data.forEach((series) => {
    series.entries.forEach((entry) => {
      set.add(entry.timestamp)
    })
  })
  return Array.from(set).sort()
}

function generateLinePath(
  series: MetricSeries,
  index: number,
  maxValue: number,
  dimensions: { width: number; height: number },
) {
  if (series.entries.length === 0) return null

  const pathData = series.entries
    .map((entry, i) => {
      const x =
        xLabelLeftOffset +
        i *
          ((dimensions.width - xLabelLeftOffset - xLabelRightOffset) / // xLabelRightOffset
            (series.entries.length - 1 || 1))
      const y =
        yLabelTopOffset +
        (dimensions.height - yLabelTopOffset - yLabelBottomOffset) *
          (1 - (entry.value ?? 0) / maxValue)

      if (
        i > 0 &&
        y ===
          yLabelTopOffset +
            (dimensions.height - yLabelTopOffset - yLabelBottomOffset) *
              (1 - (series.entries[i - 1].value ?? 0) / maxValue)
      ) {
        return `L ${x} ${y + 0.001}`
      }

      return `${i === 0 ? 'M' : 'L'} ${x} ${y}`
    })
    .join(' ')

  return (
    <path
      key={`line-${index}`}
      d={pathData}
      fill="none"
      stroke={getSeriesColor(index)}
      strokeWidth={1}
      strokeLinecap={'round'}
      strokeLinejoin={'round'}
      vectorEffect={'non-scaling-stroke'}
    />
  )
}

function generateLineDots(
  series: MetricSeries,
  seriesIndex: number,
  maxValue: number,
  dimensions: { width: number; height: number },
  xOffset: number,
  yOffset: number,
  yBottomOffset: number,
  hoverIndex: number | null,
) {
  if (hoverIndex !== null && series.entries.length > hoverIndex) {
    const entry = series.entries[hoverIndex]
    const x =
      xOffset +
      hoverIndex *
        ((dimensions.width - xOffset - 32) / // xLabelRightOffset
          (series.entries.length - 1 || 1))
    const y =
      yOffset +
      (dimensions.height - yOffset - yBottomOffset) *
        (1 - (entry.value ?? 0) / maxValue)

    return (
      <g key={`dot-${seriesIndex}`}>
        <ellipse
          cx={x}
          cy={y}
          rx="2"
          ry="2"
          fill={getSeriesColor(seriesIndex)}
        />
      </g>
    )
  }
  return null
}

function getSeriesColor(index: number): string {
  const colors = [
    '#80EAFF',
    '#FFD980',
    '#81FF80',
    '#FF8480',
    '#8480FF',
    '#D980FF',
    '#80ACFF',
    '#F4FF80',
  ]
  return colors[index % colors.length]
}

function hasNoValidData(data: MetricSeries[]): boolean {
  return (
    data.length === 1 &&
    data[0].entries.length === 1 &&
    data[0].entries[0].value === null
  )
}

export default GraphDisplay
