import { useState, useEffect, RefObject } from 'react'

export const useHovered = (ref: RefObject<HTMLElement | null>) => {
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    const element = ref.current
    if (!element) return

    const handleMouseEnter = () => setIsHovered(true)
    const handleMouseLeave = () => setIsHovered(false)

    const checkIfHovered = (e?: MouseEvent) => {
      if (!element) return

      if (e) {
        lastMouseX = e.clientX
        lastMouseY = e.clientY
      }

      const { left, top, right, bottom } = element.getBoundingClientRect()
      const isMouseInsideBounds =
        lastMouseX >= left &&
        lastMouseX <= right &&
        lastMouseY >= top &&
        lastMouseY <= bottom

      if (!isMouseInsideBounds) {
        setIsHovered(false)
        return
      }

      const topmostElement = document.elementFromPoint(lastMouseX, lastMouseY)

      const isElementTopmost =
        element === topmostElement || element.contains(topmostElement)

      setIsHovered(isElementTopmost)
    }

    let lastMouseX = 0
    let lastMouseY = 0

    const handleMouseMove = (e: MouseEvent) => checkIfHovered(e)

    const layoutObserver = new MutationObserver(() => {
      checkIfHovered()
    })

    const checkOnNextFrame = () => {
      checkIfHovered()
      requestAnimationFrame(checkOnNextFrame)
    }

    element.addEventListener('mouseenter', handleMouseEnter)
    element.addEventListener('mouseleave', handleMouseLeave)
    window.addEventListener('mousemove', handleMouseMove)

    layoutObserver.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    })

    requestAnimationFrame(checkOnNextFrame)

    return () => {
      element.removeEventListener('mouseenter', handleMouseEnter)
      element.removeEventListener('mouseleave', handleMouseLeave)
      window.removeEventListener('mousemove', handleMouseMove)
      layoutObserver.disconnect()
    }
  }, [ref])

  return isHovered
}

export default useHovered
