import CodeDisplay from '../CodeDisplay/CodeDisplay'

interface EventDetailsRawProps {
  value: string
}

const EventDetailsRaw = ({ value }: EventDetailsRawProps) => {
  return <CodeDisplay language="json" content={value} />
}

export default EventDetailsRaw
