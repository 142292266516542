import { AnalyticsUpdateRequest } from '../../backend/types'
import useLoadAnalytics from '../data/load/useLoadAnalytics'
import useBackend from '../services/useBackend'
import { captureAxiosError } from '../../utils/obeservability'

const useUpdateAnalytics = () => {
  const backend = useBackend()

  const loadAnalytics = useLoadAnalytics()

  const updateAnalytics = async (
    id: string,
    request: AnalyticsUpdateRequest,
  ) => {
    try {
      await backend.updateAnalytics(id, request)
      await loadAnalytics()
    } catch (e) {
      captureAxiosError(e)
    }
  }

  return updateAnalytics
}

export default useUpdateAnalytics
