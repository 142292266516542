import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import PreviewLogs from '../../../../library/PreviewLogs/PreviewLogs'
import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const SetupLogsRustTracing = () => {
  useSetBrandColor('info')

  return (
    <SetupTemplate
      title="Log Setup - Rust Tracing"
      keyName="rust-tracing-api-key"
      installStep={installSnippets}
      sendTitle="Send a log"
      sendEventStep={getSendMessageSnippets}
      preview={<PreviewLogs />}
      done={LinkKey.Logs}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'cargo',
    language: 'bash',
    displayLanguage: 'bash',
    content: `cargo add vigilant`,
  },
]

function getSendMessageSnippets(key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'Rust',
      language: 'rust',
      displayLanguage: 'rust',
      content: `use tracing_subscriber::prelude::*;
use vigilant::TracingAdapterBuilder;

// Create the adapter
let adapter = TracingAdapterBuilder::new()
  .name("rust-app")
  .token("${key}")
  .build();

// Register the adapter
tracing_subscriber::registry().with(adapter.clone()).init();

// Shutdown the adapter before exiting
adapter.shutdown().expect("Failed to shutdown adapter");`,
    },
  ]
}

export default SetupLogsRustTracing
