import { useState } from 'react'
import useNav, { LinkKey } from '../../../hooks/nav/useNav'
import useLoggedOut from '../../../hooks/utils/useLoggedOut'
import useInitAdminData from '../../../hooks/data/effects/useInitAdminData'
import useCreateProject from '../../../hooks/actions/useCreateProject'
import AnimationFadeSlideIn from '../../library/AnimationFadeSlideIn/AnimationFadeSlideIn'
import SolidInput from '../../library/SolidInput/SolidInput'
import BrandButton from '../../library/BrandButton/BrandButton'

const NewProject = () => {
  const loggedOut = useLoggedOut()
  useInitAdminData()

  const navigate = useNav()
  const createProject = useCreateProject()

  const [name, setName] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await createProject(name)
    navigate(LinkKey.SetupLogs)
  }

  if (loggedOut) return <></>

  return (
    <div className="h-screen w-screen flex flex-col gap-6 items-center justify-center bg-main-bg">
      <AnimationFadeSlideIn renderDelay={0.2} delay={0}>
        <p className="text-title1-medium text-text-1">Create a Project</p>
      </AnimationFadeSlideIn>
      <AnimationFadeSlideIn renderDelay={0.2} delay={0.2}>
        <p className="w-[400px] text-normal-light text-text-2 text-center">
          A project is an isolated environment. We recommend using one project
          per environment (ex: prod, dev, local).
        </p>
      </AnimationFadeSlideIn>
      <AnimationFadeSlideIn renderDelay={0.2} delay={0.4}>
        <form
          className="w-[400px] p-6 border border-panel-border bg-panel-bg rounded"
          onSubmit={handleSubmit}
        >
          <div className="w-full flex flex-col items-start justify-start gap-2">
            <p className="text-small-light text-text-2">Project name</p>
            <SolidInput
              value={name}
              onChange={(e) => setName(e)}
              placeholder="prod"
            />
          </div>
        </form>
      </AnimationFadeSlideIn>
      <AnimationFadeSlideIn renderDelay={0.2} delay={0.6}>
        <BrandButton mode="warning" disabled={!name} onClick={handleSubmit}>
          Create Project
        </BrandButton>
      </AnimationFadeSlideIn>
    </div>
  )
}

export default NewProject
