import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import PreviewMetrics from '../../../../library/PreviewMetrics/PreviewMetrics'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const SetupMetricsGo = () => {
  useSetBrandColor('success')

  return (
    <SetupTemplate
      title="Metrics Setup - Go"
      keyName="go-api-key"
      installStep={installSnippets}
      sendTitle="Send a metric"
      sendEventStep={getSendMetricSnippets}
      preview={<PreviewMetrics />}
      done={LinkKey.Metrics}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'Go',
    language: 'go',
    displayLanguage: 'go',
    content: `go get github.com/vigilant-run/vigilant-golang`,
  },
]

function getSendMetricSnippets(key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'Go',
      language: 'go',
      displayLanguage: 'go',
      content: `package main

import (
  "github.com/vigilant-run/vigilant-golang/v2"
)

func main() {
  // Create the metrics handler config
  metricsConfig := vigilant.NewMetricsHandlerConfigBuilder().
    WithName("my-service").
    WithToken("${key}").
    Build()

  // Initialize the metrics handler
  vigilant.InitMetricsHandler(metricsConfig)

  // Call shutdownMetricsHandler() before your app terminates
  defer vigilant.ShutdownMetricsHandler()

  // Create the metric details
  metricName := "http_response_time"
  metricValue := 100.0
  metricAttribute := vigilant.NewAttribute("path", "/api/login")

  // Send a metric
  vigilant.EmitMetric(metricName, metricValue, metricAttribute)
}`,
    },
  ]
}

export default SetupMetricsGo
