interface SpinnerProps {
  mode?: 'info' | 'warning' | 'error' | 'success' | 'default'
}

const Spinner = ({ mode }: SpinnerProps) => {
  const { borderColor, borderColorTint } = getBorderColors(mode)

  return (
    <div className="flex items-center justify-center">
      <div className="relative w-4 h-4">
        <div
          className={`absolute w-full h-full border-2 ${borderColorTint} rounded-full`}
        ></div>
        <div
          className={`absolute w-full h-full border-2 ${borderColor} border-t-transparent border-l-transparent border-b-transparent rounded-full animate-spin`}
        ></div>
      </div>
    </div>
  )
}

function getBorderColors(mode: SpinnerProps['mode']) {
  switch (mode) {
    case 'info':
      return {
        borderColor: 'border-brand-info',
        borderColorTint: 'border-brand-infoTint',
      }
    case 'warning':
      return {
        borderColor: 'border-brand-warning',
        borderColorTint: 'border-brand-warningTint',
      }
    case 'error':
      return {
        borderColor: 'border-brand-error',
        borderColorTint: 'border-brand-errorTint',
      }
    case 'success':
      return {
        borderColor: 'border-brand-success',
        borderColorTint: 'border-brand-successTint',
      }
    default:
      return {
        borderColor: 'border-main-border',
        borderColorTint: 'border-main-borderTint',
      }
  }
}

export default Spinner
