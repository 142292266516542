import { useAtomValue } from 'jotai'
import useCreateToken from '../../../../../../hooks/actions/useCreateToken'
import { tokensAtom } from '../../../../../../state/state'
import useInitTokens from '../../../../../../hooks/data/effects/useInitTokens'
import SettingsSection from '../../../../../library/SettingsSection/SettingsSection'
import SettingsTitle from '../../../../../library/SettingsTitle/SettingsTitle'
import { useState } from 'react'
import useDeleteToken from '../../../../../../hooks/actions/useDeleteToken'
import SettingsTable from '../../../../../library/SettingsTable/SettingsTable'
import SettingsTableColumn from '../../../../../library/SettingsTable/SettingsTableColumn'
import SettingsTableHeader from '../../../../../library/SettingsTable/SettingsTableHeader'
import SettingsTableCell from '../../../../../library/SettingsTable/SettingsTableCell'
import SettingsTableIcon from '../../../../../library/SettingsTable/SettingsTableIcon'
import { TrashIcon } from 'lucide-react'
import NewTokenPopup from './NewTokenPopup/NewTokenPopup'
import DeleteTokenPopup from './DeleteTokenPopup/DeleteTokenPopup'
import CopyTokenPopup from './CopyTokenPopup/CopyTokenPopup'
import { formatTimestamp } from '../../../../../../utils/time'

const ProjectAPIKeySettings = () => {
  useInitTokens()

  const createToken = useCreateToken()
  const deleteToken = useDeleteToken()

  const tokens = useAtomValue(tokensAtom)

  const [isCreatingToken, setIsCreatingToken] = useState(false)
  const [isDisplayingToken, setIsDisplayingToken] = useState(false)
  const [isDeletingToken, setIsDeletingToken] = useState(false)
  const [privateToken, setPrivateToken] = useState('')
  const [tokenToDelete, setTokenToDelete] = useState('')

  const handleCreateToken = async (name: string, description: string) => {
    const response = await createToken(name, description)
    if (!response) return
    setPrivateToken(response.token)
    setIsCreatingToken(false)
    setIsDisplayingToken(true)
  }

  const handleDeleteToken = async () => {
    await deleteToken(tokenToDelete)
    setIsDeletingToken(false)
    setTokenToDelete('')
  }

  return (
    <div className="flex-1 px-12 py-12 flex flex-col items-center gap-6 overflow-y-auto">
      <SettingsSection>
        <SettingsTitle
          title="API Keys"
          description="These are used to identify your servers when sending data to Vigilant. Use them when creating an instance of the Vigilant logger or error handler in the your application."
          button="Create Token"
          onClick={() => setIsCreatingToken(true)}
        />
        <SettingsTable>
          <SettingsTableColumn>
            <SettingsTableHeader content="Name" />
            {tokens?.map((token) => (
              <SettingsTableCell key={token.id} content={token.label} />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn>
            <SettingsTableHeader content="Token" />
            {tokens?.map((token) => (
              <SettingsTableCell key={token.id} content={token.display_token} />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn>
            <SettingsTableHeader content="Description" />
            {tokens?.map((token) => (
              <SettingsTableCell key={token.id} content={token.description} />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn>
            <SettingsTableHeader content="Created Date" />
            {tokens?.map((token) => (
              <SettingsTableCell
                key={token.id}
                content={formatTimestamp(token.created_at)}
              />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn flex={false}>
            <div className="h-[40px] border-b border-panel-border"></div>
            {tokens?.map((token) => (
              <SettingsTableIcon
                key={token.id}
                icon={<TrashIcon size={16} />}
                onClick={() => {
                  setTokenToDelete(token.id)
                  setIsDeletingToken(true)
                }}
              />
            ))}
          </SettingsTableColumn>
        </SettingsTable>
      </SettingsSection>
      {isCreatingToken && (
        <NewTokenPopup
          close={() => setIsCreatingToken(false)}
          onSubmit={handleCreateToken}
        />
      )}
      {isDisplayingToken && (
        <CopyTokenPopup
          token={privateToken}
          close={() => {
            setPrivateToken('')
            setIsDisplayingToken(false)
          }}
        />
      )}
      {isDeletingToken && (
        <DeleteTokenPopup
          name={tokens?.find((t) => t.id === tokenToDelete)?.label ?? ''}
          close={() => {
            setTokenToDelete('')
            setIsDeletingToken(false)
          }}
          onSubmit={handleDeleteToken}
        />
      )}
    </div>
  )
}

export default ProjectAPIKeySettings
