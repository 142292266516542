import { Check } from 'lucide-react'
import { useState } from 'react'
import { cn } from '../../../utils/cn'

interface CheckboxProps {
  checked: boolean
  hidden: boolean
  toggle: () => void

  checkboxRef?: React.RefObject<HTMLDivElement | null>
}

const Checkbox = ({ checked, hidden, toggle, checkboxRef }: CheckboxProps) => {
  const [hover, setHover] = useState(false)

  return hidden ? (
    <div className="w-6 h-full flex-none" ref={checkboxRef} />
  ) : (
    <div
      ref={checkboxRef}
      className="w-6 h-full flex-none flex items-center justify-center cursor-pointer"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={toggle}
    >
      <button
        className={cn(
          'w-4 h-4 flex items-center justify-center border rounded',
          getBorderClass(checked),
          getBgClass(checked, hover),
        )}
      >
        {checked && <Check className="text-text-1" size={8} />}
      </button>
    </div>
  )
}

function getBorderClass(checked: boolean): string {
  if (checked) return 'border-text-1'
  return 'border-input-border'
}

function getBgClass(checked: boolean, hover: boolean): string {
  if (hover || checked) return 'bg-input-tint'
  return 'bg-input-bgTint'
}

export default Checkbox
