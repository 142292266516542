import { instanceIdAtom } from '../../state/state'
import { useAtomValue } from 'jotai'
import useLoadProjects from '../data/load/useLoadProjects'
import useBackend from '../services/useBackend'
import useToast from '../utils/useToast'
import { captureAxiosError } from '../../utils/obeservability'

const useUpdateProjectName = () => {
  const backend = useBackend()
  const toast = useToast()

  const { projectId } = useAtomValue(instanceIdAtom)
  const loadProjects = useLoadProjects()

  const updateProjectName = async (name: string) => {
    if (!projectId) return
    try {
      await backend.updateProject(projectId, name)
      await loadProjects()
      toast('Project name updated', 'success')
    } catch (e) {
      captureAxiosError(e)
      toast('Project name update failed', 'error')
    }
  }

  return updateProjectName
}

export default useUpdateProjectName
