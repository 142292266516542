import PreviewLogs from '../../../../library/PreviewLogs/PreviewLogs'
import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const SetupLogsRubyRails = () => {
  useSetBrandColor('info')

  return (
    <SetupTemplate
      title="Log Setup - Ruby on Rails"
      keyName="ruby-rails-api-key"
      installStep={installSnippets}
      sendTitle="Send a log"
      sendEventStep={getSendMessageSnippets}
      preview={<PreviewLogs />}
      done={LinkKey.Logs}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'bundle',
    language: 'bash',
    displayLanguage: 'bash',
    content: `bundle add vigilant-ruby`,
  },
  {
    title: 'gem',
    language: 'bash',
    displayLanguage: 'bash',
    content: `gem install vigilant-ruby`,
  },
]

function getSendMessageSnippets(key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'Ruby',
      language: 'ruby',
      displayLanguage: 'ruby',
      content: `# In config/application.rb
require 'vigilant-ruby'

module YourApp
  class Application < Rails::Application
    config.logger = Vigilant::Rails::Logger.new(name: 'rails-app', token: '${key}')
    config.log_level = :debug
  end
end

# In your controller
Rails.logger.info('Hello, World!')`,
    },
  ]
}

export default SetupLogsRubyRails
