import { useAtomValue, useSetAtom } from 'jotai'
import { billingInfoAtom, instanceIdAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'
import { captureAxiosError } from '../../../utils/obeservability'

const useLoadBillingInfo = () => {
  const backend = useBackend()
  const setBillingInfo = useSetAtom(billingInfoAtom)
  const instanceId = useAtomValue(instanceIdAtom)

  const loadBillingInfo = useCallback(async () => {
    if (!instanceId.organizationId) return
    try {
      const billingInfo = await backend.getBillingInfo(
        instanceId.organizationId,
      )
      setBillingInfo(billingInfo)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, setBillingInfo, instanceId])

  return loadBillingInfo
}

export default useLoadBillingInfo
