import { useState } from 'react'
import useNav, { LinkKey } from '../../../hooks/nav/useNav'
import useLoggedOut from '../../../hooks/utils/useLoggedOut'
import useInitAdminData from '../../../hooks/data/effects/useInitAdminData'
import useCreateOrganization from '../../../hooks/actions/useCreateOrganization'
import SolidInput from '../../library/SolidInput/SolidInput'
import BrandButton from '../../library/BrandButton/BrandButton'
import AnimationFadeSlideIn from '../../library/AnimationFadeSlideIn/AnimationFadeSlideIn'

const NewOrganization = () => {
  const loggedOut = useLoggedOut()
  useInitAdminData()

  const navigate = useNav()
  const createOrganization = useCreateOrganization()

  const [name, setName] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await createOrganization(name)
    navigate(LinkKey.Invite)
  }

  if (loggedOut) return <></>

  return (
    <div className="h-screen w-screen flex flex-col gap-6 items-center justify-center bg-main-bg">
      <AnimationFadeSlideIn renderDelay={0.2} delay={0}>
        <p className="text-title1-medium text-text-1">Create an Organization</p>
      </AnimationFadeSlideIn>
      <AnimationFadeSlideIn renderDelay={0.2} delay={0.2}>
        <p className="w-[400px] text-normal-light text-text-2 text-center">
          Your organization is your top-level account, for all your coworkers,
          projects, and data.
        </p>
      </AnimationFadeSlideIn>
      <AnimationFadeSlideIn renderDelay={0.2} delay={0.4}>
        <form
          className="w-[400px] p-6 border border-panel-border bg-panel-bg rounded"
          onSubmit={handleSubmit}
        >
          <div className="w-full flex flex-col items-start justify-start gap-2">
            <p className="text-small-light text-text-2">Organization name</p>
            <SolidInput
              value={name}
              onChange={(e) => setName(e)}
              placeholder="Company Name"
            />
          </div>
        </form>
      </AnimationFadeSlideIn>
      <AnimationFadeSlideIn renderDelay={0.2} delay={0.6}>
        <BrandButton mode="warning" disabled={!name} onClick={handleSubmit}>
          Create Organization
        </BrandButton>
      </AnimationFadeSlideIn>
    </div>
  )
}

export default NewOrganization
