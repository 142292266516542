import { AnalyticsCreateRequest } from '../../backend/types'
import useLoadAnalytics from '../data/load/useLoadAnalytics'
import useLoadAnalyticsLayout from '../data/load/useLoadAnalyticsLayout'
import useBackend from '../services/useBackend'
import { captureAxiosError } from '../../utils/obeservability'

const useCreateAnalytics = () => {
  const backend = useBackend()

  const loadAnalytics = useLoadAnalytics()
  const loadAnalyticsLayout = useLoadAnalyticsLayout()

  const createAnalytics = async (request: AnalyticsCreateRequest) => {
    try {
      const response = await backend.createAnalytics(request)
      await Promise.all([loadAnalytics(), loadAnalyticsLayout()])
      return response.id
    } catch (e) {
      captureAxiosError(e)
    }
  }

  return createAnalytics
}

export default useCreateAnalytics
