import { useCallback } from 'react'
import useBackend from '../../services/useBackend'
import { useSetAtom } from 'jotai'
import { analyticsAtom } from '../../../state/state'
import { captureAxiosError } from '../../../utils/obeservability'

const useLoadAnalytics = () => {
  const backend = useBackend()

  const setAnalytics = useSetAtom(analyticsAtom)

  const loadAnalytics = useCallback(async () => {
    try {
      const response = await backend.getAnalytics()
      setAnalytics(response)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, setAnalytics])

  return loadAnalytics
}

export default useLoadAnalytics
