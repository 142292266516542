import { sha256 } from 'js-sha256'
import { AuthService } from './auth'
import { HTTPService } from './http'
import {
  UserAuthResponse,
  GetUserResponse,
  AuthResponse,
  GetOrganizationResponse,
  CreateOrganizationResponse,
  CreateProjectResponse,
  GetProjectResponse,
  GetOrganizationsResponse,
  CreateUserResponse,
  GetOrganizationProjectsResponse,
  AuthRefreshResponse,
  CreateTokenResponse,
  GetTokensResponse,
  GetOrganizationMembersResponse,
  OrganizationMemberJoinResponse,
  CreateOrganizationInviteResponse,
  IntegrationCreateRequest,
  GetIntegrationsResponse,
  GetIntegrationExternalDetailsResponse,
  AggregationPeriod,
  GetUsageSeriesResponse,
  AggregationMode,
  LogCountRequest,
  LogCountResponse,
  LogQueryRequest,
  LogQueryResponse,
  LogAttributesRequest,
  LogAttributesResponse,
  LogColumnsResponse,
  LogColumnsRequest,
  LogAttributeOptionsRequest,
  LogAttributeOptionsResponse,
  LogColumnOptionsRequest,
  LogColumnOptionsResponse,
  LogDetailsResponse,
  MetricsListResponse,
  MetricsListRequest,
  MetricsDetailsRequest,
  MetricsDetails,
  AnalyticsEntry,
  AnalyticsUpdateRequest,
  AnalyticsCreateRequest,
  AnalyticsUpdateLayoutRequest,
  MetricAttributesResponse,
  MetricAttributesRequest,
  MetricSeriesQueryRequest,
  MetricSeriesQueryResponse,
  MetricValueQueryRequest,
  MetricValueQueryResponse,
  UserData,
  LogDetailsRequest,
  GetUsageTotalsResponse,
  OrganizationUpdateRequest,
  GetVersionResponse,
  AnalyticsLayoutEntry,
  AnalyticsCreateResponse,
  BillingInfo,
  CreateBillingPortalSessionResponse,
  GetAlertsResponse,
  AlertUpdateRequest,
  GetAlertEventsResponse,
  GetAlertSettingsResponse,
  UpdateAlertSettingsRequest,
  AlertQueryParams,
} from './types'

export class BackendService {
  private httpService: HTTPService
  private authService: AuthService

  constructor(httpService: HTTPService, authService: AuthService) {
    this.httpService = httpService
    this.authService = authService
  }

  getVersion = async (): Promise<GetVersionResponse> => {
    return this.httpService.request<GetVersionResponse>(
      '/api/version',
      'GET',
      {},
    )
  }

  sendSupport = async (message: string): Promise<void> => {
    return this.httpService.request<void>(
      '/api/user/support',
      'POST',
      { message },
      this.getAuthHeaders(),
    )
  }

  login = async (
    email: string,
    password: string,
  ): Promise<UserAuthResponse> => {
    return this.httpService
      .request<UserAuthResponse>('/api/login', 'POST', {
        email: email,
        password: sha256(password),
      })
      .then((response) => {
        this.authService.setUserToken(response.token)
        return response
      })
  }

  authOrganization = async (organization_id: string): Promise<AuthResponse> => {
    return this.httpService
      .request<AuthResponse>(
        '/api/auth',
        'POST',
        {
          type: 'organization',
          organization_id,
        },
        this.getAuthHeaders(),
      )
      .then((response) => {
        this.authService.setUserToken(response.token)
        return response
      })
  }

  authProject = async (
    organization_id: string,
    project_id: string,
  ): Promise<AuthResponse> => {
    return this.httpService
      .request<AuthResponse>(
        '/api/auth',
        'POST',
        {
          type: 'project',
          organization_id,
          project_id,
        },
        this.getAuthHeaders(),
      )
      .then((response) => {
        this.authService.setUserToken(response.token)
        return response
      })
  }

  refresh = async (): Promise<AuthRefreshResponse> => {
    return this.httpService
      .request<AuthRefreshResponse>(
        '/api/refresh',
        'POST',
        {},
        this.getAuthHeaders(),
      )
      .then((response) => {
        this.authService.setUserToken(response.token)
        return response
      })
  }

  getUser = async (): Promise<GetUserResponse> => {
    return this.httpService.request<GetUserResponse>(
      '/api/user',
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createUser = async (
    email: string,
    password: string,
  ): Promise<CreateUserResponse> => {
    const passwordHash = sha256(password)
    return this.httpService
      .request<CreateUserResponse>(
        '/api/user',
        'POST',
        { email, password: passwordHash },
        this.getAuthHeaders(),
      )
      .then((response) => {
        this.authService.setUserToken(response.token)
        return response
      })
  }

  getUserData = async (id: string): Promise<UserData> => {
    return this.httpService.request<UserData>(
      `/api/user/${id}/data`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  updateUserData = async (id: string, data: UserData): Promise<void> => {
    return this.httpService.request<void>(
      `/api/user/${id}/data`,
      'PUT',
      { data },
      this.getAuthHeaders(),
    )
  }

  getOrganization = async (
    organization_id: string,
  ): Promise<GetOrganizationResponse> => {
    return this.httpService.request<GetOrganizationResponse>(
      `/api/organization/${organization_id}`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createOrganization = async (
    name: string,
  ): Promise<CreateOrganizationResponse> => {
    return this.httpService.request<CreateOrganizationResponse>(
      '/api/organization',
      'POST',
      { name },
      this.getAuthHeaders(),
    )
  }

  getOrganizationMembers = async (
    organization_id: string,
  ): Promise<GetOrganizationMembersResponse> => {
    return this.httpService.request<GetOrganizationMembersResponse>(
      `/api/organization/${organization_id}/members`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  deleteOrganizationMember = async (
    organization_id: string,
    user_id: string,
  ): Promise<void> => {
    return this.httpService.request<void>(
      `/api/organization/${organization_id}/member/${user_id}`,
      'DELETE',
      {},
      this.getAuthHeaders(),
    )
  }

  getOrganizations = async (): Promise<GetOrganizationsResponse> => {
    return this.httpService.request<GetOrganizationsResponse>(
      `/api/organizations`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createOrganizationInvite = async (
    organization_id: string,
    email: string,
  ): Promise<CreateOrganizationInviteResponse> => {
    return this.httpService.request<CreateOrganizationInviteResponse>(
      `/api/organization/${organization_id}/invite`,
      'POST',
      { email },
      this.getAuthHeaders(),
    )
  }

  updateOrganization = async (
    organization_id: string,
    request: OrganizationUpdateRequest,
  ): Promise<void> => {
    return this.httpService.request<void>(
      `/api/organization/${organization_id}`,
      'PUT',
      request,
      this.getAuthHeaders(),
    )
  }

  deleteOrganization = async (organization_id: string): Promise<void> => {
    return this.httpService.request<void>(
      `/api/organization/${organization_id}`,
      'DELETE',
      {},
      this.getAuthHeaders(),
    )
  }

  joinOrganization = async (
    token: string,
  ): Promise<OrganizationMemberJoinResponse> => {
    return this.httpService.request<OrganizationMemberJoinResponse>(
      '/api/organization/join',
      'POST',
      { token },
      this.getAuthHeaders(),
    )
  }

  getBillingInfo = async (
    organization_id: string,
  ): Promise<BillingInfo | null> => {
    return this.httpService.request<BillingInfo>(
      `/api/organization/${organization_id}/billing`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createBillingPortalSession = async (
    organization_id: string,
  ): Promise<CreateBillingPortalSessionResponse> => {
    return this.httpService.request<CreateBillingPortalSessionResponse>(
      `/api/organization/${organization_id}/billing/portal`,
      'POST',
      {},
      this.getAuthHeaders(),
    )
  }

  getLogsUsageSeries = async (
    organization_id: string,
    start_date: string,
    end_date: string,
    aggregation_period: AggregationPeriod,
    aggregation_mode: AggregationMode,
  ): Promise<GetUsageSeriesResponse> => {
    return this.httpService.request<GetUsageSeriesResponse>(
      '/api/usage/series/logs',
      'POST',
      {
        organization_id,
        start_date,
        end_date,
        aggregation_period,
        aggregation_mode,
      },
      this.getAuthHeaders(),
    )
  }

  getMetricsUsageSeries = async (
    organization_id: string,
    start_date: string,
    end_date: string,
    aggregation_period: AggregationPeriod,
    aggregation_mode: AggregationMode,
  ): Promise<GetUsageSeriesResponse> => {
    return this.httpService.request<GetUsageSeriesResponse>(
      '/api/usage/series/metrics',
      'POST',
      {
        organization_id,
        start_date,
        end_date,
        aggregation_period,
        aggregation_mode,
      },
      this.getAuthHeaders(),
    )
  }

  getLogsUsageTotals = async (
    organization_id: string,
    start_date: string,
    end_date: string,
  ): Promise<GetUsageTotalsResponse> => {
    return this.httpService.request<GetUsageTotalsResponse>(
      '/api/usage/totals/logs',
      'POST',
      { organization_id, start_date, end_date },
      this.getAuthHeaders(),
    )
  }

  getMetricsUsageTotals = async (
    organization_id: string,
    start_date: string,
    end_date: string,
  ): Promise<GetUsageTotalsResponse> => {
    return this.httpService.request<GetUsageTotalsResponse>(
      '/api/usage/totals/metrics',
      'POST',
      { organization_id, start_date, end_date },
      this.getAuthHeaders(),
    )
  }

  getProjects = async (
    organization_id: string,
  ): Promise<GetOrganizationProjectsResponse> => {
    return this.httpService.request<GetOrganizationProjectsResponse>(
      `/api/organization/${organization_id}/projects`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  getProject = async (project_id: string): Promise<GetProjectResponse> => {
    return this.httpService.request<GetProjectResponse>(
      `/api/project/${project_id}`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createProject = async (
    organization_id: string,
    name: string,
  ): Promise<CreateProjectResponse> => {
    return this.httpService.request<CreateProjectResponse>(
      '/api/project',
      'POST',
      { organization_id, name },
      this.getAuthHeaders(),
    )
  }

  updateProject = async (project_id: string, name?: string): Promise<void> => {
    return this.httpService.request<void>(
      `/api/project/${project_id}`,
      'PUT',
      { name },
      this.getAuthHeaders(),
    )
  }

  deleteProject = async (project_id: string): Promise<void> => {
    return this.httpService.request<void>(
      `/api/project/${project_id}`,
      'DELETE',
      {},
      this.getAuthHeaders(),
    )
  }

  getProjectTokens = async (project_id: string): Promise<GetTokensResponse> => {
    return this.httpService.request<GetTokensResponse>(
      `/api/project/${project_id}/tokens`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createProjectToken = async (
    project_id: string,
    label: string,
    description: string,
  ): Promise<CreateTokenResponse> => {
    return this.httpService.request<CreateTokenResponse>(
      `/api/project/${project_id}/token`,
      'POST',
      { label, description },
      this.getAuthHeaders(),
    )
  }

  deleteProjectToken = async (
    project_id: string,
    id: string,
  ): Promise<void> => {
    return this.httpService.request<void>(
      `/api/project/${project_id}/token/${id}`,
      'DELETE',
      {},
      this.getAuthHeaders(),
    )
  }

  getIntegrations = async (): Promise<GetIntegrationsResponse> => {
    return this.httpService.request<GetIntegrationsResponse>(
      '/api/integrations',
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createIntegration = async (
    request: IntegrationCreateRequest,
  ): Promise<void> => {
    return this.httpService.request<void>(
      '/api/integration',
      'POST',
      request,
      this.getAuthHeaders(),
    )
  }

  getIntegrationExternalDetails = async (
    integration_id: string,
  ): Promise<GetIntegrationExternalDetailsResponse> => {
    return this.httpService.request<GetIntegrationExternalDetailsResponse>(
      `/api/integration/${integration_id}/external`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  getAlertSettings = async (): Promise<GetAlertSettingsResponse> => {
    return this.httpService.request<GetAlertSettingsResponse>(
      '/api/alerts/settings',
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  updateAlertSettings = async (
    request: UpdateAlertSettingsRequest,
  ): Promise<void> => {
    return this.httpService.request<void>(
      '/api/alerts/settings',
      'PUT',
      request,
      this.getAuthHeaders(),
    )
  }

  getAlerts = async (query: AlertQueryParams): Promise<GetAlertsResponse> => {
    return this.httpService.request<GetAlertsResponse>(
      '/api/alerts',
      'POST',
      query,
      this.getAuthHeaders(),
    )
  }

  updateAlert = async (request: AlertUpdateRequest): Promise<void> => {
    return this.httpService.request<void>(
      `/api/alerts`,
      'PUT',
      request,
      this.getAuthHeaders(),
    )
  }

  getAlertEvents = async (
    alert_id: string,
  ): Promise<GetAlertEventsResponse> => {
    return this.httpService.request<GetAlertEventsResponse>(
      `/api/alert/${alert_id}/events`,
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  logsQuery = async (
    request: LogQueryRequest,
    signal?: AbortSignal,
  ): Promise<LogQueryResponse> => {
    return this.httpService.request<LogQueryResponse>(
      `/api/logs`,
      'POST',
      request,
      this.getAuthHeaders(),
      signal,
    )
  }

  logsCount = async (
    request: LogCountRequest,
    signal?: AbortSignal,
  ): Promise<LogCountResponse> => {
    return this.httpService.request<LogCountResponse>(
      `/api/logs/count`,
      'POST',
      request,
      this.getAuthHeaders(),
      signal,
    )
  }

  logsAttributes = async (
    request: LogAttributesRequest,
    signal?: AbortSignal,
  ): Promise<LogAttributesResponse> => {
    return this.httpService.request<LogAttributesResponse>(
      `/api/logs/attributes`,
      'POST',
      request,
      this.getAuthHeaders(),
      signal,
    )
  }

  logsAttributeOptions = async (
    request: LogAttributeOptionsRequest,
    signal?: AbortSignal,
  ): Promise<LogAttributeOptionsResponse> => {
    return this.httpService.request<LogAttributeOptionsResponse>(
      `/api/logs/attribute/options`,
      'POST',
      request,
      this.getAuthHeaders(),
      signal,
    )
  }

  logsColumns = async (
    request: LogColumnsRequest,
    signal?: AbortSignal,
  ): Promise<LogColumnsResponse> => {
    return this.httpService.request<LogColumnsResponse>(
      `/api/logs/columns`,
      'POST',
      request,
      this.getAuthHeaders(),
      signal,
    )
  }

  logsColumnOptions = async (
    request: LogColumnOptionsRequest,
    signal?: AbortSignal,
  ): Promise<LogColumnOptionsResponse> => {
    return this.httpService.request<LogColumnOptionsResponse>(
      `/api/logs/column/options`,
      'POST',
      request,
      this.getAuthHeaders(),
      signal,
    )
  }

  logsDetails = async (
    request: LogDetailsRequest,
    signal?: AbortSignal,
  ): Promise<LogDetailsResponse> => {
    return this.httpService.request<LogDetailsResponse>(
      `/api/logs/details`,
      'POST',
      request,
      this.getAuthHeaders(),
      signal,
    )
  }

  metricAttributes = async (
    request: MetricAttributesRequest,
  ): Promise<MetricAttributesResponse> => {
    return this.httpService.request<MetricAttributesResponse>(
      '/api/metric/attributes',
      'POST',
      request,
      this.getAuthHeaders(),
    )
  }

  metricsList = async (
    request: MetricsListRequest,
  ): Promise<MetricsListResponse> => {
    return this.httpService.request<MetricsListResponse>(
      '/api/metrics/list',
      'POST',
      request,
      this.getAuthHeaders(),
    )
  }

  metricsDetails = async (
    request: MetricsDetailsRequest,
  ): Promise<MetricsDetails> => {
    return this.httpService.request<MetricsDetails>(
      '/api/metrics/details',
      'POST',
      request,
      this.getAuthHeaders(),
    )
  }

  metricsQuerySeries = async (
    request: MetricSeriesQueryRequest,
  ): Promise<MetricSeriesQueryResponse> => {
    return this.httpService.request<MetricSeriesQueryResponse>(
      '/api/metrics/query/series',
      'POST',
      request,
      this.getAuthHeaders(),
    )
  }

  metricsQueryValue = async (
    request: MetricValueQueryRequest,
  ): Promise<MetricValueQueryResponse> => {
    return this.httpService.request<MetricValueQueryResponse>(
      '/api/metrics/query/counter',
      'POST',
      request,
      this.getAuthHeaders(),
    )
  }

  getAnalytics = async (): Promise<AnalyticsEntry[]> => {
    return this.httpService.request<AnalyticsEntry[]>(
      '/api/analytics',
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  createAnalytics = async (
    request: AnalyticsCreateRequest,
  ): Promise<AnalyticsCreateResponse> => {
    return this.httpService.request<AnalyticsCreateResponse>(
      '/api/analytics',
      'POST',
      request,
      this.getAuthHeaders(),
    )
  }

  updateAnalytics = async (
    id: string,
    request: AnalyticsUpdateRequest,
  ): Promise<void> => {
    return this.httpService.request<void>(
      `/api/analytics/${id}`,
      'PUT',
      request,
      this.getAuthHeaders(),
    )
  }

  deleteAnalytics = async (id: string): Promise<void> => {
    return this.httpService.request<void>(
      `/api/analytics/${id}`,
      'DELETE',
      {},
      this.getAuthHeaders(),
    )
  }

  getAnalyticsLayout = async (): Promise<AnalyticsLayoutEntry> => {
    return this.httpService.request<AnalyticsLayoutEntry>(
      '/api/analytics/layout',
      'GET',
      {},
      this.getAuthHeaders(),
    )
  }

  updateAnalyticsLayout = async (
    request: AnalyticsUpdateLayoutRequest,
  ): Promise<void> => {
    return this.httpService.request<void>(
      '/api/analytics/layout',
      'PUT',
      request,
      this.getAuthHeaders(),
    )
  }

  private getAuthHeaders = (): Record<string, string> => {
    const headers: Record<string, string> = {}

    const token = this.authService.getUserToken()
    if (token !== '') {
      headers.Authorization = `Bearer ${token}`
    }

    const sessionId = this.authService.getUserSessionId()
    if (sessionId !== '') {
      headers['X-Session-Id'] = sessionId
    }

    const userEmail = this.authService.getUserEmail()
    if (userEmail !== '') {
      headers['X-User-Email'] = userEmail
    }

    return headers
  }
}
