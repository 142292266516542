import { useSearchParams } from 'react-router-dom'
import useBackend from '../../../hooks/services/useBackend'
import { useEffect } from 'react'

const AuthSlack: React.FC = () => {
  const backend = useBackend()

  const [searchParams] = useSearchParams()
  const token = searchParams.get('code')

  useEffect(() => {
    const createIntegration = async () => {
      if (!token) return
      await backend.createIntegration({
        type: 'slack',
        settings: { token: token },
      })
      window.close()
    }
    createIntegration()
  }, [token, backend])

  return null
}

export default AuthSlack
