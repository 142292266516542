import { useState } from 'react'
import useCreateToken from '../../../hooks/actions/useCreateToken'
import APIKeyInput from '../APIKeyInput/APIKeyInput'
import BrandButton from '../BrandButton/BrandButton'
import useNav, { LinkKey } from '../../../hooks/nav/useNav'

interface SetupCustomTemplateProps {
  title: string
  keyName: string
  customContent: (apiKeyValue: string) => React.ReactNode
  preview: React.ReactNode
  done: LinkKey
}

const SetupCustomTemplate = ({
  title,
  keyName,
  customContent,
  preview,
  done,
}: SetupCustomTemplateProps) => {
  const navigate = useNav()
  const generateToken = useCreateToken()
  const [apiKeyValue, setApiKeyValue] = useState('')

  const handleGenerateToken = async () => {
    const response = await generateToken(keyName, 'Token created during setup.')
    if (!response) return
    setApiKeyValue(response.token)
  }

  return (
    <div className="w-full h-full flex flex-col items-center justify-start gap-2 overflow-hidden">
      <div className="w-full h-full p-12 max-w-[1200px] flex items-start justify-start gap-12 overflow-y-auto">
        <div className="pt-12 flex-1 flex items-start justify-start gap-8">
          <div className="w-full flex flex-col items-start justify-start gap-8">
            <p className="text-title1-medium text-text-1">{title}</p>
            <div className="w-full flex flex-col items-start justify-start gap-2">
              <p className="text-title3-medium text-text-1">
                Create an API key
              </p>
              <APIKeyInput
                apiKey={apiKeyValue}
                onGenerate={handleGenerateToken}
              />
            </div>
            {customContent(apiKeyValue)}
            <BrandButton onClick={() => navigate(done)}>Done</BrandButton>
          </div>
          <div className="w-[400px] self-stretch flex-none flex flex-col items-start justify-start gap-4">
            <div className="sticky top-[108px] w-full">{preview}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetupCustomTemplate
