import _ from 'lodash'
import { Filter } from '../backend/types'

export const COLUMN_PREFIX = 'column.'
export const COLUMN_BODY = 'column.body'
export const COLUMN_LEVEL = 'column.severity_name'
export const COLUMN_TIMESTAMP = 'column.timestamp'

export const ATTRIBUTE_PREFIX = 'attribute.'
export const ATTRIBUTE_SERVICE = 'attribute.service.name'

export function propertyToDisplay(property: string): string {
  if (property === COLUMN_LEVEL) {
    return 'level'
  } else if (property.startsWith(ATTRIBUTE_PREFIX)) {
    return property.replace(ATTRIBUTE_PREFIX, '')
  } else if (property.startsWith(COLUMN_PREFIX)) {
    return property.replace(COLUMN_PREFIX, '')
  }
  return property
}

export function operatorToDisplay(operator: string): string {
  switch (operator) {
    case 'equals':
      return 'equals'
    case 'not_equals':
      return 'not equals'
    case 'contains':
      return 'contains'
    case 'not_contains':
      return 'not contains'
    case 'exists':
      return 'is defined'
    case 'not_exists':
      return 'is not defined'
    case 'regex':
      return 'matches regex'
    default:
      return operator
  }
}
export function formatProperty(property: string): string {
  if (property.startsWith(ATTRIBUTE_PREFIX)) {
    return property.replace(ATTRIBUTE_PREFIX, '')
  } else if (property.startsWith(COLUMN_PREFIX)) {
    return property.replace(COLUMN_PREFIX, '')
  }
  return property
}

export function formatFilters(filters: Filter[]): Filter[] {
  return filters
    .filter((filter) => {
      switch (filter.operator) {
        case 'exists':
        case 'not_exists':
          return filter.value === null
        default:
          return filter.value !== null
      }
    })
    .map((filter) => {
      return {
        ...filter,
        field: formatProperty(filter.field),
      }
    })
}

export function formatNumber(number: number, decimals: number = 1): string {
  if (number === 0 || isNaN(number)) return '0'

  if (number >= 1_000_000_000) {
    const value = (number / 1_000_000_000).toFixed(decimals)
    return `${value.endsWith('.0'.padEnd(decimals + 1, '0')) ? value.slice(0, -(decimals + 1)) : value}b`
  }

  if (number >= 1_000_000) {
    const value = (number / 1_000_000).toFixed(decimals)
    return `${value.endsWith('.0'.padEnd(decimals + 1, '0')) ? value.slice(0, -(decimals + 1)) : value}m`
  }

  if (number >= 1_000) {
    const value = (number / 1_000).toFixed(decimals)
    return `${value.endsWith('.0'.padEnd(decimals + 1, '0')) ? value.slice(0, -(decimals + 1)) : value}k`
  }

  return number.toFixed(decimals).replace(/\.0+$/, '')
}

export function formatEvents(events: number, decimals: number = 0) {
  if (events === 1) return '1 event'
  return `${formatNumber(events, decimals)} events`
}

export function formatVolume(size: number, decimals: number = 1) {
  if (size >= 1_000_000_000_000) {
    return `${(size / 1_000_000_000_000).toFixed(decimals)} TB`
  }
  if (size >= 1_000_000_000) {
    return `${(size / 1_000_000_000).toFixed(decimals)} GB`
  }
  if (size >= 1_000_000) {
    return `${(size / 1_000_000).toFixed(decimals)} MB`
  }
  if (size >= 1_000) {
    return `${(size / 1_000).toFixed(decimals)} KB`
  }
  return `${size} B`
}

export function formatLevelValue(level: string) {
  if (level === 'INFO') return 'info'
  if (level === 'WARNING') return 'warning'
  if (level === 'ERROR') return 'error'
  if (level === 'DEBUG') return 'debug'
  if (level === 'TRACE') return 'trace'
  return null
}

export function formatValue(header: string, content: string) {
  switch (header) {
    case COLUMN_LEVEL:
      return formatLevelValue(content)
    default:
      return content
  }
}

export function getValueColor(property: string, value: string) {
  if (property === COLUMN_LEVEL) {
    if (value === 'INFO') return 'info'
    if (value === 'WARNING') return 'warning'
    if (value === 'ERROR') return 'error'
    if (value === 'DEBUG') return 'success'
    if (value === 'TRACE') return 'trace'
  }
  return undefined
}

export function getUpdatedFilters(
  filters: Filter[],
  property: string,
  value: string | null,
): Filter[] {
  const hasSome = filters.some(
    (f) => f.field === property && f.operator === 'equals',
  )
  if (hasSome) return filters.filter((f) => f.field !== property)
  return [
    ...filters.filter((f) => f.field !== property),
    {
      field: property,
      operator: 'equals',
      value: value,
    },
  ]
}

export function updateFilterAttribute(
  filter: Filter,
  property: string,
): Filter {
  switch (property) {
    case COLUMN_TIMESTAMP:
    case COLUMN_LEVEL:
      return { ...filter, field: property, operator: 'equals', value: null }
    default:
      return { ...filter, field: property }
  }
}

export function modifiedBlankFilter(prevState: Filter[], state: Filter[]) {
  const oldFiltered = prevState.filter((f) => f.value !== null)
  const newFiltered = state.filter((f) => f.value !== null)
  return _.isEqual(oldFiltered, newFiltered)
}
