import { RefObject, useCallback, useRef } from 'react'

const useSelectOnFocus = (
  inputRef: RefObject<HTMLInputElement | null>,
  mouseUpFunction?: () => void,
  mouseDownFunction?: () => void,
  threshold = 5,
) => {
  const mouseDownPosRef = useRef<{ x: number; y: number } | null>(null)
  const wasFocusedRef = useRef<boolean>(false)

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      mouseDownPosRef.current = { x: e.clientX, y: e.clientY }
      wasFocusedRef.current = document.activeElement === inputRef.current
      mouseDownFunction?.()
    },
    [mouseDownFunction, inputRef],
  )

  const handleMouseUp = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      if (!mouseDownPosRef.current || !inputRef.current) {
        return
      }

      const dx = Math.abs(e.clientX - mouseDownPosRef.current.x)
      const dy = Math.abs(e.clientY - mouseDownPosRef.current.y)
      const distance = Math.sqrt(dx * dx + dy * dy)

      if (distance <= threshold && !wasFocusedRef.current) {
        inputRef.current.select()
        mouseUpFunction?.()
      }

      mouseDownPosRef.current = null
    },
    [threshold, inputRef, mouseUpFunction],
  )

  return { handleMouseDown, handleMouseUp }
}

export default useSelectOnFocus
