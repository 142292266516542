import { useEffect } from 'react'
import useLoadHasData from '../load/useLoadHasData'

const useInitHasData = () => {
  const loadHasData = useLoadHasData()

  useEffect(() => {
    loadHasData()
  }, [loadHasData])
}

export default useInitHasData
