import { ChevronDown, ChevronRight } from 'lucide-react'
import { useState, useRef, useCallback } from 'react'
import { cn } from '../../../utils/cn'
import Portal from '../Portal/Portal'
import usePopupPauseScroll from '../../../hooks/utils/usePopupPauseScroll'
import usePopupPosition from '../../../hooks/utils/usePopupPosition'
import usePopupStack from '../../../hooks/utils/usePopupStack'
import TextInputSmall from '../TextInputSmall/TextInputSmall'

interface PopupInputProps {
  icon: React.ReactNode
  title: string
  emptyValue: string

  placeholder: string
  value: string
  units?: string
  onUpdateValue: (value: string) => void | Promise<void>
}

const PopupInput = ({
  icon,
  title,
  emptyValue,
  placeholder,
  value,
  units,
  onUpdateValue,
}: PopupInputProps) => {
  const portalRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [localValue, setLocalValue] = useState(value)

  const handleClose = useCallback(async () => {
    await onUpdateValue(localValue)
    setIsOpen(false)
  }, [onUpdateValue, localValue])

  usePopupStack(portalRef, handleClose, buttonRef)
  usePopupPauseScroll(portalRef, isOpen)
  const position = usePopupPosition(
    isOpen,
    buttonRef,
    popupRef,
    'bottom-right',
    { x: -232 },
  )

  return (
    <>
      <button
        ref={buttonRef}
        className={cn(
          'w-full h-[28px] px-2 flex items-center justify-between gap-2 rounded group',
          isOpen ? 'bg-input-tint' : 'hover:bg-input-tint',
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-none items-center gap-2 text-text-1">
          {icon}
          <p className="text-small-light">{title}</p>
        </div>
        <div
          className={cn(
            'flex flex-1 items-center justify-end gap-2 overflow-hidden',
            isOpen ? 'text-text-1' : 'text-text-2 group-hover:text-text-1',
          )}
        >
          <p className="text-small-light overflow-hidden text-ellipsis whitespace-nowrap">
            {(value || emptyValue) + (units ? ` ${units}` : '')}
          </p>
          {isOpen ? <ChevronDown size={12} /> : <ChevronRight size={12} />}
        </div>
      </button>
      {isOpen && (
        <Portal ref={portalRef}>
          <div
            ref={popupRef}
            className="absolute w-[232px] p-2 flex flex-col gap-1 bg-popup-bg border border-popup-border rounded shadow-popup-shadow"
            style={{ ...position }}
          >
            <TextInputSmall
              value={localValue}
              placeholder={placeholder}
              autoFocus={true}
              onUpdateValue={setLocalValue}
              onSubmit={handleClose}
            />
          </div>
        </Portal>
      )}
    </>
  )
}

export default PopupInput
