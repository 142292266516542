import PreviewLogs from '../../../../library/PreviewLogs/PreviewLogs'
import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const SetupLogsNextJS = () => {
  useSetBrandColor('info')

  return (
    <SetupTemplate
      title="Log Setup - NextJS"
      keyName="nextjs-api-key"
      installStep={installSnippets}
      sendTitle="Send a log"
      sendEventStep={getSendMessageSnippets}
      preview={<PreviewLogs />}
      done={LinkKey.Logs}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'npm',
    language: 'bash',
    displayLanguage: 'bash',
    content: `npm install vigilant-js`,
  },
  {
    title: 'yarn',
    language: 'bash',
    displayLanguage: 'bash',
    content: `yarn add vigilant-js`,
  },
  {
    title: 'bun',
    language: 'bash',
    displayLanguage: 'bash',
    content: `bun install vigilant-js`,
  },
]

function getSendMessageSnippets(key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'NextJS',
      language: 'javascript',
      displayLanguage: 'javascript',
      content: `// In _app.tsx
import type { AppProps } from "next/app";
import { Logger } from "vigilant-js";

const logger = new Logger({
  name: "my-service",
  token: "${key}",
});

logger.autocapture_enable();

export default function App({ Component, pageProps }: AppProps) {
  // Autocapture directly reads from console
  console.log("Example Log");
  return <Component {...pageProps} />;
}`,
    },
  ]
}

export default SetupLogsNextJS
