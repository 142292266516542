import { useState, useEffect, RefObject } from 'react'

type Dimensions = {
  width: number
  height: number
  top: number
  left: number
  bottom: number
  right: number
}

export const useSVGDimensions = (
  ref: RefObject<SVGSVGElement | null>,
): Dimensions => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  })

  useEffect(() => {
    const updateDimensions = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect()
        setDimensions(rect)
      }
    }

    let observer: MutationObserver | null = null

    if (ref.current) {
      updateDimensions()
    } else {
      observer = new MutationObserver(() => {
        if (ref.current) {
          updateDimensions()
          observer?.disconnect()
          observer = null
        }
      })
      observer.observe(document.body, { childList: true, subtree: true })
    }

    window.addEventListener('resize', updateDimensions)
    return () => {
      window.removeEventListener('resize', updateDimensions)
      if (observer) {
        observer.disconnect()
        observer = null
      }
    }
  }, [ref])

  return dimensions
}

export default useSVGDimensions
