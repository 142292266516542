import { useEffect, useState } from 'react'
import useNav, { LinkKey } from '../../../hooks/nav/useNav'
import useSignup from '../../../hooks/actions/useSignup'
import useLoggedIn from '../../../hooks/utils/useLoggedIn'
import { setBrandCTA } from '../../../utils/setBrandColor'
import { isValidEmail } from '../../../utils/inputs'
import SolidInput from '../../library/SolidInput/SolidInput'
import BrandButton from '../../library/BrandButton/BrandButton'

const Signup: React.FC = () => {
  const loggedIn = useLoggedIn()

  const navigate = useNav()
  const signup = useSignup()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const valid =
    validEmail(email) &&
    validPassword(password) &&
    validConfirmPassword(password, confirmPassword)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!valid) return
    await signup(email, password)
  }

  useEffect(() => {
    setBrandCTA('success')
  }, [])

  if (loggedIn) return <></>

  return (
    <div className="h-screen w-screen flex items-center justify-center bg-main-bg">
      <form
        className="p-6 w-[360px] flex flex-col items-start justify-start gap-6 rounded-[4px] border border-panel-border"
        onSubmit={handleSubmit}
      >
        <div className="w-full flex flex-col items-start justify-start gap-1.5">
          <h1 className="text-h1 text-text-1">Sign Up</h1>
          <p className="text-body text-text-2">
            Enter your email to create an account
          </p>
        </div>
        <div className="w-full flex flex-col items-start justify-start gap-4">
          <div className="w-full flex flex-col items-start justify-start gap-2">
            <p className="text-small-light text-text-2">Email</p>
            <SolidInput
              value={email}
              onChange={setEmail}
              placeholder="Enter email"
              validFn={isValidEmail}
            />
          </div>
          <div className="w-full flex flex-col items-start justify-start gap-2">
            <p className="text-small-light text-text-2">Password</p>
            <SolidInput
              value={password}
              onChange={setPassword}
              placeholder="Enter password"
              secure={true}
            />
          </div>
          <div className="w-full flex flex-col items-start justify-start gap-2">
            <p className="text-small-light text-text-2">Confirm Password</p>
            <SolidInput
              value={confirmPassword}
              onChange={setConfirmPassword}
              placeholder="Enter password"
              secure={true}
            />
          </div>
        </div>
        <div className="w-full flex flex-col items-start gap-4">
          <BrandButton
            className="w-full"
            mode="success"
            disabled={!valid}
            onClick={handleSubmit}
          >
            Create Account
          </BrandButton>
          <div className="flex items-center justify-start">
            <p className="text-normal-light text-text-2">
              Already have an account?{' '}
              <span
                className="text-text-1 underline cursor-pointer"
                onClick={() => navigate(LinkKey.Login)}
              >
                Login
              </span>
            </p>
          </div>
        </div>
      </form>
    </div>
  )
}

function validEmail(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

function validPassword(password: string) {
  return password.length >= 1
}

function validConfirmPassword(password: string, confirmPassword: string) {
  return password === confirmPassword
}

export default Signup
