import { UpdateAlertSettingsRequest } from '../../backend/types'
import { useCallback } from 'react'
import useBackend from '../services/useBackend'
import { captureAxiosError } from '../../utils/obeservability'
import useLoadAlertSettings from '../data/load/useLoadAlertSettings'

const useUpdateAlertSettings = () => {
  const backend = useBackend()
  const loadAlertSettings = useLoadAlertSettings()

  const updateAlertSettings = useCallback(
    async (request: UpdateAlertSettingsRequest) => {
      try {
        await backend.updateAlertSettings(request)
        await loadAlertSettings()
      } catch (e) {
        captureAxiosError(e)
      }
    },
    [backend, loadAlertSettings],
  )

  return updateAlertSettings
}

export default useUpdateAlertSettings
