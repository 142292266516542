import { useMemo, useState } from 'react'
import { useAtomValue } from 'jotai'
import useCreateOrganizationInvite from '../../../../../../hooks/actions/useCreateOrganizationInvite'
import useDeleteOrganizationMember from '../../../../../../hooks/actions/useDeleteOrganizationMember'
import useInitOrganizationMembers from '../../../../../../hooks/data/effects/useInitOrganizationMemebers'
import SettingsSection from '../../../../../library/SettingsSection/SettingsSection'
import SettingsTitle from '../../../../../library/SettingsTitle/SettingsTitle'
import {
  organizationMembersAtom,
  userInfoAtom,
} from '../../../../../../state/state'
import { organizationInfoAtom } from '../../../../../../state/state'
import {
  OrganizationMember,
  OrganizationRole,
} from '../../../../../../backend/types'
import SettingsTable from '../../../../../library/SettingsTable/SettingsTable'
import SettingsTableColumn from '../../../../../library/SettingsTable/SettingsTableColumn'
import SettingsTableHeader from '../../../../../library/SettingsTable/SettingsTableHeader'
import SettingsTableCell from '../../../../../library/SettingsTable/SettingsTableCell'
import { TrashIcon } from 'lucide-react'
import SettingsTableIcon from '../../../../../library/SettingsTable/SettingsTableIcon'
import RemoveMemberPopup from './RemoveMemberPopup/RemoveMemberPopup'
import InviteTeammatePopup from './InviteTeammatePopup/InviteTeammatePopup'
import { User } from '../../../../../../state/types'
import CTAButton from '../../../../../library/CTAButton/CTAButton'
import TransferOwnershipPopup from './TransferOwnershipPopup/TransferOwnershipPopup'
import useUpdateOrganizationOwner from '../../../../../../hooks/actions/useUpdateOrganizationOwner'

const OrganizationSettings = () => {
  useInitOrganizationMembers()

  const createInvite = useCreateOrganizationInvite()
  const deleteMember = useDeleteOrganizationMember()
  const updateOwner = useUpdateOrganizationOwner()

  const organizationInfo = useAtomValue(organizationInfoAtom)
  const userInfo = useAtomValue(userInfoAtom)
  const members = useAtomValue(organizationMembersAtom)
  const isOwner = useMemo(
    () => isUserOwner(userInfo, members ?? []),
    [userInfo, members],
  )

  const [deletingMember, setDeletingMember] = useState<string | null>(null)
  const [invitingMember, setInvitingMember] = useState(false)
  const [transferringOwnership, setTransferringOwnership] = useState(false)

  const handleCreateInvite = async (email: string) => {
    if (!organizationInfo?.id) return
    await createInvite(email)
    setInvitingMember(false)
  }

  const handleDeleteMember = async (id: string) => {
    if (!organizationInfo?.id) return
    await deleteMember(organizationInfo.id, id)
    setDeletingMember(null)
  }

  const handleTransferOwnership = async (userId: string) => {
    if (!organizationInfo?.id) return
    await updateOwner(userId)
    setTransferringOwnership(false)
  }

  return (
    <div className="flex-1 px-12 py-12 flex flex-col items-center gap-6 overflow-y-auto">
      <SettingsSection>
        <SettingsTitle
          title="Members"
          description="This is the list of everyone in the organization. Members can invite new members. Owners and admins can change roles and remove people."
          button="Invite Member"
          onClick={() => setInvitingMember(true)}
        />
        <SettingsTable>
          <SettingsTableColumn flex={false}>
            <SettingsTableHeader content="Email" />
            {members?.map((member) => (
              <SettingsTableCell key={member.user_id} content={member.email} />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn>
            <SettingsTableHeader content="Role" />
            {members?.map((member) => (
              <SettingsTableCell
                key={member.user_id}
                content={formatRole(member.role)}
              />
            ))}
          </SettingsTableColumn>
          <SettingsTableColumn flex={false}>
            <div className="h-[40px] border-b border-panel-border"></div>
            {members
              ?.filter((m) => m.user_id !== userInfo?.id)
              .map((member) => (
                <SettingsTableIcon
                  key={member.user_id}
                  icon={<TrashIcon size={16} />}
                  onClick={() => setDeletingMember(member.user_id)}
                />
              ))}
          </SettingsTableColumn>
        </SettingsTable>
      </SettingsSection>

      {isOwner && members && members?.length > 1 && (
        <SettingsSection>
          <SettingsTitle
            title="Transfer Ownership"
            description="Transfer the ownership of the organization to another member."
          />
          <div className="py-4 w-full flex flex-col items-start justify-center gap-1">
            <div className="w-full flex items-center justify-between">
              <p className="text-body text-brand-error">
                Warning: This cannot be undone.
              </p>
              <CTAButton
                mode="error"
                onClick={() => setTransferringOwnership(true)}
              >
                Transfer Ownership
              </CTAButton>
            </div>
          </div>
        </SettingsSection>
      )}

      {invitingMember && (
        <InviteTeammatePopup
          onSubmit={handleCreateInvite}
          close={() => setInvitingMember(false)}
        />
      )}

      {deletingMember && (
        <RemoveMemberPopup
          email={
            members?.find((m) => m.user_id === deletingMember)?.email ?? ''
          }
          onSubmit={() => handleDeleteMember(deletingMember)}
          close={() => setDeletingMember(null)}
        />
      )}

      {transferringOwnership && (
        <TransferOwnershipPopup
          members={members ?? []}
          onSubmit={handleTransferOwnership}
          close={() => setTransferringOwnership(false)}
        />
      )}
    </div>
  )
}

function isUserOwner(userInfo: User | null, members: OrganizationMember[]) {
  return members?.some((m) => m.user_id === userInfo?.id && m.role === 'owner')
}

function formatRole(role: OrganizationRole) {
  switch (role) {
    case 'owner':
      return 'Owner'
    case 'admin':
      return 'Admin'
    case 'user':
      return 'Member'
  }
}

export default OrganizationSettings
