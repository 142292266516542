import { useEffect } from 'react'
import { useState } from 'react'
import Terminal from '../Terminal/Terminal'
import useBackend from '../../../hooks/services/useBackend'
import { useAtomValue } from 'jotai'
import { instanceIdAtom } from '../../../state/state'
import { Alert } from '../../../backend/types'

interface PreviewAlertsProps {
  className?: string
}

const PreviewAlerts = ({ className }: PreviewAlertsProps) => {
  const backend = useBackend()
  const { projectId } = useAtomValue(instanceIdAtom)

  const [alerts, setAlerts] = useState<Alert[]>([])

  useEffect(() => {
    if (!projectId) return

    const fetchAlertsList = () => {
      backend.getAlerts({ search_term: '' }).then((alerts) => {
        setAlerts(alerts.alerts)
      })
    }

    fetchAlertsList()

    const intervalId = setInterval(fetchAlertsList, 1000)

    return () => clearInterval(intervalId)
  }, [backend, projectId])

  return (
    <div
      className={`w-full flex flex-col items-start justify-start gap-2 ${className}`}
    >
      <h2 className="text-h2 text-text-1">Preview Alerts</h2>
      <Terminal
        className="w-full h-[220px]"
        content={getAllAlertList(alerts)}
      />
    </div>
  )
}

function getAllAlertList(alerts: Alert[]): string[] {
  return alerts.map((alert) => alert.title)
}

export default PreviewAlerts
