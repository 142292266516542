import { useMemo } from 'react'
import { QueryParameterManager } from '../../library/query/queryParameterManager'
import { QueryParameters } from '../../library/query/types'

const useQueryParameterManager = (queryParams: QueryParameters) => {
  const queryParameterManager = useMemo(() => {
    return new QueryParameterManager(queryParams)
  }, [queryParams])

  return queryParameterManager
}

export default useQueryParameterManager
