import { useSetAtom } from 'jotai'
import useBackend from '../services/useBackend'
import useNav, { LinkKey } from '../nav/useNav'
import useJoinToken from './useJoinToken'
import { instanceIdAtom } from '../../state/state'
import useToast from '../utils/useToast'
import { captureAxiosError, logger } from '../../utils/obeservability'

const useLogin = () => {
  const backend = useBackend()
  const navigate = useNav()
  const toast = useToast()

  const setInstanceId = useSetAtom(instanceIdAtom)
  const joinToken = useJoinToken()

  return async (email: string, password: string) => {
    try {
      await backend.login(email, password)
      logger.info(`user login - ${email}`)

      const joined = await joinToken()
      if (joined) {
        navigate(LinkKey.Logs)
        return
      }

      const { organizations } = await backend.getOrganizations()
      if (!organizations || organizations.length === 0) {
        navigate(LinkKey.NewOrganization)
        return
      }

      const organizationId = organizations[0].id
      await backend.authOrganization(organizationId)

      const { projects } = await backend.getProjects(organizationId)
      if (!projects || projects.length === 0) {
        navigate(LinkKey.NewProject)
        return
      }

      const projectId = projects[0].id
      await backend.authProject(organizationId, projectId)

      setInstanceId({ organizationId, projectId })

      navigate(LinkKey.Logs)
    } catch (e) {
      captureAxiosError(e)
      toast('Login failed - account not found', 'error')
    }
  }
}

export default useLogin
