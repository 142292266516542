import { ChevronRightIcon } from 'lucide-react'
import { useState, useRef } from 'react'
import { cn } from '../../../utils/cn'

type Option = {
  icon: React.ReactNode
  title: string
  onClick?: () => void
}

interface SetupLogoButtonProps {
  icon: React.ReactNode
  title: string
  options?: Option[]
  onClick?: () => void
}

const SetupLogoButton: React.FC<SetupLogoButtonProps> = ({
  title,
  icon,
  options,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    if (!options || options?.length === 0) {
      onClick?.()
    } else {
      setIsOpen(!isOpen)
    }
  }

  return (
    <div className="w-full flex flex-col items-start justify-start border border-input-border rounded-[4px] overflow-hidden">
      <button
        className="px-3 py-2 w-full flex flex-row items-center gap-2 bg-input-bgTint hover:bg-input-tint"
        onClick={handleClick}
      >
        <div className="flex-none flex flex-row items-center">{icon}</div>
        <h2 className="flex-1 text-input text-text-1">{title}</h2>
        {options && options.length > 0 && (
          <ChevronRightIcon
            className={cn(
              'flex-none w-4 h-4 text-text-1 transition-transform duration-200 ease-out',
              isOpen ? 'rotate-90' : 'rotate-0',
            )}
          />
        )}
      </button>
      <div
        className={cn(
          'w-full overflow-hidden transition-[height] duration-200 ease-out',
          isOpen ? 'h-auto' : 'h-0',
        )}
        style={{ height: isOpen ? contentRef.current?.scrollHeight : 0 }}
      >
        <div
          className="w-full flex flex-col items-start justify-start"
          ref={contentRef}
        >
          {options?.map((option) => (
            <Row
              key={option.title}
              icon={option.icon}
              text={option.title}
              onClick={option.onClick}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

interface RowProps {
  icon: React.ReactNode
  text: string
  onClick?: () => void
}

const Row: React.FC<RowProps> = ({ icon, text, onClick }) => {
  return (
    <button
      className="px-3 pl-9 py-2 w-full flex flex-row items-center gap-2 bg-input-bgTint hover:bg-input-tint"
      onClick={onClick}
    >
      <div className="flex-none flex flex-row items-center">{icon}</div>
      <p className="flex-1 text-input text-text-1">{text}</p>
    </button>
  )
}

export default SetupLogoButton
