import { useAtomValue, useSetAtom } from 'jotai'
import {
  hasLogsAtom,
  hasMetricsAtom,
  instanceIdAtom,
  hasAlertsAtom,
} from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'
import { captureAxiosError } from '../../../utils/obeservability'

const useLoadHasData = () => {
  const backend = useBackend()
  const { projectId } = useAtomValue(instanceIdAtom)
  const setHasLogs = useSetAtom(hasLogsAtom)
  const setHasAlerts = useSetAtom(hasAlertsAtom)
  const setHasMetrics = useSetAtom(hasMetricsAtom)

  const loadHasLogs = useCallback(async () => {
    if (!projectId) return
    try {
      const { count } = await backend.logsCount({
        project_id: projectId,
      })
      setHasLogs(count > 0)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, projectId, setHasLogs])

  const loadHasMetrics = useCallback(async () => {
    if (!projectId) return
    try {
      const { names } = await backend.metricsList({
        project_id: projectId,
      })
      setHasMetrics(names.length > 0)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, projectId, setHasMetrics])

  const loadHasAlerts = useCallback(async () => {
    if (!projectId) return
    try {
      const { alerts } = await backend.getAlerts({
        search_term: '',
      })
      setHasAlerts(alerts.length > 0)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, projectId, setHasAlerts])

  const loadHasData = useCallback(async () => {
    await Promise.all([loadHasLogs(), loadHasMetrics(), loadHasAlerts()])
  }, [loadHasLogs, loadHasMetrics, loadHasAlerts])

  return loadHasData
}

export default useLoadHasData
