import Checkbox from '../../../../../library/Checkbox/Checkbox'

interface AlertHeaderRowProps {
  selectedCount: number
  toggleAll: () => void
}

const AlertHeaderRow = ({ selectedCount, toggleAll }: AlertHeaderRowProps) => {
  return (
    <div className="w-full h-8 px-3 flex items-center gap-6 border-b border-panel-border">
      <div className="flex-1 h-full flex items-center gap-2">
        <Checkbox
          checked={selectedCount > 0}
          hidden={false}
          toggle={toggleAll}
        />
        <p className="text-mini-code text-text-2">Alert title</p>
      </div>
      <div className="w-[100px] flex-none text-mini-code text-text-2">
        Status
      </div>
      <div className="w-[100px] flex-none text-mini-code text-text-2">
        Times Fired
      </div>
      <div className="w-[120px] flex-none text-mini-code text-text-2">
        Most Recent
      </div>
    </div>
  )
}

export default AlertHeaderRow
