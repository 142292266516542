import {
  AnalyticsEntry,
  AnalyticsSettingsCounter,
  AnalyticsSettingsGraph,
  AnalyticsUpdateRequest,
} from '../../../backend/types'
import useLoadAnalyticsCounterValue from '../../../hooks/data/load/useLoadAnalyticsCounterValue'
import AnalyticsDisplayCounter from '../AnalyticsDisplayCounter/AnalyticsDisplayCounter'
import useLoadAnalyticsGraphValues from '../../../hooks/data/load/useLoadAnalyticsGraphValues'
import AnalyticsDisplayGraph from '../AnalyticsDisplayGraph/AnalyticsDisplayGraph'

interface AnalyticsEntryDisplayProps {
  entry: AnalyticsEntry
  startTime: string | undefined
  endTime: string | undefined
  refreshing?: boolean
  rearranging?: boolean
  onUpdate: (settings: AnalyticsUpdateRequest) => void
  onDuplicate: () => void
  onDelete: () => void
}

const AnalyticsEntryDisplay = ({
  entry,
  startTime,
  endTime,
  refreshing,
  rearranging,
  onUpdate,
  onDuplicate,
  onDelete,
}: AnalyticsEntryDisplayProps) => {
  switch (entry.type) {
    case 'counter':
      return (
        <CounterWrapper
          settings={entry.settings}
          startTime={startTime}
          endTime={endTime}
          refreshing={refreshing}
          rearranging={rearranging}
          onUpdate={onUpdate}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      )
    case 'graph':
      return (
        <GraphWrapper
          settings={entry.settings}
          startTime={startTime}
          endTime={endTime}
          refreshing={refreshing}
          rearranging={rearranging}
          onUpdate={onUpdate}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      )
  }
}

interface CounterWrapperProps {
  settings: AnalyticsSettingsCounter
  startTime: string | undefined
  endTime: string | undefined
  refreshing?: boolean
  rearranging?: boolean
  onUpdate: (settings: AnalyticsUpdateRequest) => void
  onDuplicate: () => void
  onDelete: () => void
}

const CounterWrapper = ({
  settings,
  startTime,
  endTime,
  refreshing,
  rearranging,
  onUpdate,
  onDuplicate,
  onDelete,
}: CounterWrapperProps) => {
  const value = useLoadAnalyticsCounterValue(
    startTime,
    endTime,
    settings,
    refreshing,
  )

  return (
    <AnalyticsDisplayCounter
      settings={settings}
      value={value.value}
      previousValue={value.previousValue}
      rearranging={rearranging}
      onUpdate={onUpdate}
      onDuplicate={onDuplicate}
      onDelete={onDelete}
    />
  )
}

interface GraphWrapperProps {
  settings: AnalyticsSettingsGraph
  startTime: string | undefined
  endTime: string | undefined
  rearranging?: boolean
  refreshing?: boolean
  onUpdate: (settings: AnalyticsUpdateRequest) => void
  onDuplicate: () => void
  onDelete: () => void
}

const GraphWrapper = ({
  settings,
  startTime,
  endTime,
  rearranging,
  refreshing,
  onUpdate,
  onDuplicate,
  onDelete,
}: GraphWrapperProps) => {
  const values = useLoadAnalyticsGraphValues(
    startTime,
    endTime,
    settings,
    refreshing,
  )

  return (
    <AnalyticsDisplayGraph
      settings={settings}
      values={values}
      rearranging={rearranging}
      onUpdate={onUpdate}
      onDuplicate={onDuplicate}
      onDelete={onDelete}
    />
  )
}

export default AnalyticsEntryDisplay
