import { differenceInSeconds, subDays, subHours, subMonths } from 'date-fns'
import {
  AnalyticsBucketSize,
  AnalyticsCreateRequest,
  AnalyticsLayout,
  AnalyticsLayoutRow,
  AnalyticsUpdateRequest,
} from '../backend/types'

export type AnalyticsTimeRange = TimeRangeRelative | TimeRangeAbsolute

export type TimeRangePresets =
  | '1m'
  | '5m'
  | '15m'
  | '30m'
  | '1h'
  | '4h'
  | '12h'
  | '1d'
  | '3d'
  | '1w'
  | '1mo'

export type TimeRangeRelative = {
  type: 'preset'
  value: TimeRangePresets
}

export type TimeRangeAbsolute = {
  type: 'absolute'
  startTime: string
  endTime: string | undefined
}

export function getStartTime(
  timeRange: TimeRangeRelative | TimeRangeAbsolute,
): string | undefined {
  switch (timeRange.type) {
    case 'preset':
      switch (timeRange.value) {
        case '1h':
          return subHours(new Date(), 1).toISOString()
        case '4h':
          return subHours(new Date(), 4).toISOString()
        case '1d':
          return subDays(new Date(), 1).toISOString()
        case '1w':
          return subDays(new Date(), 7).toISOString()
        case '1mo':
          return subMonths(new Date(), 1).toISOString()
      }
      break
    case 'absolute':
      return timeRange.startTime
  }
}

export function getEndTime(
  timeRange: TimeRangeRelative | TimeRangeAbsolute,
): string | undefined {
  switch (timeRange.type) {
    case 'preset':
      return undefined
    case 'absolute':
      return timeRange.endTime
  }
}

export const validateAnalyticsCreateRequest = (
  request: AnalyticsCreateRequest,
): boolean => {
  if (!request.settings) return false
  if (!request.settings.name) return false
  if (!request.settings.metric_name) return false
  if (!request.settings.aggregation_function) return false
  return true
}

export const validateAnalyticsUpdateRequest = (
  request: AnalyticsUpdateRequest,
): boolean => {
  if (!request.settings) return false
  if (!request.settings.name) return false
  if (!request.settings.metric_name) return false
  if (!request.settings.aggregation_function) return false
  return true
}

export const getBucketSize = (
  startTime: string | undefined,
  endTime: string | undefined,
  bucketSize: AnalyticsBucketSize | undefined,
): AnalyticsBucketSize => {
  if (!startTime) return '1h'
  const diff = differenceInSeconds(
    new Date(endTime ?? new Date()),
    new Date(startTime),
  )

  if (bucketSize) {
    return getMinBucketSize(bucketSize, diff)
  }

  if (diff <= 30) return '1s'
  if (diff <= 60) return '5s'
  if (diff <= 300) return '15s'
  if (diff <= 600) return '30s'
  if (diff <= 1800) return '1m'
  if (diff <= 3600) return '5m'
  if (diff <= 14400) return '15m'
  if (diff <= 21600) return '30m'
  if (diff <= 43200) return '1h'
  if (diff <= 86400) return '1h'
  if (diff <= 172800) return '6h'
  if (diff <= 259200) return '12h'
  if (diff <= 604800) return '1d'
  return '1d'
}

export const getMinBucketSize = (
  bucketSize: AnalyticsBucketSize,
  diffInSeconds: number,
): AnalyticsBucketSize => {
  const bucketSizeToSeconds: Record<AnalyticsBucketSize, number> = {
    '1s': 1,
    '5s': 5,
    '15s': 15,
    '30s': 30,
    '1m': 60,
    '5m': 300,
    '15m': 900,
    '30m': 1800,
    '1h': 3600,
    '4h': 14400,
    '6h': 21600,
    '12h': 43200,
    '1d': 86400,
  }

  const maxBuckets = 50
  if (diffInSeconds / bucketSizeToSeconds[bucketSize] > maxBuckets) {
    const bucketSizes = Object.keys(
      bucketSizeToSeconds,
    ) as AnalyticsBucketSize[]
    bucketSizes.sort((a, b) => bucketSizeToSeconds[a] - bucketSizeToSeconds[b])

    const currentIndex = bucketSizes.indexOf(bucketSize)
    for (let i = currentIndex; i < bucketSizes.length; i++) {
      const size = bucketSizes[i]
      if (diffInSeconds / bucketSizeToSeconds[size] <= maxBuckets) {
        return size
      }
    }

    return bucketSizes[bucketSizes.length - 1]
  }

  return bucketSize
}

export function getLayoutAfterMove(
  id: string,
  direction: 'up' | 'down' | 'left' | 'right',
  layout: AnalyticsLayout,
): AnalyticsLayout | null {
  switch (direction) {
    case 'left':
      return getLayoutAfterMoveLeft(id, layout)
    case 'right':
      return getLayoutAfterMoveRight(id, layout)
    case 'up':
      return getLayoutAfterMoveUp(id, layout)
    case 'down':
      return getLayoutAfterMoveDown(id, layout)
    default:
      return null
  }
}

export function getLayoutAfterMoveLeft(
  id: string,
  layout: AnalyticsLayout,
): AnalyticsLayout | null {
  const flattened = flattenLayout(layout)
  const indexes = getIndexRangeForElement(id, flattened)

  const previousElement = getPreviousElement(indexes[0], flattened)
  if (previousElement === null) return null

  const previousElementIndexes = seekBackwardForElementIndexRange(
    indexes[0] - 1,
    flattened,
  )

  const minIndex = Math.min(indexes[0], previousElementIndexes[0])
  const maxIndex = Math.max(indexes[1], previousElementIndexes[1])
  const updatedFlattened = invertRange(minIndex, maxIndex, flattened)

  return unflattenLayout(updatedFlattened)
}

export function getLayoutAfterMoveRight(
  id: string,
  layout: AnalyticsLayout,
): AnalyticsLayout | null {
  const flattened = flattenLayout(layout)
  const indexes = getIndexRangeForElement(id, flattened)

  const nextElement = getNextElement(indexes[1], flattened)
  if (nextElement === null) return null

  const nextElementIndexes = seekForwardForElementIndexRange(
    indexes[1] + 1,
    flattened,
  )

  const minIndex = Math.min(indexes[0], nextElementIndexes[0])
  const maxIndex = Math.max(indexes[1], nextElementIndexes[1])
  const updatedFlattened = invertRange(minIndex, maxIndex, flattened)

  return unflattenLayout(updatedFlattened)
}

export function getLayoutAfterMoveUp(
  id: string,
  layout: AnalyticsLayout,
): AnalyticsLayout | null {
  const flattened = flattenLayout(layout)
  const indexes = getIndexRangeForElement(id, flattened)

  const emptyRange = seekUpForEmptyCells(indexes, flattened)
  const updatedFlattened = swapRange(indexes, emptyRange, flattened)

  return unflattenLayout(updatedFlattened)
}

export function getLayoutAfterMoveDown(
  id: string,
  layout: AnalyticsLayout,
): AnalyticsLayout | null {
  const flattened = flattenLayout(layout)
  const indexes = getIndexRangeForElement(id, flattened)

  const emptyRange = seekDownForEmptyCells(indexes, flattened)
  const updatedFlattened = swapRange(indexes, emptyRange, flattened)

  return unflattenLayout(updatedFlattened)
}

function getIndexRangeForElement(
  id: string,
  flattened: string[],
): [number, number] {
  const indexes = []
  for (let i = 0; i < flattened.length; i++) {
    if (flattened[i] === id) {
      indexes.push(i)
    }
  }
  const sorted = indexes.sort((a, b) => a - b)
  return [sorted[0], sorted[sorted.length - 1]]
}

function seekForwardForElementIndexRange(
  startIndex: number,
  flattened: string[],
): [number, number] {
  if (startIndex < 0 || startIndex >= flattened.length) {
    return [0, 0]
  }

  const id = flattened[startIndex]
  if (id === '') {
    return [startIndex, startIndex]
  }

  let startBoundary = startIndex
  let endBoundary = startIndex

  let i = startIndex + 1
  while (i < flattened.length && flattened[i] === id) {
    endBoundary = i
    i++
  }

  return [startBoundary, endBoundary]
}

function seekBackwardForElementIndexRange(
  startIndex: number,
  flattened: string[],
): [number, number] {
  if (startIndex < 0 || startIndex >= flattened.length) {
    return [0, 0]
  }

  const id = flattened[startIndex]
  if (id === '') {
    return [startIndex, startIndex]
  }

  let startBoundary = startIndex
  let endBoundary = startIndex

  let i = startIndex - 1
  while (i >= 0 && flattened[i] === id) {
    startBoundary = i
    i--
  }

  return [startBoundary, endBoundary]
}

function seekUpForEmptyCells(
  range: [number, number],
  flattened: string[],
): [number, number] {
  let startIndex = range[0] - 4
  let endIndex = range[1] - 4

  let foundEmptyRange = false
  while (startIndex >= 0 && !foundEmptyRange) {
    foundEmptyRange = true
    for (let i = startIndex; i <= endIndex; i++) {
      if (i >= flattened.length || flattened[i] !== '') {
        foundEmptyRange = false
        break
      }
    }
    if (!foundEmptyRange) {
      startIndex -= 4
      endIndex -= 4
    }
  }
  return [startIndex, endIndex]
}

function seekDownForEmptyCells(
  range: [number, number],
  flattened: string[],
): [number, number] {
  let startIndex = range[0] + 4
  let endIndex = range[1] + 4

  let foundEmptyRange = false
  while (startIndex < flattened.length && !foundEmptyRange) {
    foundEmptyRange = true
    for (let i = startIndex; i <= endIndex; i++) {
      if (i >= flattened.length || flattened[i] !== '') {
        foundEmptyRange = false
        break
      }
    }
    if (!foundEmptyRange) {
      startIndex += 4
      endIndex += 4
    }
  }
  return [startIndex, endIndex]
}

function getPreviousElement(index: number, flattened: string[]): string | null {
  const previousIndex = index - 1
  if (previousIndex < 0 || previousIndex >= flattened.length) return null
  return flattened[previousIndex]
}

function getNextElement(index: number, flattened: string[]): string | null {
  const nextIndex = index + 1
  if (nextIndex < 0 || nextIndex >= flattened.length) return null
  return flattened[nextIndex]
}

function invertRange(
  index1: number,
  index2: number,
  flattened: string[],
): string[] {
  return [
    ...flattened.slice(0, index1),
    ...flattened.slice(index1, index2 + 1).reverse(),
    ...flattened.slice(index2 + 1),
  ]
}

function swapRange(
  range: [number, number],
  newRange: [number, number],
  flattened: string[],
): string[] {
  const newFlattened = [...flattened]
  for (let i = 0; i < range[1] - range[0] + 1; i++) {
    newFlattened[newRange[0] + i] = flattened[range[0] + i]
  }
  for (let i = 0; i < newRange[1] - newRange[0] + 1; i++) {
    newFlattened[range[0] + i] = flattened[newRange[0] + i]
  }
  return newFlattened
}

function unflattenLayout(flattened: string[]): AnalyticsLayout {
  const unfilteredRows: AnalyticsLayoutRow[] = []
  let rowIndex = 0
  let currentRow: AnalyticsLayoutRow = ['', '', '', '']

  const addCell = (id: string, cellLength: number) => {
    if (rowIndex + cellLength > 4) {
      rowIndex = 0
      unfilteredRows.push(currentRow)
      currentRow = ['', '', '', '']
      rowIndex = 0
    }
    for (let i = 0; i < cellLength; i++) {
      currentRow[rowIndex] = id
      rowIndex++
    }
  }

  let flattenedIndex = 0
  while (flattenedIndex < flattened.length) {
    const cell = flattened[flattenedIndex]
    if (cell === '') {
      addCell('', 1)
      flattenedIndex++
    } else {
      const indexes = getIndexRangeForElement(cell, flattened)
      const cellLength = indexes[1] - indexes[0] + 1
      addCell(cell, cellLength)
      flattenedIndex = indexes[1] + 1
    }
  }
  unfilteredRows.push(currentRow)

  const rows = unfilteredRows.filter((row) => row.some((cell) => cell !== ''))
  return { rows }
}

function flattenLayout(layout: AnalyticsLayout): string[] {
  const flattened = []
  flattened.push('', '', '', '')
  for (const row of layout.rows) {
    for (const cell of row) {
      flattened.push(cell)
    }
  }
  flattened.push('', '', '', '')
  return flattened
}
