import { cn } from '../../../utils/cn'

type onClickType =
  | (() => void | Promise<void>)
  | ((e: React.FormEvent) => Promise<void>)

interface BrandButtonProps {
  children: React.ReactNode
  disabled?: boolean
  mode?: 'info' | 'warning' | 'error' | 'success' | 'trace' | 'default'
  onClick?: onClickType
  className?: string
  ref?: React.RefObject<HTMLButtonElement | null>
}

const BrandButton = ({
  children,
  disabled,
  mode,
  onClick,
  ref,
  className,
}: BrandButtonProps) => {
  return (
    <button
      ref={ref}
      className={cn(
        `px-[14px] h-8 flex-none flex items-center justify-center rounded text-small-light border`,
        getBackgroundColor(mode),
        getBorderColor(mode),
        getBackgroundHoverColor(mode, disabled),
        getTextColor(mode),
        disabled ? 'opacity-60' : 'opacity-100',
        disabled ? 'cursor-default' : 'cursor-pointer',
        className,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

function getBackgroundColor(mode: BrandButtonProps['mode']) {
  switch (mode) {
    case 'default':
      return 'bg-input-bgTint'
    case 'info':
      return 'bg-brand-infoTint'
    case 'warning':
      return 'bg-brand-warningTint'
    case 'error':
      return 'bg-brand-errorTint'
    case 'success':
      return 'bg-brand-successTint'
    case 'trace':
      return 'bg-brand-traceTint'
    default:
      return 'bg-brand-ctaTint'
  }
}

function getBorderColor(mode: BrandButtonProps['mode']) {
  switch (mode) {
    case 'default':
      return 'border-input-border'
    case 'info':
      return 'border-brand-infoTint'
    case 'warning':
      return 'border-brand-warningTint'
    case 'error':
      return 'border-brand-errorTint'
    case 'success':
      return 'border-brand-successTint'
    case 'trace':
      return 'border-brand-traceTint'
    default:
      return 'border-brand-ctaTint'
  }
}

function getBackgroundHoverColor(
  mode: BrandButtonProps['mode'],
  disabled: BrandButtonProps['disabled'],
) {
  if (disabled) return ''
  switch (mode) {
    case 'default':
      return 'hover:bg-input-tint'
    case 'info':
      return 'hover:bg-brand-infoTint2'
    case 'warning':
      return 'hover:bg-brand-warningTint2'
    case 'error':
      return 'hover:bg-brand-errorTint2'
    case 'success':
      return 'hover:bg-brand-successTint2'
    case 'trace':
      return 'hover:bg-brand-traceTint2'
    default:
      return 'hover:bg-brand-ctaTint2'
  }
}

function getTextColor(mode: BrandButtonProps['mode']) {
  switch (mode) {
    case 'default':
      return 'text-text-1'
    case 'info':
      return 'text-brand-info'
    case 'warning':
      return 'text-brand-warning'
    case 'error':
      return 'text-brand-error'
    case 'success':
      return 'text-brand-success'
    case 'trace':
      return 'text-brand-trace'
    default:
      return 'text-brand-cta'
  }
}

export default BrandButton
