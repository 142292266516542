import { useRef, useState } from 'react'

import { useEffect } from 'react'
import { cn } from '../../../utils/cn'
import useHovered from '../../../hooks/utils/useHover'

interface SolidInputProps {
  value: string
  placeholder?: string
  onChange?: (value: string) => void
  onSubmit?: (value: string) => void
  validFn?: (value: string) => boolean
  secure?: boolean
  managed?: boolean
  autoFocus?: boolean
}

const SolidInput = ({
  value,
  onChange,
  onSubmit,
  placeholder,
  validFn,
  secure = false,
  managed = false,
  autoFocus = false,
}: SolidInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [isValid, setIsValid] = useState(true)
  const [isFocused, setIsFocused] = useState(false)
  const [hasBlurred, setHasBlurred] = useState(false)
  const [localValue, setLocalValue] = useState(value)
  const hovered = useHovered(inputRef)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
    setHasBlurred(true)
    if (managed && onSubmit) {
      onSubmit(localValue)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (managed) {
      setLocalValue(e.target.value)
    } else if (onChange) {
      onChange(e.target.value)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      inputRef.current?.blur()
    }
  }

  useEffect(() => {
    if (validFn && !managed) {
      setIsValid(validFn(value))
    } else if (validFn && managed) {
      setIsValid(validFn(localValue))
    }
  }, [value, validFn, managed, localValue])

  useEffect(() => {
    if (!managed && value !== localValue) {
      setLocalValue(value)
    }
  }, [value, managed, localValue])

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus()
    }
  }, [autoFocus])

  return (
    <input
      ref={inputRef}
      className={cn(
        'w-full h-8 px-[14px] flex items-center justify-start gap-2 text-small-light border border-input-border rounded outline-none',
        isFocused || hovered ? 'bg-input-tint' : 'bg-input-bgTint',
        isValid || !hasBlurred
          ? 'text-text-1 placeholder:text-text-2'
          : 'text-brand-error placeholder:text-text-2',
      )}
      value={managed ? localValue : value}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      placeholder={placeholder}
      type={secure ? 'password' : 'text'}
      onKeyDown={handleKeyDown}
    />
  )
}

export default SolidInput
