import { useEffect, useRef, useState } from 'react'
import { Filter } from '../../../../../../../../backend/types'
import useQueryOptionsManager from '../../../../../../../../hooks/stateServices/useQueryOptionsManager'
import { OptionsQueryState } from '../../../../../../../../library/queryOptions/types'
import { genKey } from '../../../../../../../../utils/sub'
import useClickOutside from '../../../../../../../../hooks/utils/useClickOutside'
import PopupCodeButton from '../../../../../../../library/PopupCodeButton/PopupCodeButton'
import {
  formatValue,
  getValueColor,
} from '../../../../../../../../utils/properties'

interface FilterValueButtonProps {
  filter: Filter
  setValue: (value: string) => void
}

const FilterValueButton = ({ filter, setValue }: FilterValueButtonProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  useClickOutside(ref, () => setValueOpen(false), buttonRef)

  const queryOptionsManager = useQueryOptionsManager()
  const [options, setOptions] = useState<OptionsQueryState | null>(null)
  const [valueOpen, setValueOpen] = useState(false)

  useEffect(() => {
    if (!queryOptionsManager) return
    const key = genKey()
    queryOptionsManager.subscribe(key, setOptions)
    queryOptionsManager?.queryOptions(null, {
      term: '',
      time_range: { start: undefined, end: undefined },
      filters: [],
      properties: [],
      field: filter.field,
    })
    return () => {
      queryOptionsManager.unsubscribe(key)
    }
  }, [queryOptionsManager, filter.field])

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        className="px-[10px] h-6 flex-none text-mini-code border border-l-0 border-brand-ctaTint text-brand-cta bg-brand-ctaTint hover:bg-brand-ctaTint2"
        onClick={() => setValueOpen(!valueOpen)}
      >
        <p className={filter.value ? 'opacity-100' : 'opacity-50'}>
          {filter.value ? formatValue(filter.field, filter.value) : '"..."'}
        </p>
      </button>
      {valueOpen && (
        <div
          ref={ref}
          className="absolute top-[calc(100%+4px)] left-0 min-w-[200px] max-w-[300px] max-h-[400px] bg-popup-bg border border-popup-border rounded-[4px] shadow-popup-shadow flex flex-col overflow-y-auto"
        >
          <div className="px-4 py-2 w-full flex items-center gap-1 border-b border-popup-border">
            <p className="text-text-2 text-small-code">value</p>
          </div>
          <div className="p-2 w-full flex flex-col items-center gap-1">
            {options?.result?.options.map((option) => (
              <PopupCodeButton
                key={option}
                text={formatValue(filter.field, option) ?? '"..."'}
                color={getValueColor(filter.field, option)}
                active={true}
                onClick={() => {
                  setValue(option)
                  setValueOpen(false)
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default FilterValueButton
