import Prism from 'prismjs'
import 'prism-themes/themes/prism-vsc-dark-plus.css'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-go'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-python'
import { useEffect, useRef } from 'react'
import { cn } from '../../../utils/cn'
import CopyButton from '../CopyButton/CopyButton'

interface CodeDisplayProps {
  content: string
  language: string
  className?: string
}

const CodeDisplay = ({ language, content, className }: CodeDisplayProps) => {
  const codeRef = useRef<HTMLPreElement | null>(null)

  useEffect(() => {
    if (codeRef.current) {
      Prism.highlightElement(codeRef.current)
    }
  }, [content])

  return (
    <div
      className={cn(
        'relative px-4 py-3 flex items-start justify-start bg-code-bg border border-code-border rounded-[4px]',
        className,
      )}
    >
      <pre key={`content-${content}`} className="flex-1 p-0 m-0 bg-code-bg">
        <code
          ref={codeRef}
          className={`code-display language-${language} text-small-code`}
        >
          {content}
        </code>
      </pre>
      <div className="absolute top-1 right-2">
        <CopyButton content={content} />
      </div>
    </div>
  )
}

export default CodeDisplay
