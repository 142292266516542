import { ChartLine, Grip, Hash, RefreshCcw, X } from 'lucide-react'
import { cn } from '../../../../../utils/cn'
import BrandButton from '../../../../library/BrandButton/BrandButton'
import { useState, useRef } from 'react'
import IconButton from '../../../../library/IconButton/IconButton'
import Portal from '../../../../library/Portal/Portal'
import OutlineIconTextButtonSmall from '../../../../library/OutlineIconTextButtonSmall/OutlineIconTextButtonSmall'
import SolidTabs, { SolidTab } from '../../../../library/SolidTabs/SolidTabs'
import {
  AnalyticsTimeRange,
  TimeRangePresets,
} from '../../../../../utils/analytics'
import TimeRangeDisplay from '../../../../library/TimeRangeDisplay/TimeRangeDisplay'
import SolidIconButton from '../../../../library/SolidIconButton/SolidIconButton'
import usePopupPauseScroll from '../../../../../hooks/utils/usePopupPauseScroll'
import usePopupPosition from '../../../../../hooks/utils/usePopupPosition'
import usePopupStack from '../../../../../hooks/utils/usePopupStack'

interface AnalyticsActionBarProps {
  canRearrange: boolean
  canCreate: boolean

  createGraph: () => Promise<void>
  createCounter: () => Promise<void>

  rearranging: boolean
  toggleRearranging: () => void

  timeRange: AnalyticsTimeRange
  setTimeRange: (timeRange: AnalyticsTimeRange) => void

  refreshData: () => Promise<void>
}

const AnalyticsActionBar = ({
  canRearrange,
  canCreate,
  createGraph,
  createCounter,
  rearranging,
  toggleRearranging,
  timeRange,
  setTimeRange,
  refreshData,
}: AnalyticsActionBarProps) => {
  const [createOpen, setCreateOpen] = useState(false)

  const portalRef = useRef<HTMLDivElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  usePopupStack(portalRef, () => setCreateOpen(false), buttonRef)
  usePopupPauseScroll(portalRef, createOpen)
  const position = usePopupPosition(
    createOpen,
    buttonRef,
    popupRef,
    'bottom-left',
    { y: 8 },
  )

  const handleCreateGraph = async () => {
    await createGraph()
    setCreateOpen(false)
  }

  const handleCreateCounter = async () => {
    await createCounter()
    setCreateOpen(false)
  }

  const handleSetRelativeTimeRange = (tab: TimeRangePresets) => {
    setTimeRange({ type: 'preset', value: tab })
  }

  return (
    <div className="sticky top-0 p-6 w-full flex items-center justify-between bg-main-bg z-[2]">
      <div className="flex items-center gap-2">
        <SolidTabs
          tabs={tabs}
          activeTab={getActiveTab(timeRange)}
          setActiveTab={handleSetRelativeTimeRange}
        />
        <TimeRangeDisplay timeRange={timeRange} setTimeRange={setTimeRange} />
        <SolidIconButton
          icon={<RefreshCcw size={16} />}
          onClick={refreshData}
        />
      </div>
      <div className="flex items-center gap-2">
        {canRearrange && (
          <button
            className={cn(
              'h-8 px-4 flex items-center gap-2 border rounded-[4px] text-normal-light',
              rearranging
                ? 'bg-brand-ctaTint border-brand-cta text-brand-cta'
                : 'hover:bg-input-tint text-text-1 border-input-border',
            )}
            onClick={toggleRearranging}
          >
            <Grip size={16} />
            {rearranging ? 'Done Rearranging' : 'Rearrange'}
          </button>
        )}
        {canCreate && (
          <div className="relative">
            <BrandButton
              ref={buttonRef}
              onClick={() => setCreateOpen(!createOpen)}
              disabled={rearranging}
            >
              New Analytic
            </BrandButton>
            {createOpen && (
              <Portal ref={portalRef}>
                <div
                  ref={popupRef}
                  className="absolute w-[232px] flex flex-col rounded bg-popup-bg border border-popup-border shadow-popup"
                  style={{ ...position }}
                >
                  <div className="p-2 pl-4 w-full flex items-center justify-between border-b border-popup-border">
                    <p className="text-small-medium text-text-1">
                      New Analytic
                    </p>
                    <IconButton
                      size={'28'}
                      onClick={() => setCreateOpen(false)}
                      icon={<X size={16} />}
                    />
                  </div>
                  <div className="p-2 flex flex-col gap-2">
                    <OutlineIconTextButtonSmall onClick={handleCreateGraph}>
                      <ChartLine size={16} />
                      Create a graph
                    </OutlineIconTextButtonSmall>
                    <OutlineIconTextButtonSmall onClick={handleCreateCounter}>
                      <Hash size={16} />
                      Create a counter
                    </OutlineIconTextButtonSmall>
                  </div>
                </div>
              </Portal>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

function getActiveTab(
  timeRange: AnalyticsTimeRange,
): TimeRangePresets | undefined {
  if (timeRange.type === 'preset') {
    return timeRange.value
  }
  return undefined
}

const tabs: SolidTab<TimeRangePresets>[] = [
  { label: '1H', value: '1h' },
  { label: '4H', value: '4h' },
  { label: '1D', value: '1d' },
  { label: '1W', value: '1w' },
  { label: '1M', value: '1mo' },
]

export default AnalyticsActionBar
