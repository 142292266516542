import BrandButton from '../../library/BrandButton/BrandButton'
import AnimationFadeSlideIn from '../../library/AnimationFadeSlideIn/AnimationFadeSlideIn'
import AnimationFadeShrinkIn from '../../library/AnimationFadeShrinkIn/AnimationFadeShrinkIn'
import useLoggedOut from '../../../hooks/utils/useLoggedOut'
import useNav, { LinkKey } from '../../../hooks/nav/useNav'

const Welcome = () => {
  const navigate = useNav()

  const loggedOut = useLoggedOut()
  if (loggedOut) return <></>

  return (
    <div className="h-screen w-screen flex flex-col gap-8 items-center justify-center bg-main-bg">
      <AnimationFadeShrinkIn renderDelay={0.5}>
        <div className="w-24 h-24 flex items-center justify-center bg-panel-bg rounded border border-panel-border">
          <img src="/Logo.svg" alt="logo" className="w-12 h-12" />
        </div>
      </AnimationFadeShrinkIn>
      <div className="flex flex-col items-center gap-3">
        <AnimationFadeSlideIn renderDelay={0.5} delay={1}>
          <p className="text-unique-onboarding-header text-text-1">
            Welcome to Vigilant
          </p>
        </AnimationFadeSlideIn>
        <AnimationFadeSlideIn renderDelay={0.5} delay={1.2}>
          <p className="w-[400px] text-large-light text-text-2 text-center">
            Vigilant helps developers track activity in their application and
            debug quickly.
          </p>
        </AnimationFadeSlideIn>
      </div>
      <AnimationFadeSlideIn renderDelay={0.5} delay={1.4}>
        <BrandButton
          mode="info"
          onClick={() => navigate(LinkKey.NewOrganization)}
        >
          Get Started
        </BrandButton>
      </AnimationFadeSlideIn>
    </div>
  )
}

export default Welcome
