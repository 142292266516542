import { useEffect } from 'react'

const usePopupStack = (
  ref: React.RefObject<HTMLElement | null>,
  onClose: () => void,
  sourceRef?: React.RefObject<HTMLElement | null>,
  portalRootId: string = 'portal-root',
) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!shouldClosePopup(e, ref, sourceRef, portalRootId)) return
      onClose()
    }

    document.addEventListener('mousedown', handleClick)
    return () => document.removeEventListener('mousedown', handleClick)
  }, [onClose, ref, sourceRef, portalRootId])
}

function shouldClosePopup(
  e: MouseEvent,
  ref: React.RefObject<HTMLElement | null>,
  sourceRef: React.RefObject<HTMLElement | null> | undefined,
  portalRootId: string,
): boolean {
  const popupElement = ref.current
  if (!popupElement) return false

  const target = e.target as HTMLElement
  if (popupElement.contains(target)) return false
  if (sourceRef?.current && sourceRef.current.contains(target)) return false

  const portalRoot = document.getElementById(portalRootId)
  if (!portalRoot) return false

  const portalContainers = Array.from(
    portalRoot.querySelectorAll('.portal-container'),
  )

  const lastContainer = portalContainers[portalContainers.length - 1]
  return !!(lastContainer && lastContainer.contains(popupElement))
}

export default usePopupStack
