import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'

interface AnimationFadeSlideInProps {
  children: React.ReactNode
  renderDelay?: number
  delay?: number
  distance?: number
  duration?: number
}

const AnimationFadeSlideIn: React.FC<AnimationFadeSlideInProps> = ({
  children,
  renderDelay = 0,
  delay = 0,
  distance = 50,
  duration = 1.2,
}) => {
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldRender(true)
    }, renderDelay * 1000)

    return () => clearTimeout(timer)
  }, [renderDelay])

  if (!shouldRender) return null

  return (
    <motion.div
      initial={{ opacity: 0, y: distance }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: duration,
        delay: delay,
        ease: [0.22, 1, 0.36, 1],
      }}
    >
      {children}
    </motion.div>
  )
}

export default AnimationFadeSlideIn
