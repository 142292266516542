import { useCallback, useEffect, useState } from 'react'
import {
  AggregationPeriod,
  GetUsageSeriesResponse,
} from '../../../backend/types'
import useBackend from '../../services/useBackend'
import { instanceIdAtom } from '../../../state/state'
import { useAtomValue } from 'jotai'
import { BackendService } from '../../../backend/backend'
import { captureAxiosError } from '../../../utils/obeservability'

export type UsageRange = 'last_day' | 'last_week' | 'last_month'
export type UsageMode = 'cumulative' | 'none'
export type UsageType = 'logs' | 'metrics'

const useLoadUsageSeries = (
  range: UsageRange,
  aggregationMode: UsageMode,
  type: UsageType,
): GetUsageSeriesResponse | null => {
  const backend = useBackend()

  const { organizationId } = useAtomValue(instanceIdAtom)

  const [usage, setUsage] = useState<GetUsageSeriesResponse | null>(null)

  const loadUsage = useCallback(async () => {
    if (!organizationId) return
    try {
      const startDate = getFlooredStartDate(range)
      const endDate = getEndDate()
      const aggregationPeriod = getAggregationPeriod(range)
      const response = await getBackendFunction(backend, type)(
        organizationId,
        startDate,
        endDate,
        aggregationPeriod,
        aggregationMode,
      )
      if (response === null) return
      setUsage(response)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, organizationId, range, aggregationMode, type])

  useEffect(() => {
    loadUsage()
  }, [loadUsage, range, aggregationMode])

  return usage
}

function getBackendFunction(backend: BackendService, type: UsageType) {
  switch (type) {
    case 'logs':
      return backend.getLogsUsageSeries
    case 'metrics':
      return backend.getMetricsUsageSeries
  }
}

function floorToHour(date: Date): Date {
  date.setMinutes(0, 0, 0)
  return date
}

function getFlooredStartDate(mode: UsageRange): string {
  const now = Date.now()
  let date: Date
  switch (mode) {
    case 'last_day':
      date = new Date(now - 24 * 60 * 60 * 1000)
      break
    case 'last_week':
      date = new Date(now - 6 * 24 * 60 * 60 * 1000)
      break
    case 'last_month':
      date = new Date(now - 30 * 24 * 60 * 60 * 1000)
      break
  }
  return floorToHour(date).toISOString()
}

function getEndDate(): string {
  return new Date().toISOString()
}

function getAggregationPeriod(mode: UsageRange): AggregationPeriod {
  switch (mode) {
    case 'last_day':
      return 'hour'
    case 'last_week':
    case 'last_month':
      return 'day'
  }
}

export default useLoadUsageSeries
