import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { logger } from '../../utils/obeservability'

const useLogPageVisit = () => {
  const location = useLocation()
  const previousPath = useRef<string | null>(null)

  useEffect(() => {
    const currentPath = location.pathname
    if (previousPath.current !== currentPath) {
      logger.info(formatNavLog(previousPath.current, currentPath))
    }
    previousPath.current = currentPath
  }, [location.pathname])
}

function formatNavLog(from: string | null, to: string) {
  if (!from) {
    return `user loaded page '${to}'`
  }
  return `user navigated from '${from}' to '${to}'`
}

export default useLogPageVisit
