import { createRef, useCallback, useEffect, useMemo, useState } from 'react'
import { Header } from '../../../../Logs'
import LogHeaderCell from './LogHeaderCell/LogHeaderCell'
import DraggingHaptics from './DraggingHaptics/DraggingHaptics'
import { Filter } from '../../../../../../../../backend/types'

interface LogHeaderRowProps {
  containerRef: React.RefObject<HTMLDivElement | null>

  headers: Header[]
  setHeaders: (headers: Header[]) => void
  toggleHeader: (header: string) => void

  filters: Filter[]
  setFilters: (filters: Filter[]) => void
}

const LogHeaderRow = ({
  containerRef,
  headers,
  setHeaders,
  toggleHeader,
  filters,
  setFilters,
}: LogHeaderRowProps) => {
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null)
  const [resizing, setResizing] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState<number | null>(null)
  const [overIndex, setOverIndex] = useState<number | null>(null)
  const [startingWidth, setStartingWidth] = useState<number | null>(null)
  const [startX, setStartX] = useState<number | null>(null)
  const [mouseDelta, setMouseDelta] = useState<number | null>(null)

  const headerRefs = useMemo(
    () => headers.map(() => createRef<HTMLDivElement>()),
    [headers],
  )

  const handleStartReorder = useCallback(
    (index: number, e: React.MouseEvent<HTMLDivElement>) => {
      setCursor('grabbing')
      setUpdatingIndex(index)
      setOverIndex(index)
      setStartX(e.clientX)
      setDragging(true)
    },
    [],
  )

  const handleStopReorder = useCallback(() => {
    if (updatingIndex !== null && overIndex !== null) {
      const newHeaders = [...headers]
      const [draggedHeader] = newHeaders.splice(updatingIndex, 1)
      newHeaders.splice(overIndex, 0, draggedHeader)
      setHeaders(newHeaders)
    }
    setDragging(false)
    setUpdatingIndex(null)
    setOverIndex(null)
    setStartingWidth(null)
    setStartX(null)
    setMouseDelta(null)
    resetCursor()
  }, [updatingIndex, headers, overIndex, setHeaders])

  const handleStartResize = useCallback(
    (index: number, e: React.MouseEvent<HTMLDivElement>) => {
      setCursor('ew-resize')
      setUpdatingIndex(index)
      setStartingWidth(headers[index].width)
      setStartX(e.clientX)
      setResizing(true)
    },
    [headers],
  )

  const handleStopResize = useCallback(() => {
    setResizing(false)
    setUpdatingIndex(null)
    setStartX(null)
    setMouseDelta(null)
    setStartingWidth(null)
    resetCursor()
  }, [])

  const handleMove = useCallback(
    (event: MouseEvent) => {
      if (dragging) {
        const mouseDelta = event.clientX - (startX ?? 0)
        const overIndex = getColumnIndex(event.clientX, containerRef, headers)
        setMouseDelta(mouseDelta)
        setOverIndex(overIndex)
      } else if (resizing) {
        const mouseDelta = event.clientX - (startX ?? 0)
        const newWidth = (startingWidth ?? 0) + mouseDelta
        setMouseDelta(mouseDelta)
        setHeaders(
          headers.map((h, i) =>
            i === updatingIndex ? { ...h, width: newWidth } : h,
          ),
        )
      }
    },
    [
      dragging,
      resizing,
      startX,
      containerRef,
      headers,
      startingWidth,
      setHeaders,
      updatingIndex,
    ],
  )

  useEffect(() => {
    if (!dragging && !resizing) return

    window.addEventListener('mousemove', handleMove)

    return () => {
      window.removeEventListener('mousemove', handleMove)
    }
  }, [dragging, handleMove, resizing])

  useEffect(() => {
    if (!dragging) return

    window.addEventListener('mouseup', handleStopReorder)

    return () => {
      window.removeEventListener('mouseup', handleStopReorder)
    }
  }, [dragging, handleStopReorder])

  useEffect(() => {
    if (!resizing) return

    window.addEventListener('mouseup', handleStopResize)
    return () => {
      window.removeEventListener('mouseup', handleStopResize)
    }
  }, [resizing, handleStopResize])

  return (
    <>
      <DraggingHaptics
        overIndex={overIndex}
        draggingIndex={updatingIndex}
        mouseDelta={mouseDelta}
        headers={headers}
        containerRef={containerRef}
      />
      <div className="sticky top-0 flex bg-panel-bg border-y border-panel-border z-[1]">
        {headers.map((header, index) => (
          <LogHeaderCell
            headerRef={headerRefs[index]}
            key={`${header.value}-${index}`}
            property={header.value}
            width={header.width}
            resizing={resizing}
            setResizing={(e) => handleStartResize(index, e)}
            setDragging={(e) => handleStartReorder(index, e)}
            selected={selectedProperty}
            setSelected={setSelectedProperty}
            filters={filters}
            setFilters={setFilters}
            toggleHeader={toggleHeader}
          />
        ))}
      </div>
    </>
  )
}

function getColumnIndex(
  mouseX: number,
  containerRef: React.RefObject<HTMLDivElement | null>,
  headers: Header[],
): number {
  const container = containerRef.current
  if (!container) return -1

  const rect = container.getBoundingClientRect()
  const scrollLeft = container.scrollLeft
  let relativeX = mouseX - rect.left + scrollLeft

  for (let i = 0; i < headers.length; i++) {
    if (relativeX < headers[i].width) {
      return i
    }
    relativeX -= headers[i].width
  }

  return headers.length - 1
}

const setCursor = (cursor: string) => {
  document.body.style.cursor = cursor
  document.body.style.userSelect = 'none'
}

const resetCursor = () => {
  document.body.style.cursor = 'default'
  document.body.style.userSelect = 'auto'
}

export default LogHeaderRow
