import { logger } from '../../utils/obeservability'
import { QueryRunner } from './queryRunner'
import {
  QueryParameters,
  QueryRequestState,
  QueryState,
  Response,
} from './types'
import {
  createInitialState,
  createQueryState,
  createLiveTickState,
  createLoadMoreDownState,
  MergeFunction,
  loadMoreDownMerge,
  defaultMerge,
  liveTickMerge,
  createLoadMoreUpState,
  loadMoreUpMerge,
  createViewContextState,
  viewContextMerge,
} from './utils'

export type QuerySubscriber = (queryState: QueryState) => void

type QueryFunction = (
  request: QueryRequestState,
  signal?: AbortSignal,
) => Promise<Response>

export class QueryManager {
  private queryRunner: QueryRunner

  private queryMap: { [key: number]: QueryState }
  private queryIndex: number

  private subscribers: { [key: string]: QuerySubscriber }

  constructor(queryRunner: QueryRunner) {
    this.queryRunner = queryRunner

    this.queryMap = {}
    this.queryIndex = 0
    this.subscribers = {}
  }

  subscribe = (key: string, subscriber: QuerySubscriber) => {
    this.subscribers[key] = subscriber
    subscriber(this.queryMap[this.getMaxQuery()])
  }

  unsubscribe = (key: string) => {
    delete this.subscribers[key]
  }

  init = async (params: QueryParameters) => {
    const index = this.queryIndex++
    const state = createInitialState(index, params)

    await this.runFunction(
      'init',
      state,
      state,
      this.queryRunner.init,
      defaultMerge,
    )
  }

  initWithFocus = async (params: QueryParameters) => {
    const index = this.queryIndex++
    const state = createInitialState(index, params)
    await this.runFunction(
      'init',
      state,
      state,
      this.queryRunner.viewContext,
      viewContextMerge,
    )
  }

  query = async (prevState: QueryState, params: QueryParameters) => {
    const index = this.queryIndex++
    const state = createQueryState(index, prevState, params)

    await this.runFunction(
      'query',
      prevState,
      state,
      this.queryRunner.query,
      defaultMerge,
    )
  }

  viewContext = async (prevState: QueryState, params: QueryParameters) => {
    const index = this.queryIndex++
    const state = createViewContextState(index, prevState, params)

    await this.runFunction(
      'viewContext',
      prevState,
      state,
      this.queryRunner.viewContext,
      viewContextMerge,
    )
  }

  loadMoreUp = async (prevState: QueryState) => {
    if (this.isLoadingMore()) return
    const index = this.queryIndex++
    const state = createLoadMoreUpState(index, prevState)

    await this.runFunction(
      'loadMoreUp',
      prevState,
      state,
      this.queryRunner.loadMoreUp,
      loadMoreUpMerge,
    )
  }

  loadMoreDown = async (prevState: QueryState) => {
    if (this.isLoadingMore()) return
    const index = this.queryIndex++
    const state = createLoadMoreDownState(index, prevState)

    await this.runFunction(
      'loadMoreDown',
      prevState,
      state,
      this.queryRunner.loadMoreDown,
      loadMoreDownMerge,
    )
  }

  liveTick = async (prevState: QueryState) => {
    const index = this.queryIndex++
    const state = createLiveTickState(index, prevState)

    await this.runFunction(
      'liveTick',
      prevState,
      state,
      this.queryRunner.query,
      liveTickMerge,
    )
  }

  cancelLiveTick = () => {
    for (const query of Object.values(this.queryMap)) {
      if (query.mode === 'live_tick') {
        query.controller.abort()
      }
    }
    this.publishMaxQuery()
  }

  private runFunction = async (
    name: string,
    prevState: QueryState,
    state: QueryState,
    queryFn: QueryFunction,
    mergeFn: MergeFunction,
  ) => {
    this.queryMap[state.index] = state
    this.cancelLowerQueries(state.index)
    this.publishMaxQuery()

    const result = await queryFn(state.request, state.controller.signal)
    if (result.result === 'canceled') {
      logger.info(`${name} canceled`)
      state.mode = 'none'
      this.publishMaxQuery()
      return
    }

    this.queryMap[state.index] = mergeFn(prevState, state, result)
    this.publishMaxQuery()
  }

  private cancelLowerQueries = (index: number): void => {
    for (const query of Object.values(this.queryMap)) {
      if (query.index < index) {
        query.controller.abort()
      }
    }
  }

  private publishMaxQuery = (): void => {
    const maxQuery = this.getMaxQuery()
    const queryState = this.queryMap[maxQuery]
    for (const subscriber of Object.values(this.subscribers)) {
      subscriber(queryState)
    }
  }

  private getMaxQuery = (): number => {
    return Object.values(this.queryMap).reduce((max, query) => {
      return Math.max(max, query.index)
    }, 0)
  }

  private isLoadingMore = (): boolean => {
    const maxQuery = Object.values(this.queryMap).reduce((max, query) => {
      return Math.max(max, query.index)
    }, 0)

    const queryState = this.queryMap[maxQuery]
    if (!queryState) return false

    return (
      queryState.mode === 'loading_more_down' ||
      queryState.mode === 'loading_more_up'
    )
  }
}
