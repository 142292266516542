import { useEffect, useRef, useState } from 'react'
import { cn } from '../../../../../../utils/cn'
import IconButton from '../../../../../library/IconButton/IconButton'
import { Check } from 'lucide-react'
import Checkbox from '../../../../../library/Checkbox/Checkbox'
import { AlertEvent, Alert, AlertStatus } from '../../../../../../backend/types'
import { formatNumber } from '../../../../../../utils/properties'
import { formatTimeAsDate } from '../../../../../../utils/time'
import useHovered from '../../../../../../hooks/utils/useHover'
import useLoadAlertEvents from '../../../../../../hooks/data/load/useLoadAlertEvents'
import useNav, { LinkKey } from '../../../../../../hooks/nav/useNav'

interface AlertRowProps {
  alert: Alert
  selected: boolean
  toggleAlert: () => void
  setClose: () => void
}

const AlertRow = ({
  alert,
  selected,
  toggleAlert,
  setClose,
}: AlertRowProps) => {
  const navigate = useNav()

  const [events, setEvents] = useState<AlertEvent[]>([])
  const loadAlertEvents = useLoadAlertEvents()

  const rowRef = useRef<HTMLDivElement>(null)
  const checkboxRef = useRef<HTMLDivElement>(null)
  const actionContainerRef = useRef<HTMLDivElement>(null)
  const hovered = useHovered(rowRef)
  const active = hovered || selected

  const handleClickRow = (e: React.MouseEvent) => {
    if (!canOpenLogs(events)) return
    if (actionContainerRef.current?.contains(e.target as Node)) return
    if (checkboxRef.current?.contains(e.target as Node)) return
    navigateToLogs(events, navigate)
  }

  useEffect(() => {
    loadAlertEvents(alert.id).then((events) => {
      setEvents(events)
    })
  }, [alert.id, loadAlertEvents])

  return (
    <div
      ref={rowRef}
      className={cn(
        'w-full h-8 px-3 flex-none flex items-center gap-6 border-b border-panel-border cursor-pointer hover:bg-input-bgTint hover:text-text-1 group',
        selected ? 'bg-input-tint hover:bg-input-tint' : 'bg-panel-bg',
        selected ? 'text-text-1' : 'text-text-2',
      )}
      onClick={handleClickRow}
    >
      <div className="flex-1 h-full pr-6 flex items-center gap-2 overflow-hidden">
        <Checkbox
          checkboxRef={checkboxRef}
          checked={selected}
          hidden={!active}
          toggle={toggleAlert}
        />
        <p className="text-mini-code overflow-hidden whitespace-nowrap text-ellipsis">
          {alert.title}
        </p>
      </div>
      <div
        className={cn(
          'w-[100px] flex-none text-mini-code',
          formatStatusColor(alert.status),
        )}
      >
        {formatStatus(alert.status)}
      </div>
      <div className="w-[100px] flex-none text-mini-code">
        {formatNumber(alert.event_count)}
      </div>
      <div
        ref={actionContainerRef}
        className="w-[120px] flex-none flex items-center gap-2 text-mini-code overflow-hidden"
      >
        <p className="overflow-hidden whitespace-nowrap text-ellipsis">
          {formatTimeAsDate(alert.last_event_time)}
        </p>
        {hovered && alert.status === 'open' && (
          <IconButton icon={<Check size={16} />} onClick={setClose} />
        )}
      </div>
    </div>
  )
}

function formatStatusColor(status: AlertStatus) {
  switch (status) {
    case 'open':
      return 'text-brand-cta'
    case 'closed':
      return 'text-text-2'
  }
}

function formatStatus(status: AlertStatus) {
  switch (status) {
    case 'open':
      return 'Open'
    case 'closed':
      return 'Closed'
    default:
      return status
  }
}

function canOpenLogs(events: AlertEvent[]) {
  if (!events.length) return false
  const firstEvent = events[0]
  const logId = firstEvent.attributes['log.id']
  const logTimestamp = firstEvent.attributes['log.timestamp']
  return logId && logTimestamp
}

function navigateToLogs(
  events: AlertEvent[],
  navigate: (key: LinkKey, params?: Record<string, string>) => void,
) {
  if (!canOpenLogs(events)) return
  const firstEvent = events[0]
  const logId = firstEvent.attributes['log.id']
  const logTimestamp = firstEvent.attributes['log.timestamp']
  navigate(LinkKey.Logs, { logId, logTimestamp })
}

export default AlertRow
