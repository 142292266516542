interface SolidButtonSmallProps {
  children: React.ReactNode
  disabled?: boolean
  onClick: () => void
}

const SolidButtonSmall = ({
  children,
  disabled,
  onClick,
}: SolidButtonSmallProps) => {
  return (
    <button
      className={`px-2 h-[28px] flex items-center gap-2 border border-input-border rounded text-mini-light text-text-1 bg-input-bgTint ${
        disabled ? 'opacity-60' : 'opacity-100 hover:bg-input-tint'
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default SolidButtonSmall
