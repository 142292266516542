import { useSearchParams } from 'react-router-dom'

export type NavLog = {
  logId: string | null
  logTimestamp: string | null
}

const useNavLog = (): [NavLog, (log: NavLog | null) => void] => {
  const [searchParams, setSearchParams] = useSearchParams()
  const logId = searchParams.get('logId')
  const logTimestamp = searchParams.get('logTimestamp')

  const setLog = (log: NavLog | null) => {
    if (log && log.logId && log.logTimestamp) {
      const params = new URLSearchParams(searchParams)
      params.set('logId', log.logId)
      params.set('logTimestamp', log.logTimestamp)
      setSearchParams(params)
    } else {
      const params = new URLSearchParams(searchParams)
      params.delete('logId')
      params.delete('logTimestamp')
      setSearchParams(params)
    }
  }

  return [{ logId, logTimestamp }, setLog]
}

export { useNavLog }
