import { useEffect } from 'react'
import useLoadLogDetails from '../load/useLoadLogDetails'

const useInitLogDetails = () => {
  const loadLogDetails = useLoadLogDetails()

  useEffect(() => {
    loadLogDetails()
  }, [loadLogDetails])
}

export default useInitLogDetails
