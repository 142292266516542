import { useSetAtom } from 'jotai'
import { instanceIdAtom } from '../../state/state'
import useBackend from '../services/useBackend'
import useNav, { LinkKey } from '../nav/useNav'
import { captureAxiosError } from '../../utils/obeservability'

const useSetInstance = () => {
  const backend = useBackend()
  const navigate = useNav()

  const setInstanceId = useSetAtom(instanceIdAtom)

  const switchInstance = async (
    organizationId: string,
    projectId: string | undefined,
  ) => {
    if (!organizationId) return
    try {
      await backend.authOrganization(organizationId)
      if (projectId) {
        await backend.authProject(organizationId, projectId)
        setInstanceId({ organizationId, projectId })
      } else {
        const { projects } = await backend.getProjects(organizationId)
        if (!projects.length) {
          navigate(LinkKey.NewProject)
        } else {
          const projectId = projects[0].id
          await backend.authProject(organizationId, projectId)
          setInstanceId({ organizationId, projectId })
        }
      }
    } catch (e) {
      captureAxiosError(e)
    }
  }

  return switchInstance
}

export default useSetInstance
