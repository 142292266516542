import { Building2, Plus } from 'lucide-react'
import NavPopup from '../NavPopup/NavPopup'
import { useRef, useState } from 'react'
import { instanceIdAtom } from '../../../state/state'
import useSetInstance from '../../../hooks/actions/useSetInstance'
import { useAtomValue } from 'jotai'
import { organizationsAtom } from '../../../state/state'
import useNav, { LinkKey } from '../../../hooks/nav/useNav'
import OutlineIconTextButton from '../OutlineIconTextButton/OutlineIconTextButton'

const OrganizationButton = () => {
  const navigate = useNav()
  const switchInstance = useSetInstance()

  const organizations = useAtomValue(organizationsAtom)
  const { organizationId } = useAtomValue(instanceIdAtom)

  const [isOrgOpen, setIsOrgOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  return (
    <div className="relative">
      <OutlineIconTextButton
        buttonRef={buttonRef}
        active={isOrgOpen}
        onClick={() => setIsOrgOpen(!isOrgOpen)}
      >
        <Building2 size={16} />
        {organizationId
          ? organizations?.find((o) => o.id === organizationId)?.name
          : 'Organization'}
      </OutlineIconTextButton>
      {isOrgOpen && (
        <NavPopup
          title="Organization"
          buttonRef={buttonRef}
          selected={organizationId}
          options={[
            ...(organizations?.map((o) => ({
              title: o.name,
              value: o.id,
              icon: <Building2 size={16} />,
              onClick: async () => {
                await switchInstance(o.id, undefined)
                setIsOrgOpen(false)
                window.location.reload()
              },
            })) ?? []),
            {
              title: 'New Organization',
              value: 'new-organization',
              icon: <Plus size={16} />,
              onClick: () => navigate(LinkKey.NewOrganization),
            },
          ]}
          close={() => setIsOrgOpen(false)}
        />
      )}
    </div>
  )
}

export default OrganizationButton
