import { useAtomValue } from 'jotai'
import useDeleteProject from '../../../../../../hooks/actions/useDeleteProject'
import { instanceIdAtom } from '../../../../../../state/state'
import { projectsAtom } from '../../../../../../state/state'
import useUpdateProjectName from '../../../../../../hooks/actions/useUpdateProjectName'
import useInitOrganizationMembers from '../../../../../../hooks/data/effects/useInitOrganizationMemebers'
import useInitTokens from '../../../../../../hooks/data/effects/useInitTokens'
import SettingsSection from '../../../../../library/SettingsSection/SettingsSection'
import SettingsTitle from '../../../../../library/SettingsTitle/SettingsTitle'
import TextInput from '../../../../../library/TextInput/TextInput'
import { useMemo, useState } from 'react'
import CTAButton from '../../../../../library/CTAButton/CTAButton'
import DeleteProjectPopup from './DeleteProjectPopup/DeleteProjectPopup'
import useNav, { LinkKey } from '../../../../../../hooks/nav/useNav'

const ProjectSettings = () => {
  const navigate = useNav()

  useInitOrganizationMembers()
  useInitTokens()

  const deleteProject = useDeleteProject()
  const updateProjectName = useUpdateProjectName()

  const instanceId = useAtomValue(instanceIdAtom)
  const projects = useAtomValue(projectsAtom)
  const project = useMemo(
    () => projects?.find((p) => p.id === instanceId.projectId),
    [projects, instanceId.projectId],
  )

  const [projectName, setProjectName] = useState(project?.name ?? '')
  const [isDeletingProject, setIsDeletingProject] = useState(false)

  const handleSaveProjectName = async () => {
    const name = projectName.trim()
    if (!project?.id || !name) return
    await updateProjectName(projectName)
  }

  const handleDeleteProject = async () => {
    if (!instanceId.projectId) return
    await deleteProject(instanceId.projectId)
    setIsDeletingProject(false)
    navigate(LinkKey.Logs)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSaveProjectName()
      e.currentTarget.blur()
    }
  }

  return (
    <div className="flex-1 px-12 py-12 flex flex-col items-center gap-6 overflow-y-auto">
      <SettingsSection>
        <SettingsTitle
          title="Project Name"
          description="We recommend having one project per environment. (ex. local, dev, prod)"
        />
        <div className="py-4 flex flex-col items-start justify-center gap-1">
          <div className="flex gap-4">
            <div className="w-[264px]">
              <TextInput
                value={projectName}
                onChange={setProjectName}
                onKeyDown={handleKeyDown}
              />
            </div>
            <CTAButton
              disabled={!isProjectNameValid(project?.name ?? '', projectName)}
              onClick={handleSaveProjectName}
            >
              Save
            </CTAButton>
          </div>
          <p className="text-label text-text-2">Max 32 characters</p>
        </div>
      </SettingsSection>

      {projects && projects.length > 1 && (
        <SettingsSection>
          <SettingsTitle
            title="Delete Project"
            description="This will delete your project and all data associated with it."
          />
          <div className="py-4 w-full flex flex-col items-start justify-center gap-1">
            <div className="w-full flex items-center justify-between">
              <p className="text-body text-brand-error">
                Warning: This cannot be undone.
              </p>
              <div className="flex items-center justify-center">
                <CTAButton
                  mode="error"
                  onClick={() => setIsDeletingProject(true)}
                >
                  Delete Project
                </CTAButton>
              </div>
            </div>
          </div>
        </SettingsSection>
      )}

      {isDeletingProject && (
        <DeleteProjectPopup
          name={project?.name ?? ''}
          close={() => setIsDeletingProject(false)}
          onSubmit={handleDeleteProject}
        />
      )}
    </div>
  )
}

function isProjectNameValid(currentProjectName: string, projectName: string) {
  return (
    projectName.trim().length > 0 &&
    projectName.trim().length < 32 &&
    projectName.trim() !== currentProjectName
  )
}

export default ProjectSettings
