import { useEffect, useRef } from 'react'
import useSelectOnFocus from '../../../hooks/utils/useSelectOnFocus'

interface TextInputSmallProps {
  placeholder?: string
  value: string
  onUpdateValue: (value: string) => void
  onSubmit?: (value: string) => void
  autoFocus?: boolean
}

const TextInputSmall = ({
  value,
  placeholder = '',
  onUpdateValue,
  onSubmit,
  autoFocus = false,
}: TextInputSmallProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  useSelectOnFocus(inputRef)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      inputRef.current?.blur()
      onSubmit?.(value)
    }
  }

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.select()
    }
  }

  const handleBlur = () => {
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [autoFocus])

  return (
    <input
      ref={inputRef}
      className="w-full h-[28px] p-2 text-input text-text-1 bg-input-bgTint border border-input-border rounded placeholder:text-text-2 hover:bg-input-tint focus:bg-input-tint outline-none"
      value={value}
      placeholder={placeholder}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={(e) => onUpdateValue(e.target.value)}
      onKeyDown={handleKeyDown}
    />
  )
}

export default TextInputSmall
