import { useRef, useState } from 'react'
import usePopupStack from '../../../hooks/utils/usePopupStack'
import usePopupPauseScroll from '../../../hooks/utils/usePopupPauseScroll'
import usePopupPosition from '../../../hooks/utils/usePopupPosition'
import Portal from '../Portal/Portal'
import { cn } from '../../../utils/cn'
import { ChevronDown, ChevronRight } from 'lucide-react'
import OutlineButton from '../OutlineButton/OutlineButton'

export type SolidDropdownOption<T> = {
  label: string
  value: T
}

interface SolidDropdownProps<T> {
  placeholder?: string
  disabled?: boolean

  options: SolidDropdownOption<T>[]
  selected: T
  onSelect: (value: T) => void | Promise<void>
}

const SolidDropdown = <T,>({
  placeholder,
  disabled,
  options,
  selected,
  onSelect,
}: SolidDropdownProps<T>) => {
  const selectedOption = options.find((option) => option.value === selected)

  const portalRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  usePopupStack(portalRef, () => setIsOpen(false), buttonRef)
  usePopupPauseScroll(portalRef, isOpen)
  const position = usePopupPosition(
    isOpen,
    buttonRef,
    popupRef,
    'bottom-left',
    { y: 8 },
  )

  const handleSelect = async (value: T) => {
    await onSelect(value)
    setIsOpen(false)
  }

  return (
    <>
      <button
        ref={buttonRef}
        className={cn(
          'px-[10px] w-full h-8 flex-none flex items-center border border-input-border rounded-[4px] text-small-light text-text-1 bg-input-bgTint',
          disabled ? 'opacity-60' : 'hover:bg-input-tint opacity-100',
        )}
        disabled={disabled}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-1 items-center justify-between gap-2 overflow-hidden">
          <p
            className={cn(
              'pl-1 text-small-light overflow-hidden text-ellipsis whitespace-nowrap',
              selectedOption ? 'text-text-1' : 'text-text-2',
            )}
          >
            {selectedOption?.label ?? placeholder}
          </p>
          {isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
        </div>
      </button>
      {isOpen && (
        <Portal ref={portalRef}>
          <div
            ref={popupRef}
            className={cn(
              'absolute max-h-[180px] p-2 flex flex-col gap-1 overflow-y-auto bg-popup-bg border border-popup-border rounded shadow-popup-shadow',
            )}
            style={{ ...position, width: buttonRef.current?.offsetWidth }}
          >
            {options.map((option) => (
              <OutlineButton
                key={option.label}
                onClick={() => handleSelect(option.value)}
              >
                {option.label}
              </OutlineButton>
            ))}
          </div>
        </Portal>
      )}
    </>
  )
}

export default SolidDropdown
