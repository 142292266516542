import { useState } from 'react'
import { cn } from '../../../utils/cn'

interface PopupToggleProps {
  icon: React.ReactNode
  title: string

  value: boolean
  onUpdateValue: (value: boolean) => void
}

const PopupToggle = ({
  icon,
  title,
  value,
  onUpdateValue,
}: PopupToggleProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleUpdateValue = (update: boolean) => {
    onUpdateValue(update)
    setIsOpen(false)
  }

  return (
    <button
      className={cn(
        'w-full h-[28px] px-2 flex items-center justify-between gap-2 rounded group',
        isOpen ? 'bg-input-tint' : 'hover:bg-input-tint',
      )}
      onClick={() => handleUpdateValue(!value)}
    >
      <div className="flex flex-none items-center gap-2 text-text-1">
        {icon}
        <p className="text-small-light">{title}</p>
      </div>
      <div
        className={cn(
          'flex flex-1 items-center justify-end gap-2 overflow-hidden',
          isOpen ? 'text-text-1' : 'text-text-2 group-hover:text-text-1',
        )}
      >
        <div
          className={cn(
            'w-[33px] h-[17px] p-0.5 flex items-center rounded-full bg-input-bgTint border border-input-border',
            value
              ? 'bg-input-tint justify-end'
              : 'bg-input-bgTint justify-start',
          )}
        >
          <div
            className={cn(
              'w-3 h-3 rounded-full bg-text-1 border border-input-border',
              value ? 'opacity-100' : 'opacity-50',
            )}
          />
        </div>
      </div>
    </button>
  )
}

export default PopupToggle
