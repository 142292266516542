export const scrollToElement = (
  elementId: string,
  containerId: string,
  behavior: ScrollBehavior = 'instant',
) => {
  const element = document.getElementById(elementId)
  const container = document.getElementById(containerId)
  if (!element || !container) return

  const elementRect = element.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  const relativePosition = elementRect.top - containerRect.top
  const containerVisibleHeight = containerRect.height
  const elementHeight = elementRect.height
  const scrollPosition =
    container.scrollTop +
    relativePosition -
    (containerVisibleHeight / 2 - elementHeight / 2)
  const clampedScrollPosition = Math.max(
    0,
    Math.min(scrollPosition, container.scrollHeight - containerVisibleHeight),
  )

  container.scrollTo({
    top: clampedScrollPosition,
    behavior: behavior,
  })
}
