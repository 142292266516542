import { Filter, LogCursor, OrderBy } from '../../backend/types'
import { LogEntry } from '../../state/types'
import { TimeRange } from '../../utils/time'

export const SERVICE_FIELD = 'service.name'
export const LEVEL_FIELD = 'severity_name'

export type QueryMode =
  | 'initial_load'
  | 'user_query'
  | 'view_context'
  | 'live_tick'
  | 'loading_more_down'
  | 'loading_more_up'
  | 'none'

export type QueryParameters = {
  term: string
  time_range: TimeRange
  filters: Filter[]
  cursor_start: LogCursor | undefined
  cursor_end: LogCursor | undefined
  cursor_focus: LogCursor | undefined
  order_by: OrderBy | undefined
  limit: number
}

export type QueryRequestState = {
  term: string
  time_range: TimeRange
  filters: Filter[]
  cursor_start: LogCursor | undefined
  cursor_end: LogCursor | undefined
  cursor_focus: LogCursor | undefined
  order_by: OrderBy | undefined
  limit: number
}

export type InitResponseSuccess = {
  result: 'success'
  type: 'init'
  count: number
  logs: LogEntry[]
  properties: string[]
  serviceOptions: string[]
  levelOptions: string[]
}

export type InitResponseCanceled = {
  result: 'canceled'
  type: 'init'
}

export type QueryResponseSuccess = {
  result: 'success'
  type: 'query'
  count: number
  logs: LogEntry[]
  properties: string[]
  serviceOptions: string[]
  levelOptions: string[]
}

export type QueryResponseCanceled = {
  result: 'canceled'
  type: 'query'
}

export type ViewContextResponseSuccess = {
  result: 'success'
  type: 'viewContext'
  count: number
  log: LogEntry | null
  logsBefore: LogEntry[]
  logsAfter: LogEntry[]
  properties: string[]
  serviceOptions: string[]
  levelOptions: string[]
}

export type ViewContextResponseCanceled = {
  result: 'canceled'
  type: 'viewContext'
}

export type LiveTickResponseSuccess = {
  result: 'success'
  type: 'liveTick'
  count: number
  logs: LogEntry[]
  properties: string[]
  serviceOptions: string[]
  levelOptions: string[]
}

export type LiveTickResponseCanceled = {
  result: 'canceled'
  type: 'liveTick'
}

export type LoadMoreResponseSuccess = {
  result: 'success'
  type: 'loadMore'
  logs: LogEntry[]
}

export type LoadMoreResponseCanceled = {
  result: 'canceled'
  type: 'loadMore'
}

export type InitResponse = InitResponseSuccess | InitResponseCanceled

export type QueryResponse = QueryResponseSuccess | QueryResponseCanceled

export type ViewContextResponse =
  | ViewContextResponseSuccess
  | ViewContextResponseCanceled

export type LiveTickResponse =
  | LiveTickResponseSuccess
  | LiveTickResponseCanceled

export type LoadMoreResponse =
  | LoadMoreResponseSuccess
  | LoadMoreResponseCanceled

export type ResponseSuccess =
  | InitResponseSuccess
  | QueryResponseSuccess
  | ViewContextResponseSuccess
  | LiveTickResponseSuccess
  | LoadMoreResponseSuccess

export type Response =
  | InitResponse
  | QueryResponse
  | ViewContextResponse
  | LiveTickResponse
  | LoadMoreResponse

export type QueryResultState = {
  count: number
  logs: LogEntry[]
  properties: string[]
  serviceOptions: string[]
  levelOptions: string[]
  newLogs: string[]
  atStart: boolean
  atEnd: boolean
  shouldFocus: boolean
}

export type QueryState = {
  index: number
  previousMode: QueryMode
  mode: QueryMode
  request: QueryRequestState
  controller: AbortController
  result: QueryResultState | null
}
