import { useAtomValue } from 'jotai'
import { AnalyticsSettingsCounter } from '../../../backend/types'
import { instanceIdAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback, useEffect, useState } from 'react'
import { captureAxiosError } from '../../../utils/obeservability'

type CounterValue = {
  value: number
  previousValue: number
}

const useLoadAnalyticsCounterValue = (
  startTime: string | undefined,
  endTime: string | undefined,
  settings: AnalyticsSettingsCounter,
  refreshing?: boolean,
): CounterValue => {
  const backend = useBackend()

  const { projectId } = useAtomValue(instanceIdAtom)

  const [firstLoad, setFirstLoad] = useState(true)
  const [value, setValue] = useState<CounterValue>({
    value: 0,
    previousValue: 0,
  })

  const loadAnalyticsCounterValues = useCallback(async () => {
    try {
      if (!projectId) return
      const response = await backend.metricsQueryValue({
        project_id: projectId,
        metric_name: settings.metric_name,
        start_time: startTime ?? new Date().toISOString(),
        end_time: endTime ?? new Date().toISOString(),
        aggregation_function: settings.aggregation_function,
      })
      setValue({
        value: response.value,
        previousValue: response.previous_value,
      })
    } catch (e) {
      captureAxiosError(e)
    }
  }, [
    backend,
    projectId,
    settings.metric_name,
    settings.aggregation_function,
    startTime,
    endTime,
  ])

  useEffect(() => {
    if (firstLoad) return
    loadAnalyticsCounterValues()
  }, [settings, loadAnalyticsCounterValues, firstLoad])

  useEffect(() => {
    if (!refreshing && !firstLoad) return
    loadAnalyticsCounterValues()
    setFirstLoad(false)
  }, [loadAnalyticsCounterValues, refreshing, firstLoad, settings])

  return value
}

export default useLoadAnalyticsCounterValue
