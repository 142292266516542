type onClickType =
  | (() => void | Promise<void>)
  | ((e: React.FormEvent) => Promise<void>)

interface SolidButtonProps {
  children: React.ReactNode
  disabled?: boolean
  onClick?: onClickType
}

const SolidButton = ({ children, disabled, onClick }: SolidButtonProps) => {
  return (
    <button
      className={`px-[14px] h-8 flex-none flex items-center border border-input-border rounded-[4px] text-small-light text-text-1 bg-input-bgTint ${disabled ? 'opacity-60' : 'hover:bg-input-tint opacity-100'}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default SolidButton
