import { useRef } from 'react'
import useClickOutside from '../../../../../../../../hooks/utils/useClickOutside'
import PopupCodeButton from '../../../../../../../library/PopupCodeButton/PopupCodeButton'
import { FilterOperator } from '../../../../../../../../backend/types'
import {
  COLUMN_BODY,
  COLUMN_LEVEL,
  COLUMN_TIMESTAMP,
  operatorToDisplay,
} from '../../../../../../../../utils/properties'

interface FilterOperatorPopupProps {
  property: string
  setOperator: (operator: FilterOperator) => void

  buttonRef: React.RefObject<HTMLButtonElement | null>
  close: () => void
}

const FilterOperatorPopup = ({
  property,
  setOperator,
  buttonRef,
  close,
}: FilterOperatorPopupProps) => {
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, close, buttonRef)

  const handleSetOperator = (operator: FilterOperator) => {
    setOperator(operator)
    close()
  }

  const operatorOptions = getOperatorOptions(property)

  return (
    <div
      ref={ref}
      className="absolute top-[calc(100%+4px)] left-0 w-[200px] bg-popup-bg border border-popup-border rounded-[4px] shadow-popup-shadow flex flex-col z-[2]"
    >
      <div className="px-4 py-2 w-full flex items-center gap-1 border-b border-popup-border">
        <p className="text-text-2 text-small-code">operator</p>
      </div>
      <div className="p-2 w-full flex flex-col items-center gap-1">
        {operatorOptions.map((operator) => (
          <PopupCodeButton
            key={operator}
            text={operatorToDisplay(operator)}
            active={true}
            onClick={() => handleSetOperator(operator)}
          />
        ))}
      </div>
    </div>
  )
}

const getOperatorOptions = (property: string): FilterOperator[] => {
  switch (property) {
    case COLUMN_TIMESTAMP:
      return ['equals', 'not_equals']
    case COLUMN_LEVEL:
      return ['equals', 'not_equals']
    case COLUMN_BODY:
      return ['contains', 'not_contains', 'equals', 'not_equals', 'regex']
    default:
      return [
        'equals',
        'not_equals',
        'exists',
        'not_exists',
        'contains',
        'not_contains',
        'regex',
      ]
  }
}

export default FilterOperatorPopup
