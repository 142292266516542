import NavbarPlaceholder from '../../../library/NavbarPlaceholder/NavbarPlaceholder'
import SetupLogoButton from '../../../library/SetupLogoButton/SetupLogoButton'
import LogoIcon from '../../../library/LogoIcon/LogoIcon'
import useNav, { LinkKey } from '../../../../hooks/nav/useNav'

const SetupAlerts = () => {
  const navigate = useNav()
  const alertOptions = alertLanguageOptions(navigate)

  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-start">
      <NavbarPlaceholder />
      <div className="px-6 py-12 w-full h-full max-w-[800px] flex flex-col items-start justify-start gap-8">
        <div className="w-full flex flex-col items-start justify-start gap-2">
          <h1 className="text-h1 text-text-1">Alerts Setup</h1>
        </div>
        {alertOptions.length > 0 && (
          <>
            <div className="w-full flex flex-col items-start justify-start gap-2">
              <h2 className="text-h2 text-text-1">Language</h2>
              <p className="text-body text-text-2">
                Send alerts by using an SDK and adding a few lines of code to
                your app.
              </p>
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-start">
              {alertOptions.map((option) => (
                <SetupLogoButton
                  key={option.title}
                  icon={option.icon}
                  title={option.title}
                  options={option.options}
                  onClick={option.onClick}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

function alertLanguageOptions(navigate: (key: LinkKey) => void) {
  return [
    {
      icon: <LogoIcon logo="go" />,
      title: 'Go',
      options: [],
      onClick: () => navigate(LinkKey.SetupAlertsGo),
    },
  ]
}

export default SetupAlerts
