import { useAtomValue } from 'jotai'
import useSetBrandColor from '../../../../hooks/utils/useSetBrandColor'
import { metricDetailsAtom, metricNamesAtom } from '../../../../state/state'
import NavbarPlaceholder from '../../../library/NavbarPlaceholder/NavbarPlaceholder'
import MetricsSidebar from './MetricsSidebar/MetricsSidebar'
import { useEffect } from 'react'
import { useNavMetric } from '../../../../hooks/nav/useNavMetric'
import useInitMetricsNames from '../../../../hooks/data/effects/useInitMetricsNames'
import useInitMetricsDetails from '../../../../hooks/data/effects/useInitMetricsDetails'
import MetricDisplay from './MetricDisplay/MetricDisplay'
import MetricsOnboardingDisplay from './MetricsOnboardingDisplay/MetricsOnboardingDisplay'
import useInitHasData from '../../../../hooks/data/effects/useInitHasData'

const Metrics = () => {
  useSetBrandColor('success')
  useInitHasData()
  useInitMetricsNames()
  useInitMetricsDetails()

  const metricNames = useAtomValue(metricNamesAtom)
  const metricDetails = useAtomValue(metricDetailsAtom)
  const [navMetric, setNavMetric] = useNavMetric()

  useEffect(() => {
    if (metricNames.length > 0 && !navMetric?.name) {
      setNavMetric({ name: metricNames[0] })
    }
  }, [metricNames, setNavMetric, navMetric?.name])

  return (
    <div className="flex flex-col w-full h-full">
      <NavbarPlaceholder />
      <div className="flex-1 flex items-center overflow-hidden">
        <MetricsSidebar
          metricNames={metricNames}
          navMetric={navMetric?.name ?? null}
          setNavMetric={(name) => setNavMetric({ name })}
        />
        {navMetric?.name && metricDetails && (
          <div className="p-6 h-full flex-1 flex flex-col items-start gap-6 overflow-hidden">
            {metricDetails && (
              <MetricDisplay
                metricName={navMetric.name}
                metricDetails={metricDetails}
              />
            )}
          </div>
        )}
      </div>
      <MetricsOnboardingDisplay />
    </div>
  )
}

export default Metrics
