import { useSearchParams } from 'react-router-dom'

type project = {
  id: string | null
}

const useNavProject = (): [
  project | null,
  (project: project | null) => void,
] => {
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get('projectId')

  const setProject = (project: project | null) => {
    if (project) {
      const params = new URLSearchParams(searchParams)
      if (project.id !== null) {
        params.set('projectId', project.id)
      } else {
        params.delete('projectId')
      }
      setSearchParams(params)
    } else {
      const params = new URLSearchParams(searchParams)
      params.delete('projectId')
      setSearchParams(params)
    }
  }

  return [{ id }, setProject]
}

export { useNavProject }
