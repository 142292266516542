import { Link } from 'react-router-dom'
import { cn } from '../../../utils/cn'

export type TabDetails<T> = {
  title: string
  value: T
  mode: 'info' | 'warning' | 'error' | 'success' | 'trace'
  href: string
}

interface MainTabsProps<T> {
  tabs: TabDetails<T>[]
  activeTab: T
}

const MainTabs = <T,>({ tabs, activeTab }: MainTabsProps<T>) => {
  return (
    <div className="flex">
      {tabs.map((tab) => (
        <Tab
          key={tab.title}
          title={tab.title}
          href={tab.href}
          value={tab.value}
          mode={tab.mode}
          active={activeTab === tab.value}
        />
      ))}
    </div>
  )
}

interface TabProps<T> {
  title: string
  value: T
  href: string
  mode: 'info' | 'warning' | 'error' | 'success' | 'trace'
  active: boolean
}

const Tab = <T,>({ title, href, value, mode, active }: TabProps<T>) => {
  const bgClass = active ? getTabBgColor(mode) : 'bg-transparent'
  const borderClass = active ? getBorderColor(mode) : 'border-transparent'
  const textClass = active ? getTabTextColor(mode) : 'text-text-2'
  const textHoverClass = getTabTextHoverColor(mode)

  return (
    <Link
      className={cn(
        'h-12 px-3 flex items-center justify-center border-b text-input outline-none',
        bgClass,
        borderClass,
        textClass,
        textHoverClass,
      )}
      to={href}
    >
      {title}
    </Link>
  )
}

const getTabBgColor = (
  mode: 'info' | 'warning' | 'error' | 'success' | 'trace',
) => {
  switch (mode) {
    case 'trace':
      return 'bg-brand-traceTint'
    case 'success':
      return 'bg-brand-successTint'
    case 'error':
      return 'bg-brand-errorTint'
    case 'warning':
      return 'bg-brand-warningTint'
    case 'info':
      return 'bg-brand-infoTint'
    default:
      return 'bg-transparent'
  }
}

const getBorderColor = (
  mode: 'info' | 'warning' | 'error' | 'success' | 'trace',
) => {
  switch (mode) {
    case 'info':
      return 'border-brand-info'
    case 'warning':
      return 'border-brand-warning'
    case 'error':
      return 'border-brand-error'
    case 'success':
      return 'border-brand-success'
    case 'trace':
      return 'border-brand-trace'
  }
}

const getTabTextColor = (
  mode: 'info' | 'warning' | 'error' | 'success' | 'trace',
) => {
  switch (mode) {
    case 'info':
      return 'text-brand-info'
    case 'warning':
      return 'text-brand-warning'
    case 'error':
      return 'text-brand-error'
    case 'success':
      return 'text-brand-success'
    case 'trace':
      return 'text-brand-trace'
  }
}

const getTabTextHoverColor = (
  mode: 'info' | 'warning' | 'error' | 'success' | 'trace',
) => {
  switch (mode) {
    case 'info':
      return 'hover:text-brand-info'
    case 'warning':
      return 'hover:text-brand-warning'
    case 'error':
      return 'hover:text-brand-error'
    case 'success':
      return 'hover:text-brand-success'
    case 'trace':
      return 'hover:text-brand-trace'
  }
}

export default MainTabs
