import { useAtomValue, useSetAtom } from 'jotai'
import { instanceIdAtom, logDetailsAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'
import { useNavLog } from '../../nav/useNavLog'
import { captureAxiosError } from '../../../utils/obeservability'

const useLoadLogDetails = () => {
  const backend = useBackend()

  const { projectId } = useAtomValue(instanceIdAtom)
  const [navLog] = useNavLog()
  const setLogDetails = useSetAtom(logDetailsAtom)

  const loadLogDetails = useCallback(async () => {
    if (!navLog.logId || !projectId) return
    try {
      const { log } = await backend.logsDetails({
        project_id: projectId,
        log_id: navLog.logId,
      })
      setLogDetails(log ?? null)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, navLog, projectId, setLogDetails])

  return loadLogDetails
}

export default useLoadLogDetails
