import { useEffect, useRef } from 'react'
import PopupCodeButton from '../../../../../../library/PopupCodeButton/PopupCodeButton'

interface RecentSearchesProps {
  selectedIndex: number
  handleSelectRecent: (term: string) => void
  searches: string[]
  filteredSearches: string[]
}

const RecentSearches = ({
  selectedIndex,
  handleSelectRecent,
  searches,
  filteredSearches,
}: RecentSearchesProps) => {
  const recentSearchesRef = useRef<HTMLDivElement>(null)
  const selectedItemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      selectedIndex >= 0 &&
      selectedItemRef.current &&
      recentSearchesRef.current
    ) {
      const container = recentSearchesRef.current
      const item = selectedItemRef.current

      const containerRect = container.getBoundingClientRect()
      const itemRect = item.getBoundingClientRect()

      if (itemRect.bottom > containerRect.bottom) {
        container.scrollTop += itemRect.bottom - containerRect.bottom + 8
      } else if (itemRect.top < containerRect.top) {
        container.scrollTop -= containerRect.top - itemRect.top + 8
      }
    }
  }, [selectedIndex])

  return (
    <div
      ref={recentSearchesRef}
      className="p-2 max-h-[180px] absolute top-10 left-0 right-0 flex flex-col gap-2 bg-popup-bg border border-popup-border rounded-[4px] overflow-y-auto z-[2]"
      onMouseDown={(e) => e.preventDefault()}
    >
      {searches.length === 0 && (
        <div className="px-2 h-[28px] flex items-center text-small-code text-text-2">
          No recent searches
        </div>
      )}
      {searches.length > 0 && filteredSearches.length === 0 && (
        <div className="px-2 h-[28px] flex items-center text-small-code text-text-2">
          No matching recent searches
        </div>
      )}
      {filteredSearches.map((search, index) => (
        <div
          key={`recent-${index}`}
          ref={selectedIndex === index ? selectedItemRef : undefined}
        >
          <PopupCodeButton
            text={search}
            selected={selectedIndex === index}
            onClick={() => handleSelectRecent(search)}
          />
        </div>
      ))}
    </div>
  )
}

export default RecentSearches
