import { useAtomValue } from 'jotai'
import { hasMetricsAtom } from '../../../../../state/state'
import EmptyDataPlaceholder from '../../../../library/EmptyDataPlaceholder/EmptyDataPlaceholder'
import { LinkKey } from '../../../../../hooks/nav/useNav'

const MetricsOnboardingDisplay = () => {
  const hasMetrics = useAtomValue(hasMetricsAtom)
  return (
    hasMetrics === false && (
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center pointer-events-none">
        <EmptyDataPlaceholder
          imgUrl={'/onboarding/metrics.svg'}
          title="Metrics"
          paragraphs={[
            'Vigilant lets you track activity in your app with custom metrics.',
            'Get started by sending metrics from your app.',
          ]}
          ctaText="Setup Metrics"
          ctaLink={LinkKey.SetupMetrics}
        />
      </div>
    )
  )
}

export default MetricsOnboardingDisplay
