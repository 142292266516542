import { RefObject, useEffect } from 'react'

const useFocusOnHotkey = (
  inputRef: RefObject<HTMLInputElement | null>,
  hotkey: string = '/',
  selectOnFocus: boolean = true,
) => {
  useEffect(() => {
    const input = inputRef.current
    if (!input) return
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === hotkey && document.activeElement !== input) {
        event.preventDefault()
        input.focus()
        if (selectOnFocus) {
          input.select()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [inputRef, selectOnFocus, hotkey])
}

export default useFocusOnHotkey
