import { useRef, useState } from 'react'
import useClickOutside from '../../../../../../../hooks/utils/useClickOutside'
import Fullscreen from '../../../../../../library/Fullscreen/Fullscreen'
import PanelSection from '../../../../../../library/PanelSection/PanelSection'
import PopupContainer from '../../../../../../library/PopupContainer/PopupContainer'
import { X } from 'lucide-react'
import CTAButton from '../../../../../../library/CTAButton/CTAButton'
import { OrganizationMember } from '../../../../../../../backend/types'
import Dropdown from '../../../../../../library/Dropdown/Dropdown'

interface TransferOwnershipPopupProps {
  members: OrganizationMember[]
  onSubmit: (userId: string) => Promise<void>
  close: () => void
}

const TransferOwnershipPopup: React.FC<TransferOwnershipPopupProps> = ({
  members,
  onSubmit,
  close,
}) => {
  const popupRef = useRef<HTMLDivElement>(null)
  useClickOutside(popupRef, () => close())

  const currentOwner = members.find((m) => m.role === 'owner')
  const [selectedMember, setSelectedMember] =
    useState<OrganizationMember | null>(null)

  const handleSubmit = async () => {
    if (!selectedMember) return
    await onSubmit(selectedMember.user_id)
  }

  return (
    <Fullscreen>
      <PopupContainer popupRef={popupRef} className="w-[380px]">
        <PanelSection>
          <div className="w-full flex justify-between items-center">
            <h2 className="text-h2 text-text-1">Transfer Ownership</h2>
            <X
              className="w-4 h-4 text-text-2 hover:text-text-1 cursor-pointer"
              onClick={close}
            />
          </div>
        </PanelSection>
        <PanelSection className="gap-2">
          <p className="text-body text-text-1">
            Select a new owner for the organization.
          </p>
          <Dropdown
            options={members
              .filter((m) => m.user_id !== currentOwner?.user_id)
              .map((m) => ({
                label: m.email,
                value: m.user_id,
              }))}
            onChange={(option) =>
              setSelectedMember(
                members.find((m) => m.user_id === option) ?? null,
              )
            }
            placeholder="Select a new owner"
            selected={selectedMember?.user_id}
          />
        </PanelSection>

        {selectedMember && (
          <>
            <PanelSection className="gap-2">
              <p className="text-body text-text-1">
                Are you sure you want to transfer ownership to{' '}
                <span className="font-bold">{selectedMember.email}</span>?
              </p>
              <p className="text-body text-brand-error">
                Warning: This cannot be undone.
              </p>
            </PanelSection>
          </>
        )}
        <PanelSection bottom={true}>
          <CTAButton
            disabled={!selectedMember}
            mode="error"
            onClick={handleSubmit}
          >
            Transfer Ownership
          </CTAButton>
        </PanelSection>
      </PopupContainer>
    </Fullscreen>
  )
}

export default TransferOwnershipPopup
