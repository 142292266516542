import CodeSnippet from '../../../../library/CodeSnippet/CodeSnippet'
import PreviewLogs from '../../../../library/PreviewLogs/PreviewLogs'
import SetupCustomTemplate from '../../../../library/SetupCustomTemplate/SetupCustomTemplate'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const { REACT_APP_INGRESS_ADDRESS } = process.env
const INGRESS_ADDRESS = REACT_APP_INGRESS_ADDRESS || 'ingress.vigilant.run'

const SetupLogsFlyIO = () => {
  useSetBrandColor('info')

  return (
    <SetupCustomTemplate
      title="Fly.io"
      keyName="flyio-api-key"
      customContent={(apiKeyValue) => (
        <>
          <div className="w-full flex flex-col items-start justify-start gap-2">
            <h2 className="text-h2 text-text-1">Setup Fly.io log shipper</h2>
            <p className="text-body text-text-2">
              Create a new folder called log-shipper. You will use this to
              create a new Fly.io service.
            </p>
            <CodeSnippet
              className={'w-full'}
              canHide={false}
              content={`mkdir log-shipper
cd log-shipper`}
              language="bash"
            />
          </div>

          <div className="w-full flex flex-col items-start justify-start gap-2">
            <h2 className="text-h2 text-text-1">
              Create the Fly.io log shipper
            </h2>
            <p className="text-body text-text-2">
              Create the log shipper app by running the following command:
            </p>
            <CodeSnippet
              className={'w-full'}
              canHide={false}
              content={`fly launch --image ghcr.io/superfly/fly-log-shipper:latest --no-deploy`}
              language="bash"
            />
            <p className="text-body text-text-2">
              You can say "N" to the question about tweaking the settings.
            </p>
          </div>

          <div className="w-full flex flex-col items-start justify-start gap-2">
            <h2 className="text-h2 text-text-1">
              Modify generated the fly.toml file
            </h2>
            <p className="text-body text-text-2">
              Add the following to the fly.toml file:
            </p>
            <CodeSnippet
              className={'w-full'}
              canHide={false}
              content={`[[services]]
  http_checks = []
  internal_port = 8686`}
              language="toml"
            />
            <p className="text-body text-text-2">
              Update these settings in the http_service section:
            </p>
            <CodeSnippet
              className={'w-full'}
              canHide={false}
              content={`[http_service]
  ...
  auto_stop_machines = false
  min_machines_running = 1
  ...`}
              language="toml"
            />
          </div>

          <div className="w-full flex flex-col items-start justify-start gap-2">
            <h2 className="text-h2 text-text-1">Get your Fly.io Org Name</h2>
            <p className="text-body text-text-2">
              You can find the "slug" of your organization in the output of the
              command below.
            </p>
            <CodeSnippet
              className={'w-full'}
              canHide={false}
              content={`fly orgs list`}
              language="bash"
            />
          </div>

          <div className="w-full flex flex-col items-start justify-start gap-2">
            <h2 className="text-h2 text-text-1">Set the log-shipper secrets</h2>
            <p className="text-body text-text-2">
              Set the log-shipper secrets by running the following commands:
            </p>
            <CodeSnippet
              className={'w-full'}
              canHide={false}
              content={`fly secrets set ORG=<ENTER YOUR ORG NAME HERE>
fly secrets set ACCESS_TOKEN=$(fly auth token)
fly secrets set HTTP_URL=https://${INGRESS_ADDRESS}/api/vector
fly secrets set HTTP_TOKEN=${apiKeyValue}`}
              language="bash"
            />
          </div>

          <div className="w-full flex flex-col items-start justify-start gap-2">
            <h2 className="text-h2 text-text-1">
              Deploy the log-shipper service
            </h2>
            <p className="text-body text-text-2">
              Deploy the log-shipper service by running the following command:
            </p>
            <CodeSnippet
              className={'w-full'}
              canHide={false}
              content={`fly deploy
fly scale count 1`}
              language="bash"
            />
          </div>

          <div className="w-full flex flex-col items-start justify-start gap-2">
            <h2 className="text-h2 text-text-1">
              Delete the log-shipper folder
            </h2>
            <p className="text-body text-text-2">
              You no longer need the log-shipper folder. Feel free to delete it.
            </p>
            <CodeSnippet
              className={'w-full'}
              canHide={false}
              content={`cd ../ && rm -rf log-shipper`}
              language="bash"
            />
          </div>
        </>
      )}
      preview={<PreviewLogs />}
      done={LinkKey.Logs}
    />
  )
}

export default SetupLogsFlyIO
