import { Box, DollarSign, Lock, Siren, User, Users } from 'lucide-react'
import PanelRow from '../../../../library/PanelRow/PanelRow'
import PanelSection from '../../../../library/PanelSection/PanelSection'
import RowButton from '../../../../library/RowButton/RowButton'
import { useLocation } from 'react-router-dom'
import useNav, { LinkKey, linkMap } from '../../../../../hooks/nav/useNav'

const SettingsSidebar = () => {
  const navigate = useNav()
  const location = useLocation()

  const isActive = (link: LinkKey) => {
    return location.pathname.includes(linkMap[link])
  }

  return (
    <div className="flex-none w-[264px] h-full flex flex-col border-r border-panel-border">
      <PanelSection className="gap-2">
        <PanelRow>
          <h3 className="text-small-header text-text-1">Project</h3>
        </PanelRow>
        <RowButton
          text="General"
          icon={<Box size={16} />}
          onClick={() => navigate(LinkKey.SettingsProjectGeneral)}
          active={isActive(LinkKey.SettingsProjectGeneral)}
        />
        <RowButton
          text="API Keys"
          icon={<Lock size={16} />}
          onClick={() => navigate(LinkKey.SettingsProjectAPI)}
          active={isActive(LinkKey.SettingsProjectAPI)}
        />
        <RowButton
          text="Alerts"
          icon={<Siren size={16} />}
          onClick={() => navigate(LinkKey.SettingsProjectAlerts)}
          active={isActive(LinkKey.SettingsProjectAlerts)}
        />
      </PanelSection>
      <PanelSection className="gap-2">
        <PanelRow>
          <h3 className="text-small-header text-text-1">Organization</h3>
        </PanelRow>
        <RowButton
          text="General"
          icon={<Box size={16} />}
          onClick={() => navigate(LinkKey.SettingsOrganizationGeneral)}
          active={isActive(LinkKey.SettingsOrganizationGeneral)}
        />
        <RowButton
          text="Team"
          icon={<Users size={16} />}
          onClick={() => navigate(LinkKey.SettingsOrganizationTeam)}
          active={isActive(LinkKey.SettingsOrganizationTeam)}
        />
        <RowButton
          text="Billing"
          icon={<DollarSign size={16} />}
          onClick={() => navigate(LinkKey.SettingsOrganizationBilling)}
          active={isActive(LinkKey.SettingsOrganizationBilling)}
        />
      </PanelSection>
      <PanelSection className="gap-2">
        <PanelRow>
          <h3 className="text-small-header text-text-1">User</h3>
        </PanelRow>
        <RowButton
          text="Profile"
          icon={<User size={16} />}
          onClick={() => navigate(LinkKey.SettingsUserProfile)}
          active={isActive(LinkKey.SettingsUserProfile)}
        />
      </PanelSection>
    </div>
  )
}

export default SettingsSidebar
