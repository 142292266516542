import { useAtomValue } from 'jotai'
import { instanceIdAtom } from '../../state/state'
import useSetInstance from '../actions/useSetInstance'
import { useNavProject } from '../nav/useNavProject'
import { useCallback, useEffect } from 'react'

const useProjectLink = () => {
  const [project, setProject] = useNavProject()
  const { organizationId, projectId } = useAtomValue(instanceIdAtom)

  const switchInstance = useSetInstance()

  const selectProject = useCallback(async () => {
    if (project?.id && project.id !== projectId) {
      await switchInstance(organizationId!, project.id)
      setProject(null)
      window.location.reload()
    } else if (project?.id && project.id === projectId) {
      setProject(null)
    }
  }, [project, projectId, organizationId, switchInstance, setProject])

  useEffect(() => {
    selectProject()
  }, [selectProject])

  return selectProject
}

export default useProjectLink
