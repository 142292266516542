import {
  AnalyticsSettingsGraph,
  AnalyticsUpdateRequest,
  MetricSeries,
} from '../../../backend/types'
import GraphDisplay from './GraphDisplay/GraphDisplay'
import EditButton from './EditButton/EditButton'
import { useRef } from 'react'

interface AnalyticsDisplayGraphProps {
  settings: AnalyticsSettingsGraph
  values: MetricSeries[]

  rearranging?: boolean

  onUpdate: (settings: AnalyticsUpdateRequest) => void
  onDuplicate: () => void
  onDelete: () => void
}

const AnalyticsDisplayGraph = ({
  settings,
  values,
  rearranging,
  onUpdate,
  onDuplicate,
  onDelete,
}: AnalyticsDisplayGraphProps) => {
  const graphRef = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={graphRef}
      className="w-full flex flex-col bg-panel-bg border border-panel-border rounded-[4px]"
    >
      <div className="px-6 py-4 w-full flex items-center justify-between border-b border-panel-border">
        <p className="text-normal-medium text-text-1">
          {settings.name || 'Graph'}
        </p>
        <EditButton
          settings={settings}
          onUpdate={onUpdate}
          onDuplicate={onDuplicate}
          onDelete={onDelete}
        />
      </div>
      <div className="flex flex-wrap items-start justify-start">
        <GraphDisplay
          data={values}
          units={settings.unit}
          disabled={rearranging}
        />
      </div>
    </div>
  )
}

export default AnalyticsDisplayGraph
