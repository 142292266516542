import * as chrono from 'chrono-node'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { X } from 'lucide-react'
import PanelRow from '../../../../../../../library/PanelRow/PanelRow'
import PanelSection from '../../../../../../../library/PanelSection/PanelSection'
import CodeButton from '../../../../../../../library/CodeButton/CodeButton'
import { useRef, useState } from 'react'
import PanelInput from '../../../../../../../library/PanelInput/PanelInput'
import useClickOutside from '../../../../../../../../hooks/utils/useClickOutside'
import { TimeRange } from '../../../../../../../../utils/time'

dayjs.extend(utc)
dayjs.extend(timezone)

interface TimePopupProps {
  buttonRef: React.RefObject<HTMLDivElement | null>
  range: TimeRange
  setRange: (range: TimeRange) => void
  close: () => void
}

const TimePopup = ({ buttonRef, range, setRange, close }: TimePopupProps) => {
  const ref = useRef<HTMLDivElement>(null)
  useClickOutside(ref, () => close(), buttonRef)

  const [localStart, setLocalStart] = useState(getInitialTime(range.start))
  const [localEnd, setLocalEnd] = useState(getInitialTime(range.end))

  const handleStartSubmit = () => {
    const start = parseTimeString(localStart)
    if (start) setRange({ ...range, start })
    else if (localStart.length === 0) setRange({ ...range, start: undefined })
  }

  const handleEndSubmit = () => {
    const end = parseTimeString(localEnd)
    if (end) {
      const newRange = { ...range, end }
      if (typeof range.start === 'string' && /^\d+[mhd]$/.test(range.start)) {
        newRange.start = undefined
      }
      setRange(newRange)
    } else if (localEnd.length === 0) {
      setRange({ ...range, end: undefined })
    }
  }

  return (
    <div
      ref={ref}
      className="absolute top-[calc(100%+8px)] left-[calc(50%-190px)] w-[380px] bg-popup-bg border border-popup-border rounded-[4px] shadow-popup-shadow z-[2]"
    >
      <PanelSection mode="popup">
        <PanelRow>
          <h3 className="text-small-header text-text-1">Time Range</h3>
          <X
            size={16}
            className="text-text-2 hover:text-text-1 cursor-pointer"
            onClick={close}
          />
        </PanelRow>
      </PanelSection>
      <PanelSection mode="popup">
        <PanelRow>
          <h3 className="text-small-header text-text-1">Custom Range</h3>
        </PanelRow>
        <PanelRow>
          <p className="w-16 text-small text-text-2">After:</p>
          <PanelInput
            value={localStart}
            valid={isTimeValid(localStart)}
            onChange={(value) => setLocalStart(value)}
            onBlur={handleStartSubmit}
            placeholder="3pm, yesterday, timestamp"
          />
        </PanelRow>
        <PanelRow>
          <p className="w-16 text-small text-text-2">Before:</p>
          <PanelInput
            value={localEnd}
            valid={isTimeValid(localEnd)}
            onChange={(value) => setLocalEnd(value)}
            onBlur={handleEndSubmit}
            placeholder="7pm, today, timestamp"
          />
        </PanelRow>
      </PanelSection>
      <PanelSection mode="popup" bottom={true}>
        <PanelRow>
          <h3 className="text-small-header text-text-1">Presets</h3>
        </PanelRow>
        {presets.map((preset) => (
          <div key={preset.name} className="flex w-full">
            <CodeButton
              text={preset.name}
              onClick={() => {
                setRange(preset.range)
                close()
              }}
            />
          </div>
        ))}
      </PanelSection>
    </div>
  )
}

const presets: { name: string; range: TimeRange }[] = [
  { name: 'Last 1 minute', range: { start: '1m', end: undefined } },
  { name: 'Last 5 minutes', range: { start: '5m', end: undefined } },
  { name: 'Last 30 minutes', range: { start: '30m', end: undefined } },
  { name: 'Last 60 minutes', range: { start: '60m', end: undefined } },
  { name: 'Last 3 hours', range: { start: '3h', end: undefined } },
  { name: 'Last 6 hours', range: { start: '6h', end: undefined } },
  { name: 'Last 12 hours', range: { start: '12h', end: undefined } },
  { name: 'Last 24 hours', range: { start: '24h', end: undefined } },
  { name: 'Last 2 days', range: { start: '2d', end: undefined } },
  { name: 'Last 7 days', range: { start: '7d', end: undefined } },
  { name: 'Everything', range: { start: undefined, end: undefined } },
]

function parseTimeString(timeString: string): Date | null {
  if (timeString.length === 0) return null
  try {
    return chrono.parseDate(timeString)
  } catch {
    return null
  }
}

function formatDate(date: Date): string | null {
  try {
    return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
  } catch {
    return null
  }
}

function isTimeValid(timeString: string): boolean {
  if (timeString.length === 0) return true
  return parseTimeString(timeString) !== null
}

function getInitialTime(time: string | Date | undefined): string {
  if (!time) return ''
  if (time instanceof Date) {
    return formatDate(time) || ''
  }
  const parsed = parseTimeString(time)
  return parsed ? formatDate(parsed) || '' : ''
}

export default TimePopup
