import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

interface PortalProps {
  ref?: React.RefObject<HTMLDivElement | null>
  children: React.ReactNode
  pointerEvents?: boolean
  level?: number
  id?: string
}

const Portal = ({
  ref,
  children,
  pointerEvents = true,
  level = 1,
  id,
}: PortalProps) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    return () => setMounted(false)
  }, [])

  if (!mounted) return null

  const portalRoot = document.getElementById('portal-root')
  if (!portalRoot) return null

  const wrappedChildren = (
    <div
      ref={ref}
      id={id}
      style={{
        pointerEvents: pointerEvents ? 'auto' : 'none',
        zIndex: level,
        position: 'relative',
      }}
      className="portal-container"
    >
      {children}
    </div>
  )

  return createPortal(wrappedChildren, portalRoot)
}

export default Portal
