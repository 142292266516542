import { cn } from '../../../../../../../utils/cn'
import { Pause, Play } from 'lucide-react'

interface LiveButtonProps {
  live: boolean
  setLive: (live: boolean) => void
}

const LiveButton = ({ live, setLive }: LiveButtonProps) => {
  const backgroundClass = live
    ? 'bg-brand-ctaTint hover:bg-brand-ctaTint2'
    : 'bg-input-bgTint hover:bg-input-tint'
  const borderClass = live ? 'border-brand-ctaTint' : 'border-input-border'
  const textClass = live ? 'text-brand-cta' : 'text-text-1'

  return (
    <button
      className={`px-2.5 py-2 flex items-center justify-center gap-2 rounded-[4px] border ${borderClass} ${backgroundClass} outline-none`}
      onClick={() => setLive(!live)}
    >
      {live ? (
        <Pause size={16} className={textClass} />
      ) : (
        <Play size={16} className={textClass} />
      )}
      <p className={cn('text-small whitespace-nowrap', textClass)}>Live</p>
    </button>
  )
}

export default LiveButton
