import { useState } from 'react'
import { useAtomValue } from 'jotai'
import useInitOrganizationMembers from '../../../../../../hooks/data/effects/useInitOrganizationMemebers'
import CTAButton from '../../../../../library/CTAButton/CTAButton'
import SettingsSection from '../../../../../library/SettingsSection/SettingsSection'
import SettingsTitle from '../../../../../library/SettingsTitle/SettingsTitle'
import TextInput from '../../../../../library/TextInput/TextInput'
import { useMemo } from 'react'
import {
  organizationMembersAtom,
  userInfoAtom,
} from '../../../../../../state/state'
import { organizationInfoAtom } from '../../../../../../state/state'
import { useDeleteOrganization } from '../../../../../../hooks/actions/useDeleteOrganization'
import { OrganizationMember } from '../../../../../../backend/types'
import { MetricsTTL, User } from '../../../../../../state/types'
import DeleteOrganizationPopup from './DeleteOrganizationPopup/DeleteOrganizationPopup'
import useNav, { LinkKey } from '../../../../../../hooks/nav/useNav'
import Dropdown from '../../../../../library/Dropdown/Dropdown'
import { LogsTTL } from '../../../../../../state/types'
import useUpdateOrganizationName from '../../../../../../hooks/actions/useUpdateOrganizationName'
import useUpdateOrganizationLogsTTL from '../../../../../../hooks/actions/useUpdateOrganizationLogsTTL'
import useUpdateOrganizationMetricsTTL from '../../../../../../hooks/actions/useUpdateOrganizationMetricsTTL'

const OrganizationGeneralSettings = () => {
  const navigate = useNav()

  useInitOrganizationMembers()

  const updateOrganizationName = useUpdateOrganizationName()
  const updateOrganizationLogsTTL = useUpdateOrganizationLogsTTL()
  const updateOrganizationMetricsTTL = useUpdateOrganizationMetricsTTL()
  const deleteOrganization = useDeleteOrganization()

  const organizationInfo = useAtomValue(organizationInfoAtom)
  const userInfo = useAtomValue(userInfoAtom)
  const members = useAtomValue(organizationMembersAtom)
  const isAdmin = useMemo(
    () => isUserAdmin(userInfo, members ?? []),
    [userInfo, members],
  )

  const [deletingOrganization, setDeletingOrganization] = useState(false)
  const [isSavingLogsTTL, setIsSavingLogsTTL] = useState(false)
  const [isSavingMetricsTTL, setIsSavingMetricsTTL] = useState(false)
  const [logsTTL, setLogsTTL] = useState(organizationInfo?.logs_ttl ?? '7_days')
  const [metricsTTL, setMetricsTTL] = useState(
    organizationInfo?.metrics_ttl ?? '1_year',
  )

  const [orgName, setOrgName] = useState(organizationInfo?.name ?? '')

  const handleSaveOrgName = async () => {
    if (!organizationInfo?.id || !orgName) return
    await updateOrganizationName(orgName)
  }

  const handleDeleteOrganization = async () => {
    if (!organizationInfo?.id) return
    await deleteOrganization(organizationInfo.id)
    setDeletingOrganization(false)
    navigate(LinkKey.Logs)
  }

  const handleSaveLogsTTL = async () => {
    if (!organizationInfo?.id) return
    setIsSavingLogsTTL(true)
    await updateOrganizationLogsTTL(logsTTL)
    setIsSavingLogsTTL(false)
  }

  const handleSaveMetricsTTL = async () => {
    if (!organizationInfo?.id) return
    setIsSavingMetricsTTL(true)
    await updateOrganizationMetricsTTL(metricsTTL)
    setIsSavingMetricsTTL(false)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSaveOrgName()
      e.currentTarget.blur()
    }
  }

  return (
    <div className="flex-1 px-12 py-12 flex flex-col items-center gap-6 overflow-y-auto">
      <SettingsSection>
        <SettingsTitle
          title="Organization Name"
          description="We recommend having one organization for the entire company."
        />
        <div className="py-4 flex flex-col items-start justify-center gap-1">
          <div className="flex gap-4">
            <div className="w-[264px]">
              <TextInput
                value={orgName}
                onChange={setOrgName}
                onKeyDown={handleKeyDown}
              />
            </div>
            <CTAButton
              disabled={!isOrgNameValid(organizationInfo?.name ?? '', orgName)}
              onClick={handleSaveOrgName}
            >
              Save
            </CTAButton>
          </div>
          <p className="text-label text-text-2">Max 32 characters</p>
        </div>
      </SettingsSection>

      <SettingsSection>
        <SettingsTitle
          title="Log Retention"
          description="Higher log retention slows down queries and increases costs."
        />
        <div className="w-full py-4 flex items-center justify-start gap-4">
          <Dropdown
            options={logRetentionOptions}
            selected={logsTTL}
            onChange={setLogsTTL}
            disabled={isSavingLogsTTL}
          />
          <div className="flex items-center justify-center">
            <CTAButton
              disabled={logsTTL === organizationInfo?.logs_ttl}
              loading={isSavingLogsTTL}
              onClick={handleSaveLogsTTL}
            >
              Save
            </CTAButton>
          </div>
        </div>
      </SettingsSection>

      <SettingsSection>
        <SettingsTitle
          title="Metrics Retention"
          description="Higher metrics retention slows down queries and increases costs."
        />
        <div className="w-full py-4 flex items-center justify-start gap-4">
          <Dropdown
            options={metricsRetentionOptions}
            selected={metricsTTL}
            onChange={setMetricsTTL}
            disabled={isSavingMetricsTTL}
          />
          <div className="flex items-center justify-center">
            <CTAButton
              disabled={metricsTTL === organizationInfo?.metrics_ttl}
              loading={isSavingMetricsTTL}
              onClick={handleSaveMetricsTTL}
            >
              Save
            </CTAButton>
          </div>
        </div>
      </SettingsSection>

      {isAdmin && (
        <SettingsSection>
          <SettingsTitle
            title="Delete Organization"
            description="This will delete your organization and all data associated with it. Only the organization owner can delete it."
          />
          <div className="py-4 w-full flex flex-col items-start justify-center gap-1">
            <div className="w-full flex items-center justify-between">
              <p className="text-body text-brand-error">
                Warning: This cannot be undone.
              </p>
              <CTAButton
                mode="error"
                onClick={() => setDeletingOrganization(true)}
              >
                Delete Organization
              </CTAButton>
            </div>
          </div>
        </SettingsSection>
      )}

      {deletingOrganization && (
        <DeleteOrganizationPopup
          name={organizationInfo?.name ?? ''}
          onSubmit={() => handleDeleteOrganization()}
          close={() => setDeletingOrganization(false)}
        />
      )}
    </div>
  )
}

const logRetentionOptions: { label: string; value: LogsTTL }[] = [
  { label: '1 day', value: '1_day' },
  { label: '3 days', value: '3_days' },
  { label: '7 days', value: '7_days' },
  { label: '14 days', value: '14_days' },
  { label: '30 days', value: '30_days' },
  { label: '60 days', value: '60_days' },
  { label: '90 days', value: '90_days' },
]

const metricsRetentionOptions: { label: string; value: MetricsTTL }[] = [
  { label: '1 month', value: '1_month' },
  { label: '3 months', value: '3_months' },
  { label: '6 months', value: '6_months' },
  { label: '1 year', value: '1_year' },
]

function isUserAdmin(userInfo: User | null, members: OrganizationMember[]) {
  return (
    members?.some((m) => m.user_id === userInfo?.id && m.role === 'admin') ||
    members?.some((m) => m.user_id === userInfo?.id && m.role === 'owner')
  )
}

function isOrgNameValid(currentOrgName: string, orgName: string) {
  return (
    orgName.trim().length > 0 &&
    orgName.trim().length <= 32 &&
    orgName !== currentOrgName
  )
}

export default OrganizationGeneralSettings
