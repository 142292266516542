import { X } from 'lucide-react'
import { Filter, FilterOperator } from '../../../../../../../backend/types'
import {
  COLUMN_LEVEL,
  operatorToDisplay,
  propertyToDisplay,
  updateFilterAttribute,
} from '../../../../../../../utils/properties'
import { useRef, useState } from 'react'
import FilterOperatorPopup from './FilterOperatorPopup/FilterOperatorPopup'
import FilterValueInput from './FilterValueInput/FilterValueInput'
import FilterValueButton from './FilterValueButton/FilterValueButton'
import FilterPropertyPopup from './FilterPropertyPopup/FilterPropertyPopup'

type FilterPillProps = {
  filter: Filter
  setFilter: (filter: Filter) => void

  visibleProperties: string[]
  hiddenProperties: string[]

  remove: () => void
}

const FilterPill = ({
  filter,
  setFilter,
  visibleProperties,
  hiddenProperties,
  remove,
}: FilterPillProps) => {
  const operatorButtonRef = useRef<HTMLButtonElement>(null)
  const propertyButtonRef = useRef<HTMLButtonElement>(null)
  const [operatorOpen, setOperatorOpen] = useState(false)
  const [propertyOpen, setPropertyOpen] = useState(false)

  const handleSetOperator = (operator: FilterOperator) => {
    setFilter({ ...filter, operator: operator })
  }

  const handleSetProperty = (property: string) => {
    setFilter(updateFilterAttribute(filter, property))
  }

  const handleSetValue = (value: string) => {
    setFilter({ ...filter, value: value })
  }

  return (
    <div className="flex items-center">
      <div className="relative">
        <button
          ref={propertyButtonRef}
          className="px-[10px] h-6 flex items-center flex-none text-mini-code rounded-l-[4px] border border-brand-ctaTint text-brand-cta bg-brand-ctaTint hover:bg-brand-ctaTint2"
          onClick={() => setPropertyOpen(!propertyOpen)}
        >
          {propertyToDisplay(filter.field)}
        </button>
        {propertyOpen && (
          <FilterPropertyPopup
            visibleProperties={visibleProperties}
            hiddenProperties={hiddenProperties}
            setProperty={handleSetProperty}
            buttonRef={propertyButtonRef}
            close={() => setPropertyOpen(false)}
          />
        )}
      </div>
      <div className="relative">
        <button
          ref={operatorButtonRef}
          className="px-[10px] h-6 flex-none text-mini-code border border-l-0 border-brand-ctaTint text-brand-cta bg-brand-ctaTint hover:bg-brand-ctaTint2"
          onClick={() => setOperatorOpen(!operatorOpen)}
        >
          {operatorToDisplay(filter.operator)}
        </button>
        {operatorOpen && (
          <FilterOperatorPopup
            property={filter.field}
            buttonRef={operatorButtonRef}
            close={() => setOperatorOpen(false)}
            setOperator={handleSetOperator}
          />
        )}
      </div>
      {isValueOperator(filter.operator) &&
      isInputValueProperty(filter.field) ? (
        <FilterValueInput filter={filter} setFilter={setFilter} />
      ) : (
        <FilterValueButton filter={filter} setValue={handleSetValue} />
      )}
      <button
        className="px-[6px] h-6 flex-none text-mini-code rounded-r-[4px] border border-l-0 border-brand-ctaTint text-brand-cta bg-brand-ctaTint hover:bg-brand-ctaTint2"
        onClick={remove}
      >
        <X size={12} />
      </button>
    </div>
  )
}

const isValueOperator = (operator: FilterOperator) => {
  switch (operator) {
    case 'equals':
    case 'not_equals':
    case 'contains':
    case 'not_contains':
    case 'regex':
      return true
  }
}

function isInputValueProperty(property: string) {
  switch (property) {
    case COLUMN_LEVEL:
      return false
    default:
      return true
  }
}

export default FilterPill
