import { atom } from 'jotai'
import persistAtom from './persistAtom'
import { LogEntry, Organization, Project, Token, User } from './types'
import {
  Integration,
  IntegrationExternalDetails,
  OrganizationMember,
  MetricsDetails,
  AnalyticsEntry,
  AnalyticsLayout,
  UserData,
  BillingInfo,
  Alert,
  AlertQueryParams,
  AlertSettings,
} from '../backend/types'
import { ToastItem } from '../components/library/ToastContainer/ToastContainer'
import { isOrgOwner } from '../utils/organization'

export const userInfoAtom = atom<User>({ id: '', email: '' })

export const userDataAtom = atom<UserData | null>(null)

export const logLiveDefaultAtom = atom(
  (get) => get(userDataAtom)?.logs_live_default ?? false,
)

export const analyticsTimerangeDefaultAtom = atom(
  (get) => get(userDataAtom)?.analytics_timerange_default ?? '1d',
)

export const userDataSearchesAtom = atom(
  (get) => get(userDataAtom)?.searches ?? [],
)

export const instanceIdAtom = persistAtom<{
  organizationId: string | null
  projectId: string | null
}>({
  key: 'instanceId',
  defaultValue: { organizationId: null, projectId: null },
  persistMode: 'local',
})

export const organizationInfoAtom = atom<Organization | null>(null)

export const organizationMembersAtom = atom<OrganizationMember[] | null>(null)

export const isOrgOwnerAtom = atom((get) =>
  isOrgOwner(get(userInfoAtom)?.id ?? '', get(organizationMembersAtom) ?? []),
)

export const organizationsAtom = atom<Organization[] | null>(null)

export const billingInfoAtom = atom<BillingInfo | null>(null)

export const joinOrganizationTokenAtom = atom<string | null>(null)

export const projectsAtom = atom<Project[] | null>(null)

export const tokensAtom = atom<Token[]>([])

export const integrationsAtom = atom<Integration[]>([])

export const integrationDetailsAtom = atom<IntegrationExternalDetails | null>(
  null,
)

export const logDetailsAtom = atom<LogEntry | null>(null)

export const alertsAtom = atom<Alert[] | null>(null)

export const alertsQueryAtom = atom<AlertQueryParams>({
  search_term: '',
})

export const alertSettingsAtom = atom<AlertSettings | null>(null)

export const metricNamesAtom = atom<string[]>([])

export const metricDetailsAtom = atom<MetricsDetails | null>(null)

export const metricAttributesAtom = atom<Record<string, string[]>>({})

export const analyticsAtom = atom<AnalyticsEntry[]>([])

export const analyticsLayoutAtom = atom<AnalyticsLayout | null>(null)

export const toastsAtom = atom<ToastItem[]>([])

export const isAdminDataInitializedAtom = atom(
  (get) =>
    get(userInfoAtom) !== null &&
    get(userDataAtom) !== null &&
    get(organizationsAtom) !== null &&
    get(organizationInfoAtom) !== null &&
    get(projectsAtom) !== null,
)

export const hasLogsAtom = atom<boolean | null>(null)

export const hasAlertsAtom = atom<boolean | null>(null)

export const hasMetricsAtom = atom<boolean | null>(null)
