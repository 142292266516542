import { Outlet } from 'react-router-dom'
import SettingsSidebar from './SettingsSidebar/SettingsSidebar'
import NavbarPlaceholder from '../../../library/NavbarPlaceholder/NavbarPlaceholder'
import useSetBrandColor from '../../../../hooks/utils/useSetBrandColor'
import useInitHasData from '../../../../hooks/data/effects/useInitHasData'

const Settings = () => {
  useSetBrandColor('success')
  useInitHasData()

  return (
    <div className="h-full w-full flex flex-col">
      <NavbarPlaceholder />
      <div className="flex-1 flex overflow-hidden">
        <SettingsSidebar />
        <Outlet />
      </div>
    </div>
  )
}

export default Settings
