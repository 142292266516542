import { useRef } from 'react'
import useHovered from '../../../hooks/utils/useHover'
import { cn } from '../../../utils/cn'

interface OutlineIconButtonProps {
  children: React.ReactNode
  active?: boolean
  onClick?: () => void
  buttonRef?: React.RefObject<HTMLButtonElement | null>
}

const OutlineIconButton = ({
  children,
  onClick,
  active,
  buttonRef,
}: OutlineIconButtonProps) => {
  const internalRef = useRef<HTMLButtonElement>(null)
  const hovered = useHovered(buttonRef ?? internalRef)
  const hoverClass =
    hovered || active
      ? 'bg-input-tint text-text-1'
      : 'bg-transparent text-text-2'

  return (
    <button
      ref={buttonRef ?? internalRef}
      className={cn(
        'w-8 h-8 p-2 flex items-center justify-center rounded',
        hoverClass,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default OutlineIconButton
