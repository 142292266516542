import { ChevronDown, ChevronRight } from 'lucide-react'
import { useState, useRef } from 'react'
import { cn } from '../../../utils/cn'
import Portal from '../Portal/Portal'
import usePopupPauseScroll from '../../../hooks/utils/usePopupPauseScroll'
import usePopupPosition from '../../../hooks/utils/usePopupPosition'
import usePopupStack from '../../../hooks/utils/usePopupStack'
import OutlineButtonSmall from '../OutlineButtonSmall/OutlineButtonSmall'

export type PopupDropdownOption<T> = {
  label: string
  value: T
}

interface PopupDropdownProps<T> {
  icon: React.ReactNode
  title?: string
  placeholder?: string
  scrollable?: boolean

  options: PopupDropdownOption<T>[]
  selected: T
  onSelect: (value: T) => void | Promise<void>
}

const PopupDropdown = <T,>({
  icon,
  title,
  placeholder,
  scrollable,
  options,
  selected,
  onSelect,
}: PopupDropdownProps<T>) => {
  const selectedOption = options.find((option) => option.value === selected)

  const portalRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  usePopupStack(portalRef, () => setIsOpen(false), buttonRef)
  usePopupPauseScroll(portalRef, isOpen)
  const position = usePopupPosition(
    isOpen,
    buttonRef,
    popupRef,
    'bottom-right',
    { x: -232 },
  )

  const handleSelect = async (value: T) => {
    await onSelect(value)
    setIsOpen(false)
  }

  return (
    <>
      <button
        ref={buttonRef}
        className={cn(
          'w-full h-[28px] px-2 flex items-center justify-between gap-2 rounded group',
          isOpen ? 'bg-input-tint' : 'hover:bg-input-tint',
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center gap-2 text-text-1">
          {icon}
          {title && <p className="text-small-light">{title}</p>}
        </div>
        <div
          className={cn(
            'flex flex-1 items-center justify-end gap-2 overflow-hidden',
            isOpen || !title
              ? 'text-text-1'
              : 'text-text-2 group-hover:text-text-1',
            title ? 'justify-end' : 'justify-between',
          )}
        >
          <p className="text-small-light overflow-hidden text-ellipsis whitespace-nowrap">
            {selectedOption?.label ?? placeholder}
          </p>
          {isOpen ? <ChevronDown size={12} /> : <ChevronRight size={12} />}
        </div>
      </button>
      {isOpen && (
        <Portal ref={portalRef}>
          <div
            ref={popupRef}
            className={cn(
              'absolute w-[232px] p-2 flex flex-col gap-1 overflow-y-auto bg-popup-bg border border-popup-border rounded shadow-popup-shadow',
              scrollable ? 'max-h-[232px]' : '',
            )}
            style={{ ...position }}
          >
            {options.map((option) => (
              <OutlineButtonSmall
                key={option.label}
                onClick={() => handleSelect(option.value)}
              >
                {option.label}
              </OutlineButtonSmall>
            ))}
          </div>
        </Portal>
      )}
    </>
  )
}

export default PopupDropdown
