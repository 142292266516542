import { cn } from '../../../utils/cn'

interface SolidIconTextButtonProps {
  icon: React.ReactNode
  text: string
  disabled?: boolean
  onClick?: () => void
}

const SolidIconTextButton = ({
  icon,
  text,
  disabled = false,
  onClick,
}: SolidIconTextButtonProps) => {
  return (
    <button
      className={cn(
        'h-8 px-[14px] flex-none flex items-center justify-center gap-2 rounded border border-input-border text-text-1 bg-input-bgTint hover:bg-input-tint',
        disabled ? 'opacity-60' : 'opacity-100',
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {icon}
      <p className="text-small-light text-text-1">{text}</p>
    </button>
  )
}

export default SolidIconTextButton
