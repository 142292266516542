import { cn } from '../../../../utils/cn'
import { formatNumber } from '../../../../utils/properties'
import { Point, GroupedSeries, Grouping } from '../Chart'

interface HoverTooltipProps {
  containerRef: React.RefObject<HTMLDivElement | null>
  hoverX: number
  columnWidth: number
  pointIndex: number
  unit: string
  grouping: Grouping
  groupedSeries: GroupedSeries[]
}

const HoverTooltip = ({
  containerRef,
  hoverX,
  columnWidth,
  pointIndex,
  unit,
  grouping,
  groupedSeries,
}: HoverTooltipProps) => {
  const containerRect = containerRef.current?.getBoundingClientRect()
  if (!containerRect) return null
  if (pointIndex < 0 || pointIndex >= groupedSeries.length) return null

  const midpoint = containerRect.left + containerRect.width / 2
  const tooltipWidth = 200
  const offset = 8

  const isOnLeftHalf = hoverX < midpoint
  let leftPos = 0

  if (isOnLeftHalf) {
    leftPos = hoverX - containerRect.left + columnWidth / 2 + offset
  } else {
    leftPos =
      hoverX - containerRect.left - columnWidth / 2 - tooltipWidth - offset
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: '20px',
        left: `${leftPos}px`,
        pointerEvents: 'none',
        zIndex: 3,
        width: `${tooltipWidth}px`,
        transition: 'left 150ms ease-out',
      }}
    >
      <HoverDisplay
        unit={unit}
        grouping={grouping}
        time={groupedSeries[pointIndex].time}
        values={groupedSeries[pointIndex].values}
      />
    </div>
  )
}

interface HoverDisplayProps {
  unit: string
  grouping: Grouping
  time: Date
  values: Point[]
}

const HoverDisplay = ({ unit, grouping, time, values }: HoverDisplayProps) => {
  const total = values.reduce((acc, value) => acc + value.value, 0)
  return (
    <div className="p-2 w-[200px] flex flex-col items-start justify-start gap-2 bg-popup-bg border border-popup-border rounded-[4px]">
      <p className="text-small-header text-text-2">
        {getTimeLabel(time, grouping)}
      </p>
      {values.map((value, index) => (
        <div
          key={index}
          className="w-full flex items-center justify-between gap-4"
        >
          <div className="min-w-0 flex items-center gap-2">
            <div
              className={cn('w-2 h-2 rounded-full', getBackgroundColor(index))}
            />
            <p className="text-small-code text-text-1 text-ellipsis overflow-hidden whitespace-nowrap">
              {value.name}
            </p>
          </div>
          <p className="flex-none text-small-code text-text-1">
            {formatNumber(value.value)} {unit}
          </p>
        </div>
      ))}
      <div className="w-full flex items-center justify-between gap-4">
        <div className="min-w-0 flex items-center gap-2">
          <div className="w-2 h-2 rounded-full bg-white" />
          <p className="text-small-code text-text-1 text-ellipsis overflow-hidden whitespace-nowrap">
            Total
          </p>
        </div>
        <p className="flex-none text-small-code text-text-1">
          {formatNumber(total)} {unit}
        </p>
      </div>
    </div>
  )
}

function getBackgroundColor(index: number): string {
  switch (index % 4) {
    case 0:
      return 'bg-brand-info'
    case 1:
      return 'bg-brand-warning'
    case 2:
      return 'bg-brand-error'
    case 3:
      return 'bg-brand-success'
    default:
      return 'bg-brand-info'
  }
}

function getTimeLabel(time: Date, grouping: Grouping): string {
  switch (grouping) {
    case 'hourly':
      return time.toLocaleTimeString('en-US', {
        day: 'numeric',
        month: 'short',
        hour: 'numeric',
        hour12: true,
        minute: '2-digit',
      })
    case 'daily':
    case 'weekly':
    case 'monthly':
      return new Date(time.getTime() + 24 * 60 * 60 * 1000).toLocaleTimeString(
        'en-US',
        {
          day: 'numeric',
          month: 'short',
          hour: 'numeric',
          hour12: true,
          minute: '2-digit',
        },
      )
  }
}
export default HoverTooltip
