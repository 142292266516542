import { useAtomValue } from 'jotai'
import { userDataAtom, userInfoAtom } from '../../state/state'
import useBackend from '../services/useBackend'
import useLoadUserData from '../data/load/useLoadUserData'
import { captureAxiosError } from '../../utils/obeservability'
import { UserData } from '../../backend/types'

const useUpdateUserData = () => {
  const backend = useBackend()
  const userData = useAtomValue(userDataAtom)
  const userInfo = useAtomValue(userInfoAtom)
  const loadUserData = useLoadUserData()

  const updateUserData = async (partialUserData: Partial<UserData>) => {
    if (!userData) return

    try {
      await backend.updateUserData(userInfo.id, {
        ...userData,
        ...partialUserData,
      })
      await loadUserData()
    } catch (e) {
      captureAxiosError(e)
    }
  }

  return updateUserData
}

export default useUpdateUserData
