import { useMemo } from 'react'
import useBackend from '../services/useBackend'
import { useAtomValue } from 'jotai'
import { instanceIdAtom } from '../../state/state'
import { QueryOptionsManager } from '../../library/queryOptions/queryOptionsManager'
import { QueryOptionsRunner } from '../../library/queryOptions/queryOptionsRunner'

const useQueryOptionsManager = () => {
  const { projectId } = useAtomValue(instanceIdAtom)
  const backend = useBackend()

  const queryOptionsManager = useMemo(() => {
    if (!projectId) return null
    const queryRunner = new QueryOptionsRunner(projectId, backend)
    return new QueryOptionsManager(queryRunner)
  }, [projectId, backend])

  return queryOptionsManager
}

export default useQueryOptionsManager
