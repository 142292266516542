import BillingNoticePopup from '../../../library/BillingNoticePopup/BillingNoticePopup'
import Portal from '../../../library/Portal/Portal'
import { getFreeTier } from '../../../../utils/billing'
import useLoadUsageTotals from '../../../../hooks/data/load/useLoadUsageTotals'
import { useAtomValue } from 'jotai'
import {
  organizationInfoAtom,
  billingInfoAtom,
  isOrgOwnerAtom,
} from '../../../../state/state'
import { useState } from 'react'
import { formatNumber, formatVolume } from '../../../../utils/properties'
import useCreateBillingPortalSession from '../../../../hooks/actions/useCreateBillingPortalSession'

const BillingNotice = () => {
  const [isOpen, setIsOpen] = useState(true)

  const createSession = useCreateBillingPortalSession()

  const organizationInfo = useAtomValue(organizationInfoAtom)
  const billingInfo = useAtomValue(billingInfoAtom)
  const isOrgOwner = useAtomValue(isOrgOwnerAtom)

  const billingDate = organizationInfo?.billing_date
  const logTotals = useLoadUsageTotals('logs', billingDate ?? '')
  const metricTotals = useLoadUsageTotals('metrics', billingDate ?? '')

  const logFreeTier = getFreeTier('logs')
  const metricFreeTier = getFreeTier('metrics')

  const { title, message, mode } = getMessageAndTitle(
    logTotals?.total ?? 0,
    logFreeTier,
    metricTotals?.total ?? 0,
    metricFreeTier,
  )

  if (
    !billingDate ||
    billingInfo?.payment_method_added ||
    !isOrgOwner ||
    title === '' ||
    message === ''
  ) {
    return null
  }

  return (
    <>
      {isOpen && (
        <Portal>
          <div className="fixed bottom-2 right-2">
            <BillingNoticePopup
              mode={mode}
              title={title}
              message={message}
              ctaMessage={'Enter payment method'}
              onClickCTA={() => {
                createSession().then((url) => {
                  window.open(url, '_blank')
                })
              }}
              onClose={() => setIsOpen(false)}
            />
          </div>
        </Portal>
      )}
    </>
  )
}

function getMessageAndTitle(
  logsValue: number,
  logsLimit: number,
  metricsValue: number,
  metricsLimit: number,
): {
  title: string
  message: string
  ctaMessage: string
  mode: 'warning' | 'error'
} {
  if (logsValue > logsLimit) {
    return {
      title: 'You hit the free tier limit!',
      message: `Your organization has sent more than ${formatVolume(logsLimit)} of logs to Vigilant this month, which means you exceeded the free tier. You must enter a payment method or Vigilant will stop processing new logs at the end of the billing period.`,
      ctaMessage: 'Upgrade',
      mode: 'error',
    }
  }
  if (metricsValue > metricsLimit) {
    return {
      title: 'You hit the free tier limit!',
      message: `Your organization has sent more than ${formatNumber(metricsLimit)} of metrics to Vigilant this month, which means you exceeded the free tier. You must enter a payment method or Vigilant will stop processing new metrics at the end of the billing period.`,
      ctaMessage: 'Upgrade',
      mode: 'error',
    }
  }
  if (logsValue > logsLimit * 0.8) {
    return {
      title: 'You are approaching the free tier limit!',
      message: `Your organization has sent ${formatVolume(logsValue)} of logs to Vigilant this month, nearly reaching the ${formatVolume(logsLimit)} free tier limit. You must enter a payment method or Vigilant will stop procesing new logs at the end of the billing period.`,
      ctaMessage: 'Upgrade',
      mode: 'warning',
    }
  }
  if (metricsValue > metricsLimit * 0.8) {
    return {
      title: 'You are approaching the free tier limit!',
      message: `Your organization has sent ${formatNumber(metricsValue)} metrics to Vigilant this month, nearly reaching the ${formatNumber(metricsLimit)} free tier limit. You must enter a payment method or Vigilant will stop procesing new metrics at the end of the billing period.`,
      ctaMessage: 'Upgrade',
      mode: 'warning',
    }
  }
  return {
    title: '',
    message: '',
    ctaMessage: '',
    mode: 'warning',
  }
}

export default BillingNotice
