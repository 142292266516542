import { useEffect, RefObject } from 'react'

const usePopupPauseScroll = <T extends HTMLElement | null>(
  ref: RefObject<T>,
  isActive: boolean = false,
  portalRootId: string = 'portal-root',
) => {
  useEffect(() => {
    if (!isActive) return

    const handleWheel = (event: WheelEvent) => {
      if (shouldAllowScroll(event, ref, portalRootId)) return

      event.preventDefault()
      event.stopPropagation()
    }

    document.addEventListener('wheel', handleWheel, {
      passive: false,
      capture: true,
    })

    const handleTouchMove = (event: TouchEvent) => {
      if (shouldAllowScroll(event, ref, portalRootId)) return

      event.preventDefault()
      event.stopPropagation()
    }

    document.addEventListener('touchmove', handleTouchMove, {
      passive: false,
      capture: true,
    })

    return () => {
      document.removeEventListener('wheel', handleWheel, { capture: true })
      document.removeEventListener('touchmove', handleTouchMove, {
        capture: true,
      })
    }
  }, [ref, isActive, portalRootId])
}

function shouldAllowScroll<T extends HTMLElement | null>(
  event: Event,
  ref: RefObject<T>,
  portalRootId: string,
): boolean {
  const targetElement = ref.current
  const target = event.target as Node

  if (targetElement && targetElement.contains(target)) {
    return true
  }

  const portalRoot = document.getElementById(portalRootId)
  if (portalRoot) {
    const portalContainers = Array.from(
      portalRoot.querySelectorAll('.portal-container'),
    )

    const lastContainer = portalContainers[portalContainers.length - 1]
    if (lastContainer && lastContainer.contains(target)) {
      return true
    }
  }

  return false
}

export default usePopupPauseScroll
