import { useAtomValue } from 'jotai'
import {
  instanceIdAtom,
  organizationsAtom,
  projectsAtom,
} from '../../state/state'
import useNav, { LinkKey } from '../nav/useNav'
import { useCallback, useEffect } from 'react'
import useSetInstance from '../actions/useSetInstance'

const useProjectSelected = () => {
  const navigate = useNav()

  const organizations = useAtomValue(organizationsAtom)
  const projects = useAtomValue(projectsAtom)

  const { organizationId, projectId } = useAtomValue(instanceIdAtom)

  const switchInstance = useSetInstance()

  const selectProject = useCallback(async () => {
    if (organizations === null) return
    if (organizations.length === 0) {
      navigate(LinkKey.NewOrganization)
      return
    }

    if (organizationId === null) {
      const organizationId = organizations[0].id
      await switchInstance(organizationId, undefined)
    } else if (projects !== null && projectId === null) {
      const projectId = projects[0].id
      await switchInstance(organizationId, projectId)
    }
  }, [
    organizations,
    projects,
    organizationId,
    projectId,
    switchInstance,
    navigate,
  ])

  useEffect(() => {
    selectProject()
  }, [selectProject])

  return selectProject
}

export default useProjectSelected
