import useNav, { LinkKey } from '../../../hooks/nav/useNav'
import BrandButton from '../../library/BrandButton/BrandButton'

const AppError = () => {
  const navigate = useNav()

  return (
    <div className="flex h-[100vh] w-[100vw] flex-col items-center justify-center gap-8">
      <div className="text-title1-regular text-text-1">
        Sorry, an error occurred while loading the page.
      </div>
      <BrandButton onClick={() => navigate(LinkKey.Logs)}>Back</BrandButton>
    </div>
  )
}

export default AppError
