import { useAtomValue, useSetAtom } from 'jotai'
import { instanceIdAtom, metricAttributesAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback } from 'react'
import { captureAxiosError } from '../../../utils/obeservability'

const useLoadMetricAttributes = () => {
  const backend = useBackend()
  const { projectId } = useAtomValue(instanceIdAtom)
  const setMetricAttributes = useSetAtom(metricAttributesAtom)

  const loadMetricAttributes = useCallback(
    async (name: string) => {
      if (!projectId) return
      try {
        const { attributes } = await backend.metricAttributes({
          project_id: projectId,
          name: name,
        })
        setMetricAttributes((prev) => ({ ...prev, [name]: attributes }))
      } catch (e) {
        captureAxiosError(e)
      }
    },
    [backend, projectId, setMetricAttributes],
  )

  return loadMetricAttributes
}

export default useLoadMetricAttributes
