import { useAtomValue } from 'jotai'
import { instanceIdAtom } from '../../state/state'
import useBackend from '../services/useBackend'
import useToast from '../utils/useToast'
import { captureAxiosError } from '../../utils/obeservability'

const useCreateOrganizationInvite = () => {
  const backend = useBackend()
  const toast = useToast()

  const { organizationId } = useAtomValue(instanceIdAtom)

  const createOrganizationInvite = async (email: string) => {
    if (!organizationId) return
    try {
      await backend.createOrganizationInvite(organizationId, email)
      toast('Organization invite sent', 'success')
    } catch (e) {
      captureAxiosError(e)
      toast('Organization invite failed', 'error')
    }
  }

  return createOrganizationInvite
}

export default useCreateOrganizationInvite
