import useLogout from '../../../../../../hooks/utils/useLogout'
import { useAtomValue } from 'jotai'
import CTAButton from '../../../../../library/CTAButton/CTAButton'
import SettingsSection from '../../../../../library/SettingsSection/SettingsSection'
import SettingsTitle from '../../../../../library/SettingsTitle/SettingsTitle'
import { userInfoAtom } from '../../../../../../state/state'

const ProfileSettings = () => {
  const logout = useLogout()

  const info = useAtomValue(userInfoAtom)

  return (
    <div className="flex-1 px-12 py-12 flex flex-col items-center gap-6 overflow-y-auto">
      <SettingsSection>
        <SettingsTitle
          title="User Info"
          description="This is your personal account information."
        />
        <div className="py-4 w-full max flex flex-col items-start justify-start gap-4">
          <div className="w-full flex items-start justify-start gap-2">
            <p className="w-[120px] text-body text-text-1">Email</p>
            <p className="text-body text-text-1">{info?.email}</p>
          </div>
          <div className="w-full flex items-center justify-start gap-2">
            <p className="w-[120px] text-body text-text-1">Password</p>
            <p className="text-body text-text-1">************</p>
          </div>
        </div>
        <CTAButton onClick={logout}>Logout</CTAButton>
      </SettingsSection>
    </div>
  )
}

export default ProfileSettings
