import { isAxiosError } from 'axios'
import {
  Attributes,
  captureError as captureErrorVigilant,
  initErrorHandler,
  Logger,
} from 'vigilant-js/react'

export interface InternalLogger {
  info: (message: string, attributes?: { [key: string]: string }) => void
}

class NoopLogger implements InternalLogger {
  info = () => {}
}

class ProdLogger implements InternalLogger {
  private vigilantLogger: Logger

  constructor() {
    this.vigilantLogger = new Logger({
      name: 'frontend',
      token: 'tk_be1985623d374922',
      passthrough: false,
    })
  }

  info = (message: string, attributes: Attributes = {}) => {
    this.vigilantLogger.info(message, { ...getIdentity(), ...attributes })
  }
}

export let logger: InternalLogger = new NoopLogger()
export const setLogger = (newLogger: InternalLogger) => {
  logger = newLogger
}

export const prodLogger = new ProdLogger()

export const logWithTiming = <T>(
  name: string,
  fn: () => T,
  attributes: Attributes = {},
): T => {
  logger.info(`${name} started`, attributes)
  const startTime = Date.now()

  const result = fn()
  const logFinished = () => {
    const duration = Date.now() - startTime
    logger.info(`${name} finished`, {
      duration: `${duration}ms`,
      ...attributes,
    })
  }

  if (result instanceof Promise) {
    return result.finally(logFinished) as T
  } else {
    logFinished()
    return result
  }
}

export const captureAxiosError = (error: any) => {
  if (
    !isAxiosError(error) ||
    error.response?.status === undefined ||
    error.response.status !== 401
  ) {
    captureError(error)
  }
}

export const captureError = (error: any, attributes: Attributes = {}) => {
  if (error === null || error === undefined) return
  if (error instanceof Error) {
    captureErrorVigilant(error, { ...getIdentity(), ...attributes })
  } else if (typeof error === 'object') {
    captureErrorVigilant(new Error(JSON.stringify(error)), {
      ...getIdentity(),
      ...attributes,
    })
  } else {
    captureErrorVigilant(new Error(String(error)), {
      ...getIdentity(),
      ...attributes,
    })
  }
}

export const initProdErrorHandler = () => {
  initErrorHandler({
    name: 'frontend',
    token: 'tk_be1985623d374922',
  })
}

export const initNoopErrorHandler = () => {
  initErrorHandler({
    name: 'frontend',
    token: 'tk_be1985623d374922',
    noop: true,
  })
}

const getIdentity = (): Attributes => {
  const attributes: Attributes = {}
  const userEmail = localStorage.getItem('userEmail')
  const instanceId = localStorage.getItem('instanceId')

  if (userEmail) {
    attributes['identity.user_email'] = userEmail
  }

  if (instanceId) {
    try {
      const instanceIdObject = JSON.parse(instanceId)
      attributes['identity.organization_id'] = instanceIdObject.organizationId
      attributes['identity.project_id'] = instanceIdObject.projectId
    } catch (error) {}
  }

  return attributes
}
