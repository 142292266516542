import Divider from '../Divider/Divider'
import { useRef } from 'react'
import Portal from '../Portal/Portal'
import usePopupStack from '../../../hooks/utils/usePopupStack'
import usePopupPauseScroll from '../../../hooks/utils/usePopupPauseScroll'
import usePopupPosition from '../../../hooks/utils/usePopupPosition'
import OutlineIconTextButtonSmall from '../OutlineIconTextButtonSmall/OutlineIconTextButtonSmall'

export type NavPopupOption = {
  title: string
  value: string
  icon: React.ReactNode
  onClick?: () => void
}

interface NavPopupProps {
  title: string
  buttonRef: React.RefObject<HTMLButtonElement | null>

  options: NavPopupOption[]
  selected: string | null

  close: () => void
}

const NavPopup = ({
  title,
  options,
  close,
  selected,
  buttonRef,
}: NavPopupProps) => {
  const portalRef = useRef<HTMLDivElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)

  usePopupStack(popupRef, () => close(), buttonRef)
  usePopupPauseScroll(popupRef, true)
  const position = usePopupPosition(true, buttonRef, popupRef, 'bottom-left', {
    y: 6,
  })

  return (
    <Portal ref={portalRef}>
      <div
        ref={popupRef}
        className="absolute top-8 p-6 w-[264px] flex flex-col gap-2 bg-popup-bg border border-popup-border rounded-[4px]"
        style={position}
      >
        <p className="text-text-1 text-normal-light">{title}</p>
        <Divider mode="popup" />
        <div className="w-full flex flex-col gap-2 items-start">
          {options.map((option) => (
            <OutlineIconTextButtonSmall
              active={option.value === selected}
              key={option.value}
              onClick={option.onClick ?? (() => {})}
            >
              {option.icon}
              {option.title}
            </OutlineIconTextButtonSmall>
          ))}
        </div>
      </div>
    </Portal>
  )
}

export default NavPopup
