import {
  captureError,
  initNoopErrorHandler,
  initProdErrorHandler,
  prodLogger,
  setLogger,
} from '../../utils/obeservability'
import { useEffect, useState } from 'react'

const useObservability = () => {
  const [loggerReady, setLoggerReady] = useState(false)
  const [errorHandlerReady, setErrorHandlerReady] = useState(false)

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.hostname === 'dashboard.vigilant.run'
    ) {
      setLogger(prodLogger)
    }
    setLoggerReady(true)
  }, [])

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.hostname === 'dashboard.vigilant.run'
    ) {
      initProdErrorHandler()
    } else {
      initNoopErrorHandler()
    }
    setErrorHandlerReady(true)
  }, [])

  useEffect(() => {
    if (!errorHandlerReady) return
    const handleError = (event: ErrorEvent) => {
      event.preventDefault()
      captureError(event.error || new Error(event.message))
    }
    window.addEventListener('error', handleError)
    return () => window.removeEventListener('error', handleError)
  }, [errorHandlerReady])

  return loggerReady && errorHandlerReady
}

export default useObservability
