import posthog from 'posthog-js'
import { useEffect } from 'react'

const {
  REACT_APP_POSTHOG_API_KEY,
  REACT_APP_POSTHOG_UI_HOST,
  REACT_APP_POSTHOG_API_HOST,
} = process.env

export const usePosthogInit = () => {
  useEffect(() => {
    posthog.init(REACT_APP_POSTHOG_API_KEY || '', {
      api_host: REACT_APP_POSTHOG_API_HOST,
      ui_host: REACT_APP_POSTHOG_UI_HOST,
      person_profiles: 'identified_only',
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
    })
  }, [])
}
