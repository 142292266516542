import { RefreshCcw, Search, Settings } from 'lucide-react'
import { useRef } from 'react'
import useSelectOnFocus from '../../../../../hooks/utils/useSelectOnFocus'
import useFocusOnClick from '../../../../../hooks/utils/useFocusOnClick'
import useFocusOnHotkey from '../../../../../hooks/utils/useFocusOnHotkey'
import useSubmitOnHotkey from '../../../../../hooks/utils/useBlurOnHotkey'
import { useAtom } from 'jotai'
import { alertsQueryAtom } from '../../../../../state/state'
import useLoadalerts from '../../../../../hooks/data/load/useLoadAlerts'
import SolidIconTextButton from '../../../../library/SolidIconTextButton/SolidIconTextButton'
import useNav, { LinkKey } from '../../../../../hooks/nav/useNav'

const AlertsActionBar = () => {
  const navigate = useNav()
  const loadalerts = useLoadalerts()

  const [alertsQuery, setalertsQuery] = useAtom(alertsQueryAtom)

  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const { handleMouseDown, handleMouseUp } = useSelectOnFocus(inputRef)
  useFocusOnClick(containerRef, inputRef)
  useFocusOnHotkey(inputRef)
  useSubmitOnHotkey(inputRef)

  const handleSetSearchTerm = (search_term: string) => {
    setalertsQuery({ ...alertsQuery, search_term })
  }

  const handleRefresh = async () => {
    await loadalerts()
  }

  return (
    <div className="w-full py-4 flex items-center justify-between">
      <div className="flex-1 flex items-center gap-2">
        <div className="flex-1 max-w-[400px] flex rounded">
          <div
            ref={containerRef}
            className="w-full px-4 flex items-center h-8 bg-input-bgTint border border-input-border hover:bg-input-tint cursor-pointer rounded"
          >
            <div className="w-full flex items-center gap-2">
              <Search className="flex-none text-text-2" size={16} />
              <input
                ref={inputRef}
                type="text"
                value={alertsQuery.search_term}
                placeholder="Search alert title"
                className="flex-1 text-small-code text-text-1 placeholder:text-text-2"
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onChange={(e) => handleSetSearchTerm(e.target.value)}
              />
              <p className="flex-none text-small-light text-text-2">/</p>
            </div>
          </div>
        </div>
        <button className="h-8 w-8 p-2 flex items-center justify-center gap-2 rounded-[4px] text-text-2 border border-input-border bg-input-bgTint outline-none hover:bg-input-tint hover:text-text-1">
          <RefreshCcw className="flex-none" size={16} onClick={handleRefresh} />
        </button>
      </div>
      <div className="flex-none flex items-center justify-end gap-2">
        <SolidIconTextButton
          icon={<Settings size={16} />}
          text="Alert Settings"
          onClick={() => navigate(LinkKey.SettingsProjectAlerts)}
        />
      </div>
    </div>
  )
}

export default AlertsActionBar
