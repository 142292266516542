import { useAtomValue } from 'jotai'
import { AnalyticsSettingsGraph, MetricSeries } from '../../../backend/types'
import { instanceIdAtom } from '../../../state/state'
import useBackend from '../../services/useBackend'
import { useCallback, useEffect, useState } from 'react'
import { getBucketSize } from '../../../utils/analytics'
import { captureAxiosError } from '../../../utils/obeservability'

const useLoadAnalyticsGraphValues = (
  startTime: string | undefined,
  endTime: string | undefined,
  settings: AnalyticsSettingsGraph,
  refreshing?: boolean,
): MetricSeries[] => {
  const backend = useBackend()

  const { projectId } = useAtomValue(instanceIdAtom)

  const [firstLoad, setFirstLoad] = useState(true)
  const [result, setResult] = useState<MetricSeries[]>([])

  const loadAnalyticsGraphValues = useCallback(async () => {
    try {
      if (!projectId) return
      const response = await backend.metricsQuerySeries({
        project_id: projectId,
        metric_name: settings.metric_name,
        start_time: startTime ?? new Date().toISOString(),
        end_time: endTime ?? new Date().toISOString(),
        bucket_size: getBucketSize(startTime, endTime, settings.bucket_size),
        aggregation_function: settings.aggregation_function,
        group_by_attribute: settings.group_by_attribute,
      })
      setResult(response.series)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, projectId, settings, startTime, endTime])

  useEffect(() => {
    if (firstLoad) return
    loadAnalyticsGraphValues()
  }, [settings, loadAnalyticsGraphValues, firstLoad])

  useEffect(() => {
    if (!refreshing && !firstLoad) return
    loadAnalyticsGraphValues()
    setFirstLoad(false)
  }, [loadAnalyticsGraphValues, refreshing, firstLoad, settings])

  return result
}

export default useLoadAnalyticsGraphValues
