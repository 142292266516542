import { useEffect } from 'react'
import { useState } from 'react'
import Terminal from '../Terminal/Terminal'
import useBackend from '../../../hooks/services/useBackend'
import { useAtomValue } from 'jotai'
import { instanceIdAtom } from '../../../state/state'
import { MetricsDetails, MetricsListResponse } from '../../../backend/types'

interface PreviewMetricsProps {
  className?: string
}

const PreviewMetrics = ({ className }: PreviewMetricsProps) => {
  const backend = useBackend()
  const { projectId } = useAtomValue(instanceIdAtom)

  const [list, setList] = useState<MetricsListResponse | undefined>(undefined)
  const [details, setDetails] = useState<MetricsDetails[]>([])

  useEffect(() => {
    if (!projectId) return

    const fetchMetricsList = () => {
      backend.metricsList({ project_id: projectId }).then(setList)
    }

    fetchMetricsList()

    const intervalId = setInterval(fetchMetricsList, 1000)

    return () => clearInterval(intervalId)
  }, [backend, projectId])

  useEffect(() => {
    if (!list || !projectId) return
    Promise.all(
      list.names.map((name) => {
        return backend.metricsDetails({ project_id: projectId, name: name })
      }),
    ).then((details) => setDetails(details))
  }, [backend, projectId, list])

  return (
    <div
      className={`w-full flex flex-col items-start justify-start gap-2 ${className}`}
    >
      <h2 className="text-h2 text-text-1">Preview Metrics</h2>
      <Terminal
        className="w-full h-[220px]"
        content={getAllMetricList(details)}
      />
    </div>
  )
}

function getAllMetricList(details: MetricsDetails[]) {
  const allMetrics = details
    .flatMap((detail) => detail.entries)
    .sort((a, b) => {
      const dateA = new Date(a.timestamp)
      const dateB = new Date(b.timestamp)
      return dateA.getTime() - dateB.getTime()
    })
  return allMetrics.map((metric) => `${metric.name}: ${metric.value}`)
}

export default PreviewMetrics
