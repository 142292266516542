import { useSearchParams } from 'react-router-dom'

type metric = {
  name: string | null
}

const useNavMetric = (): [metric | null, (metric: metric | null) => void] => {
  const [searchParams, setSearchParams] = useSearchParams()
  const name = searchParams.get('metricName')

  const setMetric = (metric: metric | null) => {
    if (metric) {
      const params = new URLSearchParams(searchParams)
      if (metric.name !== null) {
        params.set('metricName', metric.name)
      } else {
        params.delete('metricName')
      }
      setSearchParams(params)
    } else {
      const params = new URLSearchParams(searchParams)
      params.delete('metricName')
      setSearchParams(params)
    }
  }

  return [{ name }, setMetric]
}

export { useNavMetric }
