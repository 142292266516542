import useLoadOrganizationMembers from '../data/load/useLoadOrganizationMembers'
import useBackend from '../services/useBackend'
import useToast from '../utils/useToast'
import { captureAxiosError } from '../../utils/obeservability'

const useDeleteOrganizationMember = () => {
  const backend = useBackend()
  const toast = useToast()
  const loadOrganizationMembers = useLoadOrganizationMembers()

  const deleteOrganizationMember = async (
    organization_id: string,
    user_id: string,
  ) => {
    try {
      await backend.deleteOrganizationMember(organization_id, user_id)
      await loadOrganizationMembers()
      toast('Organization member removed', 'success')
    } catch (e) {
      captureAxiosError(e)
      toast('Organization member removal failed', 'error')
    }
  }

  return deleteOrganizationMember
}

export default useDeleteOrganizationMember
