import { LogEntry } from '../../../../../../state/types'
import { useNavLog } from '../../../../../../hooks/nav/useNavLog'
import useInitLogDetails from '../../../../../../hooks/data/effects/useInitLogDetails'
import { X } from 'lucide-react'
import SolidTabs, { SolidTab } from '../../../../../library/SolidTabs/SolidTabs'
import { useRef, useState } from 'react'
import { formatTimeAsDate } from '../../../../../../utils/time'
import EventDetailsRow from '../../../../../library/EventDetailsRow/EventDetailsRow'
import EventDetailsRaw from '../../../../../library/EventDetailsRaw/EventDetailsRaw'
import SolidButton from '../../../../../library/SolidButton/SolidButton'
import SolidIconButton from '../../../../../library/SolidIconButton/SolidIconButton'
import useClickOutside from '../../../../../../hooks/utils/useClickOutside'
import useClosePanel from '../../../../../../hooks/utils/useClosePanel'
import { cn } from '../../../../../../utils/cn'

interface LogDetailsPanelProps {
  logs: LogEntry[]
  onViewContext: (log: LogEntry) => Promise<void>
}

const LogDetailsPanel = ({ logs, onViewContext }: LogDetailsPanelProps) => {
  useInitLogDetails()

  const [navLog, setNavLog] = useNavLog()
  const [format, setFormat] = useState<'formatted' | 'raw'>('formatted')

  const sidepanelRef = useRef<HTMLDivElement>(null)
  const [isClosing, handleClose] = useClosePanel()
  useClickOutside(
    sidepanelRef,
    () => {
      setNavLog({ logId: null, logTimestamp: null })
      handleClose()
    },
    undefined,
    ['log-row'],
  )

  const logDetails = logs.find((log) => log.log_id === navLog.logId)
  if (!logDetails || !navLog) return null

  return (
    <div className="absolute z-[5] top-0 left-0 w-full h-full flex flex-col items-end overflow-hidden pointer-events-none">
      <div
        className={cn(
          'h-full w-[600px] flex bg-panel-bg border-l border-panel-border slide-panel pointer-events-auto overflow-y-auto',
          isClosing ? 'slide-panel-exit' : '',
        )}
        ref={sidepanelRef}
      >
        <div className="w-full p-4 flex flex-col gap-4">
          <div className="w-full flex items-center justify-between">
            <p className="text-text-1 text-mini-medium">Log Details</p>
            <div className="flex items-center gap-2">
              <SolidTabs
                tabs={formatTabOptions}
                activeTab={format}
                setActiveTab={setFormat}
              />
              <SolidButton onClick={() => onViewContext(logDetails)}>
                View Context
              </SolidButton>
              <SolidIconButton
                icon={<X size={16} />}
                onClick={() => {
                  setNavLog({ logId: null, logTimestamp: null })
                  handleClose()
                }}
              />
            </div>
          </div>
          {format === 'formatted' && (
            <>
              <EventDetailsRow
                label={'timestamp'}
                value={formatTimeAsDate(logDetails.timestamp)}
              />
              <EventDetailsRow
                label={'service.name'}
                value={logDetails.attributes['service.name']}
              />
              <EventDetailsRow
                label={'severity'}
                value={logDetails.severity_name}
              />
              <EventDetailsRow label={'body'} value={logDetails.body} />
              {filteredAttributes(logDetails.attributes).map(([key, value]) => (
                <EventDetailsRow key={key} label={key} value={value} />
              ))}
            </>
          )}
          {format === 'raw' && (
            <EventDetailsRaw value={JSON.stringify(logDetails, null, 2)} />
          )}
        </div>
      </div>
    </div>
  )
}

const filteredAttributes = (attributes: Record<string, string>) => {
  return Object.entries(attributes).filter(([key]) => key !== 'service.name')
}

const formatTabOptions: SolidTab<'formatted' | 'raw'>[] = [
  { label: 'Formatted', value: 'formatted' },
  { label: 'Raw', value: 'raw' },
]

export default LogDetailsPanel
