import { useAtomValue } from 'jotai'
import { analyticsAtom, analyticsLayoutAtom } from '../../../../../state/state'
import AnalyticsEntryDisplay from '../../../../library/AnalyticsEntryDisplay/AnalyticsEntryDisplay'
import {
  AnalyticsEntry,
  AnalyticsLayout,
  AnalyticsUpdateRequest,
} from '../../../../../backend/types'
import { ArrowUp, ArrowDown, ArrowLeft, ArrowRight } from 'lucide-react'
import IconButton from '../../../../library/IconButton/IconButton'
import {
  AnalyticsTimeRange,
  getEndTime,
  getStartTime,
} from '../../../../../utils/analytics'

interface AnalyticsDisplayProps {
  timeRange: AnalyticsTimeRange
  refreshing: boolean
  rearranging: boolean
  onMove: (id: string, direction: 'up' | 'down' | 'left' | 'right') => void
  onUpdate: (id: string, settings: AnalyticsUpdateRequest) => void
  onDuplicate: (id: string) => void
  onDelete: (id: string) => void
}

const AnalyticsDisplay = ({
  timeRange,
  rearranging,
  refreshing,
  onUpdate,
  onDuplicate,
  onDelete,
  onMove,
}: AnalyticsDisplayProps) => {
  const analytics = useAtomValue(analyticsAtom)
  const analyticsLayout = useAtomValue(analyticsLayoutAtom)
  if (!analyticsLayout) return null

  const rows = getRows(analytics, analyticsLayout)

  return (
    <div className="p-6 pt-0 w-full">
      {rows.map((row, rowIndex) => (
        <div
          key={`row-${rowIndex}`}
          className="grid grid-cols-4 items-start justify-start gap-6 mb-6"
        >
          {row.map((rowEntry, index) => {
            switch (rowEntry.type) {
              case 'empty':
                return (
                  <RenderCellEmpty
                    key={`empty-${rowIndex}-${index}`}
                    cell={rowEntry}
                  />
                )
              case 'valid':
                return (
                  <RenderCell
                    id={rowEntry.entry.id}
                    key={`entry-${rowEntry.entry.id}-${rowIndex}-${index}`}
                    cell={rowEntry}
                    firstRow={rowIndex === 0}
                    lastRow={rowIndex === analyticsLayout.rows.length - 1}
                    firstColumn={index === 0}
                    lastColumn={index === row.length - 1}
                    emptyRow={row.length === 1}
                    timeRange={timeRange}
                    refreshing={refreshing}
                    rearranging={rearranging}
                    onUpdate={onUpdate}
                    onDuplicate={onDuplicate}
                    onDelete={onDelete}
                    onMove={onMove}
                  />
                )
              default:
                return null
            }
          })}
        </div>
      ))}
    </div>
  )
}

interface RenderCellEmptyProps {
  cell: RowEntryEmpty
}

const RenderCellEmpty = ({ cell }: RenderCellEmptyProps) => {
  return <div className={`relative ${getWidthMode(cell.spanCount)}`} />
}

interface RenderCellProps {
  id: string
  cell: RowEntryValid
  firstRow: boolean
  lastRow: boolean
  firstColumn: boolean
  lastColumn: boolean
  emptyRow: boolean
  timeRange: AnalyticsTimeRange
  refreshing: boolean
  rearranging: boolean
  onUpdate: (id: string, settings: AnalyticsUpdateRequest) => void
  onDuplicate: (id: string) => void
  onDelete: (id: string) => void
  onMove: (id: string, direction: 'up' | 'down' | 'left' | 'right') => void
}

const RenderCell = ({
  id,
  cell,
  firstRow,
  lastRow,
  firstColumn,
  lastColumn,
  emptyRow,
  timeRange,
  refreshing,
  rearranging,
  onUpdate,
  onDuplicate,
  onDelete,
  onMove,
}: RenderCellProps) => {
  return (
    <div
      id={`analytics-${id}`}
      className={`relative ${getWidthMode(cell.spanCount)}`}
    >
      <AnalyticsEntryDisplay
        entry={cell.entry}
        startTime={getStartTime(timeRange)}
        endTime={getEndTime(timeRange)}
        refreshing={refreshing}
        rearranging={rearranging}
        onUpdate={(update) => onUpdate(cell.entry.id, update)}
        onDuplicate={() => onDuplicate(cell.entry.id)}
        onDelete={() => onDelete(cell.entry.id)}
      />
      {rearranging && (
        <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center gap-2 z-[1]">
          <div className="p-2 flex items-center justify-center bg-popup-bg border border-popup-border rounded">
            <div className="flex items-center justify-center">
              <IconButton
                icon={<ArrowLeft size={16} />}
                onClick={() => onMove(cell.entry.id, 'left')}
                disabled={firstColumn}
              />
              <div className="flex flex-col justify-center gap-6">
                <IconButton
                  icon={<ArrowUp size={16} />}
                  onClick={() => onMove(cell.entry.id, 'up')}
                  disabled={firstRow && emptyRow}
                />
                <IconButton
                  icon={<ArrowDown size={16} />}
                  onClick={() => onMove(cell.entry.id, 'down')}
                  disabled={lastRow && emptyRow}
                />
              </div>
              <div className="flex flex-col justify-center gap-2">
                <IconButton
                  icon={<ArrowRight size={16} />}
                  onClick={() => onMove(cell.entry.id, 'right')}
                  disabled={lastColumn}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const getWidthMode = (spanCount: number) => {
  switch (spanCount) {
    case 2:
      return 'col-span-2 md:col-span-2'
    case 1:
      return 'col-span-1 md:col-span-1'
    default:
      return 'col-span-1 md:col-span-1'
  }
}

type RowEntryEmpty = {
  type: 'empty'
  spanCount: number
}

type RowEntryValid = {
  type: 'valid'
  spanCount: number
  entry: AnalyticsEntry
}

type RowEntry = RowEntryEmpty | RowEntryValid

function getRows(
  analytics: AnalyticsEntry[],
  analyticsLayout: AnalyticsLayout,
): RowEntry[][] {
  const rows: RowEntry[][] = []

  for (const row of analyticsLayout.rows) {
    const rowEntries: RowEntry[] = []
    let index = 0

    while (index < row.length) {
      const cellId = row[index]
      const entry = analytics.find((entry) => entry.id === cellId)
      if (!entry) {
        rowEntries.push({ type: 'empty', spanCount: 1 })
        index++
      } else {
        const spanCount = getSpanCount(row, index, cellId)
        rowEntries.push({ type: 'valid', spanCount, entry })
        index += spanCount
      }
    }

    rows.push(rowEntries)
  }

  return rows
}

function getSpanCount(row: string[], index: number, cellId: string): number {
  let spanCount = 1
  let nextIndex = index + 1

  while (nextIndex < row.length && row[nextIndex] === cellId) {
    spanCount++
    nextIndex++
  }

  return spanCount
}

export default AnalyticsDisplay
