import { useAtom } from 'jotai'
import { cn } from '../../../../../../../utils/cn'
import persistAtom from '../../../../../../../state/persistAtom'
import { ListFilter } from 'lucide-react'

export const sidebarVisibleAtom = persistAtom<boolean>({
  key: 'logsSidebarVisible',
  defaultValue: true,
  persistMode: 'local',
})

interface FilterButtonProps {
  className?: string
}

const FilterButton = ({ className }: FilterButtonProps) => {
  const [sidebarVisible, setSidebarVisible] = useAtom(sidebarVisibleAtom)
  return (
    <button
      className={cn(
        'px-2 py-2 flex items-center justify-center gap-2 text-small-light rounded bg-input-bgTint border border-input-border hover:bg-input-tint hover:text-text-1',
        sidebarVisible ? 'text-text-1' : 'text-text-2',
        className,
      )}
      onClick={() => setSidebarVisible(!sidebarVisible)}
    >
      <ListFilter size={16} />
      Filter
    </button>
  )
}

export default FilterButton
