import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export enum LinkKey {
  Signup = 'signup',
  Login = 'login',
  Welcome = 'welcome',
  NewOrganization = 'organization',
  NewProject = 'project',
  Invite = 'invite',
  Start = 'start',
  Logs = 'logs',
  Metrics = 'metrics',
  Analytics = 'analytics',
  Alerts = 'alerts',
  SettingsOrganizationGeneral = 'settingsOrganizationGeneral',
  SettingsOrganizationTeam = 'settingsOrganizationTeam',
  SettingsOrganizationBilling = 'settingsOrganizationBilling',
  SettingsProjectGeneral = 'settingsProjectGeneral',
  SettingsProjectAPI = 'settingsProjectAPI',
  SettingsProjectAlerts = 'settingsProjectAlerts',
  SettingsUserProfile = 'settingsUserProfile',
  SetupLogs = 'setupLogs',
  SetupLogsNodeJS = 'setupLogsNodeJS',
  SetupLogsNextJS = 'setupLogsNextJS',
  SetupLogsReact = 'setupLogsReact',
  SetupLogsReactNative = 'setupLogsReactNative',
  SetupLogsPython = 'setupLogsPython',
  SetupLogsGo = 'setupLogsGo',
  SetupLogsRuby = 'setupLogsRuby',
  SetupLogsRails = 'setupLogsRails',
  SetupLogsDart = 'setupLogsDart',
  SetupLogsRust = 'setupLogsRust',
  SetupLogsRustLog = 'setupLogsRustLog',
  SetupLogsRustTracing = 'setupLogsRustTracing',
  SetupLogsCpp = 'setupLogsCpp',
  SetupLogsRender = 'setupLogsRender',
  SetupLogsFlyIO = 'setupLogsFlyIO',
  SetupMetrics = 'setupMetrics',
  SetupMetricsGo = 'setupMetricsGo',
  SetupMetricsNodeJS = 'setupMetricsNodeJS',
  SetupMetricsPython = 'setupMetricsPython',
  SetupAlerts = 'setupAlerts',
  SetupAlertsGo = 'setupAlertsGo',
}

export const linkMap: Record<LinkKey, string> = {
  signup: '/signup',
  login: '/login',
  welcome: '/welcome',
  organization: '/organization',
  project: '/project',
  invite: '/invite',
  start: '/start',
  logs: '/logs',
  metrics: '/metrics',
  analytics: '/analytics',
  alerts: '/alerts',
  settingsOrganizationGeneral: '/settings/organization/general',
  settingsOrganizationTeam: '/settings/organization/team',
  settingsOrganizationBilling: '/settings/organization/billing',
  settingsProjectGeneral: '/settings/project/general',
  settingsProjectAPI: '/settings/project/api',
  settingsProjectAlerts: '/settings/project/alerts',
  settingsUserProfile: '/settings/user/profile',
  setupLogs: '/setup/logs',
  setupLogsNodeJS: '/setup/logs/nodejs',
  setupLogsNextJS: '/setup/logs/nextjs',
  setupLogsReact: '/setup/logs/react',
  setupLogsReactNative: '/setup/logs/react-native',
  setupLogsPython: '/setup/logs/python',
  setupLogsGo: '/setup/logs/go',
  setupLogsRuby: '/setup/logs/ruby',
  setupLogsRails: '/setup/logs/ruby-rails',
  setupLogsDart: '/setup/logs/dart',
  setupLogsRust: '/setup/logs/rust',
  setupLogsRustLog: '/setup/logs/rust-log',
  setupLogsRustTracing: '/setup/logs/rust-tracing',
  setupLogsCpp: '/setup/logs/cpp',
  setupLogsRender: '/setup/logs/render',
  setupLogsFlyIO: '/setup/logs/flyio',
  setupMetrics: '/setup/metrics',
  setupMetricsGo: '/setup/metrics/go',
  setupMetricsNodeJS: '/setup/metrics/nodejs',
  setupMetricsPython: '/setup/metrics/python',
  setupAlerts: '/setup/alerts',
  setupAlertsGo: '/setup/alerts/go',
}

export const isInternalLink = (key: LinkKey) => linkMap[key].startsWith('/')

const useNav = () => {
  const navigate = useNavigate()

  return useCallback(
    (key: LinkKey, params?: Record<string, string>) => {
      const link = linkMap[key]
      let path = link
      if (params) {
        let queryParams = ''
        Object.entries(params).forEach(([key, value]) => {
          queryParams += `${key}=${value}&`
        })
        path += `?${queryParams}`
      }

      if (isInternalLink(key)) {
        navigate(path)
      } else {
        window.open(path, '_blank')
      }
    },
    [navigate],
  )
}

export default useNav
