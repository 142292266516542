import { cn } from '../../../utils/cn'

export type SolidTab<T> = {
  label: string
  value: T
}

interface SolidTabsProps<T> {
  tabs: SolidTab<T>[]
  activeTab: T | undefined
  setActiveTab: (tab: T) => void
}

const SolidTabs = <T,>({
  tabs,
  activeTab,
  setActiveTab,
}: SolidTabsProps<T>) => {
  return (
    <div className="px-1 h-8 flex gap-1 items-center justify-center rounded bg-input-bgTint border border-input-border">
      {tabs.map((tab) => (
        <div
          key={tab.label}
          className={cn(
            'px-[10px] py-1 text-small-light rounded-[2px] cursor-pointer',
            activeTab === tab.value
              ? 'bg-input-tint text-text-1'
              : 'hover:bg-input-tint text-text-2',
          )}
          onClick={() => setActiveTab(tab.value)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  )
}

export default SolidTabs
