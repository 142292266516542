import SettingsSection from '../../../../../library/SettingsSection/SettingsSection'
import SettingsTitle from '../../../../../library/SettingsTitle/SettingsTitle'
import UsageChart from './UsageChart/UsageChart'
import BillingMatrix from './BillingMatrix/BillingMatrix'
import BillingChart from './BillingChart/BillingChart'
import { useAtomValue } from 'jotai'
import {
  billingInfoAtom,
  organizationInfoAtom,
  organizationMembersAtom,
  userInfoAtom,
} from '../../../../../../state/state'
import useCreateBillingPortalSession from '../../../../../../hooks/actions/useCreateBillingPortalSession'
import { OrganizationMember } from '../../../../../../backend/types'
import { useMemo } from 'react'
import useInitOrganizationMembers from '../../../../../../hooks/data/effects/useInitOrganizationMemebers'

const OrganizationBillingSettings = () => {
  useInitOrganizationMembers()

  const createSession = useCreateBillingPortalSession()

  const organizationInfo = useAtomValue(organizationInfoAtom)
  const organizationMembers = useAtomValue(organizationMembersAtom)
  const billingInfo = useAtomValue(billingInfoAtom)
  const userInfo = useAtomValue(userInfoAtom)
  const isOwner = useMemo(
    () => isOrgOwner(userInfo?.id, organizationMembers ?? []),
    [userInfo?.id, organizationMembers],
  )

  if (!organizationInfo || !userInfo) return null

  return (
    <div className="flex-1 px-12 py-12 flex flex-col items-center gap-6 overflow-y-auto">
      <SettingsSection>
        <SettingsTitle
          title="Billing and Usage"
          description="We charge at the start of each month. Here you can see your projected usage and bill."
          button={
            isOwner && billingInfo
              ? billingInfo.payment_method_added
                ? 'Manage Payments'
                : 'Add Payment Method'
              : undefined
          }
          onClick={() => {
            createSession().then((url) => {
              window.open(url, '_blank')
            })
          }}
        />
        <BillingMatrix organizationInfo={organizationInfo} />
      </SettingsSection>

      <SettingsSection>
        <SettingsTitle
          title="Logs"
          description="This shows the volume of logs you're sending to Vigilant across all your projects."
        />
        <div className="w-full py-16 flex flex-col items-start justify-center gap-16">
          <BillingChart organizationInfo={organizationInfo} type="logs" />
          <UsageChart type="logs" />
        </div>
      </SettingsSection>

      <SettingsSection>
        <SettingsTitle
          title="Metrics"
          description="This shows the volume of metrics you're sending to Vigilant across all your projects."
        />
        <div className="w-full py-16 flex flex-col items-start justify-center gap-16">
          <BillingChart organizationInfo={organizationInfo} type="metrics" />
          <UsageChart type="metrics" />
        </div>
      </SettingsSection>
    </div>
  )
}

function isOrgOwner(userId: string, organizationMembers: OrganizationMember[]) {
  return (
    organizationMembers.find((member) => member.user_id === userId)?.role ===
    'owner'
  )
}

export default OrganizationBillingSettings
