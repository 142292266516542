import { useState } from 'react'
import { MetricEntry, MetricsDetails } from '../../../../../backend/types'
import { cn } from '../../../../../utils/cn'
import { formatNumber } from '../../../../../utils/properties'
import { formatTimestamp } from '../../../../../utils/time'
import MetricEntryDetails from './MetricEntryDetails/MetricEntryDetails'
import { getMetricColumnWidth } from '../../../../../utils/metrics'

interface MetricDisplayProps {
  metricName: string
  metricDetails: MetricsDetails
}

const MetricDisplay = ({ metricName, metricDetails }: MetricDisplayProps) => {
  const { count, entries } = metricDetails
  const keys = getSortedAttributeKeys(entries)
  const columnWidths = getMetricColumnWidths(entries, keys)

  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null)
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const selectedEntry = selectedIndex !== null ? entries[selectedIndex] : null

  return (
    <>
      <div className="w-full flex-none flex flex-col items-start gap-1">
        <p className="text-normal-code text-text-1">{metricName}</p>
        <p className="text-mini-light text-text-2">
          Total Count: {formatNumber(count)}
        </p>
      </div>
      <div className="max-w-full flex-1 flex flex-col items-start gap-2 overflow-hidden">
        <p className="text-normal-medium text-text-1">History</p>
        <div className="w-full flex flex-col border border-main-border rounded-[4px] z-[0] overflow-auto">
          <div className="top-0 sticky flex z-[1]">
            <HeaderCell width={columnWidths[0]}>timestamp</HeaderCell>
            <HeaderCell width={columnWidths[1]}>name</HeaderCell>
            <HeaderCell width={columnWidths[2]}>value</HeaderCell>
            {keys.map((key, i) => (
              <HeaderCell
                key={key}
                width={columnWidths[i + 3]}
                lastCol={i === keys.length - 1}
              >
                {key}
              </HeaderCell>
            ))}
          </div>
          {entries.map((entry, i) => (
            <Row
              key={`${entry.timestamp}-${i}`}
              index={i}
              lastRow={i === entries.length - 1}
              entry={entry}
              widths={columnWidths}
              highlightedIndex={highlightedIndex}
              selectedIndex={selectedIndex}
              setHighlightedIndex={setHighlightedIndex}
              setSelectedIndex={setSelectedIndex}
              attributeKeys={keys}
            />
          ))}
        </div>
      </div>
      <MetricEntryDetails
        metricEntry={selectedEntry}
        close={() => setSelectedIndex(null)}
      />
    </>
  )
}

interface HeaderCellProps {
  children: React.ReactNode
  lastCol?: boolean
  width?: number
}

const HeaderCell = ({ children, lastCol, width }: HeaderCellProps) => {
  return (
    <p
      className={cn(
        'p-2 flex-none text-small-code text-text-1 bg-panel-bg border-b border-r border-main-border',
        lastCol ? 'border-r-0' : '',
      )}
      style={{ width: width ? `${width}px` : 'auto' }}
    >
      {children}
    </p>
  )
}

interface RowProps {
  entry: MetricEntry

  index: number
  lastRow?: boolean

  attributeKeys: string[]
  widths: number[]

  highlightedIndex: number | null
  selectedIndex: number | null

  setHighlightedIndex: (index: number | null) => void
  setSelectedIndex: (index: number | null) => void
}

const Row = ({
  entry,
  index,
  lastRow,
  attributeKeys,
  widths,
  highlightedIndex,
  selectedIndex,
  setHighlightedIndex,
  setSelectedIndex,
}: RowProps) => {
  return (
    <div
      className="metric-row flex cursor-pointer"
      onMouseEnter={() => setHighlightedIndex(index)}
      onMouseLeave={() => setHighlightedIndex(null)}
      onClick={() => setSelectedIndex(index)}
    >
      <RowCell
        width={widths[0]}
        highlight={highlightedIndex === index}
        selected={selectedIndex === index}
        lastRow={lastRow}
      >
        {formatTimestamp(entry.timestamp)}
      </RowCell>
      <RowCell
        width={widths[1]}
        highlight={highlightedIndex === index}
        selected={selectedIndex === index}
        lastRow={lastRow}
      >
        {entry.name}
      </RowCell>
      <RowCell
        width={widths[2]}
        highlight={highlightedIndex === index}
        selected={selectedIndex === index}
        lastRow={lastRow}
      >
        {entry.value}
      </RowCell>
      {attributeKeys.map((key, attributeIndex) => (
        <RowCell
          key={`${entry.timestamp}-${index}-${attributeIndex}`}
          width={widths[attributeIndex + 3]}
          highlight={highlightedIndex === index}
          selected={selectedIndex === index}
          lastRow={lastRow}
          lastCol={attributeIndex === attributeKeys.length - 1}
        >
          {entry.attributes[key] ?? '-'}
        </RowCell>
      ))}
    </div>
  )
}

interface RowCellProps {
  children: React.ReactNode
  lastCol?: boolean
  lastRow?: boolean
  selected?: boolean
  highlight?: boolean
  width?: number
}

const RowCell = ({
  children,
  lastCol,
  lastRow,
  highlight,
  selected,
  width,
}: RowCellProps) => {
  return (
    <p
      className={cn(
        'px-2 py-1 flex-none text-small-code text-text-1 whitespace-nowrap border-r border-b border-main-border',
        highlight && !selected ? 'bg-input-bgTint' : '',
        selected ? 'bg-input-tint' : '',
        lastCol ? 'border-r-0' : '',
        lastRow ? 'border-b-0' : '',
      )}
      style={{ width: width ? `${width}px` : 'auto' }}
    >
      {children}
    </p>
  )
}

function getMetricColumnWidths(
  entries: MetricEntry[],
  keys: string[],
): number[] {
  return [
    getMetricColumnWidth('timestamp', entries),
    getMetricColumnWidth('name', entries),
    getMetricColumnWidth('value', entries),
    ...keys.map((key) => getMetricColumnWidth(key, entries)),
  ]
}

function getSortedAttributeKeys(entries: MetricEntry[]): string[] {
  const keyCounts: { [key: string]: number } = {}

  for (const entry of entries) {
    for (const key of Object.keys(entry.attributes)) {
      keyCounts[key] = (keyCounts[key] || 0) + 1
    }
  }

  return Object.keys(keyCounts).sort((a, b) => keyCounts[b] - keyCounts[a])
}

export default MetricDisplay
