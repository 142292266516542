import React, { useRef } from 'react'
import useHovered from '../../../hooks/utils/useHover'
import { cn } from '../../../utils/cn'

interface OutlineIconTextButtonSmallProps {
  children: React.ReactNode
  active?: boolean
  onClick?: () => void
  buttonRef?: React.RefObject<HTMLButtonElement | null>
}

const OutlineIconTextButtonSmall = ({
  children,
  active,
  onClick,
  buttonRef,
}: OutlineIconTextButtonSmallProps) => {
  const internalRef = useRef<HTMLButtonElement>(null)
  const ref = buttonRef || internalRef

  const hovered = useHovered(ref)
  const hoverClass =
    hovered || active
      ? 'bg-input-tint text-text-1'
      : 'bg-transparent text-text-2'

  return (
    <button
      ref={ref}
      className={cn(
        'w-full h-[28px] px-2 flex items-center gap-2 rounded text-small-light',
        hoverClass,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default OutlineIconTextButtonSmall
