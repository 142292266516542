import useInitIntegrations from '../../../../../../hooks/data/effects/useInitIntegrations'
import useInitAlertSettings from '../../../../../../hooks/data/effects/useInitAlertSettings'
import AlertEmailSettings from './AlertEmailSettings/AlertEmailSettings'
import AlertSlackSettings from './AlertSlackSettings/AlertSlackSettings'

const ProjectAlertsSettings = () => {
  useInitIntegrations()
  useInitAlertSettings()

  return (
    <div className="flex-1 px-12 py-12 flex flex-col items-center gap-6 overflow-y-auto">
      <AlertEmailSettings />
      <AlertSlackSettings />
    </div>
  )
}

export default ProjectAlertsSettings
