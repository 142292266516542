import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import PreviewLogs from '../../../../library/PreviewLogs/PreviewLogs'
import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const SetupLogsCpp = () => {
  useSetBrandColor('info')

  return (
    <SetupTemplate
      title="Log Setup - C++"
      keyName="cpp-api-key"
      installStep={installSnippets}
      sendTitle="Send a log"
      sendEventStep={getSendMessageSnippets}
      preview={<PreviewLogs />}
      done={LinkKey.Logs}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'C++',
    language: 'c',
    displayLanguage: 'bash',
    content: `# Clone the repo
git clone git@github.com:vigilant-run/vigilant-cpp.git
cd vigilant-cpp

# Build the SDK
mkdir build
cd build
cmake ..
cmake --build .
cmake --install .`,
  },
]

function getSendMessageSnippets(key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'C++',
      language: 'c',
      displayLanguage: 'c',
      content: `#include "vigilant/logger.h"

int main()
{
  // Create a logger
  Logger logger = LoggerBuilder()
                    .withName("cpp-test")
                    .withToken("${key}")
                    .build();

  // Log a message
  logger.info("Hello, World!"); 

  // Shutdown the logger before your app terminates
  logger.shutdown();

  return 0;
}`,
    },
  ]
}

export default SetupLogsCpp
