import { useState } from 'react'
import { UsageSeries } from '../../../../../../../backend/types'
import Chart, {
  Grouping,
  GroupedSeries,
  ModeOption,
} from '../../../../../../library/Chart/Chart'
import useLoadUsageSeries, {
  UsageRange,
  UsageType,
} from '../../../../../../../hooks/data/load/useLoadUsageSeries'

interface UsageChartProps {
  type: UsageType
}

const UsageChart = ({ type }: UsageChartProps) => {
  const [usageRange, setUsageRange] = useState<UsageRange>('last_month')
  const usage = useLoadUsageSeries(usageRange, 'none', type)
  const cumulativeUsage = useLoadUsageSeries(usageRange, 'cumulative', type)

  const sortedUsageSeries = sortSeriesData(usage?.series ?? [])
  const sortedCumulativeSeries = sortSeriesData(cumulativeUsage?.series ?? [])
  const usageSeries = groupSeriesData(sortedUsageSeries)
  const cumulativeSeries = groupSeriesData(sortedCumulativeSeries)

  return (
    <>
      <Chart
        title={formatCumulativeUsageTitle(
          cumulativeUsage?.unit ?? 'B',
          cumulativeUsage?.grouping ?? 'hourly',
        )}
        loading={cumulativeUsage === null}
        unit={cumulativeUsage?.unit ?? ''}
        grouping={cumulativeUsage?.grouping ?? 'hourly'}
        maxYAxisValue={getMaxYAxisValue(cumulativeSeries)}
        data={cumulativeSeries}
        mode={usageRange}
        modeOptions={usageRangeOptions}
        setMode={setUsageRange}
      />
      <Chart
        title={formatUsageTitle(
          usage?.unit ?? 'B',
          usage?.grouping ?? 'hourly',
        )}
        loading={usage === null}
        unit={usage?.unit ?? ''}
        grouping={usage?.grouping ?? 'hourly'}
        maxYAxisValue={getMaxYAxisValue(usageSeries)}
        data={usageSeries}
        mode={usageRange}
        modeOptions={usageRangeOptions}
        setMode={setUsageRange}
      />
    </>
  )
}

const usageRangeOptions: ModeOption<UsageRange>[] = [
  { label: 'Today', value: 'last_day' },
  { label: 'This Week', value: 'last_week' },
  { label: 'This Month', value: 'last_month' },
]

function getMaxYAxisValue(series: GroupedSeries[]): number {
  return Math.max(
    ...series.map((s) => s.values.reduce((acc, v) => acc + v.value, 0)),
  )
}

function formatUsageTitle(unit: string, grouping: Grouping): string {
  switch (grouping) {
    case 'hourly':
      return `Ingested Volume (${formatUnit(unit)}) - Hourly`
    case 'daily':
      return `Ingested Volume (${formatUnit(unit)}) - Daily`
    case 'weekly':
      return `Ingested Volume (${formatUnit(unit)}) - Weekly`
    case 'monthly':
      return `Ingested Volume (${formatUnit(unit)}) - Monthly`
  }
}

function formatCumulativeUsageTitle(unit: string, grouping: Grouping): string {
  switch (grouping) {
    case 'hourly':
      return `Ingested Volume (${formatUnit(unit)}) - Hourly (Cumulative)`
    case 'daily':
      return `Ingested Volume (${formatUnit(unit)}) - Daily (Cumulative)`
    case 'weekly':
      return `Ingested Volume (${formatUnit(unit)}) - Weekly (Cumulative)`
    case 'monthly':
      return `Ingested Volume (${formatUnit(unit)}) - Monthly (Cumulative)`
  }
}

function formatUnit(unit: string): string {
  switch (unit) {
    case 'B':
      return 'bytes'
    case 'KB':
      return 'kilobytes'
    case 'MB':
      return 'megabytes'
    case 'GB':
      return 'gigabytes'
    default:
      return unit
  }
}

function sortSeriesData(data: UsageSeries[]): UsageSeries[] {
  return [...data].sort((a, b) => {
    const maxA = Math.max(...a.points.map((point) => point.value))
    const maxB = Math.max(...b.points.map((point) => point.value))
    return maxB - maxA
  })
}

function groupSeriesData(data: UsageSeries[]): GroupedSeries[] {
  const dateMap = new Map<string, GroupedSeries>()

  data.forEach((series, seriesIndex) => {
    series.points.forEach((point) => {
      if (!dateMap.has(point.time)) {
        dateMap.set(point.time, {
          time: new Date(point.time),
          values: new Array(data.length).fill({
            name: series.name,
            value: 0,
          }),
        })
      }

      const entry = dateMap.get(point.time)!
      entry.values[seriesIndex] = {
        name: series.name,
        value: point.value,
      }
    })
  })

  return Array.from(dateMap.values()).sort(
    (a, b) => a.time.getTime() - b.time.getTime(),
  )
}

export default UsageChart
