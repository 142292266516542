import { useCallback, useEffect, useState } from 'react'
import { GetUsageTotalsResponse } from '../../../backend/types'
import useBackend from '../../services/useBackend'
import { instanceIdAtom } from '../../../state/state'
import { useAtomValue } from 'jotai'
import { BackendService } from '../../../backend/backend'
import { UsageType } from './useLoadUsageSeries'
import { captureAxiosError } from '../../../utils/obeservability'

const useLoadUsageTotals = (
  type: UsageType,
  date: string,
): GetUsageTotalsResponse | null => {
  const backend = useBackend()

  const { organizationId } = useAtomValue(instanceIdAtom)

  const [usage, setUsage] = useState<GetUsageTotalsResponse | null>(null)

  const loadUsage = useCallback(async () => {
    try {
      if (!organizationId) return
      const response = await getBackendFunction(backend, type)(
        organizationId,
        getStartDate(date),
        getEndDate(),
      )
      if (response === null) return
      setUsage(response)
    } catch (e) {
      captureAxiosError(e)
    }
  }, [backend, organizationId, type, date])

  useEffect(() => {
    loadUsage()
  }, [loadUsage])

  return usage
}

function getBackendFunction(backend: BackendService, type: UsageType) {
  switch (type) {
    case 'logs':
      return backend.getLogsUsageTotals
    case 'metrics':
      return backend.getMetricsUsageTotals
  }
}

function getStartDate(billingDateStr: string): string {
  const billingDate = new Date(billingDateStr)
  const billingDay = billingDate.getUTCDate()

  const now = new Date()
  const currentUTCYear = now.getUTCFullYear()
  const currentUTCMonth = now.getUTCMonth() + 1
  const currentUTCDay = now.getUTCDate()

  let startYear = currentUTCYear
  let startMonth = currentUTCMonth

  if (currentUTCDay < billingDay) {
    startMonth -= 1
    if (startMonth === 0) {
      startMonth = 12
      startYear -= 1
    }
  }

  const startDate = new Date(
    Date.UTC(startYear, startMonth - 1, billingDay, 0, 0, 0, 0),
  )
  return startDate.toISOString()
}

function getEndDate(): string {
  const now = new Date()
  now.setUTCHours(23, 59, 59, 999)
  return now.toISOString()
}

export default useLoadUsageTotals
