import { useSearchParams } from 'react-router-dom'
import { useEffect, useCallback } from 'react'
import { useSetAtom } from 'jotai'
import { joinOrganizationTokenAtom } from '../../../state/state'
import useNav from '../../../hooks/nav/useNav'
import useBackend from '../../../hooks/services/useBackend'
import { LinkKey } from '../../../hooks/nav/useNav'
import useJoinOrganization from '../../../hooks/actions/useJoinOrganization'

const InviteAccept: React.FC = () => {
  const backend = useBackend()
  const navigate = useNav()

  const setJoinOrganizationToken = useSetAtom(joinOrganizationTokenAtom)
  const joinOrganization = useJoinOrganization()

  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const handleJoinOrganization = useCallback(async () => {
    if (token) {
      try {
        await backend.refresh()
        await joinOrganization(token)
        navigate(LinkKey.Logs)
      } catch (e) {
        setJoinOrganizationToken(token)
        navigate(LinkKey.Signup)
      }
    }
  }, [token, setJoinOrganizationToken, backend, joinOrganization, navigate])

  useEffect(() => {
    handleJoinOrganization()
  }, [handleJoinOrganization])

  return null
}

export default InviteAccept
