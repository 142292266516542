import { UsageType } from '../../../../../../../hooks/data/load/useLoadUsageSeries'
import useLoadUsageTotals from '../../../../../../../hooks/data/load/useLoadUsageTotals'
import { Organization } from '../../../../../../../state/types'
import {
  formatCurrency,
  computeCost,
  formatUsage,
  getFreeTier,
  formatBillingDate,
  computeProjectedValue,
} from '../../../../../../../utils/billing'
import { getPercent } from '../../../../../../../utils/billing'

interface BillingChartProps {
  organizationInfo: Organization
  type: UsageType
}

const BillingChart = ({ organizationInfo, type }: BillingChartProps) => {
  const usageTotals = useLoadUsageTotals(type, organizationInfo.billing_date)

  const usage = usageTotals?.total ?? 0
  const projected = computeProjectedValue(usage, organizationInfo)
  const free = getFreeTier(type)
  const maxValue = Math.max(usage, projected, free)

  return (
    <div className="w-full flex flex-col items-start justify-center gap-2">
      <div className="w-full flex items-center gap-6">
        <div className="w-[240px] flex items-center gap-2">
          <p className="flex-1 text-normal-light text-text-2">
            Usage this month
          </p>
          <p className="text-normal-light text-text-1">
            {formatUsage(usage, type)}
          </p>
        </div>
        <div className="flex-1 h-[22px] flex items-center gap-2">
          <div
            className="full min-w-1 h-3 bg-brand-infoTint border border-brand-info rounded-[4px]"
            style={{ width: getPercent(usage, maxValue) }}
          />
        </div>
        <div className="w-[240px] flex items-center gap-2">
          <p className="flex-1 text-normal-light text-text-2">Current cost</p>
          <p className="text-normal-light text-text-1">
            {formatCurrency(computeCost(usage, type, organizationInfo))}
          </p>
        </div>
      </div>
      <div className="w-full flex items-center gap-6">
        <div className="w-[240px] flex items-center gap-2">
          <p className="flex-1 text-normal-light text-text-2">
            Projected usage
          </p>
          <p className="text-normal-light text-text-1">
            {formatUsage(projected, type)}
          </p>
        </div>
        <div className="flex-1 h-[22px] flex items-center gap-2">
          <div
            className="w-full min-w-1 h-3 bg-brand-warningTint border border-brand-warning rounded-[4px]"
            style={{ width: getPercent(projected, maxValue) }}
          />
        </div>
        <div className="w-[240px] flex items-center gap-2">
          <p className="flex-1 text-normal-light text-text-2">Projected cost</p>
          <p className="text-normal-light text-text-1">
            {formatCurrency(computeCost(projected, type, organizationInfo))}
          </p>
        </div>
      </div>
      <div className="w-full flex items-center gap-6">
        <div className="w-[240px] flex items-center gap-2">
          <p className="flex-1 text-normal-light text-text-2">Free tier</p>
          <p className="text-normal-light text-text-1">
            {formatUsage(free, type)}
          </p>
        </div>
        <div className="flex-1 h-[22px] flex items-center gap-2">
          <div
            className="w-full min-w-1 h-3 bg-brand-successTint border border-brand-success rounded-[4px]"
            style={{ width: getPercent(free, maxValue) }}
          />
        </div>
        <div className="w-[240px] flex items-center gap-2">
          <p className="flex-1 text-normal-light text-text-2">Billing date</p>
          <p className="text-normal-light text-text-1">
            {formatBillingDate(organizationInfo.billing_date)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default BillingChart
