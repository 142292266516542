import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Filter, LogCursor } from '../../../../backend/types'
import { useAtom, useAtomValue } from 'jotai'
import { TimeRange } from '../../../../utils/time'
import persistAtom from '../../../../state/persistAtom'
import useQueryManager from '../../../../hooks/stateServices/useQueryManager'
import useQueryParameterManager from '../../../../hooks/stateServices/useQueryParameterManager'
import LogDisplay from './LogDisplay/LogDisplay'
import { getHeaderWidth } from '../../../../utils/logs'
import useSetBrandColor from '../../../../hooks/utils/useSetBrandColor'
import { QueryParameters, QueryState } from '../../../../library/query/types'
import useQueryLiveTick from '../../../../hooks/stateServices/useQueryLiveTick'
import {
  COLUMN_TIMESTAMP,
  COLUMN_LEVEL,
  ATTRIBUTE_SERVICE,
  COLUMN_BODY,
  modifiedBlankFilter,
} from '../../../../utils/properties'
import { genKey } from '../../../../utils/sub'
import useAddRecentSearch from '../../../../hooks/actions/useAddRecentSearch'
import { useNavLog } from '../../../../hooks/nav/useNavLog'
import { logLiveDefaultAtom } from '../../../../state/state'
import useInitHasData from '../../../../hooks/data/effects/useInitHasData'

export type Header = {
  value: string
  width: number
}

export const headersAtom = persistAtom<Header[]>({
  key: 'logsVisibleProperties',
  defaultValue: [
    { value: COLUMN_TIMESTAMP, width: 160 },
    { value: COLUMN_LEVEL, width: 80 },
    { value: ATTRIBUTE_SERVICE, width: 160 },
    { value: COLUMN_BODY, width: 1000 },
  ],
  persistMode: 'local',
})

const Logs = () => {
  useSetBrandColor('info')
  useInitHasData()

  const [, setNavLog] = useNavLog()

  const defaultParams = useMemo(() => getDefaultParams(), [])
  const queryManager = useQueryManager()
  const queryParameterManager = useQueryParameterManager(defaultParams)
  const addRecentSearch = useAddRecentSearch()

  const logContainerRef = useRef<HTMLDivElement | null>(null)

  const [headers, setHeaders] = useAtom(headersAtom)
  const logLiveDefault = useAtomValue(logLiveDefaultAtom)

  const [queryParams, setQueryParams] = useState<QueryParameters>(defaultParams)
  const [isLive, setIsLive] = useQueryLiveTick(queryManager, logLiveDefault)
  const [hasInit, setHasInit] = useState(false)

  const [state, setState] = useState<QueryState | null>(null)

  const handleScroll = useCallback(async () => {
    const container = logContainerRef.current
    if (!container || !queryManager) return

    const top = container.scrollTop

    const isAtTop = container.scrollTop === 0
    const isCloseToTop = top <= 264
    const isCloseToBottom =
      top + container.clientHeight + 264 >= container.scrollHeight

    if (isCloseToBottom && state?.mode === 'none' && !state?.result?.atEnd) {
      await queryManager.loadMoreDown(state)
    }
    if (isCloseToTop && state?.mode === 'none' && !state?.result?.atStart) {
      await queryManager.loadMoreUp(state)
    }
    if (!isAtTop) {
      setIsLive(false)
    }
    if (isAtTop && logLiveDefault) {
      setIsLive(true)
    }
  }, [queryManager, setIsLive, state, logLiveDefault])

  const handleSetIsLive = useCallback(
    async (isLive: boolean) => {
      setIsLive(isLive)
      const container = logContainerRef.current
      if (container) container.scrollTop = 0
      setNavLog(null)
    },
    [setIsLive, setNavLog],
  )

  const handleUpdateQueryParams = useCallback(
    (params: Partial<QueryParameters>) => {
      queryParameterManager.updateParams(params)
    },
    [queryParameterManager],
  )

  const handleSetTerm = useCallback(
    async (term: string) => {
      if (!state) return
      handleUpdateQueryParams({ term })
      setNavLog(null)
      await Promise.all([
        queryManager?.query(state, { ...queryParams, term }),
        addRecentSearch(term),
      ])
    },
    [
      state,
      handleUpdateQueryParams,
      setNavLog,
      queryManager,
      queryParams,
      addRecentSearch,
    ],
  )

  const handleSetTimeRange = useCallback(
    async (time_range: TimeRange) => {
      if (!state) return
      handleUpdateQueryParams({ time_range })
      setNavLog(null)
      await queryManager?.query(state, {
        ...queryParams,
        time_range,
      })
    },
    [handleUpdateQueryParams, setNavLog, queryManager, queryParams, state],
  )

  const handleSetFilters = useCallback(
    async (filters: Filter[]) => {
      if (!state) return
      handleUpdateQueryParams({ filters })
      setNavLog(null)
      if (modifiedBlankFilter(queryParams.filters, filters)) return
      await queryManager?.query(state, { ...queryParams, filters })
    },
    [handleUpdateQueryParams, setNavLog, queryManager, queryParams, state],
  )

  const handleToggleHeader = useCallback(
    async (value: string) => {
      const exists = headers.some((h) => h.value === value)
      let newHeaders = [...headers]
      if (exists) {
        newHeaders = newHeaders.filter((h) => h.value !== value)
      } else {
        const width = getHeaderWidth(value, state?.result?.logs ?? [])
        newHeaders = [...headers, { value, width }]
      }
      setHeaders(newHeaders)
    },
    [headers, setHeaders, state?.result?.logs],
  )

  const handleSetHeaders = useCallback(
    async (headers: Header[]) => {
      setHeaders(headers)
    },
    [setHeaders],
  )

  const handleRunSearch = useCallback(async () => {
    if (!state) return
    await queryManager?.query(state, queryParams)
  }, [queryManager, queryParams, state])

  const handleViewContext = useCallback(
    async (cursor_focus: LogCursor) => {
      if (!state) return
      const paramsUpdate = {
        term: '',
        time_range: { start: undefined, end: undefined },
        filters: [],
        cursor_focus: cursor_focus,
      }
      queryParameterManager.updateParams(paramsUpdate)
      await queryManager?.viewContext(state, {
        ...queryParams,
        ...paramsUpdate,
      })
    },
    [queryManager, queryParameterManager, queryParams, state],
  )

  useEffect(() => {
    const container = logContainerRef.current
    if (!container) return

    container.addEventListener('scroll', handleScroll)
    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, logContainerRef])

  useEffect(() => {
    if (!queryManager || hasInit) return

    const init = async () => {
      const key = genKey()
      queryManager.subscribe(key, setState)
      if (queryParams.cursor_focus) {
        await queryManager.initWithFocus(queryParams)
      } else {
        await queryManager.init(queryParams)
      }
    }

    init()
    setHasInit(true)
  }, [hasInit, queryManager, queryParams])

  useEffect(() => {
    if (!queryParameterManager) return
    const key = genKey()
    queryParameterManager.subscribe(key, setQueryParams)
    return () => {
      queryParameterManager.unsubscribe(key)
    }
  }, [queryParameterManager])

  return (
    <LogDisplay
      logs={state?.result?.logs ?? []}
      newLogs={state?.result?.newLogs ?? []}
      shouldFocus={state?.result?.shouldFocus ?? false}
      focusedCursor={state?.request?.cursor_focus ?? null}
      term={queryParams.term}
      setTerm={handleSetTerm}
      queryMode={state?.mode ?? 'initial_load'}
      previousQueryMode={state?.previousMode ?? 'initial_load'}
      atStart={state?.result?.atStart ?? false}
      atEnd={state?.result?.atEnd ?? false}
      runSearch={handleRunSearch}
      viewContext={handleViewContext}
      live={isLive}
      setLive={handleSetIsLive}
      filters={queryParams.filters}
      setFilters={handleSetFilters}
      timeRange={queryParams.time_range}
      setTimeRange={handleSetTimeRange}
      total={state?.result?.count ?? 0}
      services={state?.result?.serviceOptions ?? []}
      levels={state?.result?.levelOptions ?? []}
      properties={state?.result?.properties ?? []}
      headers={headers}
      setHeaders={handleSetHeaders}
      toggleHeader={handleToggleHeader}
      logContainerRef={logContainerRef}
    />
  )
}

const getDefaultParams = (): QueryParameters => {
  let cursorFocus: LogCursor | undefined

  const urlParams = new URLSearchParams(window.location.search)
  const logId = urlParams.get('logId')
  const logTimestamp = urlParams.get('logTimestamp')
  if (logId && logTimestamp) {
    cursorFocus = { log_id: logId, timestamp: logTimestamp }
  }

  return {
    term: '',
    time_range: { start: undefined, end: undefined },
    filters: [],
    limit: 100,
    cursor_focus: cursorFocus,
    cursor_start: undefined,
    cursor_end: undefined,
    order_by: 'desc',
  }
}

export default Logs
