import { CodeSnippetDetails } from '../../../../library/CodeBlock/CodeBlock'
import PreviewLogs from '../../../../library/PreviewLogs/PreviewLogs'
import SetupTemplate from '../../../../library/SetupSDKTemplate/SetupSDKTemplate'
import { LinkKey } from '../../../../../hooks/nav/useNav'
import useSetBrandColor from '../../../../../hooks/utils/useSetBrandColor'

const SetupLogsRuby = () => {
  useSetBrandColor('info')

  return (
    <SetupTemplate
      title="Log Setup - Ruby"
      keyName="ruby-api-key"
      installStep={installSnippets}
      sendTitle="Send a log"
      sendEventStep={getSendMessageSnippets}
      preview={<PreviewLogs />}
      done={LinkKey.Logs}
    />
  )
}

const installSnippets: CodeSnippetDetails[] = [
  {
    title: 'bundle',
    language: 'bash',
    displayLanguage: 'bash',
    content: `bundle add vigilant-ruby`,
  },
  {
    title: 'gem',
    language: 'bash',
    displayLanguage: 'bash',
    content: `gem install vigilant-ruby`,
  },
]

function getSendMessageSnippets(key: string): CodeSnippetDetails[] {
  return [
    {
      title: 'Ruby',
      language: 'ruby',
      displayLanguage: 'ruby',
      content: `require 'vigilant-ruby'

# Initialize the logger
logger = Vigilant::Logger.new(
  name: "my-service",
  token: "${key}"
)

# Call enable() to start reading logs from the console
logger.autocapture_enable

# Autocapture directly reads puts statements
puts "User logged in"

# Call shutdown before application exit
logger.shutdown`,
    },
  ]
}

export default SetupLogsRuby
