import { cn } from '../../../utils/cn'
import Spinner from '../Spinner/Spinner'

interface CTAButtonProps {
  children: React.ReactNode
  disabled?: boolean
  loading?: boolean
  mode?: 'info' | 'warning' | 'error' | 'success' | 'default'
  type?: 'submit' | 'reset' | 'button'
  onClick?: () => void
  className?: string
}

const CTAButton = ({
  children,
  disabled = false,
  loading = false,
  mode,
  type = 'button',
  onClick,
  className,
}: CTAButtonProps) => {
  const bgColor = getBackgroundColor(mode, disabled)
  const bgHoverColor = disabled || loading ? '' : getBackgroundHoverColor(mode)
  const borderColor = getBorderColor(mode, disabled)
  const textColor = disabled ? 'text-text-1' : getTextColor(mode)
  const disabledClass = disabled ? 'opacity-60' : 'opacity-100'

  return (
    <button
      className={cn(
        'relative flex items-center justify-center py-2 px-6 rounded-[4px] text-small-light border',
        bgColor,
        borderColor,
        bgHoverColor,
        textColor,
        disabledClass,
        className,
      )}
      disabled={disabled || loading}
      type={type}
      onClick={onClick}
    >
      <div
        className={cn(
          'flex items-center gap-2',
          loading ? 'opacity-0' : 'opacity-100',
        )}
      >
        {children}
      </div>
      {loading && (
        <div className="top-0 left-0 bottom-0 right-0 absolute flex items-center justify-center">
          <Spinner mode={mode} />
        </div>
      )}
    </button>
  )
}

function getBackgroundColor(mode: CTAButtonProps['mode'], disabled: boolean) {
  switch (mode) {
    case 'default':
      return disabled ? 'bg-input-bgTint' : 'bg-input-bgTint'
    case 'info':
      return disabled ? 'bg-input-bgTint' : 'bg-brand-infoTint'
    case 'warning':
      return disabled ? 'bg-input-bgTint' : 'bg-brand-warningTint'
    case 'error':
      return disabled ? 'bg-input-bgTint' : 'bg-brand-errorTint'
    case 'success':
      return disabled ? 'bg-input-bgTint' : 'bg-brand-successTint'
    default:
      return disabled ? 'bg-input-bgTint' : 'bg-brand-ctaTint'
  }
}

function getBorderColor(mode: CTAButtonProps['mode'], disabled: boolean) {
  switch (mode) {
    case 'default':
      return disabled ? 'border-input-border' : 'border-input-border'
    case 'info':
      return disabled ? 'border-input-border' : 'border-brand-infoTint'
    case 'warning':
      return disabled ? 'border-input-border' : 'border-brand-warningTint'
    case 'error':
      return disabled ? 'border-input-border' : 'border-brand-errorTint'
    case 'success':
      return disabled ? 'border-input-border' : 'border-brand-successTint'
    default:
      return disabled ? 'border-input-border' : 'border-brand-ctaTint'
  }
}

function getBackgroundHoverColor(mode: CTAButtonProps['mode']) {
  switch (mode) {
    case 'default':
      return 'hover:bg-input-tint'
    case 'info':
      return 'hover:bg-brand-infoTint2'
    case 'warning':
      return 'hover:bg-brand-warningTint2'
    case 'error':
      return 'hover:bg-brand-errorTint2'
    case 'success':
      return 'hover:bg-brand-successTint2'
    default:
      return 'hover:bg-brand-ctaTint2'
  }
}

function getTextColor(mode: CTAButtonProps['mode']) {
  switch (mode) {
    case 'default':
      return 'text-text-1'
    case 'info':
      return 'text-brand-info'
    case 'warning':
      return 'text-brand-warning'
    case 'error':
      return 'text-brand-error'
    case 'success':
      return 'text-brand-success'
    default:
      return 'text-brand-cta'
  }
}

export default CTAButton
