import { useEffect } from 'react'
import useLoadAlertSettings from '../load/useLoadAlertSettings'

const useInitAlertSettings = () => {
  const loadAlertSettings = useLoadAlertSettings()

  useEffect(() => {
    loadAlertSettings()
  }, [loadAlertSettings])
}

export default useInitAlertSettings
